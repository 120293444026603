import { takeEvery, put, call } from "redux-saga/effects"

// Files Redux States
import {
  ADD_NEW_FILE,
  DELETE_FILE,
  GET_FILES,
  GET_FILE,
  UPDATE_FILE,
} from "./actionTypes"
import {
  getFilesSuccess,
  getFilesFail,
  getFileSuccess,
  getFileFail,
  addFileFail,
  addFileSuccess,
  updateFileSuccess,
  updateFileFail,
  deleteFileSuccess,
  deleteFileFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFiles,
  getFile,
  addNewFile,
  updateFile,
  deleteFile,
} from "../../helpers/api_back"

function* fetchFiles() {
  try {
    const response = yield call(getFiles)
    yield put(getFilesSuccess(response))
  } catch (error) {
    yield put(getFilesFail(error))
  }
}

function* fetchFile() {
  try {
    const response = yield call(getFile)
    yield put(getFileSuccess(response))
  } catch (error) {
    yield put(getFileFail(error))
  }
}


function* onAddNewFile({ payload: file }) {
 
  try {
    const response = yield call(addNewFile, file)
    
    yield put(addFileSuccess(response))
  } catch (error) {
    yield put(addFileFail(error))
  }
}

function* onUpdateFile({ payload: file }) {
  try {
    const response = yield call(updateFile, file)
    yield put(updateFileSuccess(response))
  } catch (error) {
    yield put(updateFileFail(error))
  }
}

function* onDeleteFile({ payload: file }) {
  try {
    const response = yield call(deleteFile, file)
    yield put(deleteFileSuccess(response))
  } catch (error) {
    yield put(deleteFileFail(error))
  }
}
 

function* filesSaga() {
  yield takeEvery(GET_FILES, fetchFiles)
  yield takeEvery(GET_FILE, fetchFile)
  yield takeEvery(ADD_NEW_FILE, onAddNewFile)
  yield takeEvery(UPDATE_FILE, onUpdateFile)
  yield takeEvery(DELETE_FILE, onDeleteFile) 
}

export default filesSaga
