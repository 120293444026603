import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
// Importez les fonctions de regroupement de tableau
import { groupBy } from 'lodash';



const NbVentesChart = () => {

  const ventes = useSelector((state) => ({  ventes: state.ventes.ventes }));
  //console.log("ventes",Object.values(ventes.ventes))

   // regroupement des ventes par produit,mois et par annéee
   const ventesParProduitEtMois = groupBy(Object.values(ventes.ventes), vente => {
       //console.log("vente.dateEnregistrement",vente)
       const date = new Date(vente.dateEnregistrement);
       //console.log('date',date)
       const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0
       //console.log('mois',mois)
       const annee = date.getFullYear();
       //console.log('annee',annee)
       // return `${vente.produit}-${mois}-${annee}`;
       return `${vente.produit}-${mois}-${annee}`;
   });
    
          const filteredVentes = Object.keys(ventesParProduitEtMois)
          .filter(key => {
            const anneePart = key.split('-')[2];
            return parseInt(anneePart, 10) === parseInt(localStorage.getItem('anneeEnCours'), 10);
          })
          .reduce((obj, key) => {
            obj[key] = ventesParProduitEtMois[key];
            return obj;
          }, {});

   // calucle le nombre de  ventes du par prduits,mois et années
   const nbVentesProduitsMois={}
   for (const key in filteredVentes) {
       const ventesGroupees = filteredVentes[key];
       //console.log("ventesParProduitEtMois",ventesParProduitEtMois)
      // console.log("ventesGroupees",ventesGroupees)
       //const quantite= ventesGroupees.reduce((acc, vente) => acc + vente.quantiteReel, 0);
       const nb= ventesGroupees.length;
       //console.log(`Nb ${key}: ${nb}`);
      //parseFloat(quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      nbVentesProduitsMois[key]=nb
     }
  
   /**** dermination des quandité par produit des differents mois de l'annee*/
   const essence=Object.keys(nbVentesProduitsMois).filter(key => key.startsWith("5")).map(key => ({ [key]: nbVentesProduitsMois[key] }));;
       //const esJuin = Object.values(essence).find(obj => Object.keys(obj)[0].includes('-6-'));
   const gazoil=Object.keys(nbVentesProduitsMois).filter(key => key.startsWith("4")).map(key => ({ [key]: nbVentesProduitsMois[key] }));;
    
   const essenceMoto=Object.keys(nbVentesProduitsMois).filter(key => key.startsWith("7")).map(key => ({ [key]: nbVentesProduitsMois[key] }));;
    

           const MoisValue=(ob,p)=>{
               const ObjetMois = ob.map(obj => {
                   const ancienneCle = Object.keys(obj)[0];
                   const mois = ancienneCle.split('-')[1]; // Obtient le mois
                   const valeur = Object.values(obj)[0];
                   
                   return { [mois]: valeur };
                 });

               const objetMoisRecherche = ObjetMois.find(obj => Object.keys(obj)[0] === p);

               if (objetMoisRecherche) {
               const valeurMoisRecherche = Object.values(objetMoisRecherche)[0];
               //console.log(`La valeur pour le mois ${6} est : ${valeurMoisRecherche}`);
               return parseInt(valeurMoisRecherche)
               } else {
               //console.log(`Aucun objet trouvé pour le mois ${6}`);
               return 0
               }

           }
           


   const state={   
    series: [{
      name: 'Gas-OiL',
      data: [MoisValue(gazoil,'1'), MoisValue(gazoil,'2') , MoisValue(gazoil,'3'), MoisValue(gazoil,'4'), MoisValue(gazoil,'5'),  MoisValue(gazoil,'6'), MoisValue(gazoil,'7'),MoisValue(gazoil,'8'),MoisValue(gazoil,'9'),MoisValue(gazoil,'10'),MoisValue(gazoil,'11'),MoisValue(gazoil,'12')]
    }, {
      name: 'Essence',
      data: [MoisValue(essence,'1'), MoisValue(essence,'2') , MoisValue(essence,'3'), MoisValue(essence,'4'), MoisValue(essence,'5'),  MoisValue(essence,'6'), MoisValue(essence,'7'),MoisValue(essence,'8'),MoisValue(essence,'9'),MoisValue(essence,'10'),MoisValue(essence,'11'),MoisValue(essence,'12')]
    }, {
      name: 'Essence Moto',
      data: [MoisValue(essenceMoto,'1'), MoisValue(essenceMoto,'2') , MoisValue(essenceMoto,'3'), MoisValue(essenceMoto,'4'), MoisValue(essenceMoto,'5'),  MoisValue(essenceMoto,'6'), MoisValue(essenceMoto,'7'),MoisValue(essenceMoto,'8'),MoisValue(essenceMoto,'9'),MoisValue(essenceMoto,'10'),MoisValue(essenceMoto,'11'),MoisValue(essenceMoto,'12')]
    }],
    
    options: {
      chart: {
        height: 200,
        type: 'area'
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'mois',
        categories: ["Jan","Fev", "Mars", "Avril","Mai", "Juin", "Juil", "Aout", "Sept", "Oct", "Nov", "Dec"],
         
        labels: {
          style: {
            rotate: -45 , // Angle d'inclinaison en degrés,
            color: "#F7387A"
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },
  
  
  };    
    
 
  
     
  return (
    <React.Fragment>
       <ReactApexChart options={state.options} series={state.series} type="area" width={470}  />
    </React.Fragment>
  );
};
export default NbVentesChart;
