import React from 'react'; 
import { Line } from 'react-chartjs-2';

const SalesChart = ({ fuelSalesData }) => {
  const chartData = {
    labels: fuelSalesData.map(entry => entry.month), // Tableau des mois
    datasets: [
      {
        label: 'Essence',
        data: fuelSalesData.map(entry => entry.essenceSales),
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
      },
      {
        label: 'Gasoil',
        data: fuelSalesData.map(entry => entry.gasoilSales),
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 2,
      },
      {
        label: 'Gaz',
        data: fuelSalesData.map(entry => entry.gazSales),
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
      },
    ],
  };

  return (
    <div>
      <Line data={chartData} options={{ maintainAspectRatio: false }} />
    </div>
  );
};

export default SalesChart;
