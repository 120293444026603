/* PARAMETRES */
export const GET_PARAMETRES = "GET_PARAMETRES";
export const GET_PARAMETRES_SUCCESS = "GET_PARAMETRES_SUCCESS";
export const GET_PARAMETRES_FAIL = "GET_PARAMETRES_FAIL";

export const GET_PARAMETRE = "GET_PARAMETRE";
export const GET_PARAMETRE_SUCCESS = "GET_PARAMETRE_SUCCESS";
export const GET_PARAMETRE_FAIL = "GET_PARAMETRE_FAIL";

export const ADD_NEW_PARAMETRE = "ADD_NEW_PARAMETRE";
export const ADD_PARAMETRE_SUCCESS = "ADD_PARAMETRE_SUCCESS";
export const ADD_PARAMETRE_FAIL = "ADD_PARAMETRE_FAIL";

export const UPDATE_PARAMETRE = "UPDATE_PARAMETRE";
export const UPDATE_PARAMETRE_SUCCESS = "UPDATE_PARAMETRE_SUCCESS";
export const UPDATE_PARAMETRE_FAIL = "UPDATE_PARAMETRE_FAIL";

export const DELETE_PARAMETRE = "DELETE_PARAMETRE";
export const DELETE_PARAMETRE_SUCCESS = "DELETE_PARAMETRE_SUCCESS";
export const DELETE_PARAMETRE_FAIL = "DELETE_PARAMETRE_FAIL";

 