import React from "react";
import { useSelector, useDispatch } from "react-redux";
//import RadialChart from "./RadialChart";

import { Card, CardBody, Col, Row } from "reactstrap";

import { SocialSourceData } from "../../CommonData/Data/index";
import NbVentesChart from "../Charts/NbVentesChart";
import { groupBy } from 'lodash';


const NbVentesLines = () => {
  
  const ventes = useSelector((state) => ({  ventes: state.ventes.ventes }));
  
   // regroupement des ventes par produit,mois et par annéee
   const ventesParProduitEtMois = groupBy(Object.values(ventes.ventes), vente => {
 
    const date = new Date(vente.dateEnregistrement);
 
    const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0
 
    const annee = date.getFullYear();
     
    return `${vente.produit}-${annee}`;
});
//console.log('ventes',Object.values(ventes.ventes).filter(p => p.produit ===5 && parseInt(p.createdAt.substring(0, 4))=== parseInt(localStorage.getItem('anneeEnCours'))).length)
  /**** dermination des quandité par produit des differents mois de l'annee*/
      const essence=Object.keys(ventesParProduitEtMois).filter(key => key.startsWith("5")).map(key => ({ [key]: ventesParProduitEtMois[key] })); 
    const gazoil=Object.keys(ventesParProduitEtMois).filter(key => key.startsWith("4")).map(key => ({ [key]: ventesParProduitEtMois[key] }));

    const essenceMoto=Object.keys(ventesParProduitEtMois).filter(key => key.startsWith("7")).map(key => ({ [key]: ventesParProduitEtMois[key] }));

    const ess= Object.values(essence)[0];
    const gazo= Object.values(gazoil)[0];
    const essMoto=Object.values(essenceMoto)[0];
  
 

//recalcule le nombre de vente 

    const nbVentes =(prod)=>{
      return Object.values(ventes.ventes).filter(p => p.produit ===prod && parseInt(p.createdAt.substring(0, 4))=== parseInt(localStorage.getItem('anneeEnCours'))).length
          
        
             /*  for (const cle in objet) {
                if (Array.isArray(objet[cle])) {
                  const tailleDuTableau = objet[cle].length;

                  
                return tailleDuTableau;
                 }
              } */
    }
    


  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">Nombre de Ventes</h5>
              </div>
              {/* <div className="flex-shrink-0">
                <select className="form-select form-select-sm mb-0 my-n1">
                  {[
                    "May",
                    "April",
                    "March",
                    "February",
                    "January",
                    "December",
                  ].map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            {/* RadialChart */}
            <NbVentesChart />
            <Row>
             
                <div  className="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span
                        className={
                          "avatar-title rounded-circle font-size-18 bg-" +
                          "primary"
                        }
                      >
                        <i className={" text-white"}></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">Essence</h5>
                    <p className="text-muted mb-0">{nbVentes(5)} Ventes</p>
                  </div>
                </div>

                <div  className="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span
                        className={
                          "avatar-title rounded-circle font-size-18 bg-" + "info"
                        }
                      >
                        <i className={" text-white"}></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">Gas-Oil</h5>
                    <p className="text-muted mb-0">{nbVentes(4)} Ventes</p>
                  </div>
                </div>

                <div  className="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span
                        className={
                          "avatar-title rounded-circle font-size-18 bg-" +"warning"
                        }
                      >
                        <i className={""+" text-white"}></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">Essence Monto</h5>
                    <p className="text-muted mb-0">{nbVentes(7)} Ventes</p>
                  </div>
                </div>
            
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default NbVentesLines;
