import {
  GET_STOCKS,
  GET_STOCKS_SUCCESS,
  GET_STOCKS_FAIL,
  GET_STOCK,
  GET_STOCK_FAIL,
  GET_STOCK_SUCCESS,
  ADD_NEW_STOCK,
  ADD_STOCK_SUCCESS,
  ADD_STOCK_FAIL,
  UPDATE_STOCK,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_FAIL,
  DELETE_STOCK,
  DELETE_STOCK_SUCCESS,
  DELETE_STOCK_FAIL,
} from "./actionTypes";

export const getStocks = () => ({
  type: GET_STOCKS,
});

export const getStocksSuccess = stocks => ({
  type: GET_STOCKS_SUCCESS,
  payload: stocks,
});

export const getStocksFail = error => ({
  type: GET_STOCKS_FAIL,
  payload: error,
});

export const getStock = () => ({
  type: GET_STOCK,
});

export const getStockSuccess = stock => ({
  type: GET_STOCK_SUCCESS,
  payload: stock,
});

export const getStockFail = error => ({
  type: GET_STOCK_FAIL,
  payload: error,
});

export const addNewStock = stock => ({
  type: ADD_NEW_STOCK,
  payload: stock,
});

export const addStockSuccess = stock => ({
  type: ADD_STOCK_SUCCESS,
  payload: stock,
});

export const addStockFail = error => ({
  type: ADD_STOCK_FAIL,
  payload: error,
  
});

export const updateStock = (st,stock) => ({
  type: UPDATE_STOCK,
  payload: { id: st.id, data: stock },
});

export const updateStockSuccess = stock => ({
  type: UPDATE_STOCK_SUCCESS,
  payload: stock,
});

export const updateStockFail = error => ({
  type: UPDATE_STOCK_FAIL,
  payload: error,
});

export const deleteStock = stock => ({
  type: DELETE_STOCK,
  payload: stock,
});

export const deleteStockSuccess = stock => ({
  type: DELETE_STOCK_SUCCESS,
  payload: stock,
});

export const deleteStockFail = error => ({
  type: DELETE_STOCK_FAIL,
  payload: error,
});



 
