import React, { useState, useEffect } from 'react'; 
import axios from 'axios';

import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import Select  from 'react-select';
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';
import PDFGenerator from '../../utilitaires/PDFGenerator'; 
 
// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //fichiers
    getFiles as onGetFiles,
 
  } from "../../store/actions"; 

  
 
const Bd = () => { 
const d=new Date();
const today = new Date().toISOString().split('T')[0];
 const dateDeb = new Date(d.setDate(d.getDate() -7)).toISOString().split('T')[0];


const [typMsg, setTypMsg] = useState('');
const [startDate, setStartDate] = useState(dateDeb);
const [endDate, setEndDate] = useState(today);

const [cat, setCat] = useState('');
const [deps, setDeps] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document)

//  const end = new Date(endDate );
//  end.setDate(end.getDate() + 1);  

   
const files = useSelector((state) => ({  files: state.files.files  })); 
const users = useSelector((state) => ({  users:  state.users.users  }));
console.log("files",files);

const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };

 

/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(files.files);

// useEffect(() => {
           
//     // Fonction de recherche
//     const searchData = () => {
//     const filtered =  Object.values(depenses.depenses).filter((item) => {
//         const itemDate = new Date(item.dateDep);
//         const start = new Date(startDate);
//         const end = new Date(endDate );
//         // Ajouter 1 jour à la date de fin
//         end.setDate(end.getDate() + 1);  
 

//         return     cat && deps  ? itemDate >= start && itemDate <= end && item.categorie===cat && item.id===deps
//                   : cat ? itemDate >= start && itemDate <= end && item.categorie===cat
//                   : deps ? itemDate >= start && itemDate <= end && item.id===deps
//                   : itemDate >= start && itemDate <= end       

                
//     });

//     setFilteredData(filtered); 
    
//     };

//     // Appeler la fonction de recherche lorsque les dates changent
//     searchData();
// }, [d,dateDeb,startDate, endDate,cat,deps,typMsg,onDeleteDepense,onUpdateDepense,onAddNewDepense]);



 

const dataTable= filteredData.length!==0 ? 
filteredData.map(file => {
 
   // const user = Object.values(users.users).find(us => us.id === dep.createdBy);
    return { ...file  };
  }) 
  : Object.values(files.files).map(file => {
 
    // const user = Object.values(users.users).find(us => us.id === dep.createdBy);
     return { ...file  };
   }) ;


const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (file) => {
    const value = file.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.name), _.toLower(value))||
      _.startsWith(_.toLower(item.size), _.toLower(value))||
      _.startsWith(_.toLower(item.date), _.toLower(value))||
      _.startsWith(_.toLower(item.author), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (dep, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);

 
//generer le pdf
const headText ='LISTE DES FICHIER '
const tableHead =[['N°','Fichier','Taille', 'Date', 'Auteur']]
const tableD=Object.values(dataTable).map((dps)=>{  
  return { ...dps  };})

 // const tableData= tableD.map((d, index) => [index+1, formatedDate(d.dateDep), d.categorie,d.libelle,parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);
 const tableData= tableD.map((d, index) => [index+1,]);



  //interdiction des valeurs non numerique
  const handleKeyDown = (e) => {
 
    const { key } = e;

   // console.log('key',key)

  // Interdire les caractères non numériques, sauf la touche Backspace
  // if (!/\d/.test(key)  && (key !== 'Backspace' || key !== '' || key !== 'Delete' || key.key !== '.'))
  if (!/\d/.test(key)  && key !== 'Backspace' ){
  
      if(key !== '.' ) {

        if(key !== 'Tab') {
          if(key !== 'ArrowRight'){
            if(key !== 'ArrowLeft') e.preventDefault() ;
          }
        }   
      } 

 }
};


const handleDownload = (filename) => {
    axios({
      url: `/api/files/${filename}/download`, // Remplacez l'URL par votre API de téléchargement des fichiers
      method: 'GET',
      responseType: 'blob', // Utilisez 'blob' pour les téléchargements de fichiers
    })
      .then((response) => {
        // Créer un lien temporaire pour le téléchargement du fichier
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a'); 
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error(error);
      });
  };


  dataTable.sort((a, b) => (a.date > b.date ? -1 : 1))

const currentPageData =  dataTable.length>0? dataTable.slice(offset, offset + PER_PAGE).map((file,index) =>  (
    <tr key={file.id}>
    <th scope="row">
        {/* <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idDepChecBoxSelected(file.id)}}   />
        </div> */}
    </th>
    <td className="dep_id" style={{ display: "none" }}>{file.id}</td>
    <td className="dep_index">{index+1}</td>
    <td className="dep_name">{file.name}</td> 
    <td className="dep_name">{file.size}</td> 
    <td className="dep_name">{formatedDate(file.date)}</td> 
    <td className="dep_name">{file.author}</td> 
     
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{ handleDownload(file.name)} }>Télécharger</button>
            </div>
            {/* <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={''}>Suppr.</button>
            </div> */}
        </div>
    </td>
</tr>


)) :'';

   useEffect(() => { 
        
        dispatch(onGetFiles());
        setFilteredData(Object.values(files.files))
    },[dispatch,typMsg]); //onAddNewDepense,onDeleteDepense,onUpdateDepense,Depenses

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Base de données" breadcrumbItem="Liste des fichiers de la base de données " />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h2 className="card-title mb-0"><i>Veuillez télécharger les fichiers de sauvegarde de la base données</i></h2>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                {/* <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();setMontant('')}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deleteDepense('');setListIdDep('')}}            
                                                   
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                    <span></span> 
                                                     <PDFGenerator tableData={tableData} tableHead={tableHead} headText={headText} />
                                                    

                                                </div>  */}
                                               
                                            </Col>
                                            
                                                     
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                       

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="customer_name" onClick={(f) => handleSort(f, 'id')}>N°</th>      
                                                        <th className="sort" data-sort="libelle" onClick={(f) => handleSort(f, 'libelle')}>Fichier</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(f) => handleSort(f, 'taille')}>Taille</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(f) => handleSort(f, 'date')}>Date</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(f) => handleSort(f, 'author')}>Auteur</th> 
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                             {/* <div style={{textAlign: 'left',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>FICHIERS: <span style={{color: '#377E47'}}>{filteredData.length}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(filteredData.reduce((acc, dp) => acc + dp.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span></h1></div> */}
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>
 
 
        </React.Fragment>
    );
};

export default Bd;
