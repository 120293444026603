import {
  GET_FOURNISSEURS,
  GET_FOURNISSEURS_SUCCESS,
  GET_FOURNISSEURS_FAIL,
  GET_FOURNISSEUR,
  GET_FOURNISSEUR_FAIL,
  GET_FOURNISSEUR_SUCCESS,
  ADD_NEW_FOURNISSEUR,
  ADD_FOURNISSEUR_SUCCESS,
  ADD_FOURNISSEUR_FAIL,
  UPDATE_FOURNISSEUR,
  UPDATE_FOURNISSEUR_SUCCESS,
  UPDATE_FOURNISSEUR_FAIL,
  DELETE_FOURNISSEUR,
  DELETE_FOURNISSEUR_SUCCESS,
  DELETE_FOURNISSEUR_FAIL,
} from "./actionTypes";

export const getFournisseurs = () => ({
  type: GET_FOURNISSEURS,
});

export const getFournisseursSuccess = fournisseurs => ({
  type: GET_FOURNISSEURS_SUCCESS,
  payload: fournisseurs,
});

export const getFournisseursFail = error => ({
  type: GET_FOURNISSEURS_FAIL,
  payload: error,
});

export const getFournisseur = () => ({
  type: GET_FOURNISSEUR,
});

export const getFournisseurSuccess = fournisseur => ({
  type: GET_FOURNISSEUR_SUCCESS,
  payload: fournisseur,
});

export const getFournisseurFail = error => ({
  type: GET_FOURNISSEUR_FAIL,
  payload: error,
});

export const addNewFournisseur = fournisseur => ({
  type: ADD_NEW_FOURNISSEUR,
  payload: fournisseur,
});

export const addFournisseurSuccess = fournisseur => ({
  type: ADD_FOURNISSEUR_SUCCESS,
  payload: fournisseur,
});

export const addFournisseurFail = error => ({
  type: ADD_FOURNISSEUR_FAIL,
  payload: error,
  
});

export const updateFournisseur =  (frs,fournisseur) => ({
  type: UPDATE_FOURNISSEUR,
  payload: { id: frs, data: fournisseur },
});

export const updateFournisseurSuccess = (frs,fournisseur) => ({
  type: UPDATE_FOURNISSEUR_SUCCESS,
  payload: { id: frs, data: fournisseur },
});

export const updateFournisseurFail = error => ({
  type: UPDATE_FOURNISSEUR_FAIL,
  payload: error,
});

export const deleteFournisseur = fournisseur => ({
  type: DELETE_FOURNISSEUR,
  payload: fournisseur,
});

export const deleteFournisseurSuccess = fournisseur => ({
  type: DELETE_FOURNISSEUR_SUCCESS,
  payload: fournisseur,
});

export const deleteFournisseurFail = error => ({
  type: DELETE_FOURNISSEUR_FAIL,
  payload: error,
});



 
