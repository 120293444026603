import {
  GET_DEPENSES_SUCCESS,
  GET_DEPENSES_FAIL,
  GET_DEPENSE_SUCCESS,
  GET_DEPENSE_FAIL,
  ADD_DEPENSE_SUCCESS,
  ADD_DEPENSE_FAIL,
  UPDATE_DEPENSE_SUCCESS,
  UPDATE_DEPENSE_FAIL,
  DELETE_DEPENSE_SUCCESS,
  DELETE_DEPENSE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  depenses: [],
  error: {},
};

const Depenses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPENSES_SUCCESS:
      return {
        ...state,
        depenses: action.payload,
      };

    case GET_DEPENSES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DEPENSE_SUCCESS:
      return {
        ...state,
        depense: action.payload,
      };
  
    case GET_DEPENSE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_DEPENSE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        depenses: [...state.depenses, action.payload],
      };

    case ADD_DEPENSE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_DEPENSE_SUCCESS:
      return {
        ...state,
        depenses: state.depenses.map(depense =>
          depense.id.toString() === action.payload.id.toString()
            ? { depense, ...action.payload }
            : depense
        ),
        isDepenseUpdated: true
      };

    case UPDATE_DEPENSE_FAIL:
      return {
        ...state,
        error: action.payload,
        isDepenseUpdated: false
      };

    case DELETE_DEPENSE_SUCCESS:
      return {
        ...state,
        depenses: state.depenses.filter(
          depense => depense.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DEPENSE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Depenses;
