export const GET_PRODUITS = "GET_PRODUITS";
export const GET_PRODUITS_SUCCESS = "GET_PRODUITS_SUCCESS";
export const GET_PRODUITS_FAIL = "GET_PRODUITS_FAIL";

export const GET_PRODUIT = "GET_PRODUIT";
export const GET_PRODUIT_SUCCESS = "GET_PRODUIT_SUCCESS";
export const GET_PRODUIT_FAIL = "GET_PRODUIT_FAIL";

export const ADD_NEW_PRODUIT = "ADD_NEW_PRODUIT";
export const ADD_PRODUIT_SUCCESS = "ADD_PRODUIT_SUCCESS";
export const ADD_PRODUIT_FAIL = "ADD_PRODUIT_FAIL";

export const UPDATE_PRODUIT = "UPDATE_PRODUIT";
export const UPDATE_PRODUIT_SUCCESS = "UPDATE_PRODUIT_SUCCESS";
export const UPDATE_PRODUIT_FAIL = "UPDATE_PRODUIT_FAIL";

export const DELETE_PRODUIT = "DELETE_PRODUIT";
export const DELETE_PRODUIT_SUCCESS = "DELETE_PRODUIT_SUCCESS";
export const DELETE_PRODUIT_FAIL = "DELETE_PRODUIT_FAIL";
