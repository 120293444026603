import { takeEvery, put, call } from "redux-saga/effects"

// Cuves Redux States
import {
  ADD_NEW_CUVE,
  DELETE_CUVE,
  GET_CUVES,
  GET_CUVE,
  UPDATE_CUVE,
} from "./actionTypes"
import {
  getCuvesSuccess,
  getCuvesFail,
  getCuveSuccess,
  getCuveFail,
  addCuveFail,
  addCuveSuccess,
  updateCuveSuccess,
  updateCuveFail,
  deleteCuveSuccess,
  deleteCuveFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCuves,
  getCuve,
  addNewCuve,
  updateCuve,
  deleteCuve,
} from "../../helpers/api_back"

function* fetchCuves() {
  try {
    const response = yield call(getCuves)
    yield put(getCuvesSuccess(response))
  } catch (error) {
    yield put(getCuvesFail(error))
  }
}

function* fetchCuve() {
  try {
    const response = yield call(getCuve)
    yield put(getCuveSuccess(response))
  } catch (error) {
    yield put(getCuveFail(error))
  }
}


function* onAddNewCuve({ payload: cuve }) {
 
  try {
    const response = yield call(addNewCuve, cuve)
    
    yield put(addCuveSuccess(response))
  } catch (error) {
    yield put(addCuveFail(error))
  }
}

function* onUpdateCuve({ payload: cuve }) {
  try {
    const response = yield call(updateCuve, cuve)
    yield put(updateCuveSuccess(response))
  } catch (error) {
    yield put(updateCuveFail(error))
  }
}

function* onDeleteCuve({ payload: cuve }) {
  try {
    const response = yield call(deleteCuve, cuve)
    yield put(deleteCuveSuccess(response))
  } catch (error) {
    yield put(deleteCuveFail(error))
  }
}
 

function* cuvesSaga() {
  yield takeEvery(GET_CUVES, fetchCuves)
  yield takeEvery(GET_CUVE, fetchCuve)
  yield takeEvery(ADD_NEW_CUVE, onAddNewCuve)
  yield takeEvery(UPDATE_CUVE, onUpdateCuve)
  yield takeEvery(DELETE_CUVE, onDeleteCuve) 
}

export default cuvesSaga
