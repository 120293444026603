import {
  GET_SALAIRES_SUCCESS,
  GET_SALAIRES_FAIL,
  GET_SALAIRE_SUCCESS,
  GET_SALAIRE_FAIL,
  ADD_SALAIRE_SUCCESS,
  ADD_SALAIRE_FAIL,
  UPDATE_SALAIRE_SUCCESS,
  UPDATE_SALAIRE_FAIL,
  DELETE_SALAIRE_SUCCESS,
  DELETE_SALAIRE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  salaires: [],
  error: {},
};

const Salaires = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SALAIRES_SUCCESS:
      return {
        ...state,
        salaires: action.payload,
      };

    case GET_SALAIRES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_SALAIRE_SUCCESS:
      return {
        ...state,
        salaire: action.payload,
      };
  
    case GET_SALAIRE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_SALAIRE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        salaires: [...state.salaires, action.payload],
      };

    case ADD_SALAIRE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_SALAIRE_SUCCESS:
      return {
        ...state,
        salaires: state.salaires.map(salaire =>
          salaire.id.toString() === action.payload.id.toString()
            ? { salaire, ...action.payload }
            : salaire
        ),
        isSalaireUpdated: true
      };

    case UPDATE_SALAIRE_FAIL:
      return {
        ...state,
        error: action.payload,
        isSalaireUpdated: false
      };

    case DELETE_SALAIRE_SUCCESS:
      return {
        ...state,
        salaires: state.salaires.filter(
          salaire => salaire.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_SALAIRE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Salaires;
