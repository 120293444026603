/* EQUIPES */
export const GET_EQUIPES = "GET_EQUIPES";
export const GET_EQUIPES_SUCCESS = "GET_EQUIPES_SUCCESS";
export const GET_EQUIPES_FAIL = "GET_EQUIPES_FAIL";

export const GET_EQUIPE = "GET_EQUIPE";
export const GET_EQUIPE_SUCCESS = "GET_EQUIPE_SUCCESS";
export const GET_EQUIPE_FAIL = "GET_EQUIPE_FAIL";

export const ADD_NEW_EQUIPE = "ADD_NEW_EQUIPE";
export const ADD_EQUIPE_SUCCESS = "ADD_EQUIPE_SUCCESS";
export const ADD_EQUIPE_FAIL = "ADD_EQUIPE_FAIL";

export const UPDATE_EQUIPE = "UPDATE_EQUIPE";
export const UPDATE_EQUIPE_SUCCESS = "UPDATE_EQUIPE_SUCCESS";
export const UPDATE_EQUIPE_FAIL = "UPDATE_EQUIPE_FAIL";

export const DELETE_EQUIPE = "DELETE_EQUIPE";
export const DELETE_EQUIPE_SUCCESS = "DELETE_EQUIPE_SUCCESS";
export const DELETE_EQUIPE_FAIL = "DELETE_EQUIPE_FAIL";

 