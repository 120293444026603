import { takeEvery, put, call } from "redux-saga/effects"

// Stocks Redux States
import {
  ADD_NEW_STOCK,
  DELETE_STOCK,
  GET_STOCKS,
  GET_STOCK,
  UPDATE_STOCK,
} from "./actionTypes"
import {
  getStocksSuccess,
  getStocksFail,
  getStockSuccess,
  getStockFail,
  addStockFail,
  addStockSuccess,
  updateStockSuccess,
  updateStockFail,
  deleteStockSuccess,
  deleteStockFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStocks,
  getStock,
  addNewStock,
  updateStock,
  deleteStock,
} from "../../helpers/api_back"

function* fetchStocks() {
  try {
    const response = yield call(getStocks)
    yield put(getStocksSuccess(response))
  } catch (error) {
    yield put(getStocksFail(error))
  }
}

function* fetchStock() {
  try {
    const response = yield call(getStock)
    yield put(getStockSuccess(response))
  } catch (error) {
    yield put(getStockFail(error))
  }
}


function* onAddNewStock({ payload: stock }) {
 
  try {
    const response = yield call(addNewStock, stock)
    
    yield put(addStockSuccess(response))
  } catch (error) {
    yield put(addStockFail(error))
  }
}

function* onUpdateStock({ payload: stock }) {
  try {
    const response = yield call(updateStock, stock)
    yield put(updateStockSuccess(response))
  } catch (error) {
    yield put(updateStockFail(error))
  }
}

function* onDeleteStock({ payload: stock }) {
  try {
    const response = yield call(deleteStock, stock)
    yield put(deleteStockSuccess(response))
  } catch (error) {
    yield put(deleteStockFail(error))
  }
}
 

function* stocksSaga() {
  yield takeEvery(GET_STOCKS, fetchStocks)
  yield takeEvery(GET_STOCK, fetchStock)
  yield takeEvery(ADD_NEW_STOCK, onAddNewStock)
  yield takeEvery(UPDATE_STOCK, onUpdateStock)
  yield takeEvery(DELETE_STOCK, onDeleteStock) 
}

export default stocksSaga
