import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Row,Button } from "reactstrap";

import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";


import {
 
  //produits 
  getProduits as onGetProduits,
  getUsers as onGetUsers,
  getAgents as onGetAgents,
  getPompes as onGetPompes,
  getVentes as onGetVentes,
  getCuves as onGetCuves,
  
} from "../../store/actions";

import  StockCercle from '../Dashboard/StockCercle'

const FirstCirclesPanel = () => {
  const dispatch = useDispatch();

  


  const produits = useSelector((state) => ({  produits: state.produits.produits  }));
  const agents = useSelector((state) => ({  agents: state.agents.agents  }));
  const pompes = useSelector((state) => ({  pompes: state.pompes.pompes  }));
  const cuves = useSelector((state) => ({  cuves: state.cuves.cuves  }));
  const users = useSelector((state) => ({  users: state.users.users  }));
  //const utilisateurs = useSelector((state) => ({  produits: state.utilisateurs.utilisateurs  }));

  useEffect(() => { 
    dispatch(onGetProduits());
   // dispatch(onGetUtilisateurs());
    dispatch(onGetAgents());
    dispatch(onGetPompes());
    dispatch(onGetCuves());
    dispatch(onGetUsers());
     
},[dispatch]); //onAddNewProduit,onDeleteProduit,onUpdateProduit,produits


const [visibleProducts, setVisibleProducts] = useState(3);

   // Fonction pour afficher tous les produits
   const showMore = (i) => {
    if(i==3){
      setVisibleProducts(3);
    }else{
      setVisibleProducts(produits.length);
    }
  };



/// je comptes les produits par categories 
const prodCategoriesCounts = Object.values(produits.produits).reduce((counts, produit) => {
  const { categorie } = produit;
  if (!counts[categorie]) {
    counts[categorie] = 0;
  }
  counts[categorie]++;
  return counts;
}, {});

/// je comptes les agents par categories 
const agentCategoriesCounts = Object.values(agents.agents).reduce((counts, agent) => {
  const { role } = agent;
  if (!counts[role]) {
    counts[role] = 0;
  }
  counts[role]++;
  return counts;
}, {});

// console.log('prodCategoriesCounts',prodCategoriesCounts)
// console.log('utilisateur nb',users.users)
  
  return (
    <React.Fragment >
      <Row>
      {produits.produits ? produits.produits.slice(0, visibleProducts).map((produit,index) => (
       
       <Col xl={3} sm={6} key={produit.id} >
        <Card style= {produit.stockProd===produit.stockMin? {backgroundColor:"#F1FFCE" }:produit.stockProd<produit.stockMin?{backgroundColor:"#FFE6E3" }: {backgroundColor:"#CEF0FF" }}>
          <CardBody>
            <div className="d-flex text-muted" >
              {/* <div className="flex-shrink-0 me-3 align-self-center">
                <div id="radialchart-1" className="apex-charts" dir="ltr">
                  <RadialChart1 nb={((produit.stockProd * 100)/produit.stockMin)} />
                </div>
              </div> */}

              <div className="flex-grow-1 overflow-hidden">
                <p className="mb-1">{produit.nomProd}</p>
                {/* <h5 className="mb-3" style= {produit.stockProd<produit.stockMin?{color:"#FF0616"}:{color:""}}>{parseFloat(produit.stockProd).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} { produit.stockVente? '      En Vente: ' +parseFloat(produit.stockVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '):''}</h5>
                <h5 className="mb-3" style= {produit.stockProd<produit.stockMin?{color:"#FF0616"}:{color:""}}>{parseFloat(produit.stockProd).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} { produit.stockVente? '      En Vente: ' +parseFloat(produit.stockVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '):''}</h5>
                */}
                <div className="d-flex align-items-center mb-3">
                  <h5 style={produit.stockProd < produit.stockMin ? { color: "#FF0616" } : { color: "" }}>
                    {parseFloat(produit.stockProd).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                  </h5>
                  
                   { produit.categorie!==1 ? <h5 className="ms-3" style={produit.stockVente < produit.stockMin ? { color: "#E89656" } : { color: "#21AB49" }}>{' En Vente: ' + parseFloat(produit.stockVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} </h5> : ''}
                 
                </div>

                <p className="text-truncate mb-0">
                  <span className="text-success me-2">
                    {" "}
                    {parseFloat(produit.stockMin).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{" "}
                    {produit.stockProd<produit.stockMin? <i className=" mdi mdi-call-received align-bottom ms-1" style={{color:"#FF0616"}}></i>:<i className="mdi mdi-call-made align-bottom ms-1"></i> }
                    
                  </span>{" "}
                  stock de sécurité
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
     

       )):''}
       {produits.produits ? produits.produits.map((produit, index) => (
  // Vérifier si l'index est égal à 2
  index === 2 ?  ( 
    <Col xl={3} sm={6} key={produit.id + 100}>
      <StockCercle />
    </Col>  
    

  )  : null
)) : ''}  

{produits.produits.length  ? 
         <Button color="warning"  size="sm"  outline   /* style={{ width: '80px' }} */ onClick={(e=>{visibleProducts==3?showMore(6):showMore(3)})}>
                   {visibleProducts==3?'Voir plus de produits': 'Reduire' } 
                   </Button>  :''}

 
       
  
            
      </Row>
    </React.Fragment>
  );
};

export default FirstCirclesPanel;
