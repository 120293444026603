import {
  GET_MODEREMBOURSEMENTS_SUCCESS,
  GET_MODEREMBOURSEMENTS_FAIL,
  GET_MODEREMBOURSEMENT_SUCCESS,
  GET_MODEREMBOURSEMENT_FAIL,
  ADD_MODEREMBOURSEMENT_SUCCESS,
  ADD_MODEREMBOURSEMENT_FAIL,
  UPDATE_MODEREMBOURSEMENT_SUCCESS,
  UPDATE_MODEREMBOURSEMENT_FAIL,
  DELETE_MODEREMBOURSEMENT_SUCCESS,
  DELETE_MODEREMBOURSEMENT_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  modeRemboursements: [],
  error: {},
};

const ModeRemboursements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODEREMBOURSEMENTS_SUCCESS:
      return {
        ...state,
        modeRemboursements: action.payload,
      };

    case GET_MODEREMBOURSEMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_MODEREMBOURSEMENT_SUCCESS:
      return {
        ...state,
        modeRemboursement: action.payload,
      };
  
    case GET_MODEREMBOURSEMENT_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_MODEREMBOURSEMENT_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        modeRemboursements: [...state.modeRemboursements, action.payload],
      };

    case ADD_MODEREMBOURSEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MODEREMBOURSEMENT_SUCCESS:
      return {
        ...state,
        modeRemboursements: state.modeRemboursements.map(modeRemboursement =>
          modeRemboursement.id.toString() === action.payload.id.toString()
            ? { modeRemboursement, ...action.payload }
            : modeRemboursement
        ),
        isModeRemboursementUpdated: true
      };

    case UPDATE_MODEREMBOURSEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isModeRemboursementUpdated: false
      };

    case DELETE_MODEREMBOURSEMENT_SUCCESS:
      return {
        ...state,
        modeRemboursements: state.modeRemboursements.filter(
          modeRemboursement => modeRemboursement.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_MODEREMBOURSEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default ModeRemboursements;
