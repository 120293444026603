import { takeEvery, put, call } from "redux-saga/effects"

// Approvisionnements Redux States
import {
  ADD_NEW_APPROVISIONNEMENT,
  DELETE_APPROVISIONNEMENT,
  GET_APPROVISIONNEMENTS,
  GET_APPROVISIONNEMENT,
  UPDATE_APPROVISIONNEMENT,
} from "./actionTypes"
import { 
  getApprovisionnementsSuccess,
  getApprovisionnementsFail,
  getApprovisionnementSuccess,
  getApprovisionnementFail,
  addApprovisionnementFail,
  addApprovisionnementSuccess,
  updateApprovisionnementSuccess,
  updateApprovisionnementFail,
  deleteApprovisionnementSuccess,
  deleteApprovisionnementFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getApprovisionnements,
  getApprovisionnement,
  addNewApprovisionnement,
  updateApprovisionnement,
  deleteApprovisionnement,
} from "../../helpers/api_back"

function* fetchApprovisionnements() {
  try {
    const response = yield call(getApprovisionnements)
    yield put(getApprovisionnementsSuccess(response))
  } catch (error) {
    yield put(getApprovisionnementsFail(error))
  }
}

function* fetchApprovisionnement() {
  try {
    const response = yield call(getApprovisionnement)
    yield put(getApprovisionnementSuccess(response))
  } catch (error) {
    yield put(getApprovisionnementFail(error))
  }
}


function* onAddNewApprovisionnement({ payload: approvisionnement }) {
 
  try {
    const response = yield call(addNewApprovisionnement, approvisionnement)
    
    yield put(addApprovisionnementSuccess(response))
  } catch (error) {
    yield put(addApprovisionnementFail(error))
  }
}

function* onUpdateApprovisionnement({ payload: approvisionnement }) {
  try {
    const response = yield call(updateApprovisionnement, approvisionnement)
    yield put(updateApprovisionnementSuccess(response))
  } catch (error) {
    yield put(updateApprovisionnementFail(error))
  }
}

function* onDeleteApprovisionnement({ payload: approvisionnement }) {
  try {
    const response = yield call(deleteApprovisionnement, approvisionnement)
    yield put(deleteApprovisionnementSuccess(response))
  } catch (error) {
    yield put(deleteApprovisionnementFail(error))
  }
}
 

function* approvisionnementsSaga() {
  yield takeEvery(GET_APPROVISIONNEMENTS, fetchApprovisionnements)
  yield takeEvery(GET_APPROVISIONNEMENT, fetchApprovisionnement)
  yield takeEvery(ADD_NEW_APPROVISIONNEMENT, onAddNewApprovisionnement)
  yield takeEvery(UPDATE_APPROVISIONNEMENT, onUpdateApprovisionnement)
  yield takeEvery(DELETE_APPROVISIONNEMENT, onDeleteApprovisionnement) 
}

export default approvisionnementsSaga
