import React, { useState, useEffect } from 'react'; 
 
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import Select  from 'react-select';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import { FaBlackTie, FaEdit, FaTrash } from 'react-icons/fa';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



 
import { saveAs } from 'file-saver';
import PizZip from "pizzip";
 

// pdf 
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";




 
import PDFGenerator from '../../utilitaires/PDFGenerator'; 
import WordGenerator from '../../utilitaires/WordGenerator';
import generateUniqueId from '../../utilitaires/generateurID'; 
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //equipes
    getEquipes as onGetEquipes,
    
    //users

    getUsers as onGetUsers,

    //categories
    getCategories as onGetCategories,

   

    //Produits
    getProduits as onGetProduits,

   //Pompes
    getPompes as onGetPompes,


    //cuves
    getCuves as onGetCuves,

     //Agents
     getAgents as onGetAgents,


    //Ventes
    addNewVente as onAddNewVente,
    deleteVente as onDeleteVente,
    getVentes as onGetVentes,
    getVente as onGetVente,
    updateVente as onUpdateVente,
  } from "../../store/actions"; 

  
 
const Ventes = () => { 
const today = new Date();
const todayEnregDate = new Date().toISOString().split('T')[0];
const userConn = JSON.parse(localStorage.getItem("authUser")).utilisateur;

 
const [validateButtonDisabled, setValidateButtonDisabled] = useState(true);
const [vte, setVte] = useState('');
const [agnt, setAgnt] = useState('');
const [prod, setProd] = useState('');
const [pompe, setPompe] = useState('');

const [vntes, setVntes] = useState([]);

const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();
 
 
  ///dispatch(onGetEquipes()); 
   
 

const startNewVente =()=>{
  setVte(today.getTime())
 //setVte(generateUniqueId)
  setDateEnregistrement(todayEnregDate)
  setVntes([])
  setAgnt(userConn.role==='admin' ? '': userConn.agent)

} 
 



/// Enregistrements d'un vente
const [produit, setProduit] = useState('');
const [indexDepart, setIndexDepart] = useState('');
const [indexArrivee, setIndexArrivee] = useState('');
const [quantite, setQuantite] = useState('');
const [quantiteReel, setQuantiteReel] = useState('');
const [retourCuve, setRetourCuve] = useState('');
const [prixAchat, setPrixAchat] = useState('');
const [prixVente, setPrixVente] = useState('');
const [montant, setMontant] = useState('');
const [montantVerse,setMontantVerse]=useState('');
const [montantEcart,setMontantEcart]=useState('');
const [agent, setagent] = useState('');
const [equipe, setEquipe] = useState('');
const [dateEnregistrement,setDateEnregistrement] = useState(todayEnregDate) 

const [idVnte, setIdVnte] = useState('');

useEffect(() => { 
  dispatch(onGetEquipes());
  dispatch(onGetProduits());
  dispatch(onGetAgents());
  dispatch(onGetPompes());
  dispatch(onGetCuves());
  dispatch(onGetCategories());
 
   dispatch(onGetUsers());
   dispatch(onGetVentes());
 
},[dispatch,vntes,vte,dateEnregistrement,indexArrivee,indexDepart,retourCuve,agnt,pompe]);

 


//const equipess = useSelector((state) =>{console.log('state',state)});
//const [equipes, setEquipes] = useState()
const equipes = useSelector((state) =>({  equipes:  state.equipes.equipes  }));
const cuves = useSelector((state) => ({  cuves:  state.cuves.cuves  }));
const produits = useSelector((state) => ({  produits:  Object.values(state.produits.produits).filter((p)=>{
  const cuve = Object.values(cuves.cuves).find(cuv => cuv.produit === p.id);
   return cuve ? p.id === cuve.produit :false;
  })}));
const agents = useSelector((state) => ({  agents:  Object.values(state.agents.agents).filter(a=>a.actif==1)  }));
const pompes = useSelector((state) => ({pompes: state.pompes}));
const users = useSelector((state) => ({  users:  state.users.users  }));


/// option  pour afficher la liste des produits et la liste des agents
//const [agntOptions,setAgntOptions]=useState([])
const [agntOptions2,setAgntOptions2]=useState()
const numV=vte.toString() ;


 


const agentsEq = Object.values(agents.agents).map((agt) => { 
  const equipe = Object.values(equipes.equipes).find(eq=> eq.id === agt.equipe);
 
 return { ...agt , equipeInfo:equipe? equipe.libelle:'' };   
});


const agntOptions = Object.values(agentsEq).map(agnt =>  ({
  value:agnt.id, label:'👷🏽'+agnt.nom+" "+agnt.prenom + ' 👥 '+ agnt.equipeInfo 
 }))

//  const agntOptions = Object.values(agents.agents).map(agnt =>  ({
//   value:agnt.id, label:'👷🏽'+agnt.nom+" "+agnt.prenom + ' 👥 '+Object.values(equipes.equipes).find(eq => eq.id === agnt.equipe).libelle 
//  }))
  


const prodOptFiltered=Object.values(produits.produits).filter((prod) =>{
    return !vntes.some((selected) => selected.produit === 'prod.id' 
    )
});

const prodOptions=prodOptFiltered.map(prod => ({
    value:prod.id, label:prod.nomProd
}))

//etape 1 , determination des cuves contenant le produti seectionner 
const cuvesProd = Object.values(cuves.cuves).filter(cuv=> cuv.produit===prod);
 

 

const pompeCuveProd = Object.values(pompes.pompes.pompes).filter((pmp) => {
    const cuvePompe = cuvesProd.find(cuv => cuv.id === pmp.cuve);
   // console.log("cuvePompe" ,cuvePompe)
    return cuvePompe ? (pmp.cuve = cuvePompe.id) : false;
  });

 

// filtrer les pompes par rapport aux cuves et au produits
const pompeOptFiltered=Object.values(pompeCuveProd).filter((pmp) =>{
    return !vntes.some((selected) => selected.pompe === pmp.id )
});

 
const pompeOptions=pompeOptFiltered
.filter(pmpe => pmpe.actif==1)
.map(pomp => ({
    value:pomp.id, label:pomp.libelle
}))
//console.log('vntes prod',vntes)
 
 
// fonction pour ajouter un nouveau produit
const handleAddVente = (agt,prod,pomp) => {
    //const agent = Object.values(agents.agents).find(p => p.id === agnt);
    const produit = Object.values(produits.produits).find(p => p.id === prod);
   const pompe = Object.values(pompes.pompes.pompes).find(p => p.id === pomp); 
   const agent = Object.values(agents.agents).find(ag => ag.id === agt); 
   
    const newVente = {
    // id: vntes.length + 1,
      numVente: vte,
      agent: agt,
      equipe:agent.equipe,
      produit: prod,
      pompe:pomp,
      indexDepart:pompe?pompe.indexArrivee:'',
      indexArrivee:'',
      retourCuve:'',
      prixVente:pompe.prixVente>0 ? pompe.prixVente:produit.prixVente,
      quantite:indexArrivee-indexDepart, 
      quantiteReel:(indexArrivee-indexDepart)-retourCuve,
      montant: quantiteReel*prixVente,
      montantVerse:'',
      montantEcart:0,
      dateEnregistrement:dateEnregistrement,
    };
    setVntes([...vntes, newVente]);
    
};  

 //console.log('vntes', vntes);

/// lorsque l'utilisateur choisi un produit
const handleChangeProd =(prod)=>{
    setProd(prod.value)
    const produit=Object.values(produits.produits).find(pd => pd.id === prod.value);
    if(produit.stockProd<=produit.stockMin)  setIsMsgOpen(true);
   
   // 
   
  // if(!prodExistCuve)  handleAddVente(agnt,prod.value,'')
}

/// lorsque l'utilisateur choisi une pompe
const handleChangePompe =(pmp)=>{
   // console.log('ajout agnt,prod,pmp',agnt,prod,pmp.value)
   setPompe(pmp.value)
   handleAddVente(agnt,prod,pmp.value)
}

 
///lorsque l'utilisateur choisi un agent
const handleChangeAgnt =(agnt)=>{ 
  //console.log('vntes chg agnt',vntes.length)
  if(vntes.length>=1){dispatch(onAddNewVente(vntes));/*setVte('')*/;setTypMsg('add')};
    setAgnt(agnt.value)
    //if(!vte) setVntes([])
    setVntes([])
    

    }



    const handleGoBack = () => {
      // Fonction pour revenir à la page précédente
      window.history.back();
    };

    
  //impression d ecran
    const handlePrint = () => {
      const content = document.getElementById('content');
  
      html2canvas(content).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('landscape');

        const contentWidth = canvas.width;
      const contentHeight = canvas.height;

      // Calculer les proportions pour adapter l'image au PDF
      const ratio = contentWidth / contentHeight;
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdfWidth / ratio;


        pdf.addImage(imgData, 'PNG', 0, 0,pdfWidth, pdfHeight);
        pdf.save('capture.png');
      });
    };
 
 //message popup
 const [isMsgOpen, setIsMsgOpen] = useState(false);

 const openMsgPopup = () => {
 // console.log('kone',isMsgOpen)
  setIsMsgOpen(true);
};
const closeMsgPopup = () => {
  setIsMsgOpen(false);
};


/* faire clignoté le champ incorrecte */
const [isBlinking, setIsBlinking] = useState(false);

useEffect(() => {
  const interval = setInterval(() => {
    setIsBlinking(prevIsBlinking => !prevIsBlinking);
    
  }, 500); // 500ms pour la durée d'un clignotement, ajustez selon vos besoins
  
  return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage du composant
}, []);





// Les alerts d'ajout ,modification et suppression
function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 6000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
      
    ) : typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    



const newVente = {
    produit: produit,
    quantite:quantite,
    quantiteReel:quantiteReel,
    prixAchat:prixAchat,
    prixVente:prixVente,
    retourCuve:retourCuve,
    dateEnregistrement:dateEnregistrement,
    montant:montant,
    montantVerse:montantVerse>0?montantVerse:montant,
    montantEcart:montantEcart,
    agent:agent,
    equipe:'',
};


const saveNewVente = (e) => {
    e.preventDefault(); 
    setTypMsg('add')
    dispatch(onAddNewVente(newVente));
    console.log('newVente',newVente)
    
    setmodal_list(false);
    
 
  };

  // Mise a jour d"un Vente 
  const upVente = { 
    produit: produit,
    quantite:quantite,
    quantiteReel:quantiteReel,
    prixAchat:prixAchat,
    prixVente:prixVente,
    retourCuve:retourCuve,
    dateEnregistrement:dateEnregistrement,
    montant:montant,
    montantVerse:montantVerse,
    agent:agent,
    equipe:equipe,
  };

  const saveUpdateVente = (e) => {
    e.preventDefault(); 
    // console.log("upVente",upVente)
    // console.log("idVnte",idVnte)
    setTypMsg('upd')
    dispatch(onUpdateVente(idVnte,upVente));
    setmodal_update(false);
    
  };


  /// Suppression d'un Vente
 

  const [listIdVnte, setListIdVnte] = useState([]);

  const idVnteChecBoxSelected = (vnteId) => {
    setListIdVnte(prevSelectedRows => ({
      ...prevSelectedRows,
      [vnteId]: !prevSelectedRows[vnteId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdVnte)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const vnteIds = Object.keys(filteredList);
 
  const deleteVente = ( vente) => { 
    setTypMsg('del')
    if(vente)dispatch(onDeleteVente(vente));
    if(listIdVnte && vnteIds){vnteIds.forEach((vnteId) =>dispatch(onDeleteVente(vnteId)))}
    setTypMsg('del')

  };
      


 


/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(vntes);

const dataTable= filteredData.length!==0 ? 
filteredData.map(vnte => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === vnte.categorie);
    //console.log('xcate',categorie)
    const agent = Object.values(agents.agents).find(p => p.id === vnte.agent);
    const produit = Object.values(produits.produits).find(p => p.id === vnte.produit);
    const pompe = Object.values(pompes.pompes.pompes).find(p => p.id === vnte.pompe);
    const equipe = Object.values(equipes.equipes).find(eq=> eq.id === vnte.equipe);
     const user = Object.values(users.users).find(us => us.id === vnte.createdBy);
    return { ...vnte , equipeInfo:equipe? equipe.libelle:'',agentInfo:agent?agent.prenom+' '+agent.nom :'', pompeInfo:pompe? pompe.libelle:'', userInfo:user? user.prenom+' '+user.nom:'',produitInfo:produit? produit.nomProd:'' ,produitStock:produit? produit.stockProd:''};
  }) 
  : 
  vntes.map(vnte => {
    //const produit = Object.values(produits.produits).find(prod => prod.id === vnte.produit);
    ///const user = Object.values(users.users).find(us => us.id === vnte.createdBy);
    const agent = Object.values(agents.agents).find(p => p.id === vnte.agent);
    const produit = Object.values(produits.produits).find(p => p.id === vnte.produit);
    const pompe = Object.values(pompes.pompes.pompes).find(p => p.id === vnte.pompe);
     const user = Object.values(users.users).find(us => us.id === vnte.createdBy);
     const equipe = Object.values(equipes.equipes).find(eq=> eq.id === vnte.equipe);
     return { ...vnte , equipeInfo:equipe? equipe.libelle:'', agentInfo:agent?agent.prenom+' '+agent.nom :'', pompeInfo:pompe? pompe.libelle:'', userInfo:user? user.prenom+' '+user.nom:'',produitInfo:produit? produit.nomProd:'' ,produitStock:produit? produit.stockProd:''};
    });
//,categorievnte:categorie.libelle

 






const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (vnte) => {
    const value = vnte.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.produit), _.toLower(value))||
      _.startsWith(_.toLower(item.agent), _.toLower(value))||
      _.startsWith(_.toLower(item.quantite), _.toLower(value))||
      _.startsWith(_.toLower(item.quantiteReel), _.toLower(value))||
      _.startsWith(_.toLower(item.montantVerse), _.toLower(value))||
      _.startsWith(_.toLower(item.prixAchat), _.toLower(value))||
      _.startsWith(_.toLower(item.prixVente), _.toLower(value))||
      _.startsWith(_.toLower(item.dateEnregistrement), _.toLower(value))||
      _.startsWith(_.toLower(item.montantEcart), _.toLower(value))||
      _.startsWith(_.toLower(item.retourCuve), _.toLower(value))||
      _.startsWith(_.toLower(item.montant), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (vnte, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


// lorsque l'utilisateur decide de modifier ou de supprimer un element de la table 
const handleEdit = (id, property, value) => {
    const index = vntes.findIndex((vte) => vte.numVente+''+vte.agent+''+vte.produit+''+vte.pompe === id);

    const vnte = vntes[index];
    const quantite = property==='quantite' ?  value : vnte.quantite; // récupère la valeur actuelle de quantite
    const quantiteReel = property==='quantiteReel' ?  value : vnte.quantiteReel; // récupère la valeur actuelle de quantiteReelle
    const prixAchat = property==='prixAchat' ?  value : vnte.prixAchat; // récupère la valeur actuelle de prixAchat  
    const prixVente = property==='prixVente' ?  value : vnte.prixVente; // récupère la valeur actuelle de prixVente   
    const indexDepart = property==='indexDepart' ?  value : vnte.indexDepart; // récupère la valeur actuelle de indexDepart   
    const indexArrivee = property==='indexArrivee' ? indexDepart<=value ?   value : 0 :vnte.indexArrivee; // récupère la valeur actuelle de indexArrivee 
    //montantVerse= property==='indexArrivee' ? montant: 0;
    const retourCuve = property==='retourCuve' ?  value : vnte.retourCuve; // récupère la valeur actuelle de retourCuve  
    const montantVerse= property==='montantVerse' ?  value : vnte.montantVerse;
    const montantEcart= property==='montantEcart' ?  value : vnte.montantEcart;
    if(Object.values(produits.produits).find(p => p.id === vnte.produit).stockProd <Math.abs(indexArrivee-indexDepart)) setValidateButtonDisabled(true);
    // console.log('prod stock',Object.values(produits.produits).find(p => p.id === vnte.produit).stockProd,'qute',Math.abs(indexArrivee-indexDepart))
    // console.log('verif',  Object.values(produits.produits).find(p => p.id === vnte.produit).stockProd <Math.abs(indexArrivee-indexDepart))
    
    const updatedVnte = { ...vntes[index], [property]: value, quantite: indexArrivee <=0 ? 0: Math.abs(indexArrivee-indexDepart), 
      quantiteReel:indexArrivee <=0 ? 0: Math.abs(indexArrivee-indexDepart)-retourCuve, montant:indexArrivee <=0 ? 0: (Math.abs(indexArrivee-indexDepart)-retourCuve)*prixVente,
      montantEcart:indexArrivee <=0 ? 0:( (Math.abs(indexArrivee-indexDepart)-retourCuve)*prixVente)-montantVerse 
    };
    const updatedVntes = [
      ...vntes.slice(0, index),
      updatedVnte,
      ...vntes.slice(index + 1)
    ];
    setVntes(updatedVntes);
   
    //if(validateButtonDisabled===true && vntes  && updatedVntes[0].indexArrivee) { setValidateButtonDisabled(false)}
    updatedVntes.map(v =>{
      Object.values(produits.produits).find(p => p.id === v.produit).stockProd <v.quantite? setValidateButtonDisabled(true):setValidateButtonDisabled(false);
    
    })
    
  };

  const handleDelete = (id) => {
    const updatedVntes = vntes.filter((vnte) => vnte.numVente+ '' + vnte.agent+ '' + vnte.produit+ '' + vnte.pompe  !== id);
    setVntes(updatedVntes);
  ///  console.log('updatedVntes',updatedVntes.length)
    if(updatedVntes.length===0) { setValidateButtonDisabled(true)}
  };
 
///maping des donnees pour pdf 
  const headText ='LISTE DES VENTES N° '+vte 
  const tableHead =[['N°','Vente', 'Agent', 'Produit','Pompe','Index Départ', 'Index Arrivee','Quantité','Retour Cuve','Quantite Reelle','Prix Vente','Montant']]
  const tableD=dataTable.map((vt)=>{ 
    const agent = Object.values(agents.agents).find(ag => ag.id === vt.agent);
    const produit = Object.values(produits.produits).find(prod=> prod.id === vt.produit);
    const equipe = Object.values(equipes.equipes).find(eq=> eq.id === vt.equipe);
    const pompe = Object.values(pompes.pompes.pompes).find(pp => pp.id === vt.pompe);
    return { ...vt,agent: agent? agent.prenom+' '+agent.nom :'',produit: produit? produit.nomProd:'',pompe: pompe? pompe.libelle :'' };})

    const tableData= tableD.map((d, index) => [index+1, d.numVente, d.agent, d.produit,d.pompe,parseFloat(d.indexDepart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.indexArrivee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.retourCuve).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.quantiteReel).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);




    ///formater chiffres
    const formatNumber = (value) => {
        const sanitizedValue = value.replace(/\s/g, ''); // Supprimer tous les espaces
       /// let formattedValue = '';
    
        
        const formattedAmount = new Intl.NumberFormat('fr-FR').format(value);
        ///const formattedAmount = new Intl.NumberFormat('fr-FR').format(amount);
        /// console.log('formattedAmount avc .',formattedAmount)
        return sanitizedValue.toLocaleString('fr-FR', { minimumFractionDigits: 2 });
      };


   
//interdiction des valeurs non numerique
      const handleKeyDown = (e) => {
 
          const { key } = e;

         // console.log('key',key)
    
        // Interdire les caractères non numériques, sauf la touche Backspace
        // if (!/\d/.test(key)  && (key !== 'Backspace' || key !== '' || key !== 'Delete' || key.key !== '.'))
        if (!/\d/.test(key)  && key !== 'Backspace' ){
        
            if(key !== '.' ) {

              if(key !== 'Tab') {
                if(key !== 'ArrowRight'){
                  if(key !== 'ArrowLeft') e.preventDefault() ;
                }
              }   
            } 

       }
      };

// const mt=1245566934.47
      
// console.log('toLocaleString',mt.toLocaleString('fr-FR', { minimumFractionDigits: 2 }))

const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((vnte,index) =>  (
    
    <tr key={vnte.numVente+ '' + vnte.agent+ '' + vnte.produit+ '' + vnte.pompe }  style= { vnte.produitStock<vnte.quantite
      ? {backgroundColor:"#A6A437",color:"#FFFF"}:{backgroundColor:"#0076A8",color:"#FFFF"}}>
        
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idVnteChecBoxSelected(vnte.numVnte)}}   />
        </div>
    </th>
    <td className="vnte_id" style={{ display: "none" }}>{vnte.numVnte}</td>
    <td className="vnte_index">{index+1}</td>
    <td className="vnte_name">{vnte.agentInfo }</td>
    <td className="vnte_name">{vnte.equipeInfo }</td>
    <td className="vnte_name">{vnte.produitInfo }</td>
    <td className="vnte_name">{vnte.pompeInfo}</td>
    {/* <td  className="vnte_name">{vnte.quantite}</td> */}
   
    <td> <Input  type="text"  style= {{backgroundColor:"#8C160D",color:"#FFFF"}}
             value={ parseFloat(vnte.indexDepart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            // onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe  , 'indexDepart', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>

    <td> <Input  type="text"    style= {{backgroundColor:"#8C160D",color:"#FFFF"}}
             value={vnte.indexArrivee.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe , 'indexArrivee', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
              
    </td>

   
    <td>
      { vnte.indexArrivee.length>0 && vnte.indexArrivee<vnte.indexDepart  ? <span style= {{backgroundColor:"#00034F",color:"#FFFD55"}}>ATTENTION !  Index Arrivée inférieur à l'index de départ</span> :
    <Input  type="text"  style= {Object.values(produits.produits).find(p => p.id === vnte.produit).stockProd <vnte.quantite ?{backgroundColor: isBlinking ? 'green' : "#6887B0",color:"#FFFF"}:{backgroundColor:"#8C160D",color:"#FFFF"}}
             

             value={parseFloat(vnte.quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            //  onBlur={vnte.indexArrivee && vnte.indexArrivee<vnte.indexDepart ? openMsgPopup :''} 
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe  , 'quantite', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              /> 
              }
    </td>

    <td> <Input type="text"     style= {{color:"black"}} 
             value={vnte.retourCuve.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe , 'retourCuve', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>

    <td> <Input  type="text"  style= {{backgroundColor:"#000E94",color:"#FFFF"}}
             value={parseFloat(vnte.quantiteReel).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe  , 'quantiteReel', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>

  
 
    <td> <Input type="text"  style= {{color:"black"}}    
             value={parseFloat(vnte.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
             disabled ={userConn.role==='admin'? false:true}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe, 'prixVente', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>
    
    <td> <Input type="text"   style= {{color:"black"}} 
             value={parseFloat(vnte.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe, 'montant', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>   
    <td> <Input type="text"    style= {{backgroundColor:"#8C160D",color:"#FFFF"}}
             value={vnte.montantVerse.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe, 'montantVerse', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>    

    <td> <Input type="text"   style= {{color:"black"}} 
             value={parseFloat(vnte.montantEcart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe, 'montantEcart', (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>     
    {/* <td className="vnte_name">{vnte.categorie ? Object.values(categories.categories).find(cat=>cat.id==vnte.categorie).libelle:''}</td> */}
    {/* <td className="vnte_date">{ vnte.user}</td>
    <td className="vnte_utilisateur">{formatedDate(vnte.createdAt)}</td> */}
    <td>
        <div className="d-flex gap-2">
            {/* <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setProduit(vnte.produit);setagent(vnte.agent);setQuantite(vnte.quantite);setPrixAchat(vnte.prixAchat);setMontant(vnte.montant);setIdvnte(vnte.numvnte);tog_update()} }>Modifier</button>
            </div> */}
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> handleDelete(vnte.numVente+''+vnte.agent+''+vnte.produit+''+vnte.pompe)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));


   
  // gener word
//   const WordGenerator = () => { 
//     useEffect(() => {
//   const generateDocument = async () => {
//     const templatePath = '../../utilitaires/exemple1.docx'; // Spécifiez le chemin vers votre modèle Word
//     const response = await fetch(templatePath);
//     const content = await response.arrayBuffer();
//     const doc = new Docxtemplater(content);

//     const data = {
//       title: 'Mon document Word généré',
//       content: 'Contenu du document...',
//     };
//     doc.setData(data);

//     doc.render();
//     const generatedDocument = doc.getZip().generate({ type: 'blob' });
//     saveAs(generatedDocument, 'generated.docx'); // Nommez votre fichier généré ici
//   };

//   generateDocument();
// }, [vntes,dataTable,produit,agent,pompe]);

// return <div>Manipulation du document Word en cours...</div>;
// };

    
     

    return (
        <React.Fragment>
            <div className="page-content" id="content">
              
                <Container fluid>
                 
                    
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                  <Breadcrumbs title="Vente" breadcrumbItem="Liste des Ventes" />
               

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Enregister une vente</h4>
                                  
                                </CardHeader>
                                {vte  ? 
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                        <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                 <Button disabled={validateButtonDisabled} color="success" size="lg"  className="add-btn" onClick={() => {dispatch(onAddNewVente(vntes));setVte('');setTypMsg('add');}} id="create-btn">
                                                         Valider</Button>
                                                    {/* <Button color="info" size="lg" onClick={handlePrint}   
                                                    >Imp.Ecran</Button>   */}
                                                    {/* <Button color="success"></Button> */}
                                                    <PDFGenerator tableData={tableData} tableHead={tableHead} headText={headText} />
                                                    
                                                    {/* <Button size="lg"  onClick={'genererWord'}>Générer Word</Button> */}
                                                    {/* <WordGenerator /> */}
                                                    
                                                    <h1 style={{color: '#FFFFFF',backgroundColor:"#000369"}}>N°:</h1>
                                                    <h1 style={{color: '#ED1C24',backgroundColor:"#000369"}}>{vte}</h1>
                                                </div> 
                                            </Col>      
                                              
                                             <Col className="col-sm-auto">
                                                    <div  style={{ display: 'flex', alignItems: 'center', marginTop: '-5px'}}>
                                                        <h1 style={{ marginRight: '10px' }}>DATE:</h1>
                                                        <input
                                                            type="date"
                                                            style={{ color: '#EB7524', backgroundColor: '#00023D',fontSize:20 ,fontStyle:'bold'}}
                                                            disabled ={userConn.role==='admin'? false:true}
                                                            value={dateEnregistrement}
                                                            className="form-control search"
                                                            placeholder="Date d'enregistrment..."
                                                            onChange={(e) => {setDateEnregistrement(e.target.value); 
                                                                if(vntes){  const updatedVentes = vntes.map((vt) => ({ ...vt, dateEnregistrement:e.target.value }));
                                                                setVntes(updatedVentes);  }}}
                                                        />
                                                    </div>
                                                </Col>
                                                                                       
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                {/* <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div> */}
                                                 <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", }}>INTERFACE VENTE</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <label htmlFor="agntSelect">Sélectionner un agent</label>
                                            <Select options={ agntOptions }    onChange={handleChangeAgnt} /> 
                                            </Col>
                                            <Col>
                                            <label htmlFor="agntSelect">Sélectionner un produit</label>
                                            <Select options={agnt? prodOptions:''}    onChange={handleChangeProd}/>
                                            </Col>
                                            <Col>
                                            <label htmlFor="agntSelect">Sélectionner une pompe</label>
                                            <Select options={agnt? pompeOptions:''}    onChange={handleChangePompe}/>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray', width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="vnte_id" onClick={(vnte) => handleSort(vnte, 'id')}>N°</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="agent" onClick={(vnte) => handleSort(vnte, 'agent')}>👷🏽Agent</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="agent" onClick={(vnte) => handleSort(vnte, 'equipe')}>👥 Equipe</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="produit" onClick={(vnte) => handleSort(vnte, 'produit')}>🌊 Produit</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="pompe" onClick={(vnte) => handleSort(vnte, 'pompe')}>📮Pompe</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="indexDepart" onClick={(vnte) => handleSort(vnte, 'indexDepart')}>indice Depart</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="indexArrivee" onClick={(vnte) => handleSort(vnte, 'indexArrivee')}>indice Arrivee</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="quantite" onClick={(vnte) => handleSort(vnte, 'quantite')}>Quantite</th>
                                                        
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="retourCuve" onClick={(vnte) => handleSort(vnte, 'retourCuve')}>R.Cuve</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="quantiteReel" onClick={(vnte) => handleSort(vnte, 'quantiteReel')}>QTE sortie</th>
                                        
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="prixVente" onClick={(vnte) => handleSort(vnte, 'prixVente')}>prixVente</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="montant" onClick={(vnte) => handleSort(vnte, 'montant')}>Montant Reel</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="montantVerse" onClick={(vnte) => handleSort(vnte, 'montantVerse')}>Montant Versé</th>
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}} className="sort" data-sort="montantEcart" onClick={(vnte) => handleSort(vnte, 'montantEcart')}>Montant Manquant</th>
                                                        
                                                        {/* <th className="sort" data-sort="createdBy" onClick={(vnte) => handleSort(vnte, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(vnte) => handleSort(vnte, 'createdAt')}>Date Création</th> */}
                                                    
                                                        <th style={{backgroundColor:'#003952', color:'#5DCACC',border: '1px solid gray'}}  className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                {vte && vntes ? <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody> :'' }
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                
                                                />
                                                <div style={{textAlign: 'right',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>POMPES: <span style={{color: '#377E47'}}>{vntes.length}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(vntes.reduce((acc, vente) => acc + vente.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span></h1></div>
                                               
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                                : <div><Button color="primary"  onClick={startNewVente}>NOUVELLE VENTE</Button> {userConn.role==='admin'  ? <Button color="secondary" tag={Link}  to="/ventesHistoriques">HISTORIQUE VENTE</Button>:""}</div>} 
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>

                
            </div>
            
            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Vente </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewVente}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="produit" className="form-label">produit</label>
                            <input type="text" id="produit" className="form-control" placeholder="Enter produit" required  onChange={(e) => setProduit(e.target.value)}/>
                            <label htmlFor="quantite" className="form-label">quantite</label>
                            <input type="number" id="quantite" className="form-control" placeholder="Enter quantite" required  onChange={(e) => setQuantite(e.target.value)}/>
                            <label htmlFor="prixAchat" className="form-label">prixAchat</label>
                            <input type="number" id="prixAchat" className="form-control" placeholder="Enter prixAchat" required  onChange={(e) => setPrixAchat(e.target.value)}/>
                            <label htmlFor="motant" className="form-label">quantite</label>
                            <input type="number" id="montant" className="form-control" placeholder="Enter montant" required  onChange={(e) => setMontant(e.target.value)}/>
                            <label htmlFor="Agent" className="form-label">Agent</label>
                            <input type="text" id="Agent" className="form-control" placeholder="Enter Agent" required  onChange={(e) => setAgnt(e.target.value)}/>
                            {/* <label htmlFor="categorie" className="form-label">categorie</label>
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setCategorievnte(e.target.value)}  defaultValue="0">
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup> */}

                        
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewVente}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Vente </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateVente}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="produit" className="form-label">Produit</label>
                            <input type="text" id="produit" className="form-control" placeholder="Enter produit" required  value={produit} onChange={(event) => setProduit(event.target.value)}/>
                            <label htmlFor="quantite" className="form-label">Quantite</label>
                            <input type="decimal" id="quantite" className="form-control" placeholder="quantite" required   value={quantite}   onChange={(event) => setQuantite(event.target.value)}/>
                            <label htmlFor="prixAchat" className="form-label">prixAchat</label>
                            <input type="decimal" id="prixAchat" className="form-control" placeholder="prixAchat" required   value={prixAchat}   onChange={(event) => setPrixAchat(event.target.value)}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                            <input type="decimal" id="montant" className="form-control" placeholder="montant" required   value={montant}   onChange={(event) => setMontant(event.target.value)}/>
                            <label htmlFor="montantVerse" className="form-label">Montant Verse</label>
                            <input type="decimal" id="montantVerse" className="form-control" placeholder="montantVerse" required   value={montantVerse}   onChange={(event) => setMontantVerse(event.target.value)}/>
                            <label htmlFor="montantEcart" className="form-label">MontantEcart</label>
                            <input type="decimal" id="montantEcart" className="form-control" placeholder="montant Ecart" required   value={montantEcart}   onChange={(event) => setMontantEcart(event.target.value)}/>
                             
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateVente}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
                <Modal isOpen={isMsgOpen} toggle={closeMsgPopup} centered  >
                      <ModalHeader >Alerte: <span style={{color:'#235911' ,textAlign:'center', fontSize:'35px'}}>{prod ?  Object.values(produits.produits).find(pd => pd.id === prod).nomProd :''} </span></ModalHeader>
                      <span style={{color:'#EB3324' ,textAlign:'center', fontSize:'20px'}}>Le stock de sécurité atteint</span> 
                      <ModalBody >
                      <h3 style={{backgroundColor:'#ECEDD9',color:'#EB3324' ,textAlign:'center',fontSize:'18px'}}>stock actuel : {prod ? parseFloat( Object.values(produits.produits).find(pd => pd.id === prod).stockProd).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')  :''} <br/> stock de sécurité : { prod ?  parseFloat( Object.values(produits.produits).find(pd => pd.id === prod).stockMin).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') :''}</h3>
                    
                      </ModalBody>
                    <ModalFooter>  <Button onClick={closeMsgPopup}>❌ </Button></ModalFooter> 
                </Modal>
        </React.Fragment>
    );
};

export default Ventes;
