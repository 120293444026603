import { takeEvery, put, call } from "redux-saga/effects"

// DepenseCategories Redux States
import {
  ADD_NEW_DEPENSE_CATEGORIE,
  DELETE_DEPENSE_CATEGORIE,
  GET_DEPENSE_CATEGORIES,
  GET_DEPENSE_CATEGORIE,
  UPDATE_DEPENSE_CATEGORIE,
} from "./actionTypes"
import {
  getDepenseCategoriesSuccess,
  getDepenseCategoriesFail,
  getDepenseCategorieSuccess,
  getDepenseCategorieFail,
  addDepenseCategorieFail,
  addDepenseCategorieSuccess,
  updateDepenseCategorieSuccess,
  updateDepenseCategorieFail,
  deleteDepenseCategorieSuccess,
  deleteDepenseCategorieFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDepenseCategories,
  getDepenseCategorie,
  addNewDepenseCategorie,
  updateDepenseCategorie,
  deleteDepenseCategorie,
} from "../../helpers/api_back"

function* fetchDepenseCategories() {
  try {
    const response = yield call(getDepenseCategories)
    yield put(getDepenseCategoriesSuccess(response))
  } catch (error) {
    yield put(getDepenseCategoriesFail(error))
  }
}

function* fetchDepenseCategorie() {
  try {
    const response = yield call(getDepenseCategorie)
    yield put(getDepenseCategorieSuccess(response))
  } catch (error) {
    yield put(getDepenseCategorieFail(error))
  }
}


function* onAddNewDepenseCategorie({ payload: depenseDepenseCategorie }) {
 
  try {
    const response = yield call(addNewDepenseCategorie, depenseDepenseCategorie)
    
    yield put(addDepenseCategorieSuccess(response))
  } catch (error) {
    yield put(addDepenseCategorieFail(error))
  }
}

function* onUpdateDepenseCategorie({ payload: depenseDepenseCategorie }) {
  try {
    const response = yield call(updateDepenseCategorie, depenseDepenseCategorie)
    yield put(updateDepenseCategorieSuccess(response))
  } catch (error) {
    yield put(updateDepenseCategorieFail(error))
  }
}

function* onDeleteDepenseCategorie({ payload: depenseDepenseCategorie }) {
  try {
    const response = yield call(deleteDepenseCategorie, depenseDepenseCategorie)
    yield put(deleteDepenseCategorieSuccess(response))
  } catch (error) {
    yield put(deleteDepenseCategorieFail(error))
  }
}
 

function* depenseDepenseCategoriesSaga() {
  yield takeEvery(GET_DEPENSE_CATEGORIES, fetchDepenseCategories)
  yield takeEvery(GET_DEPENSE_CATEGORIE, fetchDepenseCategorie)
  yield takeEvery(ADD_NEW_DEPENSE_CATEGORIE, onAddNewDepenseCategorie)
  yield takeEvery(UPDATE_DEPENSE_CATEGORIE, onUpdateDepenseCategorie)
  yield takeEvery(DELETE_DEPENSE_CATEGORIE, onDeleteDepenseCategorie) 
}

export default depenseDepenseCategoriesSaga
