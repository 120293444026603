import {
  GET_FOURNISSEURS_SUCCESS,
  GET_FOURNISSEURS_FAIL,
  GET_FOURNISSEUR_SUCCESS,
  GET_FOURNISSEUR_FAIL,
  ADD_FOURNISSEUR_SUCCESS,
  ADD_FOURNISSEUR_FAIL,
  UPDATE_FOURNISSEUR_SUCCESS,
  UPDATE_FOURNISSEUR_FAIL,
  DELETE_FOURNISSEUR_SUCCESS,
  DELETE_FOURNISSEUR_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  fournisseurs: [],
  error: {},
};

const Fournisseurs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FOURNISSEURS_SUCCESS:
      return {
        ...state,
        fournisseurs: action.payload,
      };

    case GET_FOURNISSEURS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_FOURNISSEUR_SUCCESS:
      return {
        ...state,
        fournisseur: action.payload,
      };
  
    case GET_FOURNISSEUR_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_FOURNISSEUR_SUCCESS: 
      return {
        ...state,
        fournisseurs: [...state.fournisseurs, action.payload],
      };

    case ADD_FOURNISSEUR_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_FOURNISSEUR_SUCCESS:
      return {
        ...state,
        fournisseurs: state.fournisseurs.map(fournisseur =>
          fournisseur.id.toString() === action.payload.id.toString()
            ? { fournisseur, ...action.payload }
            : fournisseur
        ),
        isFournisseurUpdated: true
      };

    case UPDATE_FOURNISSEUR_FAIL:
      return {
        ...state,
        error: action.payload,
        isFournisseurUpdated: false
      };

    case DELETE_FOURNISSEUR_SUCCESS:
      return {
        ...state,
        fournisseurs: state.fournisseurs.filter(
          fournisseur => fournisseur.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_FOURNISSEUR_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Fournisseurs;
