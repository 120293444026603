/* APPROVISIONNEMENTS */
export const GET_SALAIRES = "GET_SALAIRES";
export const GET_SALAIRES_SUCCESS = "GET_SALAIRES_SUCCESS";
export const GET_SALAIRES_FAIL = "GET_SALAIRES_FAIL";

export const GET_SALAIRE = "GET_SALAIRE";
export const GET_SALAIRE_SUCCESS = "GET_SALAIRE_SUCCESS";
export const GET_SALAIRE_FAIL = "GET_SALAIRE_FAIL";

export const ADD_NEW_SALAIRE = "ADD_NEW_SALAIRE";
export const ADD_SALAIRE_SUCCESS = "ADD_SALAIRE_SUCCESS";
export const ADD_SALAIRE_FAIL = "ADD_SALAIRE_FAIL";

export const UPDATE_SALAIRE = "UPDATE_SALAIRE";
export const UPDATE_SALAIRE_SUCCESS = "UPDATE_SALAIRE_SUCCESS";
export const UPDATE_SALAIRE_FAIL = "UPDATE_SALAIRE_FAIL";

export const DELETE_SALAIRE = "DELETE_SALAIRE";
export const DELETE_SALAIRE_SUCCESS = "DELETE_SALAIRE_SUCCESS";
export const DELETE_SALAIRE_FAIL = "DELETE_SALAIRE_FAIL";

 