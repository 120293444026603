import {
  GET_FILES,
  GET_FILES_SUCCESS,
  GET_FILES_FAIL,
  GET_FILE,
  GET_FILE_FAIL,
  GET_FILE_SUCCESS,
  ADD_NEW_FILE,
  ADD_FILE_SUCCESS,
  ADD_FILE_FAIL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAIL,
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAIL,
} from "./actionTypes";

export const getFiles = () => ({
  type: GET_FILES,
});

export const getFilesSuccess = files => ({
  type: GET_FILES_SUCCESS,
  payload: files,
});

export const getFilesFail = error => ({
  type: GET_FILES_FAIL,
  payload: error,
});

export const getFile = () => ({
  type: GET_FILE,
});

export const getFileSuccess = file => ({
  type: GET_FILE_SUCCESS,
  payload: file,
});

export const getFileFail = error => ({
  type: GET_FILE_FAIL,
  payload: error,
});

export const addNewFile = file => ({
  type: ADD_NEW_FILE,
  payload: file,
});

export const addFileSuccess = file => ({
  type: ADD_FILE_SUCCESS,
  payload: file,
});

export const addFileFail = error => ({
  type: ADD_FILE_FAIL,
  payload: error,
  
});

export const updateFile = (f,file) => ({
  type: UPDATE_FILE,
  payload: { id: f, data: file },
});

export const updateFileSuccess = file => ({
  type: UPDATE_FILE_SUCCESS,
  payload: file,
});

export const updateFileFail = error => ({
  type: UPDATE_FILE_FAIL,
  payload: error,
});

export const deleteFile = file => ({
  type: DELETE_FILE,
  payload: file,
});

export const deleteFileSuccess = file => ({
  type: DELETE_FILE_SUCCESS,
  payload: file,
});

export const deleteFileFail = error => ({
  type: DELETE_FILE_FAIL,
  payload: error,
});



 
