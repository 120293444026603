import React, { useState, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';

import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,InputGroup,Input } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //Categorie depenses
    addNewDepenseSousCategorie as onAddNewSousCategorie,
    deleteDepenseSousCategorie as onDeleteSousCategorie,
    getDepenseSousCategories as onGetSousCategories,
    getDepenseSousCategorie as onGetSousCategorie,
    updateDepenseSousCategorie as onUpdateSousCategorie,
    getDepenseCategories as onGetCategories,

    // //produits
    // addNewProduit as onAddNewProduit,
    // deleteProduit as onDeleteProduit,
    // getProduits as onGetProduits,
    // getProduit as onGetProduit,
    // updateProduit as onUpdateProduit,
  } from "../../store/actions"; 

  
 
const CategoriesSousDepenses = () => {
const [showMsg, setShowMsg] = useState(false);
const [typMsg, setTypMsg] = useState('');
//  const tmp='2023-04-19T00:00:02.043Z'
//     console.log('formatedDate',formatedDate(tmp))
const dispatch = useDispatch();
 
   
const sousCategories = useSelector((state) => ({  sousCategories: state.depenseSousCategories.depenseSousCategories  }));
const categories = useSelector((state) => ({  categories: state.depenseCategories.depenseCategories  }));
const users = useSelector((state) => ({  users:  state.users.users  }));

console.log('sousCategories',sousCategories)

const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };


function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

  


// const mappedData = Object.values(categories).map(cat => {
//     console.log("users info",users)
//     const user = Object.values(users).find(us => us.id === cat.createdBy);
//     console.log("user",user);
//     return { ...cat, user: user.prenom+' '+user.nom  };
//   });

 
 

   /// const cats = useSelector((state) => state.categories); //bon

 

    //   const { events, categories, isEventUpdated } = useSelector((state) => ({
    //     events: state.calendar.events,
    //     categories: state.calendar.categories,
    //     isEventUpdated: state.calendar.isEventUpdated
    //   }));

    useEffect(() => { 
        dispatch(onGetSousCategories());
        dispatch(onGetCategories());
        dispatch(onGetUsers());
        setFilteredData(sousCategories.sousCategories)
         
    },[dispatch,typMsg,onAddNewSousCategorie,onUpdateSousCategorie,onDeleteSousCategorie]);// onAddNewSousCategorie,onDeleteSousCategorie,onUpdateSousCategorie,categories

   
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'une catégorie
const [libelle, setLibelle] = useState('');
const [depenseCategorie, setDepenseCategorie] = useState('');
const [idCat, setIdCat] = useState('');

const newCategorie = {
    libelle: libelle,
    depenseCategorie:depenseCategorie
};

const saveNewCategorie = (event) => {
    event.preventDefault(); 
    //setShowMsg(true);
    dispatch(onAddNewSousCategorie(newCategorie));
    setTypMsg('add')
    setmodal_list(false);
    
 
  };

  // Mise a jour d"une catégorie 
  const upCategorie = { 
    libelle: libelle,
    depenseCategorie:depenseCategorie,
  };

  const saveUpdateCategorie = (e) => {
    e.preventDefault(); 
    dispatch(onUpdateSousCategorie(idCat,upCategorie));
    setTypMsg('upd')
    setmodal_update(false);
    
  };


  /// Suppression d'une catégorie
 

  const [listIdCat, setListIdCat] = useState([]);

  const idCatChecBoxSelected = (catId) => {
    setListIdCat(prevSelectedRows => ({
      ...prevSelectedRows,
      [catId]: !prevSelectedRows[catId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdCat)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const catIds = Object.keys(filteredList);
 
  const deleteCategorie = ( categorie) => { 
    if(categorie)dispatch(onDeleteSousCategorie(categorie));
    setTypMsg('del')
    if(listIdCat && catIds){catIds.forEach((catId) =>dispatch(onDeleteSousCategorie(catId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(sousCategories.sousCategories);


const dataTable= filteredData.length!==0 ? 
filteredData.map(cat => {
    const user = Object.values(users.users).find(us => us.id === cat.createdBy);
    const categorie = Object.values(categories.categories).find(dc => dc.id === cat.depenseCategorie);
    return { ...cat, user: user? user.prenom+' '+user.nom :'',categorie:categorie? categorie.libelle:'' };
  }) 
  : 
  sousCategories.sousCategories.map(cat => {
    const user = Object.values(users.users).find(us => us.id === cat.createdBy);
    const categorie = Object.values(categories.categories).find(dc => dc.id === cat.depenseCategorie);
    return { ...cat, user: user? user.prenom+' '+user.nom :'' ,categorie:categorie? categorie.libelle:''   };
  });


const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (event) => {
    const value = event.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.libelle), _.toLower(value))||
    _.startsWith(_.toLower(item.categorie), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (event, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


// console.log("dataTable", dataTable);
// console.log("categories", categories.categories);
// console.log("users", users.users);

const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((cat,index) =>  (
    <tr key={cat.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idCatChecBoxSelected(cat.id)}}   />
        </div>
    </th>
    <td className="categorie_id" style={{ display: "none" }}>{cat.id}</td>
    <td className="categorie_index">{index+1}</td>
    <td className="categorie_name">{cat.libelle}</td>
    <td className="categorie_name">{cat.categorie}</td>
    <td className="categorie_date">{ cat.user}</td>
    <td className="categorie_utilisateur">{formatedDate(cat.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setLibelle(cat.libelle);setDepenseCategorie(cat.depenseCategorie);setIdCat(cat.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteCategorie(cat.id)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Catégories" breadcrumbItem="Liste des sous catégories de depenses" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deleteCategorie('');setListIdCat('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="customer_name" onClick={(event) => handleSort(event, 'id')}>N°</th>
                                                        <th className="sort" data-sort="email" onClick={(event) => handleSort(event, 'libelle')}>Libelle</th>
                                                        <th className="sort" data-sort="email" onClick={(event) => handleSort(event, 'libelle')}>Catégorie</th>
                                                        <th className="sort" data-sort="phone" onClick={(event) => handleSort(event, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(event) => handleSort(event, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Nouvelle Sous Catégorie </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewCategorie}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(event) => setLibelle(event.target.value)}/>
                         
                            <label htmlFor="categorie" className="form-label">Categorie</label>
                           
                           <InputGroup>
                               <Input type="select" onChange={(e) => setDepenseCategorie(e.target.value)}  defaultValue="0">
                               <option value="">Sélectionnez une catégorie</option> {/* Option vide par défaut */}
                               {Object.values(categories.categories).map((cat) => (
                                   <option key={cat.id} value={cat.id}>
                                   {cat.libelle}
                                   </option>
                               ))}
                               </Input>
                           </InputGroup>
                        </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => {saveNewCategorie();}}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Sous Catégorie </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateCategorie}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  value={libelle} onChange={(event) => setLibelle(event.target.value)}/>
                        
                            <label htmlFor="categorie" className="form-label">categorie</label>
                            <InputGroup>
                                <Input type="select" value={depenseCategorie} onChange={(e) => setDepenseCategorie(e.target.value)}  >
                                <option value="">Sélectionnez une catégorie</option> {/* Option vide par défaut */}
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                        </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateCategorie}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default CategoriesSousDepenses;
