import {
  GET_EQUIPES,
  GET_EQUIPES_SUCCESS,
  GET_EQUIPES_FAIL,
  GET_EQUIPE,
  GET_EQUIPE_FAIL,
  GET_EQUIPE_SUCCESS,
  ADD_NEW_EQUIPE,
  ADD_EQUIPE_SUCCESS,
  ADD_EQUIPE_FAIL,
  UPDATE_EQUIPE,
  UPDATE_EQUIPE_SUCCESS,
  UPDATE_EQUIPE_FAIL,
  DELETE_EQUIPE,
  DELETE_EQUIPE_SUCCESS,
  DELETE_EQUIPE_FAIL,
} from "./actionTypes";

export const getEquipes = () => ({
  type: GET_EQUIPES,
});

export const getEquipesSuccess = equipes => ({
  type: GET_EQUIPES_SUCCESS,
  payload: equipes,
});

export const getEquipesFail = error => ({
  type: GET_EQUIPES_FAIL,
  payload: error,
});

export const getEquipe = () => ({
  type: GET_EQUIPE,
});

export const getEquipeSuccess = equipe => ({
  type: GET_EQUIPE_SUCCESS,
  payload: equipe,
});

export const getEquipeFail = error => ({
  type: GET_EQUIPE_FAIL,
  payload: error,
});

export const addNewEquipe = equipe => ({
  type: ADD_NEW_EQUIPE,
  payload: equipe,
});

export const addEquipeSuccess = equipe => ({
  type: ADD_EQUIPE_SUCCESS,
  payload: equipe,
});

export const addEquipeFail = error => ({
  type: ADD_EQUIPE_FAIL,
  payload: error,
  
});

export const updateEquipe = (eq,equipe) => ({
  type: UPDATE_EQUIPE,
  payload: { id: eq, data: equipe },
});

export const updateEquipeSuccess = (eq,equipe) => ({
  type: UPDATE_EQUIPE_SUCCESS,
  payload: { id: eq, data: equipe },
});

export const updateEquipeFail = error => ({
  type: UPDATE_EQUIPE_FAIL,
  payload: error,
});

export const deleteEquipe = equipe => ({
  type: DELETE_EQUIPE,
  payload: equipe,
});

export const deleteEquipeSuccess = equipe => ({
  type: DELETE_EQUIPE_SUCCESS,
  payload: equipe,
});

export const deleteEquipeFail = error => ({
  type: DELETE_EQUIPE_FAIL,
  payload: error,
});



 
