/* DEPENSES */
export const GET_TYPEDEMANDES = "GET_TYPEDEMANDES";
export const GET_TYPEDEMANDES_SUCCESS = "GET_TYPEDEMANDES_SUCCESS";
export const GET_TYPEDEMANDES_FAIL = "GET_TYPEDEMANDES_FAIL";

export const GET_TYPEDEMANDE = "GET_TYPEDEMANDE";
export const GET_TYPEDEMANDE_SUCCESS = "GET_TYPEDEMANDE_SUCCESS";
export const GET_TYPEDEMANDE_FAIL = "GET_TYPEDEMANDE_FAIL";

export const ADD_NEW_TYPEDEMANDE = "ADD_NEW_TYPEDEMANDE";
export const ADD_TYPEDEMANDE_SUCCESS = "ADD_TYPEDEMANDE_SUCCESS";
export const ADD_TYPEDEMANDE_FAIL = "ADD_TYPEDEMANDE_FAIL";

export const UPDATE_TYPEDEMANDE = "UPDATE_TYPEDEMANDE";
export const UPDATE_TYPEDEMANDE_SUCCESS = "UPDATE_TYPEDEMANDE_SUCCESS";
export const UPDATE_TYPEDEMANDE_FAIL = "UPDATE_TYPEDEMANDE_FAIL";

export const DELETE_TYPEDEMANDE = "DELETE_TYPEDEMANDE";
export const DELETE_TYPEDEMANDE_SUCCESS = "DELETE_TYPEDEMANDE_SUCCESS";
export const DELETE_TYPEDEMANDE_FAIL = "DELETE_TYPEDEMANDE_FAIL";

 