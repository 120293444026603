import {
  GET_STOCKS_SUCCESS,
  GET_STOCKS_FAIL,
  GET_STOCK_SUCCESS,
  GET_STOCK_FAIL,
  ADD_STOCK_SUCCESS,
  ADD_STOCK_FAIL,
  UPDATE_STOCK_SUCCESS,
  UPDATE_STOCK_FAIL,
  DELETE_STOCK_SUCCESS,
  DELETE_STOCK_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  stocks: [],
  error: {},
};

const Stocks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STOCKS_SUCCESS:
      return {
        ...state,
        stocks: action.payload,
      };

    case GET_STOCKS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_STOCK_SUCCESS:
      return {
        ...state,
        stock: action.payload,
      };
  
    case GET_STOCK_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_STOCK_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        stocks: [...state.stocks, action.payload],
      };

    case ADD_STOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_STOCK_SUCCESS:
      return {
        ...state,
        stocks: state.stocks.map(stock =>
          stock.id.toString() === action.payload.id.toString()
            ? { stock, ...action.payload }
            : stock
        ),
        isStockUpdated: true
      };

    case UPDATE_STOCK_FAIL:
      return {
        ...state,
        error: action.payload,
        isStockUpdated: false
      };

    case DELETE_STOCK_SUCCESS:
      return {
        ...state,
        stocks: state.stocks.filter(
          stock => stock.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_STOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Stocks;
