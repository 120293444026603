import { takeEvery, put, call } from "redux-saga/effects"

// Depenses Redux States
import {
  ADD_NEW_DEPENSE,
  DELETE_DEPENSE,
  GET_DEPENSES,
  GET_DEPENSE,
  UPDATE_DEPENSE,
} from "./actionTypes"
import {
  getDepensesSuccess,
  getDepensesFail,
  getDepenseSuccess,
  getDepenseFail,
  addDepenseFail,
  addDepenseSuccess,
  updateDepenseSuccess,
  updateDepenseFail,
  deleteDepenseSuccess,
  deleteDepenseFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDepenses,
  getDepense,
  addNewDepense,
  updateDepense,
  deleteDepense,
} from "../../helpers/api_back"

function* fetchDepenses() {
  try {
    const response = yield call(getDepenses)
    yield put(getDepensesSuccess(response))
  } catch (error) {
    yield put(getDepensesFail(error))
  }
}

function* fetchDepense() {
  try {
    const response = yield call(getDepense)
    yield put(getDepenseSuccess(response))
  } catch (error) {
    yield put(getDepenseFail(error))
  }
}


function* onAddNewDepense({ payload: depense }) {
 
  try {
    const response = yield call(addNewDepense, depense)
    
    yield put(addDepenseSuccess(response))
  } catch (error) {
    yield put(addDepenseFail(error))
  }
}

function* onUpdateDepense({ payload: depense }) {
  try {
    const response = yield call(updateDepense, depense)
    yield put(updateDepenseSuccess(response))
  } catch (error) {
    yield put(updateDepenseFail(error))
  }
}

function* onDeleteDepense({ payload: depense }) {
  try {
    const response = yield call(deleteDepense, depense)
    yield put(deleteDepenseSuccess(response))
  } catch (error) {
    yield put(deleteDepenseFail(error))
  }
}
 

function* depensesSaga() {
  yield takeEvery(GET_DEPENSES, fetchDepenses)
  yield takeEvery(GET_DEPENSE, fetchDepense)
  yield takeEvery(ADD_NEW_DEPENSE, onAddNewDepense)
  yield takeEvery(UPDATE_DEPENSE, onUpdateDepense)
  yield takeEvery(DELETE_DEPENSE, onDeleteDepense) 
}

export default depensesSaga
