import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIE_SUCCESS,
  GET_CATEGORIE_FAIL,
  ADD_CATEGORIE_SUCCESS,
  ADD_CATEGORIE_FAIL,
  UPDATE_CATEGORIE_SUCCESS,
  UPDATE_CATEGORIE_FAIL,
  DELETE_CATEGORIE_SUCCESS,
  DELETE_CATEGORIE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  categories: [],
  loading: false,
  error: {},
};

const Categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: true,
        categories: action.payload,
      };

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_CATEGORIE_SUCCESS:
      return {
        ...state,
        categorie: action.payload,
      };
  
    case GET_CATEGORIE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_CATEGORIE_SUCCESS:
    //  console.log('Hello Sagas ok!');
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };

    case ADD_CATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: state.categories.map(categorie =>
          categorie.id.toString() === action.payload.id.toString()
            ? { categorie, ...action.payload }
            : categorie
        ),
        isCategorieUpdated: true
      };

    case UPDATE_CATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
        isCategorieUpdated: false
      };

    case DELETE_CATEGORIE_SUCCESS:
      return {
        ...state,
        cateories: state.categories.filter(
          categorie => categorie.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Categories;
