import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  users: [],
  loading: false,
  error: {},
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_USERS_SUCCESS:
      
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
       
    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
  
    case GET_USER_FAIL:
       return {
        ...state,
        loading: false,
        error: action.payload,
       }; 

    case ADD_USER_SUCCESS: 
      return {
        ...state,
        users: [...state.users, action.payload],
      };

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id.toString() === action.payload.id.toString()
            ? { user, ...action.payload }
            : user
        ),
        isUserUpdated: true
      };

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isUserUpdated: false
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        cateories: state.users.filter(
          user => user.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Users;
