import { takeEvery, put, call } from "redux-saga/effects"

// Ventes Redux States
import {
  ADD_NEW_VENTE,
  DELETE_VENTE,
  GET_VENTES,
  GET_VENTE,
  UPDATE_VENTE,
} from "./actionTypes"
import {
  getVentesSuccess,
  getVentesFail,
  getVenteSuccess,
  getVenteFail,
  addVenteFail,
  addVenteSuccess,
  updateVenteSuccess,
  updateVenteFail,
  deleteVenteSuccess,
  deleteVenteFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getVentes,
  getVente,
  addNewVente,
  updateVente,
  deleteVente,
} from "../../helpers/api_back"

function* fetchVentes() {
  try {
    const response = yield call(getVentes)
    yield put(getVentesSuccess(response))
  } catch (error) {
    yield put(getVentesFail(error))
  }
}

function* fetchVente() {
  try {
    const response = yield call(getVente)
    yield put(getVenteSuccess(response))
  } catch (error) {
    yield put(getVenteFail(error))
  }
}


function* onAddNewVente({ payload: vente }) {
 
  try {
    const response = yield call(addNewVente, vente)
    
    yield put(addVenteSuccess(response))
  } catch (error) {
    yield put(addVenteFail(error))
  }
}

function* onUpdateVente({ payload: vente }) {
  try {
    const response = yield call(updateVente, vente)
    yield put(updateVenteSuccess(response))
  } catch (error) {
    yield put(updateVenteFail(error))
  }
}

function* onDeleteVente({ payload: vente }) {
  try {
    const response = yield call(deleteVente, vente)
    yield put(deleteVenteSuccess(response))
  } catch (error) {
    yield put(deleteVenteFail(error))
  }
}
 

function* ventesSaga() {
  yield takeEvery(GET_VENTES, fetchVentes)
  yield takeEvery(GET_VENTE, fetchVente)
  yield takeEvery(ADD_NEW_VENTE, onAddNewVente)
  yield takeEvery(UPDATE_VENTE, onUpdateVente)
  yield takeEvery(DELETE_VENTE, onDeleteVente) 
}

export default ventesSaga
