import { takeEvery, put, call } from "redux-saga/effects"

// PRODUITs Redux States
import {
  ADD_NEW_PRODUIT,
  DELETE_PRODUIT,
  GET_PRODUITS,
  GET_PRODUIT,
  UPDATE_PRODUIT,
} from "./actionTypes"
import {
  getProduitsSuccess,
  getProduitsFail,
  getProduitSuccess,
  getProduitFail,
  addProduitFail,
  addProduitSuccess,
  updateProduitSuccess,
  updateProduitFail,
  deleteProduitSuccess,
  deleteProduitFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProduits,
  getProduit,
  addNewProduit,
  updateProduit,
  deleteProduit,
} from "../../helpers/api_back"
 
function* fetchProduits() {
  try {
    const response = yield call(getProduits) 
    yield put(getProduitsSuccess(response))
  } catch (error) {
    yield put(getProduitsFail(error))
  }

}

function* fetchProduit() {
  try {
    const response = yield call(getProduit)
    yield put(getProduitSuccess(response))
  } catch (error) {
    yield put(getProduitFail(error))
  }
}


function* onAddNewProduit({ payload: produit }) {
 
  try {
    const response = yield call(addNewProduit, produit)
    
    yield put(addProduitSuccess(response))
  } catch (error) {
    yield put(addProduitFail(error))
  }
}

function* onUpdateProduit({ payload: produit }) {
  try {
    const response = yield call(updateProduit, produit)
    yield put(updateProduitSuccess(response))
  } catch (error) {
    yield put(updateProduitFail(error))
  }
}

function* onDeleteProduit({ payload: produit }) {
  try {
    const response = yield call(deleteProduit, produit)
    yield put(deleteProduitSuccess(response))
  } catch (error) {
    yield put(deleteProduitFail(error))
  }
}
 

function* produitsSaga() {
  yield takeEvery(GET_PRODUITS, fetchProduits)
  yield takeEvery(GET_PRODUIT, fetchProduit)
  yield takeEvery(ADD_NEW_PRODUIT, onAddNewProduit)
  yield takeEvery(UPDATE_PRODUIT, onUpdateProduit)
  yield takeEvery(DELETE_PRODUIT, onDeleteProduit) 
}

export default produitsSaga
