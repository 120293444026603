import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import Select  from 'react-select';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import { FaBold, FaEdit, FaTrash } from 'react-icons/fa';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
 
import PDFGenerator from '../../utilitaires/PDFGenerator';

//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //categories
    getCategories as onGetCategories,

    //Produits
    getProduits as onGetProduits,

     //Fournisseurs
     getFournisseurs as onGetFournisseurs,


    //approvisionnements
    addNewApprovisionnement as onAddNewApprovisionnement,
    deleteApprovisionnement as onDeleteApprovisionnement,
    getApprovisionnements as onGetApprovisionnements,
    getApprovisionnement as onGetApprovisionnement,
    updateApprovisionnement as onUpdateApprovisionnement,
  } from "../../store/actions"; 

  
 
const Approvisionnements = () => {
const approvisionnements = useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements  }));
const categories = useSelector((state) => ({  categories:  state.categories.categories  }));
const produits = useSelector((state) => ({  produits:  state.produits.produits  }));
const fournisseurs = useSelector((state) => ({  fournisseurs:  state.fournisseurs.fournisseurs  }));
const users = useSelector((state) => ({  users:  state.users.users  }));


    // Declaration du fournisseur et des produits choisi
const today = new Date(); 
const todayEnregDate = new Date().toISOString().split('T')[0];
const [appr, setAppr] = useState();
const [validateButtonDisabled, setValidateButtonDisabled] = useState(true);
const [frs, setFrs] = useState('');
const [numFacFrs,setNumFacFrs]=useState();
const [immatTracteur,setImmatTracteur]=useState();
const [immatCiterne,setImmatCiterne]=useState();
const [nomPrenomChaufeur,setNomPrenomChaufeur]=useState();
const [contact,setContact]=useState();
const [permis,setPermis]=useState(); 
const [dateEnregistrement,setDateEnregistrement] = useState(todayEnregDate) 
const [prod, setProd] = useState('');

const [appros, setAppros] = useState([]);

const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();


const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };

 
const startNewAppro =()=>{
    setNumFacFrs('')
    setAppr(today.getTime())
    setAppros([])

  }


/// option  pour afficher la liste des produits et la liste des fournisseurs
const frsOptions=Object.values(fournisseurs.fournisseurs).map(frs => ({
    value:frs.id, label:frs.libelle
}))


const prodOptFiltered=Object.values(produits.produits).filter((prod) =>{
    return !appros.some((selected) => selected.produit === prod.id)
});
const prodOptions=prodOptFiltered.map(prod => ({
    value:prod.id, label:prod.nomProd
}))

 
// fonction pour ajouter un nouveau produit
const handleAddProd = (prod) => {
    const produit = Object.values(produits.produits).find(p => p.id === prod); 
    const newProduit = {
    // id: appros.length + 1,
      numAppro: appr,
      fournisseur: frs,
      numFacFrs:numFacFrs,
      immatCiterne:immatCiterne,
      immatTracteur:immatTracteur,
      nomPrenomChaufeur:nomPrenomChaufeur,
      contact:contact,
      permis:permis,
      dateEnregistrement:dateEnregistrement,
      produit: prod,
      quantite: '',
      prixAchat: produit.prixAchat,
      prixVente: produit.prixVente,
      montant: quantite*prixAchat,
    };
    setAppros([...appros, newProduit]);
};  

 
console.log('appros',appros)
/// lorsque l'utilisateur choisi un produit
const handleChangeProd =(prod)=>{
    handleAddProd(prod.value,)
}

///lorsque l'utilisateur choisi un fournisseur
const handleChangeFrs =(frs)=>{
    setAppr(today.getTime())
    setAppros([])
    setFrs(frs.value)

    }
 
    //impression d ecran
    const handlePrint = () => {
        const content = document.getElementById('content');
    
        html2canvas(content).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('landscape');
  
          const contentWidth = canvas.width;
        const contentHeight = canvas.height;
  
        // Calculer les proportions pour adapter l'image au PDF
        const ratio = contentWidth / contentHeight;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdfWidth / ratio;
  
  
          pdf.addImage(imgData, 'PNG', 0, 0,pdfWidth, pdfHeight);
          pdf.save('capture.png');
        });
      };
 
// Les alerts d'ajout ,modification et suppression
function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 6000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'un approvisionnement
const [produit, setProduit] = useState('');
const [quantite, setQuantite] = useState('');
const [prixAchat, setPrixAchat] = useState('');
const [prixVente, setPrixVente] = useState('');
const [montant, setMontant] = useState('');
const [fournisseur, setFournisseur] = useState('');

const [idAppro, setIdAppro] = useState('');

const newApprovisionnement = {
    produit: produit,
    quantite:quantite,
    numFacFrs:numFacFrs,
    immatCiterne:immatCiterne,
    immatTracteur:immatTracteur,
    nomPrenomChaufeur:nomPrenomChaufeur,
    contact:contact,
    permis:permis,
    dateEnregistrement:dateEnregistrement,
    prixAchat:prixAchat,
    prixVente:prixVente,
    montant:montant,
    fournisseur:fournisseur,
};

const saveNewApprovisionnement = (e) => {
    e.preventDefault(); 
    dispatch(onAddNewApprovisionnement(newApprovisionnement));
    setTypMsg('add')
    setmodal_list(false);
    
 
  };

  // Mise a jour d"un Approvisionnement 
  const upApprovisionnement = { 
    produit: produit,
    numFacFrs:numFacFrs,
    immatCiterne:immatCiterne,
    immatTracteur:immatTracteur,
    nomPrenomChaufeur:nomPrenomChaufeur,
    contact:contact,
    permis:permis,
    dateEnregistrement:dateEnregistrement,
    quantite:quantite,
    prixAchat:prixAchat,
    prixVente:prixVente,
    montant:montant,
    fournisseur:fournisseur,
  };

  const saveUpdateApprovisionnement = (e) => {
    e.preventDefault(); 
    // console.log("upApprovisionnement",upApprovisionnement)
    // console.log("idAppro",idAppro)
    dispatch(onUpdateApprovisionnement(idAppro,upApprovisionnement));
    setTypMsg('upd')
    setmodal_update(false);
    
  };


  /// Suppression d'un Approvisionnement
 

  const [listIdAppro, setListIdAppro] = useState([]);

  const idApproChecBoxSelected = (approId) => {
    setListIdAppro(prevSelectedRows => ({
      ...prevSelectedRows,
      [approId]: !prevSelectedRows[approId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdAppro)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const approIds = Object.keys(filteredList);
 
  const deleteApprovisionnement = ( approvisionnement) => { 
    if(approvisionnement)dispatch(onDeleteApprovisionnement(approvisionnement));
    setTypMsg('del')
    if(listIdAppro && approIds){approIds.forEach((approId) =>dispatch(onDeleteApprovisionnement(approId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(appros);

const dataTable= filteredData ? 
filteredData.map(appro => {
    const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === appro.fournisseur);
    const produit = Object.values(produits.produits).find(prod=> prod.id === appro.produit);
    const user = Object.values(users.users).find(us => us.id === appro.createdBy);
    return { ...appro , user: user?user.prenom+' '+user.nom :'' ,produitInfo:produit?produit.nomProd:'',fournisseurInfo:frs?frs.libelle:''};
  }) 
  : 
  appros.map(appro => {
    const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === appro.fournisseur);
    const produit = Object.values(produits.produits).find(prod=> prod.id === appro.produit);
    const user = Object.values(users.users).find(us => us.id === appro.createdBy);
    return { ...appro, user: user?user.prenom+' '+user.nom :'' ,produitInfo:produit?produit.nomProd:'',fournisseurInfo:frs?frs.libelle:''};//user: user.prenom+' '+user.nom 
  });
//,categorieappro:categorie.libelle

 





const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (appro) => {
    const value = appro.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.produit), _.toLower(value))|| 
      _.startsWith(_.toLower(item.numFacFrs), _.toLower(value))||
      _.startsWith(_.toLower(item.fournisseur), _.toLower(value))||
      _.startsWith(_.toLower(item.quantite), _.toLower(value))||
      _.startsWith(_.toLower(item.prixAchat), _.toLower(value))||
      _.startsWith(_.toLower(item.prixVente), _.toLower(value))||
      _.startsWith(_.toLower(item.montant), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.dateEnregistrement), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (appro, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


// lorsque l'utilisateur decide de modifier ou de supprimer un element de la table 
const handleEdit = (id, property, value) => {
    const index = appros.findIndex((appr) => appr.numAppro+appr.fournisseur+appr.produit === id);

    const appro = appros[index];
    const quantite = property==='quantite' ?  value : appro.quantite; // récupère la valeur actuelle de quantite
    const prixAchat = property==='prixAchat' ?  value : appro.prixAchat; // récupère la valeur actuelle de prixAchat  
    const prixVente = property==='prixVente' ?  value : appro.prixVente; // récupère la valeur actuelle de prixVente   
    const updatedAppro = { ...appros[index], [property]: value, montant: quantite*prixAchat  };
    const updatedAppros = [
      ...appros.slice(0, index),
      updatedAppro,
      ...appros.slice(index + 1)
    ];
    setAppros(updatedAppros);  
    if(validateButtonDisabled===true && appros  && updatedAppros[0].quantite) { setValidateButtonDisabled(false)}
  };

  const handleDelete = (id) => {
    const updatedAppros = appros.filter((appro) => appro.numAppro+appro.fournisseur+appro.produit !== id);
    setAppros(updatedAppros);
    if(updatedAppros.length===0) { setValidateButtonDisabled(true)}
  };

  //interdiction des valeurs non numerique
  const handleKeyDown = (e) => {
 
    const { key } = e;

   // console.log('key',key)

  // Interdire les caractères non numériques, sauf la touche Backspace
  // if (!/\d/.test(key)  && (key !== 'Backspace' || key !== '' || key !== 'Delete' || key.key !== '.'))
  if (!/\d/.test(key)  && key !== 'Backspace' ){
  
      if(key !== '.' ) {

        if(key !== 'Tab') {
          if(key !== 'ArrowRight'){
            if(key !== 'ArrowLeft') e.preventDefault() ;
          }
        }   
      } 

 }
};


  ///maping des donnees pour pdf 
  const headText ='LISTE DE L\'APPRO N° '+ appr 
  const tableHead =[['N°','N° Appro', 'Fournisseur', 'Produit','Quantite', 'Prix Achat','Prix Vente','Montant']]
  const tableD=dataTable.map((ap)=>{ 
    const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === ap.fournisseur);
    const produit = Object.values(produits.produits).find(prod=> prod.id === ap.produit);
    return { ...ap,frs: frs? frs.libelle  :'',produit: produit? produit.nomProd:'' };})

    const tableData= tableD.map((d, index) => [index+1, d.numAppro, d.frs, d.produit,parseFloat(d.quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.prixAchat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);


const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((appro,index) =>  (
    <tr key={appro.numAppro+appro.fournisseur+appro.produit} style={{backgroundColor:"#367E7F",color:"#FFFF",}}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idApproChecBoxSelected(appro.numAppro)}}   />
        </div>
    </th>
    <td className="appro_id" style={{ display: "none" }}>{appro.numAppro}</td>
    <td className="appro_index">{index+1}</td>
    <td className="appro_name">{appro.fournisseurInfo  }</td>
    <td className="appro_name">{appro.produitInfo}</td>
    {/* <td  className="appro_name">{appro.quantite}</td> */}
     

    <td> <Input type="text"   style= {{backgroundColor:"#8C160D",color:"#FFFF"}}
             value={appro.quantite.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
             onKeyDown={handleKeyDown}
            onChange={(e) => handleEdit(appro.numAppro+appro.fournisseur+appro.produit, 'quantite',  (e.target.value).replace(/ /g, '').replace(/,/g, ''))}
              />
    </td>
    {/* <td className="appro_name">{appro.prixAchat}</td> */}
    <td> <Input type="decimal"   style= {{color:"black"}}
             value={appro.prixAchat}
            onChange={(e) => handleEdit(appro.numAppro+appro.fournisseur+appro.produit, 'prixAchat', e.target.value)}
              />
    </td>
    <td> <Input type="decimal"  style= {{color:"black"}}
             value={appro.prixVente}
            onChange={(e) => handleEdit(appro.numAppro+appro.fournisseur+appro.produit, 'prixVente', e.target.value)}
              />
    </td>
    {/* <td className="appro_name">{appro.montant}</td> */}
    <td> <Input type="text"   style= {{color:"black"}} 
             value={parseFloat(appro.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            onChange={(e) => handleEdit(appro.numAppro+appro.fournisseur+appro.produit, 'montant', e.target.value)}
              />
    </td>
    {/* <td className="appro_name">{appro.categorie ? Object.values(categories.categories).find(cat=>cat.id==appro.categorie).libelle:''}</td> */}
    {/* <td className="appro_date">{ appro.user}</td>
    <td className="appro_utilisateur">{formatedDate(appro.createdAt)}</td> */}
    <td>
        <div className="d-flex gap-2">
            {/* <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setProduit(appro.produit);setFournisseur(appro.fournisseur);setQuantite(appro.quantite);setPrixAchat(appro.prixAchat);setMontant(appro.montant);setIdAppro(appro.numAppro);tog_update()} }>Modifier</button>
            </div> */}
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> handleDelete(appro.numAppro+appro.fournisseur+appro.produit)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));

   useEffect(() => { 
       dispatch(onGetProduits());
       dispatch(onGetFournisseurs());
        dispatch(onGetCategories());
        dispatch(onGetUsers());
        dispatch(onGetApprovisionnements());
        setFilteredData(appros)
        
    },[dispatch,dateEnregistrement,appros,appr,typMsg,onAddNewApprovisionnement,onUpdateApprovisionnement,onDeleteApprovisionnement]); //onAddNewApprovisionnement,onDeleteApprovisionnement,onUpdateApprovisionnement,Approvisionnements

   
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Approvisionnement" breadcrumbItem="Liste des approvisionnements" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                {/* <CardHeader>
                                    <h4 className="card-title mb-0">Effectuer un appporvisionnement</h4>
                                  
                                </CardHeader> */}
                                {appr  ? 
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="align-items-start">
                                        {appr && appros ? <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                 <Button color="success" disabled={validateButtonDisabled} className="add-btn" onClick={() => {dispatch(onAddNewApprovisionnement(appros));setAppr('');setTypMsg('add')}} id="create-btn">
                                                         Valider</Button>
                                                  {/* <Button color="info" size="lg" onClick={handlePrint}   
                                                    >Imp.Ecran</Button>   */}
                                                    {/* <Button color="success"></Button> */}
                                                    <PDFGenerator tableData={tableData} tableHead={tableHead} headText={headText} />
                                                    
                                                    {/* <Button onClick={'genererWord'}>Générer Word</Button> */}
                                                    
                                                </div> 
                                               
                                            </Col> :''} 
                                                    {appr && appros ? <Col className="col-sm-auto">
 
                                                        <label style={{ marginRight: '2px' }} className="mr-2">N° Facture Fournisseur:</label>
                                                        <input
                                                            type="text"
                                                            style={{ color: '#EB7524',   backgroundColor: '#00023D',fontSize:18 ,fontStyle:'bold'}}
                                                            value={numFacFrs}
                                                            className="form-control search"
                                                            placeholder="Entrez la facture fournisseur..."
                                                            onChange={(e) => {setNumFacFrs(e.target.value); 
                                                                if(appros){  const updatedAppros = appros.map((appro) => ({ ...appro, numFacFrs:e.target.value }));
                                                                setAppros(updatedAppros);  }}}
                                                        />
                                                     
                                            </Col>
                                            :''}   
                                            <Col className="col-sm-auto">
                                                    
                                                        <label style={{ marginRight: '2px' }} className="mr-2">N°Tracteur:</label>
                                                        <input
                                                            type="text"
                                                            style={{ color: '#EB7524',  backgroundColor: '#00023D',fontSize:18 ,fontStyle:'bold'}}
                                                            value={immatTracteur}
                                                            className="form-control search"
                                                            placeholder="Entrez l'immatriculation tracteur..."
                                                            onChange={(e) => {setImmatTracteur(e.target.value); 
                                                                if(appros){  const updatedAppros = appros.map((appro) => ({ ...appro, immatTracteur:e.target.value }));
                                                                setAppros(updatedAppros);  }}}
                                                        />
                                                     
                                            </Col>
                                            <Col className="col-sm-auto">
                                                    
                                                        <label style={{ marginRight: '2px' }}className="mr-2">N° Citerne</label>
                                                        <input
                                                            type="text"
                                                            style={{ color: '#EB7524',  backgroundColor: '#00023D',fontSize:18 ,fontStyle:'bold'}}
                                                            value={immatCiterne}
                                                            className="form-control search"
                                                            placeholder="Entrez l'immatriculation citerne..."
                                                            onChange={(e) => {setImmatCiterne(e.target.value); 
                                                                if(appros){  const updatedAppros = appros.map((appro) => ({ ...appro, immatCiterne:e.target.value }));
                                                                setAppros(updatedAppros);  }}}
                                                        />
                                                     
                                            </Col>
                                            <Col className="col-sm-auto ">
                                                   
                                                        <label style={{ marginRight: '2px' }}className="mr-2">Nom - Prénom Chauffeur</label>
                                                        <input
                                                            type="text"
                                                            style={{ color: '#EB7524',  backgroundColor: '#00023D',fontSize:18 ,fontStyle:'bold'}}
                                                            value={nomPrenomChaufeur}
                                                            className="form-control search"
                                                            placeholder="Entrez le nom et prenom du chauffeur..."
                                                            onChange={(e) => {setNomPrenomChaufeur(e.target.value); 
                                                                if(appros){  const updatedAppros = appros.map((appro) => ({ ...appro, nomPrenomChaufeur:e.target.value }));
                                                                setAppros(updatedAppros);  }}}
                                                        />
                                                     
                                            </Col>
                                            <Col className="col-sm-auto ">
                                                    
                                                        <label style={{ marginRight: '2px' }}className="mr-2">N° Permis:</label>
                                                        <input
                                                            type="text"
                                                            style={{ color: '#EB7524',  backgroundColor: '#00023D',fontSize:18 ,fontStyle:'bold'}}
                                                            value={permis}
                                                            className="form-control search"
                                                            placeholder="Entrez le N° permis..."
                                                            onChange={(e) => {setPermis(e.target.value); 
                                                                if(appros){  const updatedAppros = appros.map((appro) => ({ ...appro, permis:e.target.value }));
                                                                setAppros(updatedAppros);  }}}
                                                        />
                                                     
                                            </Col>
                                            <Col className="col-sm-auto">
 
                                                        <label style={{ marginRight: '2px' }}className="mr-2">Contact:</label>
                                                        <input
                                                            type="text"
                                                            style={{ color: '#EB7524' , backgroundColor: '#00023D',fontSize:18 ,fontStyle:'bold'}}
                                                            value={contact}
                                                            className="form-control search"
                                                            placeholder="Entrez contact..."
                                                            onChange={(e) => {setContact(e.target.value); 
                                                                if(appros){  const updatedAppros = appros.map((appro) => ({ ...appro, contact:e.target.value }));
                                                                setAppros(updatedAppros);  }}}
                                                        />
 
                                            </Col>
                                            <Col className="col-sm-auto ">
                                                    
                                                        <label style={{ marginRight: '2px' }}className="mr-2">DATE:</label>
                                                        <input
                                                            type="date"
                                                            style={{ color: '#EB7524', backgroundColor: '#00023D',fontSize:20 ,fontStyle:'bold'}}
                                                            value={dateEnregistrement}
                                                            className="form-control search"
                                                            placeholder="Date d'enregistrment..."
                                                            onChange={(e) => {setDateEnregistrement(e.target.value); 
                                                                if(appros){  const updatedAppros = appros.map((appro) => ({ ...appro, dateEnregistrement:e.target.value }));
                                                                setAppros(updatedAppros);  }}}
                                                        />
                                                    
                                                </Col>                                
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                {/* <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div> */}
                                     <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", }}>APPROVISIONNEMENTS</span>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <label htmlFor="frsSelect">Sélectionner un fournisseur</label>
                                            <Select options={frsOptions} onChange={handleChangeFrs} />
                                            </Col>
                                            <Col>
                                            <label htmlFor="frsSelect">Sélectionner un produit</label>
                                            <Select options={frs? prodOptions:''}    onChange={handleChangeProd}/>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr >
                                                        <th scope="col" style={{ backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray',width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="appro_id" onClick={(appro) => handleSort(appro, 'id')}>N°</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="fournisseur" onClick={(appro) => handleSort(appro, 'fournisseur')}>Fournisseur</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="produit" onClick={(appro) => handleSort(appro, 'produit')}>Produit</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="quantite" onClick={(appro) => handleSort(appro, 'quantite')}>Quantite</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="prixAchat" onClick={(appro) => handleSort(appro, 'prixAchat')}>prixAchat</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="prixVente" onClick={(appro) => handleSort(appro, 'prixVente')}>prixVente</th>
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="montant" onClick={(appro) => handleSort(appro, 'montant')}>Montant</th>
                                                        {/* <th className="sort" data-sort="createdBy" onClick={(appro) => handleSort(appro, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(appro) => handleSort(appro, 'createdAt')}>Date Création</th> */}
                                                    
                                                        <th style={{backgroundColor:'#365657', color:'#E7FAB9',border: '1px solid gray'}} className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                {appr && appros ? <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody> :'' }
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                         <div style={{textAlign: 'right',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>PRODUITS: <span style={{color: '#377E47'}}>{appros.length}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(appros.reduce((acc, app) => acc + app.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span></h1></div>

                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                                : <div><Button color="primary"  onClick={startNewAppro}>NOUVEL APPROVISIONNEMENT</Button> <Button color="secondary" tag={Link}  to="/HistoAppro">HISTORIQUE APPROVISIONNEMENT</Button></div>} 
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Approvisionnement </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewApprovisionnement}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="produit" className="form-label">produit</label>
                            <input type="text" id="produit" className="form-control" placeholder="Enter produit" required  onChange={(e) => setProduit(e.target.value)}/>
                            <label htmlFor="quantite" className="form-label">quantite</label>
                            <input type="number" id="quantite" className="form-control" placeholder="Enter quantite" required  onChange={(e) => setQuantite(e.target.value)}/>
                            <label htmlFor="prixAchat" className="form-label">prixAchat</label>
                            <input type="number" id="prixAchat" className="form-control" placeholder="Enter prixAchat" required  onChange={(e) => setPrixAchat(e.target.value)}/>
                            <label htmlFor="motant" className="form-label">quantite</label>
                            <input type="number" id="montant" className="form-control" placeholder="Enter montant" required  onChange={(e) => setMontant(e.target.value)}/>
                            <label htmlFor="fournisseur" className="form-label">Fournisseur</label>
                            <input type="text" id="fournisseur" className="form-control" placeholder="Enter Fournisseur" required  onChange={(e) => setFournisseur(e.target.value)}/>
                            {/* <label htmlFor="categorie" className="form-label">categorie</label>
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setCategorieappro(e.target.value)}  defaultValue="0">
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup> */}

                        
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewApprovisionnement}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Approvisionnement </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateApprovisionnement}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="produit" className="form-label">Produit</label>
                            <input type="text" id="produit" className="form-control" placeholder="Enter produit" required  value={produit} onChange={(event) => setProduit(event.target.value)}/>
                            <label htmlFor="quantite" className="form-label">Quantite</label>
                            <input type="number" id="quantite" className="form-control" placeholder="quantite" required   value={quantite}   onChange={(event) => setQuantite(event.target.value)}/>
                            <label htmlFor="prixAchat" className="form-label">prixAchat</label>
                            <input type="number" id="prixAchat" className="form-control" placeholder="prixAchat" required   value={prixAchat}   onChange={(event) => setPrixAchat(event.target.value)}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                            <input type="number" id="montant" className="form-control" placeholder="montant" required   value={montant}   onChange={(event) => setMontant(event.target.value)}/>
                             
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateApprovisionnement}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Approvisionnements;
