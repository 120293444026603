import {
  GET_AGENTS,
  GET_AGENTS_SUCCESS,
  GET_AGENTS_FAIL,
  GET_AGENT,
  GET_AGENT_FAIL,
  GET_AGENT_SUCCESS,
  ADD_NEW_AGENT,
  ADD_AGENT_SUCCESS,
  ADD_AGENT_FAIL,
  UPDATE_AGENT,
  UPDATE_AGENT_SUCCESS,
  UPDATE_AGENT_FAIL,
  DELETE_AGENT,
  DELETE_AGENT_SUCCESS,
  DELETE_AGENT_FAIL,
} from "./actionTypes";

export const getAgents = () => ({
  type: GET_AGENTS,
});

export const getAgentsSuccess = agents => ({
  type: GET_AGENTS_SUCCESS,
  payload: agents,
});

export const getAgentsFail = error => ({
  type: GET_AGENTS_FAIL,
  payload: error,
});

export const getAgent = () => ({
  type: GET_AGENT,
});

export const getAgentSuccess = agent => ({
  type: GET_AGENT_SUCCESS,
  payload: agent,
});

export const getAgentFail = error => ({
  type: GET_AGENT_FAIL,
  payload: error,
});

export const addNewAgent = agent => ({
  type: ADD_NEW_AGENT,
  payload: agent,
});

export const addAgentSuccess = agent => ({
  type: ADD_AGENT_SUCCESS,
  payload: agent,
});

export const addAgentFail = error => ({
  type: ADD_AGENT_FAIL,
  payload: error,
  
});

export const updateAgent = (ag,agent) => ({
  type: UPDATE_AGENT,
  payload: { id: ag, data: agent },
});

export const updateAgentSuccess = agent => ({
  type: UPDATE_AGENT_SUCCESS,
  payload: agent,
});

export const updateAgentFail = error => ({
  type: UPDATE_AGENT_FAIL,
  payload: error,
});

export const deleteAgent = agent => ({
  type: DELETE_AGENT,
  payload: agent,
});

export const deleteAgentSuccess = agent => ({
  type: DELETE_AGENT_SUCCESS,
  payload: agent,
});

export const deleteAgentFail = error => ({
  type: DELETE_AGENT_FAIL,
  payload: error,
});



 
