import {
  GET_DEPENSE_CATEGORIES,
  GET_DEPENSE_CATEGORIES_SUCCESS,
  GET_DEPENSE_CATEGORIES_FAIL,
  GET_DEPENSE_CATEGORIE,
  GET_DEPENSE_CATEGORIE_FAIL,
  GET_DEPENSE_CATEGORIE_SUCCESS,
  ADD_NEW_DEPENSE_CATEGORIE,
  ADD_DEPENSE_CATEGORIE_SUCCESS,
  ADD_DEPENSE_CATEGORIE_FAIL,
  UPDATE_DEPENSE_CATEGORIE,
  UPDATE_DEPENSE_CATEGORIE_SUCCESS,
  UPDATE_DEPENSE_CATEGORIE_FAIL,
  DELETE_DEPENSE_CATEGORIE,
  DELETE_DEPENSE_CATEGORIE_SUCCESS,
  DELETE_DEPENSE_CATEGORIE_FAIL,
} from "./actionTypes";

export const getDepenseCategories = () => ({
  type: GET_DEPENSE_CATEGORIES,
});

export const getDepenseCategoriesSuccess = depenseCategories => ({
  type: GET_DEPENSE_CATEGORIES_SUCCESS,
  payload: depenseCategories,
});

export const getDepenseCategoriesFail = error => ({
  type: GET_DEPENSE_CATEGORIES_FAIL,
  payload: error,
});

export const getDepenseCategorie = () => ({
  type: GET_DEPENSE_CATEGORIE,
});

export const getDepenseCategorieSuccess = depenseCategorie => ({
  type: GET_DEPENSE_CATEGORIE_SUCCESS,
  payload: depenseCategorie,
});

export const getDepenseCategorieFail = error => ({
  type: GET_DEPENSE_CATEGORIE_FAIL,
  payload: error,
});

export const addNewDepenseCategorie = depenseCategorie => ({
  type: ADD_NEW_DEPENSE_CATEGORIE,
  payload: depenseCategorie,
});

export const addDepenseCategorieSuccess = depenseCategorie => ({
  type: ADD_DEPENSE_CATEGORIE_SUCCESS,
  payload: depenseCategorie,
});

export const addDepenseCategorieFail = error => ({
  type: ADD_DEPENSE_CATEGORIE_FAIL,
  payload: error,
  
});

export const updateDepenseCategorie = (dep,depenseCategorie) => ({
  type: UPDATE_DEPENSE_CATEGORIE,
  payload:  { id: dep, data: depenseCategorie },
});

export const updateDepenseCategorieSuccess = depenseCategorie => ({
  type: UPDATE_DEPENSE_CATEGORIE_SUCCESS,
  payload: depenseCategorie,
});

export const updateDepenseCategorieFail = error => ({
  type: UPDATE_DEPENSE_CATEGORIE_FAIL,
  payload: error,
});

export const deleteDepenseCategorie = depenseCategorie => ({
  type: DELETE_DEPENSE_CATEGORIE,
  payload: depenseCategorie,
});

export const deleteDepenseCategorieSuccess = depenseCategorie => ({
  type: DELETE_DEPENSE_CATEGORIE_SUCCESS,
  payload: depenseCategorie,
});

export const deleteDepenseCategorieFail = error => ({
  type: DELETE_DEPENSE_CATEGORIE_FAIL,
  payload: error,
});



 
