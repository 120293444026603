import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { groupBy } from 'lodash';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';



const StockCercleChart = () => {

  const produits = useSelector((state) => ({  produits: state.produits.produits  }));
 
  const essence=produits.produits.find(element => element.id === 5);
  const gazoil=produits.produits.find(element => element.id === 4);
  const essenceMoto=produits.produits.find(element => element.id === 7);

const ess= essence ? parseFloat(essence.stockProd).toFixed(2) :0
const gazo= gazoil ? parseFloat(gazoil.stockProd).toFixed(2) :0
const essMoto= essenceMoto ? parseFloat(essenceMoto.stockProd).toFixed(2):0
 

   const state={          
      series: [parseFloat(ess), parseFloat(gazo), parseFloat(essMoto)],
      options: {
        chart: {
          width: 280,
          type: 'pie',
        },
       labels: ['Essence', 'Gas-Oil', 'Ess. Moto'], 
       legend: {
        show: true,
        position: 'right',
        verticalAlign: 'right',
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 200,
        labels: {
          colors: ['#FFFFFF'],
          useSeriesColors: true
        },
        markers: {
          width: 12,
          height: 12,
          radius: 12,
        },
        itemMargin: {
          horizontal: 10,
          vertical: 5
        },
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 180
            },
            legend: { 
              position: 'bottom',
           /*    offsetY: 5,
              display: 'none !important' */
               
            }
          }
        }]
      },
    
    
    };

   
 
  
     
  return (
    <React.Fragment>
       <ReactApexChart options={state.options} series={state.series} type="pie" width={280} height={100} />
    </React.Fragment>
  );
};
export default StockCercleChart;
