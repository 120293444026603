import {
  GET_VENTES_SUCCESS,
  GET_VENTES_FAIL,
  GET_VENTE_SUCCESS,
  GET_VENTE_FAIL,
  ADD_VENTE_SUCCESS,
  ADD_VENTE_FAIL,
  UPDATE_VENTE_SUCCESS,
  UPDATE_VENTE_FAIL,
  DELETE_VENTE_SUCCESS,
  DELETE_VENTE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  ventes: [],
  error: {},
};

const Ventes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENTES_SUCCESS:
      return {
        ...state,
        ventes: action.payload,
      };

    case GET_VENTES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_VENTE_SUCCESS:
      return {
        ...state,
        vente: action.payload,
      };
  
    case GET_VENTE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_VENTE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        ventes: [...state.ventes, action.payload],
      };

    case ADD_VENTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_VENTE_SUCCESS:
      return {
        ...state,
        ventes: state.ventes.map(vente =>
          vente.id.toString() === action.payload.id.toString()
            ? { vente, ...action.payload }
            : vente
        ),
        isVenteUpdated: true
      };

    case UPDATE_VENTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isVenteUpdated: false
      };

    case DELETE_VENTE_SUCCESS:
      return {
        ...state,
        ventes: state.ventes.filter(
          vente => vente.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_VENTE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Ventes;
