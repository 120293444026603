import {
  GET_PARAMETRES,
  GET_PARAMETRES_SUCCESS,
  GET_PARAMETRES_FAIL,
  GET_PARAMETRE,
  GET_PARAMETRE_FAIL,
  GET_PARAMETRE_SUCCESS,
  ADD_NEW_PARAMETRE,
  ADD_PARAMETRE_SUCCESS,
  ADD_PARAMETRE_FAIL,
  UPDATE_PARAMETRE,
  UPDATE_PARAMETRE_SUCCESS,
  UPDATE_PARAMETRE_FAIL,
  DELETE_PARAMETRE,
  DELETE_PARAMETRE_SUCCESS,
  DELETE_PARAMETRE_FAIL,
} from "./actionTypes";

export const getParametres = () => ({
  type: GET_PARAMETRES,
});

export const getParametresSuccess = parametres => ({
  type: GET_PARAMETRES_SUCCESS,
  payload: parametres,
});

export const getParametresFail = error => ({
  type: GET_PARAMETRES_FAIL,
  payload: error,
});

export const getParametre = () => ({
  type: GET_PARAMETRE,
});

export const getParametreSuccess = parametre => ({
  type: GET_PARAMETRE_SUCCESS,
  payload: parametre,
});

export const getParametreFail = error => ({
  type: GET_PARAMETRE_FAIL,
  payload: error,
});

export const addNewParametre = parametre => ({
  type: ADD_NEW_PARAMETRE,
  payload: parametre,
});

export const addParametreSuccess = parametre => ({
  type: ADD_PARAMETRE_SUCCESS,
  payload: parametre,
});

export const addParametreFail = error => ({
  type: ADD_PARAMETRE_FAIL,
  payload: error,
  
});

export const updateParametre = (cuv,parametre) => ({
  type: UPDATE_PARAMETRE,
  payload: { id: cuv, data: parametre },
} );

export const updateParametreSuccess = (cuv,parametre) => ({
  type: UPDATE_PARAMETRE_SUCCESS,
  payload:  { id: cuv, data: parametre }, 
});

export const updateParametreFail = error => ({
  type: UPDATE_PARAMETRE_FAIL,
  payload: error,
});

export const deleteParametre = parametre => ({
  type: DELETE_PARAMETRE,
  payload: parametre,
});

export const deleteParametreSuccess = parametre => ({
  type: DELETE_PARAMETRE_SUCCESS,
  payload: parametre,
});

export const deleteParametreFail = error => ({
  type: DELETE_PARAMETRE_FAIL,
  payload: error,
});



 
