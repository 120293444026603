import React from 'react';
import { Button} from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoLight from "../assets/images/logo-light.png";
//<img src={logoDark} alt="logo-dark" height="24" />

class PDFGenerator extends React.Component {
  generatePDF = () => {
    const { tableData,tableHead,tableFoot,headText,tableData2,tableHead2,tableFoot2,pdfSrc} = this.props;

    const doc = new jsPDF('landscape');

    const headerText = headText;
    
    
    const headerImage = "C:\Dev\PETROFASO\app\src\assets\images\logo-light.jpeg"; // Remplacez par l'URL de votre image
    const footerText = 'SFK/PETROFASO';

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const addHeader = () => {
      doc.setFontSize(18);
      const textWidth = doc.getStringUnitWidth(headerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const textOffset = (pageWidth - textWidth) / 2;
       //Desner un traite
       const lineX = (pageWidth - textWidth) / 2; 
       const lineY = pageHeight-198;
       doc.line(lineX, lineY, lineX + textWidth, lineY); 
       doc.setLineHeightFactor(1.5);

      doc.text(headerText, textOffset, 10);
      //doc.addImage(headerImage, 'JPEG', pageWidth - 50, 5, 40, 40);
       // Ajout de l'image d'entête
       const imageWidth = 20;
       const imageHeight = 20;
       const imageX = pageWidth - imageWidth - 10;
       const imageY = 5;
       doc.addImage(headerImage, 'JPEG', imageX, imageY, imageWidth, imageHeight);
    };

   

    const addFooter = () => {
      doc.setFontSize(12);
      const pageNumber = doc.internal.getNumberOfPages();
      const footerX = pageWidth - doc.internal.pageSize.getWidth() / 2;
      const footerY = pageHeight - 10;
      doc.text(`Page ${pageNumber}`, footerX+120, footerY);

      const date = new Date().toLocaleDateString();
      doc.text(date, 10, footerY);
      doc.setTextColor(63, 72, 204); // Couleur du texte (rouge)
      //Desner un traite
      const textWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const lineX = (pageWidth - textWidth) / 2; 
      const lineY = footerY-4;
      doc.line(lineX, lineY, lineX + textWidth, lineY); 
      doc.setLineHeightFactor(1.5);
      //doc.setFontStyle('bold');
      //doc.textWithLink(footerText, 130, footerY - 5, { url: '', underline: { color: [0, 0, 0] } });
      doc.text(footerText, 130, footerY - 5);
    };
     
    const generateTable = () => {
      //const headers =tableHead ; //[['ID', 'Nom', 'Prix']];
      ///const data = tableData.map((d, index) => [index+1, d.numVente, d.agent, d.produit,d.pompe,d.indexDepart,d.indexArrivee,d.quantite,d.prixVente,d.retourCuve,d.montant]);
      //const data = products.map((value, index) => [index, value]);

      //console.log('tableData',tableData)
      //console.log('data ',data)

      const customStyles = {
        // Styles personnalisés pour le tableau
        // Vous pouvez ajuster ces valeurs en fonction de vos besoins
        lineWidth: 0.01, // Épaisseur des bordures
        lineColor: [0, 0, 0], // Couleur des bordures (RVB)
        //fillColor: [240, 240, 240], // Couleur de fond des cellules du tableau (RVB)
       // textColor: [0, 0, 0], // Couleur du texte (RVB)
        fontStyle: 'normal', // Style du texte (normal, bold, italic, etc.)
        //fontSize: 10, // Taille de police du texte
        cellPadding: 5, // Espacement interne des cellules
        valign: 'middle', // Alignement vertical du contenu dans les cellules (top, middle, bottom)
        halign: 'center', // Alignement horizontal du contenu dans les cellules (left, center, right)
      };


      const columnWidths = [2,5, 10, 10];
      const tableWidth = 150;
      const pageWidth = doc.internal.pageSize.getWidth();
      const tableX = (pageWidth - tableWidth) / 2;

    


      doc.autoTable({
        head: tableHead,
        body: tableData,
        foot: tableFoot,
        startY: 20,
        didDrawPage:   function (tableData) {
          // Vérifier si le tableau déborde sur une nouvelle page
          if (tableData.pageNumber === 1) {
            // La première page, ajouter l'en-tête ici
            addHeader(); 
            addFooter();
            
          }
          // Vérifier si nous sommes sur une nouvelle page autre que la première
          else if (tableData.pageNumber !== 1) {
            // Ajouter l'en-tête pour les autres pages ici
            //addHeader();
            addFooter();
          }
        },
        showHead: 'firstPage', 
        showFoot: 'lastPage',
        styles: pdfSrc? {lineWidth: 0.01, lineColor: [0, 0, 0],fontSize: 8, cellPadding: 3, valign: 'middle', halign: 'center',}: customStyles,
       
      });
     
     if(tableData2) {
      //const textAboveTable = 'TABLEAU RECAPITULATIF';
      const textX = tableX + (tableWidth / 2); // Position X du texte au centre du tableau
      const textY = 15; // Position Y du texte (ajustez selon vos besoins)
      doc.setFontSize(14); // Taille de police du texte
    
      
      const addTitle = (title) => {
        // Afficher le texte du titre avant le rendu du tableau
        const textX = tableX + (tableWidth / 2); // Position X du texte au centre du tableau
        const textY = doc.autoTable.previous.finalY +16; // Position Y du texte (juste au-dessus du tableau)
        doc.setFontSize(14); // Taille de police du texte
        doc.text(title, textX, textY, { align: 'center' }); // Ajout du texte au-dessus du tableau
      };
    
   

      const lastTableY =  doc.previousAutoTable.finalY; // Position Y de départ pour le dernier tableau (en bas de la page)
      const availableSpace = doc.internal.pageSize.height - lastTableY - 10; // 10 est une marge pour assurer que le tableau ne déborde pas de la page    
      // Calculer le nombre de lignes pouvant être affichées sur la dernière page
      const maxRowsOnLastPage = doc.autoTable.previous.finalY  - lastTableY;
      const options = {
        head: tableHead2,
        body: tableData2,
        foot: tableFoot2,
         
       // startY: doc.previousAutoTable.finalY + 20, // Add some space between the tables
        didDrawPage: addFooter,
        tableWidth: tableWidth,
        margin: { left: tableX }
      };


       if (availableSpace >= maxRowsOnLastPage) {
            // Si suffisamment d'espace est disponible sur la dernière page, afficher le tableau normalement
            //doc.text(textAboveTable, textX, doc.previousAutoTable.finalY + 16, { align: 'center' }); // Ajout du texte au-dessus du tableau
            options.startY = doc.autoTable.previous.finalY+20; // Position de départ normale pour le tableau
            addTitle('TABLEAU RECAPITULATIF');
            doc.autoTable(options); 

          } else {
         //   doc.text(textAboveTable, textX, doc.previousAutoTable.finalY , { align: 'center' }); // Ajout du texte au-dessus du tableau
  
            // Sinon, ajouter une nouvelle page et commencer le tableau à partir du bas de la page
            doc.addPage();
            options.startY = lastTableY; // Position de départ pour le dernier tableau
            addTitle('TABLEAU RECAPITULATIF');
            doc.autoTable(options);
          }
       
       
   }
   

    };

    addHeader();
    generateTable();

    doc.save('tableau.pdf');
   
  };
  

  render() {
  //  const btnText=this.generatePDF.btnText;
    return (
      <div>
        {/* <button onClick={this.generatePDF}>Imprimer</button> */}
        <Button color="success" size="lg" style={{width: '150px', height: '50px',}} onClick={this.generatePDF}>Generer PDF</Button>
      </div>
    );
  }
}

export default PDFGenerator;
