import {
  GET_DEPENSES,
  GET_DEPENSES_SUCCESS,
  GET_DEPENSES_FAIL,
  GET_DEPENSE,
  GET_DEPENSE_FAIL,
  GET_DEPENSE_SUCCESS,
  ADD_NEW_DEPENSE,
  ADD_DEPENSE_SUCCESS,
  ADD_DEPENSE_FAIL,
  UPDATE_DEPENSE,
  UPDATE_DEPENSE_SUCCESS,
  UPDATE_DEPENSE_FAIL,
  DELETE_DEPENSE,
  DELETE_DEPENSE_SUCCESS,
  DELETE_DEPENSE_FAIL,
} from "./actionTypes";

export const getDepenses = () => ({
  type: GET_DEPENSES,
});

export const getDepensesSuccess = depenses => ({
  type: GET_DEPENSES_SUCCESS,
  payload: depenses,
});

export const getDepensesFail = error => ({
  type: GET_DEPENSES_FAIL,
  payload: error,
});

export const getDepense = () => ({
  type: GET_DEPENSE,
});

export const getDepenseSuccess = depense => ({
  type: GET_DEPENSE_SUCCESS,
  payload: depense,
});

export const getDepenseFail = error => ({
  type: GET_DEPENSE_FAIL,
  payload: error,
});

export const addNewDepense = depense => ({
  type: ADD_NEW_DEPENSE,
  payload: depense,
});

export const addDepenseSuccess = depense => ({
  type: ADD_DEPENSE_SUCCESS,
  payload: depense,
});

export const addDepenseFail = error => ({
  type: ADD_DEPENSE_FAIL,
  payload: error,
  
});

export const updateDepense = (dep,depense) => ({
  type: UPDATE_DEPENSE,
  payload:  { id: dep, data: depense },
});

export const updateDepenseSuccess = depense => ({
  type: UPDATE_DEPENSE_SUCCESS,
  payload: depense,
});

export const updateDepenseFail = error => ({
  type: UPDATE_DEPENSE_FAIL,
  payload: error,
});

export const deleteDepense = depense => ({
  type: DELETE_DEPENSE,
  payload: depense,
});

export const deleteDepenseSuccess = depense => ({
  type: DELETE_DEPENSE_SUCCESS,
  payload: depense,
});

export const deleteDepenseFail = error => ({
  type: DELETE_DEPENSE_FAIL,
  payload: error,
});



 
