import { takeEvery, put, call } from "redux-saga/effects"

// Equipes Redux States
import {
  ADD_NEW_EQUIPE,
  DELETE_EQUIPE,
  GET_EQUIPES,
  GET_EQUIPE,
  UPDATE_EQUIPE,
} from "./actionTypes"
import {
  getEquipesSuccess,
  getEquipesFail,
  getEquipeSuccess,
  getEquipeFail,
  addEquipeFail,
  addEquipeSuccess,
  updateEquipeSuccess,
  updateEquipeFail,
  deleteEquipeSuccess,
  deleteEquipeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getEquipes,
  getEquipe,
  addNewEquipe,
  updateEquipe,
  deleteEquipe,
} from "../../helpers/api_back"


function* fetchEquipes() {
  try {
    const response = yield call(getEquipes)
    yield put(getEquipesSuccess(response))
  } catch (error) {
    yield put(getEquipesFail(error))
  }
}

function* fetchEquipe() {
  try {
    const response = yield call(getEquipe)
    yield put(getEquipeSuccess(response))
  } catch (error) {
    yield put(getEquipeFail(error))
  }
}


function* onAddNewEquipe({ payload: Equipe }) {
 
  try {
    const response = yield call(addNewEquipe, Equipe)
    
    yield put(addEquipeSuccess(response))
  } catch (error) {
    yield put(addEquipeFail(error))
  }
}

function* onUpdateEquipe({ payload: Equipe }) {
  try {
    const response = yield call(updateEquipe, Equipe)
    yield put(updateEquipeSuccess(response))
  } catch (error) {
    yield put(updateEquipeFail(error))
  }
}

function* onDeleteEquipe({ payload: Equipe }) {
  try {
    const response = yield call(deleteEquipe, Equipe)
    yield put(deleteEquipeSuccess(response))
  } catch (error) {
    yield put(deleteEquipeFail(error))
  }
}
 

function* EquipesSaga() {
  yield takeEvery(GET_EQUIPES, fetchEquipes)
  yield takeEvery(GET_EQUIPE, fetchEquipe)
  yield takeEvery(ADD_NEW_EQUIPE, onAddNewEquipe)
  yield takeEvery(UPDATE_EQUIPE, onUpdateEquipe)
  yield takeEvery(DELETE_EQUIPE, onDeleteEquipe) 
}

export default EquipesSaga
