export const GET_MOUVEMENTSTOCKS = "GET_MOUVEMENTSTOCKS";
export const GET_MOUVEMENTSTOCKS_SUCCESS = "GET_MOUVEMENTSTOCKS_SUCCESS";
export const GET_MOUVEMENTSTOCKS_FAIL = "GET_MOUVEMENTSTOCKS_FAIL";

export const GET_MOUVEMENTSTOCK = "GET_MOUVEMENTSTOCK";
export const GET_MOUVEMENTSTOCK_SUCCESS = "GET_MOUVEMENTSTOCK_SUCCESS";
export const GET_MOUVEMENTSTOCK_FAIL = "GET_MOUVEMENTSTOCK_FAIL";

export const ADD_NEW_MOUVEMENTSTOCK = "ADD_NEW_MOUVEMENTSTOCK";
export const ADD_MOUVEMENTSTOCK_SUCCESS = "ADD_MOUVEMENTSTOCK_SUCCESS";
export const ADD_MOUVEMENTSTOCK_FAIL = "ADD_MOUVEMENTSTOCK_FAIL";

export const UPDATE_MOUVEMENTSTOCK = "UPDATE_MOUVEMENTSTOCK";
export const UPDATE_MOUVEMENTSTOCK_SUCCESS = "UPDATE_MOUVEMENTSTOCK_SUCCESS";
export const UPDATE_MOUVEMENTSTOCK_FAIL = "UPDATE_MOUVEMENTSTOCK_FAIL";

export const DELETE_MOUVEMENTSTOCK = "DELETE_MOUVEMENTSTOCK";
export const DELETE_MOUVEMENTSTOCK_SUCCESS = "DELETE_MOUVEMENTSTOCK_SUCCESS";
export const DELETE_MOUVEMENTSTOCK_FAIL = "DELETE_MOUVEMENTSTOCK_FAIL";
