/* DEPENSE_CATEGORIES */
export const GET_DEPENSE_CATEGORIES = "GET_DEPENSE_CATEGORIES";
export const GET_DEPENSE_CATEGORIES_SUCCESS = "GET_DEPENSE_CATEGORIES_SUCCESS";
export const GET_DEPENSE_CATEGORIES_FAIL = "GET_DEPENSE_CATEGORIES_FAIL";

export const GET_DEPENSE_CATEGORIE = "GET_DEPENSE_CATEGORIE";
export const GET_DEPENSE_CATEGORIE_SUCCESS = "GET_DEPENSE_CATEGORIE_SUCCESS";
export const GET_DEPENSE_CATEGORIE_FAIL = "GET_DEPENSE_CATEGORIE_FAIL";

export const ADD_NEW_DEPENSE_CATEGORIE = "ADD_NEW_DEPENSE_CATEGORIE";
export const ADD_DEPENSE_CATEGORIE_SUCCESS = "ADD_DEPENSE_CATEGORIE_SUCCESS";
export const ADD_DEPENSE_CATEGORIE_FAIL = "ADD_DEPENSE_CATEGORIE_FAIL";

export const UPDATE_DEPENSE_CATEGORIE = "UPDATE_DEPENSE_CATEGORIE";
export const UPDATE_DEPENSE_CATEGORIE_SUCCESS = "UPDATE_DEPENSE_CATEGORIE_SUCCESS";
export const UPDATE_DEPENSE_CATEGORIE_FAIL = "UPDATE_DEPENSE_CATEGORIE_FAIL";

export const DELETE_DEPENSE_CATEGORIE = "DELETE_DEPENSE_CATEGORIE";
export const DELETE_DEPENSE_CATEGORIE_SUCCESS = "DELETE_DEPENSE_CATEGORIE_SUCCESS";
export const DELETE_DEPENSE_CATEGORIE_FAIL = "DELETE_DEPENSE_CATEGORIE_FAIL";

 