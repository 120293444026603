import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';
import Select  from 'react-select';
import makeAnimated from 'react-select/animated';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _, { set } from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';
import PDFGenerator from '../../utilitaires/PDFGenerator'; 
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
 
import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

 //equipes
 getEquipes as onGetEquipes,

    //categories
    getCategories as onGetCategories,

    //produits
    addNewProduit as onAddNewProduit,
    deleteProduit as onDeleteProduit,
    getProduits as onGetProduits,
    getProduit as onGetProduit,
    updateProduit as onUpdateProduit,

     //Ventes
     addNewVente as onAddNewVente,
     deleteVente as onDeleteVente,
     getVentes as onGetVentes,
     getVente as onGetVente,
     updateVente as onUpdateVente,

     //Agents
     getAgents as onGetAgents,

     
    //cuves
    getCuves as onGetCuves,

      //Pompes
    getPompes as onGetPompes,
    getAgentsSuccess,


  } from "../../store/actions"; 

  
 
const ListProduits = () => {
  const  formatDate = (inputDate)=> {
    const date = new Date(inputDate);
    
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

const d=new Date();
const today = new Date().toISOString().split('T')[0];
 const dateDeb = new Date(d.setDate(d.getDate() -7)).toISOString().split('T')[0];
 

    
const [typMsg, setTypMsg] = useState('');
const [agnt, setAgnt] = useState('');
const [selectedAgnts, setSelectedAgnts] = useState([]);
const [prod, setProd] = useState('');
const [selectedProds, setSelectedProds] = useState([]);
const [pompe, setPompe] = useState('');
const [selectedPompes, setSelectedPompes] = useState([]);
const [startDate, setStartDate] = useState(dateDeb);
 
const [endDate, setEndDate] = useState(today);
//setEndDate(today)

const dispatch = useDispatch();
 //console.log("Document",Document)
   
const equipes = useSelector((state) => ({  equipes: state.equipes.equipes  })); 
const produits = useSelector((state) => ({  produits: state.produits.produits  }));
const categories = useSelector((state) => ({  categories:  state.categories.categories  }));
const users = useSelector((state) => ({  users:  state.users.users  }));
const ventes = useSelector((state) => ({  ventes: state.ventes.ventes })); 
const agents = useSelector((state) => ({  agents:  state.agents.agents  }));
const pompes = useSelector((state) => ({pompes: state.pompes.pompes}));
const cuves = useSelector((state) => ({  cuves:  state.cuves.cuves  }));



/// option  pour afficher la liste des produits et la liste des agents
const agntOptions=Object.values(agents.agents)
.filter(a=>a.actif==1) 
.map(agnt => ({
    value:agnt.id, label:agnt.nom+" "+agnt.prenom
}))
agntOptions.unshift({ value: '', label: '' });

const prodOptions=Object.values(produits.produits).map(prod => ({
    value:prod.id, label:prod.nomProd
}))
prodOptions.unshift({ value: '', label: '' });

//etape 1 , determination des cuves contenant le produti seectionner 
// const cuvesProd = Object.values(cuves.cuves).filter(cuv=> cuv.produit===prod);   single method
const cuvesProd = Object.values(cuves.cuves).filter(cuv=> selectedProds.includes(cuv.produit));

 

const pompeCuveProd = Object.values(pompes.pompes).filter((pmp) => {
    const cuvePompe = cuvesProd.find(cuv => cuv.id === pmp.cuve);
   // console.log("cuvePompe" ,cuvePompe)
    return cuvePompe ? (pmp.cuve = cuvePompe.id) : false;
  });

 

 
 
const pompeOptions=pompeCuveProd.map(pomp => ({
    value:pomp.id, label:pomp.libelle
}))
pompeOptions.unshift({ value: '', label: '' });
 
// const pompeOptions=Object.values(pompes.pompes).map(pomp => ({
//     value:pomp.id, label:pomp.libelle
// }))

  
/// lorsque l'utilisateur choisi un produit
const handleChangeProd =(prod)=>{
    setProd(prod.value) 
 
}

/// lorsque l'utilisateur choisi une pompe
const handleChangePompe =(pmp)=>{
   // console.log('ajout agnt,prod,pmp',agnt,prod,pmp.value)
   setPompe(pmp.value)
 //  handleAddVente(agnt,prod,pmp.value)
}


// const animatedComponents = makeAnimated();
// const MultiSelectDropdown = () => {
//             const colourOptions = [
//               { value: 'red', label: 'Red' },
//               { value: 'blue', label: 'Blue' },
//               { value: 'green', label: 'Green' },
//               { value: 'yellow', label: 'Yellow' },
//               { value: 'purple', label: 'Purple' }, 
//             ];

              

//           return (
//             <Select
//             closeMenuOnSelect={false}
//             components={animatedComponents}
//             defaultValue={[colourOptions[4], colourOptions[5]]}
//             isMulti
//             options={colourOptions}
//           />
//           );
// };

 
 
///lorsque l'utilisateur choisi un agent
const handleChangeAgnt =(agnt)=>{
  
     setAgnt(agnt.value)
    //setSelectedAgnts(Array.from(agnt.target.selectedAgnts, (a) => a.value));
    
     
   }
   

 


   const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };


  //impression d ecran
  const handlePrint = () => {
    const content = document.getElementById('content');

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape');

      const contentWidth = canvas.width;
    const contentHeight = canvas.height;

    // Calculer les proportions pour adapter l'image au PDF
    const ratio = contentWidth / contentHeight;
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdfWidth / ratio;


      pdf.addImage(imgData, 'PNG', 0, 0,pdfWidth, pdfHeight);
      pdf.save('capture.png');
    });
  };




function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 
 /* faire clignoté le champ incorrecte */
const [isBlinking, setIsBlinking] = useState(false);

useEffect(() => {
  const interval = setInterval(() => {
    setIsBlinking(prevIsBlinking => !prevIsBlinking);
    
  }, 500); // 500ms pour la durée d'un clignotement, ajustez selon vos besoins
  
  return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage du composant
}, []);


 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'un produit
const [upVte,setUpVte]=useState([]);
const [validateButtonDisabled, setValidateButtonDisabled] = useState(true);
const [produit, setProduit] = useState('');
const [dateEnregistrement, setDateEnregistrement] = useState('');
const [pomp, setPomp] = useState('');
const [indexDepart, setIndexDepart] = useState('');
const [indexArrivee, setIndexArrivee] = useState('');
const [quantite, setQuantite] = useState('');
const [prixVente, setPrixVente] = useState('');
const [montant, setMontant] = useState('');
const [montantVerse, setMontantVerse] = useState('');
const [montantEcart, setMontantEcart] = useState('');
const [quantiteReel, setQuantiteReel] = useState('');
const [retourCuve, setRetourCuve] = useState('');
const [agent, setAgent] = useState('');
 
 
const [idVente, setIdVente] = useState('');

const newVente = {
    produit: produit, 
    pompe:pomp,
    indexDepart:indexDepart,
    indexArrivee:indexArrivee,
    quantite:quantite,
    prixVente:prixVente,
    montant:montant,
    retourCuve:retourCuve,
    agent:agent,
 
};

const saveNewVente = (e) => {
    e.preventDefault(); 
    setTypMsg('add')
    dispatch(onAddNewVente(newVente));
    
    setmodal_list(false);
    
 
  };

  // Mise a jour d"un Produit 
  const upVente = {
    produit: produit, 
    pompe:pomp,
    indexDepart:indexDepart,
    indexArrivee:indexArrivee,
    quantite:quantite,
    quantiteReel:quantiteReel,
    prixVente:prixVente,
    montant:montant,
    montantVerse:montantVerse,
    montantEcart:montantEcart,
    retourCuve:retourCuve,
    agent:agent,
    dateEnregistrement:dateEnregistrement,
 
  };
 

  //interdiction des valeurs non numerique
  const handleKeyDown = (e) => {
 
    const { key } = e;

   // console.log('key',key)

  // Interdire les caractères non numériques, sauf la touche Backspace
  // if (!/\d/.test(key)  && (key !== 'Backspace' || key !== '' || key !== 'Delete' || key.key !== '.'))
  if (!/\d/.test(key)  && key !== 'Backspace' ){
  
      if(key !== '.' ) {

        if(key !== 'Tab') {
          if(key !== 'ArrowRight'){
            if(key !== 'ArrowLeft') e.preventDefault() ;
          }
        }   
      } 

 }
};
 

  const saveUpdateVente = (e) => {
    e.preventDefault(); 
    dispatch(onUpdateVente(idVente,upVente));
    setTypMsg('upd')
    //console.log('jjkl')
    setmodal_update(false);
    
  };


  /// Suppression d'un produit
 

  const [listIdVente, setListIdVente] = useState([]);

  const idVenteChecBoxSelected = (venteId) => {
    setListIdVente(prevSelectedRows => ({
      ...prevSelectedRows,
      [venteId]: !prevSelectedRows[venteId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdVente)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const venteIds = Object.keys(filteredList);
 
  const deleteVente = ( vente) => { 
    
    if(vente)dispatch(onDeleteVente(vente));
    setTypMsg('del')
    if(listIdVente && venteIds){venteIds.forEach((venteId) =>dispatch(onDeleteVente(venteId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination
 
 
const [filteredData, setFilteredData] = useState(ventes.ventes);
const resetFiltre=()=>{
    setProd('')
    setPompe('')
    setAgnt('')
    setStartDate(dateDeb)
    setEndDate(today)
    //window.location.reload();
}
 
        useEffect(() => {
           
            // Fonction de recherche
            const searchData = () => {
            const filtered =  Object.values(ventes.ventes).filter((item) => {
                const itemDate = new Date(item.dateEnregistrement);
                const start = new Date(startDate);
                const end = new Date(endDate );
                // Ajouter 1 jour à la date de fin
                end.setDate(end.getDate() + 1);  
 

                return   selectedProds.length && selectedAgnts.length && selectedPompes.length ? itemDate >= start && itemDate <= end && selectedProds.includes(item.produit) && selectedAgnts.includes(item.agent) && selectedPompes.includes(item.pompe)
                          : selectedProds.length && selectedAgnts.length  ? itemDate >= start && itemDate <= end && selectedProds.includes(item.produit) && selectedAgnts.includes(item.agent)
                          : selectedProds.length && selectedPompes.length ? itemDate >= start && itemDate <= end && selectedProds.includes(item.produit) && selectedPompes.includes(item.pompe)
                          : selectedAgnts.length && selectedPompes.length ? itemDate >= start && itemDate <= end && selectedAgnts.includes(item.agent) && selectedPompes.includes(item.pompe)
                          : selectedAgnts.length ? itemDate >= start && itemDate <= end && selectedAgnts.includes(item.agent)
                          : selectedPompes.length ? itemDate >= start && itemDate <= end && selectedPompes.includes(item.pompe)
                          : selectedProds.length ? itemDate >= start && itemDate <= end && selectedProds.includes(item.produit)
                          : itemDate >= start && itemDate <= end 
                
                //                 || item.agent===agnt||item.agent  && item.pompe===pompe||item.pompe
            });

            setFilteredData(filtered);
                     

            
            };

            // Appeler la fonction de recherche lorsque les dates changent
            searchData();
        }, [d,dateDeb,startDate, endDate,selectedProds,selectedPompes,selectedAgnts,typMsg,onDeleteVente,onUpdateVente]);






const dataTable= filteredData.length!==0 ? 
filteredData.map(prod => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    //console.log('xcate',categorie)
    const agent = Object.values(agents.agents).find(p => p.id === prod.agent);
    const produit = Object.values(produits.produits).find(p => p.id === prod.produit);
    const pompe = Object.values(pompes.pompes).find(p => p.id === prod.pompe);
    const equipe = Object.values(equipes.equipes).find(eq=> eq.id === prod.equipe);
    const user = Object.values(users.users).find(us => us.id === prod.createdBy);
    return { ...prod , user: user ? user.prenom+' '+user.nom:'',equipeInfo:equipe? equipe.libelle:'',agentInfo:agent?agent.prenom+' '+agent.nom :'', pompeInfo:pompe? pompe.libelle:'', produitInfo:produit? produit.nomProd:'' ,produitStock:produit? produit.stockProd:''};
  }) 
  : 
   '';
 


  
 


  //pagination

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (vt) => {
    const value = vt.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.numVente), _.toLower(value))||
      _.startsWith(_.toLower(item.produit), _.toLower(value))||
      _.startsWith(_.toLower(item.pompe), _.toLower(value))||
      _.startsWith(_.toLower(item.indexDepart), _.toLower(value))||
      _.startsWith(_.toLower(item.indexArrivee), _.toLower(value))||
      _.startsWith(_.toLower(item.prixVente), _.toLower(value))||
      _.startsWith(_.toLower(item.quantite), _.toLower(value))||
      _.startsWith(_.toLower(item.retourCuve), _.toLower(value))||
      _.startsWith(_.toLower(item.montant), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (prod, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


///maping des donnees pour pdf 
const headText ='HISTORIQUE VENTE DU '+formatedDate(startDate).slice(0,10)+' AU '+formatedDate(endDate).slice(0,10)
const pdfSrc='v1'
const tableHead =[['N°','Vente', 'Agent', 'Equipe','Produit','Pompe','Indice Départ', 'Indice Arrivee','Qte','Retour Cuve','Qte Sortie','Prix V.','Montant']]
const tableD=Object.values(dataTable).map((vt)=>{ 
  const agent = Object.values(agents.agents).find(ag => ag.id === vt.agent);
  const equipe = Object.values(equipes.equipes).find(eq => eq.id === vt.equipe);
  const produit = Object.values(produits.produits).find(prod=> prod.id === vt.produit);
  const pompe = Object.values(pompes.pompes).find(pp => pp.id === vt.pompe);
  return { ...vt,agent: agent? agent.prenom+' '+agent.nom :'',equipe: equipe? equipe.libelle:'',produit: produit? produit.nomProd:'',pompe: pompe? pompe.libelle :'' };})

  const tableData= tableD.map((d, index) => [index+1, d.numVente, d.agent,d.equipe, d.produit,d.pompe,parseFloat(d.indexDepart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.indexArrivee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.retourCuve?d.retourCuve:0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.quantiteReel).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);
  const tableFoot=[['','','','','','','','','','','','TOTAL',parseFloat(tableD.reduce((acc, v) => acc + v.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ]]


  const tableHead2 =[['N°','Date','Vente', 'Produit','Pompe','Quantite Reelle','Prix Vente','Montant']]

  const tableData2= tableD.map((d, index) => [index+1,formatedDate(d.dateEnregistrement).slice(0,10), d.numVente, d.produit,d.pompe,parseFloat(d.quantiteReel).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);
  const tableFoot2=[['','','','','','','TOTAL',parseFloat(tableD.reduce((acc, v) => acc + v.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ]]



  const tableHead3 =[['N°','Produit','Quantité Total', 'Montant Total']];

  const tableD3 = tableD.reduce((accumulator, app) => {
   if (!accumulator[app.produit]) {
     accumulator[app.produit] = {
       produit: app.produit,
       quantiteTotal: 0,
       montantTotal: 0,
     };
   }
   accumulator[app.produit].quantiteTotal += app.quantiteReel;
   accumulator[app.produit].montantTotal += app.montant;
   return accumulator;
 }, {});
 const totauxMontant = tableD.reduce((accumulator, ap) => accumulator + ap.montant, 0);
 const tableFoot3 =[['','','TOTAL',parseFloat(totauxMontant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ]];
 const tableData3= Object.values(tableD3).map((d, index) => [index+1,d.produit,parseFloat(d.quantiteTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ,parseFloat(d.montantTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);



//maples données pour generer un tableau au niveau du peid de page

const subTable = (data) => {
    const totauxMontant = data.reduce((accumulator, vt) => accumulator + vt.montant, 0);
    // Regrouper les données par produit et calculer le total quantités et des montants
    const approData = data.reduce((accumulator, v) => {
      if (!accumulator[v.produit]) {
        accumulator[v.produit] = {
          produit: v.produit,
          quantiteTotal: 0,
          montantTotal: 0,
        };
      }
      accumulator[v.produit].quantiteTotal += v.quantiteReel;
      accumulator[v.produit].montantTotal += v.montant;
      return accumulator;
    }, {});
  
    const newData = Object.values(approData);
  
    return (
      <table style={{ width:"49%",borderCollapse: "collapse" ,marginBottom:"20px",fontSize: '20px',}}>
        <thead>
          <tr>
            <th style={{border:'1px solid #73FBFD' ,color:"white",backgroundColor:" #16417C",textAlign:"left", padding: "8px"}}>Produit</th>
            <th style={{border:'1px solid #73FBFD' ,color:"white",backgroundColor:" #16417C",textAlign:"left", padding: "8px"}}>Quantite Total</th>
            <th style={{border:'1px solid #73FBFD' ,color:"white",backgroundColor:" #16417C",textAlign:"left", padding: "8px"}}>Montant Total</th>
          </tr>
        </thead>
        <tbody>
          {newData.map((d,index) => (
            <tr key={index+1}  style={(index+1) % 2 === 0 ? { backgroundColor: '#EBF4FC',border:'1px solid #73FBFD' , } : {border:'1px solid #73FBFD' ,}}>
              <td stylle={{border:'1px solid #73FBFD' ,textAlign:"center",padding: "8px"}}>{d.produit}</td>
              <td stylle={{ border:'1px solid #73FBFD' ,textAlign:"center",padding: "8px"}}>{parseFloat(d.quantiteTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
              <td stylle={{border:'1px solid #73FBFD' , textAlign:"center",padding: "8px"}}>{parseFloat(d.montantTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr>
          <td style={{ padding: '8px', border: '1px solid #7E919C', fontWeight: 'bold',color:"white",backgroundColor: '#7E919C'}}>  </td>
          <td style={{ padding: '8px', border: '1px solid #7E919C', fontWeight: 'bold',color:"white",backgroundColor: '#7E919C' }}> TOTAL</td>
          <td style={{ padding: '8px', border: '1px solid #7E919C', fontWeight: 'bold',color:"white",backgroundColor: '#7E919C'}}>{parseFloat(totauxMontant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
        </tr>
      </tfoot>
      </table>
    );
  };







const currentPageData = dataTable.length>=1 ?  dataTable.slice(offset, offset + PER_PAGE).map((vt,index) =>  (
    <tr key={index}   style={index % 2 === 0 ? { backgroundColor: "#E6F1FF",border: "2px solid #3282F6",color:'black'}:{ backgroundColor: "#EBFFED" ,border: "2px solid #3282F6",color:'black'}}>
    {/* <th style={{ border: "2px solid #3282F6" }} scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idVenteChecBoxSelected(vt.id)}}   />
        </div>
    </th> */}
    <td className="prod_id" style={{ border: "2px solid #3282F6" ,display: "none" }}>{vt.id}</td> 
    <td style={{ border: "2px solid #3282F6" }} className="prod_index">{index+1}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_utilisateur">{formatedDate(vt.dateEnregistrement).slice(0,10)}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.numVente}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.agentInfo}</td> 
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.equipeInfo}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.produitInfo}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.pompeInfo}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.indexDepart ? parseFloat(vt.indexDepart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '):''}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.indexArrivee? parseFloat(vt.indexArrivee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '):''}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.quantite ? parseFloat(vt.quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '):''}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.retourCuve? parseFloat(vt.retourCuve ? vt.retourCuve:0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '):''}</td>
    <td style={{ border: "2px solid #3282F6" }} className="quaniteReel">{parseFloat(vt.quantiteReel).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prixVente">{parseFloat(vt.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td style={{ border: "2px solid #3282F6" }} className="montant">{parseFloat(vt.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td style={{ border: "2px solid #3282F6" }} className="montantVerse">{parseFloat(vt.montantVerse).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td style={{ border: "2px solid #3282F6" }} className="montantEcart">{parseFloat(vt.montantEcart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    
    <td style={{ border: "2px solid #3282F6" }} className="prod_date">{ vt.user}</td>
    
    <td style={{ border: "2px solid #3282F6" }} >
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setProduit(vt.produit);setPomp(vt.pompe);setIndexDepart(vt.indexDepart);setIndexArrivee(vt.indexArrivee);setQuantite(vt.quantite);setPrixVente(vt.prixVente);setMontant(vt.montant);setMontantVerse(vt.montantVerse);setMontantEcart(vt.montantEcart);setRetourCuve(vt.retourCuve);setQuantiteReel(vt.quantiteReel);setAgent(vt.agent);setIdVente(vt.id);setDateEnregistrement(formatDate(vt.dateEnregistrement));tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteVente(vt.id)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


)) :'';

   useEffect(() => { 
        dispatch(onGetEquipes());
        dispatch(onGetCategories());
        dispatch(onGetUsers());
        dispatch(onGetProduits());
        dispatch(onGetVentes());
        dispatch(onGetAgents());
        dispatch(onGetPompes());
        dispatch(onGetCuves());
         
    },[typMsg,onDeleteVente,onUpdateVente]); //onAddNewProduit,onDeleteProduit,onUpdateProduit,produits


   
/* Pendant la modification */
const handEditVente = () => {
    setQuantite(indexDepart-indexArrivee);
    setQuantiteReel(quantite-retourCuve);
    setMontant(quantiteReel*prixVente);
    setMontantEcart(montant-montantVerse)

}

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    
            <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Produit" breadcrumbItem="Historique des Ventes" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                               

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                        {ventes.ventes ? <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                
                                                  <PDFGenerator tableData={tableData} tableHead={tableHead} tableFoot={tableFoot} headText={headText} tableData2={tableData3} tableHead2={tableHead3} tableFoot2={tableFoot3} pdfSrc={pdfSrc}/>
                                                  <PDFGenerator tableData={tableData2} tableHead={tableHead2} tableFoot={tableFoot2} headText={headText} tableData2={tableData3} tableHead2={tableHead3} tableFoot2={tableFoot3} btnColor="#0023F5" />
                                                    
                                                    {/* <Button size="lg"  onClick={'genererWord'}>Générer Word</Button> */}
                                                   
                                                </div> 
                                            </Col> :''}   
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                {/* <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div> */}
                                                 <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", }}>HISTORIQUES DES VENTES</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                           <Col>
                                            <label htmlFor="agntSelect">Sélectionner un agent</label>
                                            {/* <Select defaultValue='' options={agntOptions} onChange={handleChangeAgnt} /> */}
                                            {/* <div className="card flex justify-content-center"> */}
                                            <MultiSelect value={selectedAgnts} onChange={(e) => {setSelectedAgnts(e.value)}} options={agntOptions} optionLabel="label" 
                                                filter placeholder="Choisir un agent" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                           
                                         
                                            </Col>
                                            <Col>
                                            <label htmlFor="agntSelect">Sélectionner un produit</label>
                                            {/* <Select defaultValue='' options={ prodOptions}    onChange={handleChangeProd}/> */}
                                            <MultiSelect value={selectedProds} onChange={(e) => {setSelectedProds(e.value)}} defaultValue='' options={prodOptions} optionLabel="label" 
                                                filter placeholder="Choisir un produit" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                            </Col>

                                            <Col>
                                            <label htmlFor="agntSelect">Sélectionner une pompe</label>
                                            {/* <Select defaultValue='' options={prod ? pompeOptions:''}    onChange={handleChangePompe}/> */}
                                            <MultiSelect value={selectedPompes} onChange={(e) => {setSelectedPompes(e.value)}} options={pompeOptions} optionLabel="label" 
                                                filter placeholder="Choisir une pompe" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                            </Col>

                                            <Col>
                                            <label htmlFor="startDate">Du</label> 
                                         
                                            <Input   type="date" value={startDate}  onChange={(e) => setStartDate(e.target.value)}  />
                                          
                                            </Col>
                                                
                                            <Col> 
                                            <label htmlFor="endate">Au</label> 
                                        
                                             <Input  type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                              
                                            </Col> 
                                            <Col> 
                                            <label htmlFor="reset">Réinitialiser</label> 
                                            <div><Button color="primary" onClick={resetFiltre}>Annuler Fitre</Button> </div>  
                                                                        
                                             
                                              
                                            </Col>   
                                        </Row>


                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr style={{ border: "2px solid #3282F6",color:"#F3F5D9" }}>
                                                        {/* <th style={{width: "50px", border: "2px solid #3282F6", backgroundColor:"#16417C"}}  scope="col" >
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th> */}
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="customer_name" onClick={(prod) => handleSort(prod, 'id')}>N°</th>                                                        
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="date" onClick={(prod) => handleSort(prod, 'createdAt')}>Date Vente</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="nom" onClick={(prod) => handleSort(prod, 'numVente')}>Vente</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="categorie" onClick={(prod) => handleSort(prod, 'agent')}>👷🏽Agent</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="equipe" onClick={(prod) => handleSort(prod, 'equipe')}>👥Equipe</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="nom" onClick={(prod) => handleSort(prod, 'produit')}>🌊Produit</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="stockProd" onClick={(prod) => handleSort(prod, 'pompe')}>📮Pompe</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="prixAchat" onClick={(prod) => handleSort(prod, 'indexDepart')}>Indice Depart</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="prixAchat" onClick={(prod) => handleSort(prod, 'indexArrivee')}>Indice Arrivee</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="prixAchat" onClick={(prod) => handleSort(prod, 'quantite')}>Quantite</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="description" onClick={(prod) => handleSort(prod, 'retourCuve')}>Retour Cuve</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="prixAchat" onClick={(prod) => handleSort(prod, 'quantiteReel')}>Quantite Sortie</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="prixVente" onClick={(prod) => handleSort(prod, 'prixVente')}>Prix Vente</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="description" onClick={(prod) => handleSort(prod, 'montant')}>Montant</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="description" onClick={(prod) => handleSort(prod, 'montantVerse')}>MontantVers</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="description" onClick={(prod) => handleSort(prod, 'montantEcart')}>MontantManq</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="createdBy" onClick={(prod) => handleSort(prod, 'createdBy')}>Crée par</th>
                                                    
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#16417C"}} className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all"  style={{ color: 'black' }} >
                                                    {/* {console.log('curent page data ',currentPageData.length)} */}
                                                    {currentPageData.length>=1 ? currentPageData: ''}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                                 <div style={{textAlign: 'left',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>  <span style={{color: '#377E47'}}>{filteredData.length}</span> POMPES &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(filteredData.reduce((acc, vente) => acc + vente.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>  </h1> <span>{subTable(tableD)}</span></div>
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            {/* <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Produit </ModalHeader>
                <form className="tablelist-form" onSubmit={'saveNewProduit'}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="libelle" className="form-label">nom</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(e) => 'setNomProd(e.target.value)'}/>
                            <label htmlFor="stock" className="form-label">stock</label>
                            <input type="number"  step="0.01"  id="stock" className="form-control" placeholder="Enter Stock" required  onChange={(e) => 'setStockProd(e.target.value)'}/>
                            <label htmlFor="prixAchat" className="form-label">Prix Achat</label>
                            <input type="number"  step="0.01" id="prixAchat" className="form-control" placeholder="Enter Achat" required  onChange={(e) => 'setPrixAchat(e.target.value)'}/>
            
                            <label htmlFor="prixVente" className="form-label">prix Vente</label>
                            <input type="number"   step="0.01" id="prixVente" className="form-control" placeholder="Enter Vente" required  onChange={(e) => setPrixVente(e.target.value)}/>
                            <label htmlFor="categorie" className="form-label">categorie</label>
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setCategorieProd(e.target.value)}  defaultValue="0">
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>

                        </div>  
                        <div className="mb-3">
                            <label htmlFor="descritpion" className="form-label">Description</label>
                            <input type="text" id="description" className="form-control" placeholder="Description" required  onChange={(e) => setDescProd(e.target.value)}/>
                             
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewProduit}>Enregistrer</button>
                            
                        </div>
                    </ModalFooter>
                </form>
            </Modal> */}

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour d'une vente </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateVente}>
                    <ModalBody  > 

                        <div className="mb-3">
                            
                            <label htmlFor="produit" className="form-label">produit</label>
                            <InputGroup>
                                <Input type="select" value={produit} onChange={(e) => setProduit(e.target.value)}  >
                                <option value="">Sélectionnez un produit</option> {/* Option vide par défaut */}
                                {Object.values(produits.produits).map((prod) => (
                                    <option key={prod.id} value={prod.id}>
                                    {prod.nomProd}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>

                            <label htmlFor="pompe" className="form-label">Pompe</label>
                            <InputGroup>
                                <Input type="select" value={pomp} onChange={(e) => setPomp(e.target.value)}  >
                                <option value="">Sélectionnez une pompe</option> {/* Option vide par défaut */}
                                {Object.values(pompes.pompes).map((pmp) => (
                                    <option key={pmp.id} value={pmp.id}>
                                    {pmp.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                            <label htmlFor="pompe" className="form-label">Date de vente</label>
                            <InputGroup>
                                <Input type="date" value={dateEnregistrement} onChange={(e) => setDateEnregistrement(e.target.value)}/> 
                            </InputGroup>
                            
                            <div style={{ display: 'flex', flexDirection: 'row' , alignItems: 'center' }}>

                            <div style={{ marginLeft: '15px', marginRight: '15px' }}>                
                            <label htmlFor="indexDepart" className="form-label">indexDepart</label>
                            <input type="decimal"     id="indexDepart" className="form-control" placeholder="indexDepart" required    onKeyDown={handleKeyDown} value={indexDepart}   onChange={(event) => {setIndexDepart(event.target.value);;setQuantite(indexArrivee-event.target.value);setQuantiteReel(indexArrivee-event.target.value-retourCuve);setMontant((indexArrivee-event.target.value-retourCuve)*prixVente);setMontantEcart(((indexArrivee-event.target.value-retourCuve)*prixVente)-montantVerse)}}/>
                            <label htmlFor="indexArrivee" className="form-label">indexArrivee</label>
                            <input type="text" style= {{backgroundColor:"#8C160D",color:"#FFFF"}}   id="indexArrivee" className="form-control" placeholder="indexArrivee" required   onKeyDown={handleKeyDown} value={indexArrivee}   onChange={(event) => {setIndexArrivee(event.target.value);setQuantite(event.target.value-indexDepart);setQuantiteReel(event.target.value-indexDepart-retourCuve);setMontant((event.target.value-indexDepart-retourCuve)*prixVente);setMontantEcart(((event.target.value-indexDepart-retourCuve)*prixVente)-montantVerse)}}/>
                            <label htmlFor="quantite" className="form-label">Quantite</label>                           
                            <input type="decimal"   style= {{backgroundColor:"#000E94",color:"#FFFF"}} readOnly  id="quantite" className="form-control" placeholder="quantite" required  onKeyDown={handleKeyDown}  value={parseFloat(Math.abs(quantite)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}   onChange={(event) => {setQuantite(event.target.value);}}/>
                           
                            <label htmlFor="retourCuve" className="form-label">Retour Cuve</label>
                            <input type="decimal"    style= {{backgroundColor:"#8C160D",color:"#FFFF"}}  id="retourCuve" className="form-control" placeholder="retourCuve" required  onKeyDown={handleKeyDown}  value={retourCuve||0}   onChange={(event) => {setRetourCuve(event.target.value);setQuantiteReel(quantite-event.target.value);setMontant((quantite-event.target.value)*prixVente);setMontant((quantite-event.target.value)*prixVente);setMontantEcart(((quantite-event.target.value)*prixVente)-montantVerse)}}/>
                           
                            <label htmlFor="quantite" className="form-label">Quantite Sortie</label>
                            <input type="decimal" style= {{backgroundColor:"#000E94",color:"#FFFF"}}   readOnly  id="quantiteReel" className="form-control" placeholder="quantite Sortie" required  onKeyDown={handleKeyDown}  value={parseFloat(Math.abs(quantiteReel)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}   onChange={(event) => {setQuantiteReel(event.target.value)}}/>               
                           
                            </div>

                            <div>
                         
                           

                             <label htmlFor="prixVente" className="form-label">Prix Vente</label>
                            <input type="decimal"    readOnly  id="prixVente" className="form-control" placeholder="stock" required   onKeyDown={handleKeyDown} value={parseFloat(Math.abs(prixVente)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}   onChange={(event) => {setPrixVente(event.target.value);}}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                            <input type="decimal" style= {{backgroundColor:"#367E7F",color:"#FFFF"}}   readOnly  id="montant" className="form-control" placeholder="montant" required  onKeyDown={handleKeyDown}  value={parseFloat(Math.abs(montant)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}   onChange={(event) => {setMontant(event.target.value);}}/>
                            <label htmlFor="montantVerse" className="form-label">Montant Versé</label>
                            <input type="decimal"  style= {{backgroundColor:"#8C160D",color:"#FFFF"}}   id="montantVerse" className="form-control" placeholder="montant versé" required  onKeyDown={handleKeyDown}  value={Math.abs(montantVerse)}   onChange={(event) => {setMontantVerse(event.target.value);setMontantEcart(montant-event.target.value)}}/>
                            <label htmlFor="montantEcart" className="form-label">Montant Manquant</label>
                            <input type="decimal"   style= {montantEcart<0? {backgroundColor: isBlinking ? '#75163F' : "#6887B0",color:"#FFFF"}:{backgroundColor:"#85842C",color:"#FFFF"}}  readOnly  id="montantEcart" className="form-control" placeholder="montant Manquant" required  onKeyDown={handleKeyDown}   value={parseFloat(montantEcart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}   onChange={(event) => setMontantEcart(event.target.value)}/>
                            <label htmlFor="agent" className="form-label">Agent</label>
                            <InputGroup>
                                <Input type="select" value={agent} onChange={(e) => setAgent(e.target.value)}  >
                                <option value="">Sélectionnez un agent</option> {/* Option vide par défaut */}
                                {Object.values(agents.agents).map((ag) => (
                                    <option key={ag.id} value={ag.id}>
                                    {ag.prenom+' '+ag.prenom}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                            </div>
                            </div>
                 
                        </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button   type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateVente}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ListProduits;
