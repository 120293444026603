/* FOURNISSEURS */
export const GET_FOURNISSEURS = "GET_FOURNISSEURS";
export const GET_FOURNISSEURS_SUCCESS = "GET_FOURNISSEURS_SUCCESS";
export const GET_FOURNISSEURS_FAIL = "GET_FOURNISSEURS_FAIL";

export const GET_FOURNISSEUR = "GET_FOURNISSEUR";
export const GET_FOURNISSEUR_SUCCESS = "GET_FOURNISSEUR_SUCCESS";
export const GET_FOURNISSEUR_FAIL = "GET_FOURNISSEUR_FAIL";

export const ADD_NEW_FOURNISSEUR = "ADD_NEW_FOURNISSEUR";
export const ADD_FOURNISSEUR_SUCCESS = "ADD_FOURNISSEUR_SUCCESS";
export const ADD_FOURNISSEUR_FAIL = "ADD_FOURNISSEUR_FAIL";

export const UPDATE_FOURNISSEUR = "UPDATE_FOURNISSEUR";
export const UPDATE_FOURNISSEUR_SUCCESS = "UPDATE_FOURNISSEUR_SUCCESS";
export const UPDATE_FOURNISSEUR_FAIL = "UPDATE_FOURNISSEUR_FAIL";

export const DELETE_FOURNISSEUR = "DELETE_FOURNISSEUR";
export const DELETE_FOURNISSEUR_SUCCESS = "DELETE_FOURNISSEUR_SUCCESS";
export const DELETE_FOURNISSEUR_FAIL = "DELETE_FOURNISSEUR_FAIL";

 