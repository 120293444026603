import {
  GET_DEMANDES,
  GET_DEMANDES_SUCCESS,
  GET_DEMANDES_FAIL,
  GET_DEMANDE,
  GET_DEMANDE_FAIL,
  GET_DEMANDE_SUCCESS,
  ADD_NEW_DEMANDE,
  ADD_DEMANDE_SUCCESS,
  ADD_DEMANDE_FAIL,
  UPDATE_DEMANDE,
  UPDATE_DEMANDE_SUCCESS,
  UPDATE_DEMANDE_FAIL,
  DELETE_DEMANDE,
  DELETE_DEMANDE_SUCCESS,
  DELETE_DEMANDE_FAIL,
} from "./actionTypes";

export const getDemandes = () => ({
  type: GET_DEMANDES,
});

export const getDemandesSuccess = demandes => ({
  type: GET_DEMANDES_SUCCESS,
  payload: demandes,
});

export const getDemandesFail = error => ({
  type: GET_DEMANDES_FAIL,
  payload: error,
});

export const getDemande = () => ({
  type: GET_DEMANDE,
});

export const getDemandeSuccess = demande => ({
  type: GET_DEMANDE_SUCCESS,
  payload: demande,
});

export const getDemandeFail = error => ({
  type: GET_DEMANDE_FAIL,
  payload: error,
});

export const addNewDemande = demande => ({
  type: ADD_NEW_DEMANDE,
  payload: demande,
});

export const addDemandeSuccess = demande => ({
  type: ADD_DEMANDE_SUCCESS,
  payload: demande,
});

export const addDemandeFail = error => ({
  type: ADD_DEMANDE_FAIL,
  payload: error,
  
});

export const updateDemande = (dep,demande) => ({
  type: UPDATE_DEMANDE,
  payload:  { id: dep, data: demande },
});

export const updateDemandeSuccess = demande => ({
  type: UPDATE_DEMANDE_SUCCESS,
  payload: demande,
});

export const updateDemandeFail = error => ({
  type: UPDATE_DEMANDE_FAIL,
  payload: error,
});

export const deleteDemande= demande => ({
  type: DELETE_DEMANDE,
  payload: demande,
});

export const deleteDemandeSuccess = demande => ({
  type: DELETE_DEMANDE_SUCCESS,
  payload: demande,
});

export const deleteDemandeFail = error => ({
  type: DELETE_DEMANDE_FAIL,
  payload: error,
});



 
