import React, { useEffect } from 'react';
import * as Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const WordGenerator = () => { 
    useEffect(() => {
  const generateDocument = async () => {
    const templatePath = '../../utilitaires/exemple1.docx'; // Spécifiez le chemin vers votre modèle Word
    const response = await fetch(templatePath);
    const content = await response.arrayBuffer();
    const doc = new Docxtemplater(content);

    const data = {
      title: 'Mon document Word généré',
      content: 'Contenu du document...',
    };
    doc.setData(data);

    doc.render();
    const generatedDocument = doc.getZip().generate({ type: 'blob' });
    saveAs(generatedDocument, 'generated.docx'); // Nommez votre fichier généré ici
  };

  generateDocument();
}, []);

return <div>Manipulation du document Word en cours...</div>;
};

export default WordGenerator;
