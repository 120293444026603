/* DEPENSES */
export const GET_DEPENSES = "GET_DEPENSES";
export const GET_DEPENSES_SUCCESS = "GET_DEPENSES_SUCCESS";
export const GET_DEPENSES_FAIL = "GET_DEPENSES_FAIL";

export const GET_DEPENSE = "GET_DEPENSE";
export const GET_DEPENSE_SUCCESS = "GET_DEPENSE_SUCCESS";
export const GET_DEPENSE_FAIL = "GET_DEPENSE_FAIL";

export const ADD_NEW_DEPENSE = "ADD_NEW_DEPENSE";
export const ADD_DEPENSE_SUCCESS = "ADD_DEPENSE_SUCCESS";
export const ADD_DEPENSE_FAIL = "ADD_DEPENSE_FAIL";

export const UPDATE_DEPENSE = "UPDATE_DEPENSE";
export const UPDATE_DEPENSE_SUCCESS = "UPDATE_DEPENSE_SUCCESS";
export const UPDATE_DEPENSE_FAIL = "UPDATE_DEPENSE_FAIL";

export const DELETE_DEPENSE = "DELETE_DEPENSE";
export const DELETE_DEPENSE_SUCCESS = "DELETE_DEPENSE_SUCCESS";
export const DELETE_DEPENSE_FAIL = "DELETE_DEPENSE_FAIL";

 