import { takeEvery, takeLatest,put, call } from "redux-saga/effects"

// Fournisseurs Redux States
import {
  ADD_NEW_FOURNISSEUR,
  DELETE_FOURNISSEUR,
  GET_FOURNISSEURS,
  GET_FOURNISSEUR,
  UPDATE_FOURNISSEUR,
  UPDATE_FOURNISSEUR_SUCCESS
} from "./actionTypes"
import {
  getFournisseursSuccess,
  getFournisseursFail,
  getFournisseurSuccess,
  getFournisseurFail,
  addFournisseurFail,
  addFournisseurSuccess,
  updateFournisseurSuccess,
  updateFournisseurFail,
  deleteFournisseurSuccess,
  deleteFournisseurFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFournisseurs,
  getFournisseur,
  addNewFournisseur,
  updateFournisseur,
  deleteFournisseur,
} from "../../helpers/api_back"

function* fetchFournisseurs() {
  try {
    const response = yield call(getFournisseurs)
    yield put(getFournisseursSuccess(response))
  } catch (error) {
    yield put(getFournisseursFail(error))
  }
}

function* fetchFournisseur() {
  try {
    const response = yield call(getFournisseur)
    yield put(getFournisseurSuccess(response))
  } catch (error) {
    yield put(getFournisseurFail(error))
  }
}


function* onAddNewFournisseur({ payload: fournisseur }) {
 
  try {
    const response = yield call(addNewFournisseur, fournisseur)
    yield put(addFournisseurSuccess(response))
    yield put(fetchFournisseurs());
  } catch (error) {
    yield put(addFournisseurFail(error))
  }
}

function* onUpdateFournisseur({ payload: fournisseur }) {
  try {
    const response = yield call(updateFournisseur, fournisseur);
    yield put(updateFournisseurSuccess(response));
    yield put(fetchFournisseurs());
  } catch (error) {
    yield put(updateFournisseurFail(error))
  }
}

function* onDeleteFournisseur({ payload: fournisseur }) {
  try {
    const response = yield call(deleteFournisseur, fournisseur)
    yield put(deleteFournisseurSuccess(response));
    yield put(fetchFournisseurs());
  } catch (error) {
    yield put(deleteFournisseurFail(error))
  }
}

// function* updateFournisseurSuccess() {
//   yield put(fetchFournisseurs());
// }

function* watchAddUpdatesDelete() {
  yield takeLatest(UPDATE_FOURNISSEUR_SUCCESS, updateFournisseurSuccess);
}
 

function* fournisseursSaga() {
  // yield all([
  //   watchAddUpdatesDelete(),
  // ]);

  yield takeLatest(GET_FOURNISSEURS, fetchFournisseurs)
  yield takeLatest(GET_FOURNISSEUR, fetchFournisseur)
  yield takeLatest(ADD_NEW_FOURNISSEUR, onAddNewFournisseur)
  yield takeLatest(UPDATE_FOURNISSEUR, onUpdateFournisseur)
  yield takeLatest(DELETE_FOURNISSEUR, onDeleteFournisseur) 
}

export default fournisseursSaga
