/* PRIXS */
export const GET_PRIXX = "GET_PRIXX";
export const GET_PRIXX_SUCCESS = "GET_PRIXX_SUCCESS";
export const GET_PRIXX_FAIL = "GET_PRIXX_FAIL";

export const GET_PRIX = "GET_PRIX";
export const GET_PRIX_SUCCESS = "GET_PRIX_SUCCESS";
export const GET_PRIX_FAIL = "GET_PRIX_FAIL";

export const ADD_NEW_PRIX = "ADD_NEW_PRIX";
export const ADD_PRIX_SUCCESS = "ADD_PRIX_SUCCESS";
export const ADD_PRIX_FAIL = "ADD_PRIX_FAIL";

export const UPDATE_PRIX = "UPDATE_PRIX";
export const UPDATE_PRIX_SUCCESS = "UPDATE_PRIX_SUCCESS";
export const UPDATE_PRIX_FAIL = "UPDATE_PRIX_FAIL";

export const DELETE_PRIX = "DELETE_PRIX";
export const DELETE_PRIX_SUCCESS = "DELETE_PRIX_SUCCESS";
export const DELETE_PRIX_FAIL = "DELETE_PRIX_FAIL";

 