import {
  GET_APPROVISIONNEMENTS,
  GET_APPROVISIONNEMENTS_SUCCESS,
  GET_APPROVISIONNEMENTS_FAIL,
  GET_APPROVISIONNEMENT,
  GET_APPROVISIONNEMENT_FAIL,
  GET_APPROVISIONNEMENT_SUCCESS,
  ADD_NEW_APPROVISIONNEMENT,
  ADD_APPROVISIONNEMENT_SUCCESS,
  ADD_APPROVISIONNEMENT_FAIL,
  UPDATE_APPROVISIONNEMENT,
  UPDATE_APPROVISIONNEMENT_SUCCESS,
  UPDATE_APPROVISIONNEMENT_FAIL,
  DELETE_APPROVISIONNEMENT,
  DELETE_APPROVISIONNEMENT_SUCCESS,
  DELETE_APPROVISIONNEMENT_FAIL,
} from "./actionTypes";

export const getApprovisionnements = () => ({
  type: GET_APPROVISIONNEMENTS,
});

export const getApprovisionnementsSuccess = approvisionnements => ({
  type: GET_APPROVISIONNEMENTS_SUCCESS,
  payload: approvisionnements,
});

export const getApprovisionnementsFail = error => ({
  type: GET_APPROVISIONNEMENTS_FAIL,
  payload: error,
});

export const getApprovisionnement = () => ({
  type: GET_APPROVISIONNEMENT,
});

export const getApprovisionnementSuccess = approvisionnement => ({
  type: GET_APPROVISIONNEMENT_SUCCESS,
  payload: approvisionnement,
});

export const getApprovisionnementFail = error => ({
  type: GET_APPROVISIONNEMENT_FAIL,
  payload: error,
});

export const addNewApprovisionnement = approvisionnement => ({
  type: ADD_NEW_APPROVISIONNEMENT,
  payload: approvisionnement,
});

export const addApprovisionnementSuccess = approvisionnement => ({
  type: ADD_APPROVISIONNEMENT_SUCCESS,
  payload: approvisionnement,
});

export const addApprovisionnementFail = error => ({
  type: ADD_APPROVISIONNEMENT_FAIL,
  payload: error,
  
});

export const updateApprovisionnement = (appr,approvisionnement) => ({
  type: UPDATE_APPROVISIONNEMENT,
  payload: { id: appr, data: approvisionnement },
 
});

export const updateApprovisionnementSuccess = (appr,approvisionnement) => ({
  type: UPDATE_APPROVISIONNEMENT_SUCCESS,
  payload: { id: appr, data: approvisionnement },
});

export const updateApprovisionnementFail = error => ({
  type: UPDATE_APPROVISIONNEMENT_FAIL,
  payload: error,
});

export const deleteApprovisionnement = approvisionnement => ({
  type: DELETE_APPROVISIONNEMENT,
  payload: approvisionnement,
});

export const deleteApprovisionnementSuccess = approvisionnement => ({
  type: DELETE_APPROVISIONNEMENT_SUCCESS,
  payload: approvisionnement,
});

export const deleteApprovisionnementFail = error => ({
  type: DELETE_APPROVISIONNEMENT_FAIL,
  payload: error,
});



 
