import { takeEvery, put, call } from "redux-saga/effects"

// DepenseCategories Redux States
import {
  ADD_NEW_DEPENSE_SOUSCATEGORIE,
  DELETE_DEPENSE_SOUSCATEGORIE,
  GET_DEPENSE_SOUSCATEGORIES,
  GET_DEPENSE_SOUSCATEGORIE,
  UPDATE_DEPENSE_SOUSCATEGORIE,
} from "./actionTypes"
import {
  getDepenseSousCategoriesSuccess,
  getDepenseSousCategoriesFail,
  getDepenseSousCategorieSuccess,
  getDepenseSousCategorieFail,
  addDepenseSousCategorieFail,
  addDepenseSousCategorieSuccess,
  updateDepenseSousCategorieSuccess,
  updateDepenseSousCategorieFail,
  deleteDepenseSousCategorieSuccess,
  deleteDepenseSousCategorieFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDepenseSousCategories,
  getDepenseSousCategorie,
  addNewDepenseSousCategorie,
  updateDepenseSousCategorie,
  deleteDepenseSousCategorie,
} from "../../helpers/api_back"

function* fetchDepenseSousCategories() {
  try {
    const response = yield call(getDepenseSousCategories)
    yield put(getDepenseSousCategoriesSuccess(response))
  } catch (error) {
    yield put(getDepenseSousCategoriesFail(error))
  }
}

function* fetchDepenseSousCategorie() {
  try {
    const response = yield call(getDepenseSousCategorie)
    yield put(getDepenseSousCategorieSuccess(response))
  } catch (error) {
    yield put(getDepenseSousCategorieFail(error))
  }
}


function* onAddNewDepenseSousCategorie({ payload: depenseDepenseSousCategorie }) {
 
  try {
    const response = yield call(addNewDepenseSousCategorie, depenseDepenseSousCategorie)
    
    yield put(addDepenseSousCategorieSuccess(response))
  } catch (error) {
    yield put(addDepenseSousCategorieFail(error))
  }
}

function* onUpdateDepenseSousCategorie({ payload: depenseDepenseSousCategorie }) {
  try {
    const response = yield call(updateDepenseSousCategorie, depenseDepenseSousCategorie)
    yield put(updateDepenseSousCategorieSuccess(response))
  } catch (error) {
    yield put(updateDepenseSousCategorieFail(error))
  }
}

function* onDeleteDepenseSousCategorie({ payload: depenseDepenseSousCategorie }) {
  try {
    const response = yield call(deleteDepenseSousCategorie, depenseDepenseSousCategorie)
    yield put(deleteDepenseSousCategorieSuccess(response))
  } catch (error) {
    yield put(deleteDepenseSousCategorieFail(error))
  }
}
 

function* depenseDepenseSousCategoriesSaga() {
  yield takeEvery(GET_DEPENSE_SOUSCATEGORIES, fetchDepenseSousCategories)
  yield takeEvery(GET_DEPENSE_SOUSCATEGORIE, fetchDepenseSousCategorie)
  yield takeEvery(ADD_NEW_DEPENSE_SOUSCATEGORIE, onAddNewDepenseSousCategorie)
  yield takeEvery(UPDATE_DEPENSE_SOUSCATEGORIE, onUpdateDepenseSousCategorie)
  yield takeEvery(DELETE_DEPENSE_SOUSCATEGORIE, onDeleteDepenseSousCategorie) 
}

export default depenseDepenseSousCategoriesSaga
