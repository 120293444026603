import { takeEvery, put, call } from "redux-saga/effects"

// TypeDemandes Redux States
import {
  ADD_NEW_TYPEDEMANDE,
  DELETE_TYPEDEMANDE,
  GET_TYPEDEMANDES,
  GET_TYPEDEMANDE,
  UPDATE_TYPEDEMANDE,
} from "./actionTypes"
import {
  getTypeDemandesSuccess,
  
  getTypeDemandesFail,
  getTypeDemandeSuccess,
  getTypeDemandeFail,
  addTypeDemandeFail,
  addTypeDemandeSuccess,
  updateTypeDemandeSuccess,
  updateTypeDemandeFail,
  deleteTypeDemandeSuccess,
  deleteTypeDemandeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTypeDemandes,
  getTypeDemande,
  addNewTypeDemande,
  updateTypeDemande,
  deleteTypeDemande,
} from "../../helpers/api_back"

function* fetchTypeDemandes() {
  try {
    const response = yield call(getTypeDemandes)
    yield put(getTypeDemandesSuccess(response))
  } catch (error) {
    yield put(getTypeDemandesFail(error))
  }
}

function* fetchTypeDemande() {
  try {
    const response = yield call(getTypeDemande)
    yield put(getTypeDemandeSuccess(response))
  } catch (error) {
    yield put(getTypeDemandeFail(error))
  }
}


function* onAddNewTypeDemande({ payload: typeDemande }) {
 
  try {
    const response = yield call(addNewTypeDemande, typeDemande)
    
    yield put(addTypeDemandeSuccess(response))
  } catch (error) {
    yield put(addTypeDemandeFail(error))
  }
}

function* onUpdateTypeDemande({ payload: typeDemande }) {
  try {
    const response = yield call(updateTypeDemande, typeDemande)
    yield put(updateTypeDemandeSuccess(response))
  } catch (error) {
    yield put(updateTypeDemandeFail(error))
  }
}

function* onDeleteTypeDemande({ payload: typeDemande }) {
  try {
    const response = yield call(deleteTypeDemande, typeDemande)
    yield put(deleteTypeDemandeSuccess(response))
  } catch (error) {
    yield put(deleteTypeDemandeFail(error))
  }
}
 

function* typeDemandesSaga() {
  yield takeEvery(GET_TYPEDEMANDES, fetchTypeDemandes)
  yield takeEvery(GET_TYPEDEMANDE, fetchTypeDemande)
  yield takeEvery(ADD_NEW_TYPEDEMANDE, onAddNewTypeDemande)
  yield takeEvery(UPDATE_TYPEDEMANDE, onUpdateTypeDemande)
  yield takeEvery(DELETE_TYPEDEMANDE, onDeleteTypeDemande) 
}

export default typeDemandesSaga
