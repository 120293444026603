import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup ,FormGroup } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //Cuves
    getCuves as onGetCuves,

    //Pompes
    addNewPompe as onAddNewPompe,
    deletePompe as onDeletePompe,
    getPompes as onGetPompes,
    getPompe as onGetPompe,
    updatePompe as onUpdatePompe,
  } from "../../store/actions"; 

  
 
const Pompes = () => {
const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document)
   
const cuves = useSelector((state) => ({  cuves: state.cuves.cuves  }));
const pompes = useSelector((state) => ({  pompes:  state.pompes.pompes  }));
const users = useSelector((state) => ({  users:  state.users.users  }));

console.log("pompes",pompes) 

const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };



function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'une pompe
const [libelle, setLibelle] = useState('');
const [cuve, setCuve] = useState('');
const [indexDepart, setIndexDepart] = useState('');
const [indexArrivee, setIndexArrivee] = useState('');
const [prixVente, setPrixVente] = useState('');
const [actif,setActif]=useState(true);
const [idPompe, setIdPompe] = useState('');

const newPompe = {
    libelle: libelle,
    cuve:cuve,
    indexDepart:indexDepart,
    indexArrivee:indexArrivee,
    prixVente:prixVente , 
    actif:actif,
};
 

const saveNewPompe = (e) => {
    e.preventDefault(); 
    dispatch(onAddNewPompe(newPompe));
    setTypMsg('add')
    setmodal_list(false);
    
 
  };

  // Mise a jour d"une Pompe 
  const upPompe = { 
    libelle: libelle, 
    cuve:cuve,
    indexDepart:indexDepart,
    indexArrivee:indexArrivee,
    prixVente:prixVente,
    actif:actif,  
  };

  
  const saveUpdatePompe= (e) => {
    e.preventDefault();  
    dispatch(onUpdatePompe(idPompe,upPompe));
    setTypMsg('upd')
    setmodal_update(false);
    
  };


  /// Suppression d'une Pompe
 

  const [listIdPompe, setListIdPompe] = useState([]);

  const idPompeChecBoxSelected = (pompeId) => {
    setListIdPompe(prevSelectedRows => ({
      ...prevSelectedRows,
      [pompeId]: !prevSelectedRows[pompeId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdPompe)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const pompeIds = Object.keys(filteredList);
 
  const deletePompe = ( pompe) => { 
    if(pompe)dispatch(onDeletePompe(pompe));
    setTypMsg('del')
    if(listIdPompe && pompeIds){pompeIds.forEach((pompeId) =>dispatch(onDeletePompe(pompeId)))}
    setTypMsg('del')

  };
      


/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(pompes.pompes);



const dataTable= filteredData.length!==0 ? 
filteredData.map(pompe => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === pompe.categorie);
    //console.log('xcate',categorie)
    const user = Object.values(users.users).find(us => us.id === pompe.createdBy);
    const cuve = Object.values(cuves.cuves).find(cv => cv.id === pompe.cuve);
    return { ...pompe , user: user? user.prenom+' '+user.nom :'' , cuveInfo: cuve? cuve.libelle:'' };
  }) 
  : 
  pompes.pompes.map(pompe => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === pompe.categorie);
    const user = Object.values(users.users).find(us => us.id === pompe.createdBy);
    const cuve = Object.values(cuves.cuves).find(cv => cv.id === pompe.cuve);
    return { ...pompe,user: user? user.prenom+' '+user.nom:'' , cuveInfo: cuve? cuve.libelle:'' };
  });
//,categoriepompe:categorie.libelle

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (pompe) => {
    const value = pompe.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.libelle), _.toLower(value))||
      _.startsWith(_.toLower(item.cuve), _.toLower(value))|| 
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (pompe, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


 
const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((pompe,index) =>  (
    <tr key={pompe.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idPompeChecBoxSelected(pompe.id)}}   />
        </div>
    </th>
    <td className="pompe_id" style={{ display: "none" }}>{pompe.id}</td>
    <td className="pompe_index">{index+1}</td>
    <td className="libelle">{pompe.libelle}</td> 
    <td className="cuve">{pompe.cuveInfo}</td>
    <td className="indexDepart">{parseFloat(pompe.indexDepart).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td> 
    <td className="indexArrivee">{parseFloat(pompe.indexArrivee).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td> 
    <td className="prixVente">{parseFloat(pompe.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td> 
    <td className="pompe_actif">{pompe.actif==1? '✅' :'❌' } </td>
    <td className="pompe_date">{ pompe.user}</td>
    <td className="pompe_utilisateur">{formatedDate(pompe.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setLibelle(pompe.libelle);setCuve(pompe.cuve);setIndexDepart(pompe.indexDepart);setIndexArrivee(pompe.indexArrivee);setPrixVente(pompe.prixVente);setIdPompe(pompe.id);setActif(pompe.actif);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deletePompe(pompe.id)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));

   useEffect(() => { 
        
        dispatch(onGetPompes());
        dispatch(onGetUsers());
        dispatch(onGetCuves());
        setFilteredData(pompes.pompes)
         
    },[dispatch,typMsg,onAddNewPompe,onDeletePompe,onUpdatePompe]); 

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Cuve" breadcrumbItem="Liste des Pompes" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deletePompe('');setListIdPompe('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="pompe_id" onClick={(pompe) => handleSort(pompe, 'id')}>N°</th>
                                                        <th className="sort" data-sort="libelle" onClick={(pompe) => handleSort(pompe, 'libelle')}>Libelle</th>
                                                        <th className="sort" data-sort="Cuve" onClick={(pompe) => handleSort(pompe, 'cuve')}>Cuve</th> 
                                                        <th className="sort" data-sort="indexDepart" onClick={(pompe) => handleSort(pompe, 'indexDepart')}>Index Depart</th> 
                                                        <th className="sort" data-sort="indexArrivee" onClick={(pompe) => handleSort(pompe, 'indexArrivee')}>Index Arrivee</th> 
                                                        <th className="sort" data-sort="prixVente" onClick={(pompe) => handleSort(pompe, 'prixVente')}>Prix Vente</th> 
                                                        <th className="sort" data-sort="Actif" onClick={(pompe) => handleSort(pompe, 'actif')}>Actif</th> 
                                                        <th className="sort" data-sort="createdBy" onClick={(pompe) => handleSort(pompe, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(pompe) => handleSort(pompe, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Pompe </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewPompe}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="libelle" className="form-label">Libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(e) => setLibelle(e.target.value)}/>
                            <label htmlFor="IndexDepart" className="form-label">Index Depart</label>
                            <input type="decimal" id="indexDepart" className="form-control" placeholder="Enter l'index Depart"   onChange={(e) => setIndexDepart(e.target.value)}/>
                            <label htmlFor="indexArrivee" className="form-label">Index d'Arrivee</label>
                            <input type="decimal" id="indexArrivee" className="form-control" placeholder="Enter l'index d'Arrivee"   onChange={(e) => setIndexArrivee(e.target.value)}/>
                            <label htmlFor="prixVente" className="form-label">Prix Vente</label>
                            <input type="decimal" id="prixVente" className="form-control" placeholder="Enter prix de vente de la pompe"   onChange={(e) => setPrixVente(e.target.value)}/>
                            
                            <label htmlFor="cuve" className="form-label">Cuve</label> 
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setCuve(e.target.value)}  defaultValue="0">
                                <option value="">Sélectionnez une cuve</option> {/* Option vide par défaut */}
                                {Object.values(cuves.cuves).map((cuve) => (
                                    <option key={cuve.id} value={cuve.id}>
                                    {cuve.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                            <label htmlFor="equipe" className="form-label">Actif ?</label>
                            <FormGroup switch> 
                               <Input 
                                type="switch"
                                style={{ width: '30px' }} 
                                // defaultChecked
                                checked={actif==1}
                                onClick={() => {
                                    setActif(!actif);  
                                     
                                }}
                                 />
                            
                           </FormGroup>

                        </div>  
                         
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewPompe}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Pompe </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdatePompe}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="libelle" className="form-label">libelle</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  value={libelle} onChange={(event) => setLibelle(event.target.value)}/>
                            <label htmlFor="IndexDepart" className="form-label">Index Depart</label>
                            <input type="decimal" id="indexDepart" className="form-control" placeholder="Enter l'index Depart" value={indexDepart}   onChange={(e) => setIndexDepart(e.target.value)}/>
                            <label htmlFor="indexArrivee" className="form-label">Index d'Arrivee</label>
                            <input type="decimal" id="indexArrivee" className="form-control" placeholder="Enter l'index d'Arrivee"   value={indexArrivee}  onChange={(e) => setIndexArrivee(e.target.value)}/>
                            <label htmlFor="prixVente" className="form-label">Prix Vente</label>
                            <input type="decimal" id="prixVente" className="form-control" placeholder="Enter prix de vente de la pompe" value={prixVente}   onChange={(e) => setPrixVente(e.target.value)}/>
                            
                            <label htmlFor="cuve" className="form-label">Cuve</label>  
                            <InputGroup>
                                <Input type="select" value={cuve} onChange={(e) => setCuve(e.target.value)}  >
                                <option value="">Sélectionnez une cuve</option> {/* Option vide par défaut */}
                                {Object.values(cuves.cuves).map((cuve) => (
                                    <option key={cuve.id} value={cuve.id}>
                                    {cuve.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                            <label htmlFor="equipe" className="form-label">Actif ?</label>
                            <FormGroup switch> 
                               <Input 
                                type="switch"
                                style={{ width: '30px' }} 
                                checked={actif==1}
                   
                                onClick={() => {
                                    setActif(!actif);  
                                     
                                }}
                                 />
                            
                           </FormGroup>
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdatePompe}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Pompes;
