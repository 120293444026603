import React from "react";
import { Navigate } from "react-router-dom";

 
//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Calender
//import Calender from "../Pages/Calender";
 

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/AuthenticationPages/Register";
//import UserProfile from "../Pages/Authentication/user-profile";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

 
 
// Import Charts
import ApexCharts from "../Pages/Charts/ApexCharts";
import ChartJs from "../Pages/Charts/ChartjsCharts";
import Sparklinechart from "../Pages/Charts/SparklineCharts";
import FloatChart from "../Pages/Charts/FloatCharts";
import JknobCharts from "../Pages/Charts/JqueryKnobCharts";

 

 

// import la liste des catégories 
import ListCategories from "../Pages/Categories/ListCategories";

// import la liste des produits 
import ListProduits from "../Pages/Produits/ListProduits";

// import fournisseurs 
import Fournisseurs from "../Pages/Approvisionnements/Fournisseurs";
// import approvisionnements
import Approvisionnements from "../Pages/Approvisionnements/Approvisionnements";
// import Historique Approvisionnements
import HistoAppro from "../Pages/Approvisionnements/HistoAppro";
// import des cuves 
import Cuves  from "../Pages/Cuves/Cuves";

// import des des pompes 
import Pompes  from "../Pages/Pompes/Pompes"

// import des agents 
import Agents  from "../Pages/Agents/Agents"
// import des equipes 
import Equipes  from "../Pages/Agents/Equipes"
// import des depense 
import Depenses  from "../Pages/Depenses/Depenses"
import AnalyseDepenses  from "../Pages/Depenses/AnalyseDepenses"
// import des depenseCategorie 

import HistoSalaires from "../Pages/Salaires/HistoSalaires";

import DepensesCategories  from "../Pages/Depenses/DepensesCategories"
import DepensesSousCategories from "../Pages/Depenses/DepensesSousCategories";
//vente
import Ventes from "../Pages/Ventes/Ventes";
import AutreVentes from "../Pages/Ventes/AutreVente";
import VentesHistoriques from "../Pages/Ventes/VentesHistoriques";

//demande de pret
import Prets  from "../Pages/Prets/Prets"
import Salaires from "../Pages/Salaires/Salaires"; 

//fichier
import Bd from "../Pages/Fichiers/Bd";

//utilisateur
import Utilisateurs from "../Pages/Utilisateurs/Utilisateurs";
import Profile from "../Pages/Utilisateurs/Profile";



const authProtectedRoutes = [ 
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Calender
  //{ path: "/calendar", component: <Calender /> },

  // Profile
  //{ path: "/profile", component: <Profile /> },

  
   

  // Charts Pages
  { path: "/chart-apexcharts", component: <ApexCharts /> },
  { path: "/chart-chartjscharts", component: <ChartJs /> },
  { path: "/chart-floatcharts", component: <FloatChart /> },
  { path: "/chart-jknobcharts", component: <JknobCharts /> },
  { path: "/chart-sparklinecharts", component: <Sparklinechart /> },

   

  
  // Categories,Produits pages
  { path: "/categories", component: <ListCategories /> },
  { path: "/produits", component: <ListProduits /> },

  //Fournisseurs,Appro,cuves,pompes,agents, depenses, depensesCategories pages
  { path: "/fournisseurs", component: <Fournisseurs /> },
  { path: "/approvisionnements", component: <Approvisionnements /> },
  { path: "/HistoAppro", component: <HistoAppro /> },
  { path: "/cuves", component: <Cuves /> },
  { path: "/pompes", component: <Pompes /> },
  { path: "/equipes", component: <Equipes /> },
  { path: "/agents", component: <Agents /> },
  { path: "/depenses",component: <Depenses />},
{ path: "/prets",component: <Prets />},
  { path: "/Salaires", component: <Salaires /> }, 
  { path: "/HistoSalaires", component: <HistoSalaires /> },
  { path: "/depensesCategories",component: <DepensesCategories />},
  {path:  "/depensesSousCategories",component: <DepensesSousCategories/>},
  {path: "/AnalyseDepenses", component: <AnalyseDepenses />},
  { path: "/ventes",component: <Ventes />},
  { path: "/autreVentes",component: <AutreVentes />},
  { path: "/ventesHistoriques",component: <VentesHistoriques />}, //
  { path: "/bd",component: <Bd />},
  { path: "/utilisateurs",component: <Utilisateurs />}, //
  { path: "/profile", component: <Profile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },

  // Utility Pages
  // { path: "/pages-404", component: <Error404 /> },
  // { path: "/pages-500", component: <Error500 /> },
  // { path: "/pages-maintenance", component: <Maintenance /> },
  // { path: "/pages-comingsoon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
