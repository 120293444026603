import { takeEvery, put, call } from "redux-saga/effects"

// Categories Redux States
import {
  ADD_NEW_CATEGORIE,
  DELETE_CATEGORIE,
  GET_CATEGORIES,
  GET_CATEGORIE,
  UPDATE_CATEGORIE,
} from "./actionTypes"
import {
  getCategoriesSuccess,
  getCategoriesFail,
  getCategorieSuccess,
  getCategorieFail,
  addCategorieFail,
  addCategorieSuccess,
  updateCategorieSuccess,
  updateCategorieFail,
  deleteCategorieSuccess,
  deleteCategorieFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCategories,
  getCategorie,
  addNewCategorie,
  updateCategorie,
  deleteCategorie,
} from "../../helpers/api_back"


function* fetchCategories() {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchCategorie() {
  try {
    const response = yield call(getCategorie)
    yield put(getCategorieSuccess(response))
  } catch (error) {
    yield put(getCategorieFail(error))
  }
}


function* onAddNewCategorie({ payload: categorie }) {
 
  try {
    const response = yield call(addNewCategorie, categorie)
    
    yield put(addCategorieSuccess(response))
  } catch (error) {
    yield put(addCategorieFail(error))
  }
}

function* onUpdateCategorie({ payload: categorie }) {
  try {
    const response = yield call(updateCategorie, categorie)
    yield put(updateCategorieSuccess(response))
  } catch (error) {
    yield put(updateCategorieFail(error))
  }
}

function* onDeleteCategorie({ payload: categorie }) {
  try {
    const response = yield call(deleteCategorie, categorie)
    yield put(deleteCategorieSuccess(response))
  } catch (error) {
    yield put(deleteCategorieFail(error))
  }
}
 

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_CATEGORIE, fetchCategorie)
  yield takeEvery(ADD_NEW_CATEGORIE, onAddNewCategorie)
  yield takeEvery(UPDATE_CATEGORIE, onUpdateCategorie)
  yield takeEvery(DELETE_CATEGORIE, onDeleteCategorie) 
}

export default categoriesSaga
