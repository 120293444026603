import {
  GET_APPROVISIONNEMENTS_SUCCESS,
  GET_APPROVISIONNEMENTS_FAIL,
  GET_APPROVISIONNEMENT_SUCCESS,
  GET_APPROVISIONNEMENT_FAIL,
  ADD_APPROVISIONNEMENT_SUCCESS,
  ADD_APPROVISIONNEMENT_FAIL,
  UPDATE_APPROVISIONNEMENT_SUCCESS,
  UPDATE_APPROVISIONNEMENT_FAIL,
  DELETE_APPROVISIONNEMENT_SUCCESS,
  DELETE_APPROVISIONNEMENT_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  approvisionnements: [],
  error: {},
};

const Approvisionnements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APPROVISIONNEMENTS_SUCCESS:
      return {
        ...state,
        approvisionnements: action.payload,
      };

    case GET_APPROVISIONNEMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_APPROVISIONNEMENT_SUCCESS:
      return {
        ...state,
        approvisionnement: action.payload,
      };
  
    case GET_APPROVISIONNEMENT_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_APPROVISIONNEMENT_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        approvisionnements: [...state.approvisionnements, action.payload],
      };

    case ADD_APPROVISIONNEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_APPROVISIONNEMENT_SUCCESS:
      return {
        ...state,
        approvisionnements: state.approvisionnements.map(approvisionnement =>
          approvisionnement.id.toString() === action.payload.id.toString()
            ? { approvisionnement, ...action.payload }
            : approvisionnement
        ),
        isApprovisionnementUpdated: true
      };

    case UPDATE_APPROVISIONNEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isApprovisionnementUpdated: false
      };

    case DELETE_APPROVISIONNEMENT_SUCCESS:
      return {
        ...state,
        approvisionnements: state.approvisionnements.filter(
          approvisionnement => approvisionnement.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_APPROVISIONNEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Approvisionnements;
