import {
  GET_FILES_SUCCESS,
  GET_FILES_FAIL,
  GET_FILE_SUCCESS,
  GET_FILE_FAIL,
  ADD_FILE_SUCCESS,
  ADD_FILE_FAIL,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAIL,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  files: [],
  error: {},
};

const Files = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload,
      };

    case GET_FILES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_FILE_SUCCESS:
      return {
        ...state,
        file: action.payload,
      };
  
    case GET_FILE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_FILE_SUCCESS:
      //console.log('Hello Sagas ok!');
      return {
        ...state,
        files: [...state.files, action.payload],
      };

    case ADD_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.map(file =>
          file.id.toString() === action.payload.id.toString()
            ? { file, ...action.payload }
            : file
        ),
        isFileUpdated: true
      };

    case UPDATE_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        isFileUpdated: false
      };

    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(
          file => file.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Files;
