import {
  GET_AGENTS_SUCCESS,
  GET_AGENTS_FAIL,
  GET_AGENT_SUCCESS,
  GET_AGENT_FAIL,
  ADD_AGENT_SUCCESS,
  ADD_AGENT_FAIL,
  UPDATE_AGENT_SUCCESS,
  UPDATE_AGENT_FAIL,
  DELETE_AGENT_SUCCESS,
  DELETE_AGENT_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  agents: [],
  error: {},
};

const Agents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        agents: action.payload,
      };

    case GET_AGENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_AGENT_SUCCESS:
      return {
        ...state,
        agent: action.payload,
      };
  
    case GET_AGENT_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_AGENT_SUCCESS:
      //console.log('Hello Sagas ok!');
      return {
        ...state,
        agents: [...state.agents, action.payload],
      };

    case ADD_AGENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_AGENT_SUCCESS:
      return {
        ...state,
        agents: state.agents.map(agent =>
          agent.id.toString() === action.payload.id.toString()
            ? { agent, ...action.payload }
            : agent
        ),
        isAgentUpdated: true
      };

    case UPDATE_AGENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isAgentUpdated: false
      };

    case DELETE_AGENT_SUCCESS:
      return {
        ...state,
        agents: state.agents.filter(
          agent => agent.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_AGENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Agents;
