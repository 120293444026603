import { takeEvery, put, call } from "redux-saga/effects"

// Prixx Redux States
import {
  ADD_NEW_PRIX,
  DELETE_PRIX,
  GET_PRIXX,
  GET_PRIX,
  UPDATE_PRIX,
} from "./actionTypes"
import {
  getPrixxSuccess,
  getPrixxFail,
  getPrixSuccess,
  getPrixFail,
  addPrixFail,
  addPrixSuccess,
  updatePrixSuccess,
  updatePrixFail,
  deletePrixSuccess,
  deletePrixFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPrixx,
  getPrix,
  addNewPrix,
  updatePrix,
  deletePrix,
} from "../../helpers/api_back"

function* fetchPrixx() {
  try {
    const response = yield call(getPrixx)
    yield put(getPrixxSuccess(response))
  } catch (error) {
    yield put(getPrixxFail(error))
  }
}

function* fetchPrix() {
  try {
    const response = yield call(getPrix)
    yield put(getPrixSuccess(response))
  } catch (error) {
    yield put(getPrixFail(error))
  }
}


function* onAddNewPrix({ payload: prix }) {
 
  try {
    const response = yield call(addNewPrix, prix)
    
    yield put(addPrixSuccess(response))
  } catch (error) {
    yield put(addPrixFail(error))
  }
}

function* onUpdatePrix({ payload: prix }) {
  try {
    const response = yield call(updatePrix, prix)
    yield put(updatePrixSuccess(response))
  } catch (error) {
    yield put(updatePrixFail(error))
  }
}

function* onDeletePrix({ payload: prix }) {
  try {
    const response = yield call(deletePrix, prix)
    yield put(deletePrixSuccess(response))
  } catch (error) {
    yield put(deletePrixFail(error))
  }
}
 

function* prixxSaga() {
  yield takeEvery(GET_PRIXX, fetchPrixx)
  yield takeEvery(GET_PRIX, fetchPrix)
  yield takeEvery(ADD_NEW_PRIX, onAddNewPrix)
  yield takeEvery(UPDATE_PRIX, onUpdatePrix)
  yield takeEvery(DELETE_PRIX, onDeletePrix) 
}

export default prixxSaga
