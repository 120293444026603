import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';

import Select  from 'react-select';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';
import PDFGenerator from '../../utilitaires/PDFGenerator';

import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

     //Ventes
     addNewVente as onAddNewVente,
     deleteVente as onDeleteVente,
     getVentes as onGetVentes,
     getVente as onGetVente,
     updateVente as onUpdateVente,

     //Agents
     getAgents as onGetAgents,

     
    //cuves
    getCuves as onGetCuves,

      //Pompes
    getPompes as onGetPompes,
    getAgentsSuccess,

     //categories
       getCategories as onGetCategories,

       //Produits
       getProduits as onGetProduits,

        //Fournisseurs
     getFournisseurs as onGetFournisseurs,
   
   

     //approvisionnements
     addNewApprovisionnement as onAddNewApprovisionnement,
     deleteApprovisionnement as onDeleteApprovisionnement,
     getApprovisionnements as onGetApprovisionnements,
     getApprovisionnement as onGetApprovisionnement,
     updateApprovisionnement as onUpdateApprovisionnement,


  } from "../../store/actions"; 

  
 
const HistoAppro = () => {
const d=new Date();
const today = new Date().toISOString().split('T')[0];
const dateDeb = new Date(d.setDate(d.getDate() -7)).toISOString().split('T')[0];
 
    
const [typMsg, setTypMsg] = useState('');
const [frs, setFrs] = useState('');
const [selectedFrs, setSelectedFrs] = useState([]);
const [prod, setProd] = useState('');
const [selectedProds, setSelectedProds] = useState([]);
const [startDate, setStartDate] = useState(dateDeb);
 
const [endDate, setEndDate] = useState(today);
//setEndDate(today)

const dispatch = useDispatch();
 //console.log("Document",Document)
   
const produits = useSelector((state) => ({  produits: state.produits.produits  }));
const categories = useSelector((state) => ({  categories:  state.categories.categories  }));
const approvisionnements = useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements  }));
 
const fournisseurs = useSelector((state) => ({  fournisseurs:  state.fournisseurs.fournisseurs  }));
const users = useSelector((state) => ({  users:  state.users.users  }));
const ventes = useSelector((state) => ({  ventes: state.ventes.ventes })); 
const agents = useSelector((state) => ({  agents:  state.agents.agents  }));
const pompes = useSelector((state) => ({pompes: state.pompes.pompes}));
const cuves = useSelector((state) => ({  cuves:  state.cuves.cuves  }));

const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };



/// option  pour afficher la liste des produits et la liste des agents
const frsOptions=Object.values(fournisseurs.fournisseurs).map(frs => ({
    value:frs.id, label:frs.libelle
}))
frsOptions.unshift({ value: '', label: '' });

const prodOptions=Object.values(produits.produits).map(prod => ({
    value:prod.id, label:prod.nomProd
}))
prodOptions.unshift({ value: '', label: '' });

 

 
/// lorsque l'utilisateur choisi un produit
const handleChangeProd =(prod)=>{
    setProd(prod.value) 
 
}

 
///lorsque l'utilisateur choisi un fournisseur
const handleChangeFrs =(frs)=>{
 
    setFrs(frs.value)
   }
 


function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_show, setmodal_show] = useState(false);
    
    function tog_show() {
        setmodal_show(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }



    //interdiction des valeurs non numerique
  const handleKeyDown = (e) => {
 
    const { key } = e;

   // console.log('key',key)

  // Interdire les caractères non numériques, sauf la touche Backspace
  // if (!/\d/.test(key)  && (key !== 'Backspace' || key !== '' || key !== 'Delete' || key.key !== '.'))
  if (!/\d/.test(key)  && key !== 'Backspace' ){
  
      if(key !== '.' ) {

        if(key !== 'Tab') {
          if(key !== 'ArrowRight'){
            if(key !== 'ArrowLeft') e.preventDefault() ;
          }
        }   
      } 

 }
};
    
   
    
/// Enregistrements d'un appro
const [produit, setProduit] = useState('');
const [prixAchat, setPrixAchat] = useState('');
const [prixVente, setPrixVente] = useState('');
const [quantite, setQuantite] = useState('');
const [montant, setMontant] = useState('');   
const [fournisseur, setFournisseur] = useState('');
const [numFacFrs,setNumFacFrs]=useState();
const [immatTracteur,setImmatTracteur]=useState();
const [immatCiterne,setImmatCiterne]=useState();
const [nomPrenomChaufeur,setNomPrenomChaufeur]=useState();
const [contact,setContact]=useState();
const [permis,setPermis]=useState(); 
const [dateEnregistrement,setDateEnregistrement] = useState() 

const [idAppro, setIdAppro] = useState('');

const newAppro = {
    produit: produit, 
    prixAchat:prixAchat,
    prixVente:prixVente,
    quantite:quantite,
    montant:montant, 
    fournisseur:fournisseur,
    dateEnregistrement:dateEnregistrement,
 
};

const saveNewAppro= (e) => {
    e.preventDefault(); 
    setTypMsg('add')
    dispatch(onAddNewApprovisionnement(newAppro));
    
    setmodal_list(false);
    
 
  };

  // Mise a jour d"un appro 
  const upAppro = { 
    produit: produit, 
    prixAchat:prixAchat,
    prixVente:prixVente,
    quantite:quantite,
    montant:montant, 
    fournisseur:fournisseur,
    dateEnregistrement:dateEnregistrement,
  };

  const saveUpdateAppro = (e) => {
    e.preventDefault(); 
    dispatch(onUpdateApprovisionnement(idAppro,upAppro));
    setTypMsg('upd')
    setmodal_update(false);
    
  };


  /// Suppression d'un appro
 

  const [listIdAppro, setListIdAppro] = useState([]);

  const idApproChecBoxSelected = (approId) => {
    setListIdAppro(prevSelectedRows => ({
      ...prevSelectedRows,
      [approId]: !prevSelectedRows[approId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdAppro)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const approIds = Object.keys(filteredList);
 
  const deleteAppro = ( appro) => { 
    
    if(appro)dispatch(onDeleteApprovisionnement(appro));
    setTypMsg('del')
    if(listIdAppro && approIds){approIds.forEach((approId) =>dispatch(onDeleteApprovisionnement(approId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination
 
 
const [filteredData, setFilteredData] = useState(approvisionnements.approvisionnements);
const resetFiltre=()=>{
    setProd('')
    setFrs('')
    setStartDate(dateDeb)
    setEndDate(today)
    //window.location.reload();
}
 
        useEffect(() => {
           
            // Fonction de recherche
            const searchData = () => {
            const filtered =  Object.values(approvisionnements.approvisionnements).filter((item) => {
                const itemDate = new Date(item.dateEnregistrement);
                const start = new Date(startDate);
                const end = new Date(endDate );
                // Ajouter 1 jour à la date de fin
                end.setDate(end.getDate() + 1);  
 

                return    selectedProds.length && selectedFrs.length  ? itemDate >= start && itemDate <= end && selectedProds.includes(item.produit) && selectedFrs.includes(item.fournisseur)
                          : selectedFrs.length ? itemDate >= start && itemDate <= end &&  selectedFrs.includes(item.fournisseur)
                          : selectedProds.length ? itemDate >= start && itemDate <= end && selectedProds.includes(item.produit)
                          : itemDate >= start && itemDate <= end 
                
                //                 || item.agent===agnt||item.agent  && item.pompe===pompe||item.pompe
            });

            setFilteredData(filtered);
          

            
            };

            // Appeler la fonction de recherche lorsque les dates changent
            searchData();
        }, [dispatch,d,dateDeb,startDate, endDate,selectedFrs,,selectedProds,typMsg,onDeleteApprovisionnement,onUpdateApprovisionnement]);


const dataTable= filteredData.length!==0 ? 
filteredData.map(prod => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    //console.log('xcate',categorie)
    const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === prod.fournisseur);
    const produit = Object.values(produits.produits).find(pd => pd.id === prod.produit);
    const user = Object.values(users.users).find(us => us.id === prod.createdBy);
    return { ...prod , user: user ? user.prenom+' '+user.nom:'',produitInfo:produit?produit.nomProd:'',fournisseurInfo:frs?frs.libelle:''};
  }) 
  : 
   '';
 


    ///maping des donnees pour pdf 
    const tableD=Object.values(dataTable).map((ap)=>{ 
            const frs = Object.values(fournisseurs.fournisseurs).find(fr => fr.id === ap.fournisseur);
            const produit = Object.values(produits.produits).find(prod=> prod.id === ap.produit);
            return { ...ap,frs: frs? frs.libelle  :'',produit: produit? produit.nomProd:'' };})

    const tableData= tableD.map((d, index) => [index+1,  formatedDate(d.dateEnregistrement).slice(0,10),d.numAppro,d.frs, d.produit,parseFloat(d.quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.prixAchat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);
    
    const headText ='LISTE DES APPROVISIONNEMENTS' 
    const tableHead =[['N°','Date','N° Appro', 'Fournisseur', 'Produit','Quantite', 'Prix Achat','Montant']];
    const tableFoot=[['','','','','','','TOTAL',parseFloat(tableD.reduce((acc, ap) => acc + ap.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ]]

   const tableHead2 =[['N°','Produit','Quantité Total', 'Montant Total']];

   const tableD2 = tableD.reduce((accumulator, app) => {
    if (!accumulator[app.produit]) {
      accumulator[app.produit] = {
        produit: app.produit,
        quantiteTotal: 0,
        montantTotal: 0,
      };
    }
    accumulator[app.produit].quantiteTotal += app.quantite;
    accumulator[app.produit].montantTotal += app.montant;
    return accumulator;
  }, {});
  const totauxMontant = tableD.reduce((accumulator, ap) => accumulator + ap.montant, 0);
  const tableFoot2 =[['','','TOTAL',parseFloat(totauxMontant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ]];
  const tableData2= Object.values(tableD2).map((d, index) => [index+1,d.produit,parseFloat(d.quantiteTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ,parseFloat(d.montantTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);

//  console.log('tableD',tableD)
//  console.log('tableD2',Object.values(tableD2))
  
 //maples données pour generer un tableau au niveau du peid de la page


 const subTable = (data) => {
    const totauxMontant = data.reduce((accumulator, ap) => accumulator + ap.montant, 0);
    // Regrouper les données par produit et calculer le total quantités et des montants
    const approData = data.reduce((accumulator, app) => {
      if (!accumulator[app.produit]) {
        accumulator[app.produit] = {
          produit: app.produit,
          quantiteTotal: 0,
          montantTotal: 0,
        };
      }
      accumulator[app.produit].quantiteTotal += app.quantite;
      accumulator[app.produit].montantTotal += app.montant;
      return accumulator;
    }, {});
  
    const newData = Object.values(approData);
      
    return (
      <table style={{ width:"49%",borderCollapse: "collapse" ,marginBottom:"20px",fontSize: '20px',}}>
        <thead>
          <tr>
            <th style={{border:'1px solid #73FBFD' ,color:"white",backgroundColor:" #367E7F",textAlign:"left", padding: "8px"}}>Produit</th>
            <th style={{border:'1px solid #73FBFD' ,color:"white",backgroundColor:" #367E7F",textAlign:"left", padding: "8px"}}>Quantite Total</th>
            <th style={{border:'1px solid #73FBFD' ,color:"white",backgroundColor:" #367E7F",textAlign:"left", padding: "8px"}}>Montant Total</th>
          </tr>
        </thead>
        <tbody>
          {newData.map((d,index) => (
            <tr key={index+1}  style={(index+1) % 2 === 0 ? { backgroundColor: '#EBF4FC',border:'1px solid #73FBFD' , } : {border:'1px solid #73FBFD' ,}}>
              <td stylle={{border:'1px solid #73FBFD' ,textAlign:"center",padding: "8px"}}>{d.produit}</td>
              <td stylle={{ border:'1px solid #73FBFD' ,textAlign:"center",padding: "8px"}}>{parseFloat(d.quantiteTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
              <td stylle={{border:'1px solid #73FBFD' , textAlign:"center",padding: "8px"}}>{parseFloat(d.montantTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr>
          <td style={{ padding: '8px', border: '1px solid #7E919C', fontWeight: 'bold',color:"white",backgroundColor: '#7E919C'}}>  </td>
          <td style={{ padding: '8px', border: '1px solid #7E919C', fontWeight: 'bold',color:"white",backgroundColor: '#7E919C' }}> TOTAL</td>
          <td style={{ padding: '8px', border: '1px solid #7E919C', fontWeight: 'bold',color:"white",backgroundColor: '#7E919C'}}>{parseFloat(totauxMontant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
        </tr>
      </tfoot>
      </table>
    );
  };

   
  //pagination

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (vt) => {
    const value = vt.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.numVente), _.toLower(value))||
      _.startsWith(_.toLower(item.produit), _.toLower(value))||
      _.startsWith(_.toLower(item.pompe), _.toLower(value))||
      _.startsWith(_.toLower(item.indexDepart), _.toLower(value))||
      _.startsWith(_.toLower(item.indexArrivee), _.toLower(value))||
      _.startsWith(_.toLower(item.prixVente), _.toLower(value))||
      _.startsWith(_.toLower(item.quantite), _.toLower(value))||
      _.startsWith(_.toLower(item.retourCuve), _.toLower(value))||
      _.startsWith(_.toLower(item.montant), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (prod, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);

 

const currentPageData = dataTable.length>=1 ?  dataTable.slice(offset, offset + PER_PAGE).map((vt,index) =>  (
    <tr key={index}  style={index % 2 === 0 ? { backgroundColor: "#E6F1FF",border: "2px solid #3282F6",color:'black'}:{ backgroundColor: "#EBFFED" ,border: "2px solid #3282F6",color:'black'}}>
    {/* <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idApproChecBoxSelected(vt.id)}}   />
        </div>
    </th> */}
    <td className="prod_id" style={{ display: "none" , border: "2px solid #3282F6"}}>{vt.id}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_index">{index+1}</td>
    <td style={{ border: "2px solid #3282F6" }} className="dateEnregistrement">{formatedDate(vt.dateEnregistrement)}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.numAppro}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.produitInfo}</td> 
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{parseFloat(vt.prixAchat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{parseFloat(vt.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{ parseFloat(vt.quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{parseFloat(vt.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td> 
    <td style={{ border: "2px solid #3282F6" }} className="prod_name">{vt.fournisseurInfo}</td> 
    <td style={{ border: "2px solid #3282F6" }} className="prod_date">{ vt.numFacFrs}</td> 
    <td style={{ border: "2px solid #3282F6" }} className="prod_date">{ vt.user}</td> 
    
    <td style={{ border: "2px solid #3282F6" }} >
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setProduit(vt.produit);setPrixAchat(vt.prixAchat);setPrixVente(vt.prixVente);setQuantite(vt.quantite);setMontant(vt.montant);setFournisseur(vt.fournisseur);setIdAppro(vt.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteAppro(vt.id)}>Suppr.</button>
            </div>

            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> {setNumFacFrs(vt.numFacFrs);setImmatTracteur(vt.immatTracteur);setImmatCiterne(vt.immatCiterne);setNomPrenomChaufeur(vt.nomPrenomChaufeur);setPermis(vt.permis);setContact(vt.contact);tog_show()}}>Voir plus</button>
            </div>
        </div>
    </td>
</tr>


)) :'';

   useEffect(() => { 
    
        dispatch(onGetUsers());
        dispatch(onGetVentes());
        dispatch(onGetAgents());
        dispatch(onGetPompes());
        dispatch(onGetCuves());

        dispatch(onGetProduits());
        dispatch(onGetFournisseurs());
         dispatch(onGetCategories());
         dispatch(onGetUsers());
         dispatch(onGetApprovisionnements());
         setFilteredData(approvisionnements.approvisionnements)
         
    },[dispatch,typMsg,onDeleteVente,onUpdateVente]); //onAddNewProduit,onDeleteProduit,onUpdateProduit,produits


   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Produit" breadcrumbItem="Historique des Appros" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                {/* <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                </CardHeader> */}
                                

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                        {ventes.ventes ? <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                <PDFGenerator tableData={tableData}  tableHead={tableHead} tableFoot={tableFoot} headText={headText}   tableData2={tableData2} tableHead2={tableHead2} tableFoot2={tableFoot2}/>
                                                    
                                                </div> 
                                            </Col> :''}   
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                {/* <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div> */}
                                     <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", }}>HISTORIQUES DES ACHATS</span>

                                            </Col>
                                        </Row>
                                        <Row>
                                           <Col>
                                            <label htmlFor="agntSelect">Sélectionner un fournisseur</label>
                                            {/* <Select defaultValue='' options={frsOptions} onChange={handleChangeFrs} /> */}
                                            <MultiSelect value={selectedFrs} onChange={(e) => {setSelectedFrs(e.value)}} defaultValue='' options={frsOptions} optionLabel="label" 
                                                filter placeholder="Choisir un fournisseur" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                            </Col>
                                            <Col>
                                            <label htmlFor="agntSelect">Sélectionner un produit</label>
                                            {/* <Select defaultValue='' options={ prodOptions}    onChange={handleChangeProd}/> */}
                                            <MultiSelect value={selectedProds} onChange={(e) => {setSelectedProds(e.value)}} defaultValue='' options={prodOptions} optionLabel="label" 
                                                filter placeholder="Choisir un produit" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                            </Col>

                                            <Col>
                                            <label htmlFor="startDate">Du</label> 
                                         
                                            <Input   type="date" value={startDate}  onChange={(e) => setStartDate(e.target.value)}  />
                                          
                                            </Col>
                                                
                                            <Col> 
                                            <label htmlFor="endate">Au</label> 
                                        
                                             <Input  type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                              
                                            </Col> 
                                            <Col> 
                                            <label htmlFor="reset">Réinitialiser</label> 
                                            <div><Button color="primary" onClick={resetFiltre}>Annuler Fitre</Button> </div>  
                                                                        
                                             
                                              
                                            </Col>   
                                        </Row>


                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr style={{ border: "2px solid #3282F6",color:"#F3F5D9" }} >
                                                        {/* <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div> 
                                                        </th> */}
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="customer_name" onClick={(prod) => handleSort(prod, 'id')}>N°</th>                                                        
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="date" onClick={(prod) => handleSort(prod, 'createdAt')}>Date</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="nom" onClick={(prod) => handleSort(prod, 'numVente')}>Appro</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="nom" onClick={(prod) => handleSort(prod, 'produit')}>produit</th>  
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="prixAchat" onClick={(prod) => handleSort(prod, 'prixAchat')}>Prix Achat</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="prixVente" onClick={(prod) => handleSort(prod, 'prixVente')}>Prix Vente</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="prixAchat" onClick={(prod) => handleSort(prod, 'quantite')}>Quantite</th>                                            
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="description" onClick={(prod) => handleSort(prod, 'montant')}>Montant</th> 
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="fournisseur" onClick={(prod) => handleSort(prod, 'fournisseur')}>Fournisseur</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="numFacFrs" onClick={(prod) => handleSort(prod, 'numFacFrs')}>N°Facture</th>
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="createdBy" onClick={(prod) => handleSort(prod, 'createdBy')}>Crée par</th>
                                                    
                                                        <th style={{ border: "2px solid #3282F6", backgroundColor:"#367E7F"}} className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                {/* {console.log('curent page data ',currentPageData.length)} */}
                                                    {currentPageData.length>=1 ? currentPageData: ''}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                           <div style={{textAlign: 'left',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>NOMBRE: <span style={{color: '#377E47'}}>{filteredData.length}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(filteredData.reduce((acc, app) => acc + app.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span></h1> <span>{subTable(tableD)}</span> </div>

                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            {/* <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Produit </ModalHeader>
                <form className="tablelist-form" onSubmit={'saveNewProduit'}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="libelle" className="form-label">nom</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(e) => 'setNomProd(e.target.value)'}/>
                            <label htmlFor="stock" className="form-label">stock</label>
                            <input type="number"  step="0.01"  id="stock" className="form-control" placeholder="Enter Stock" required  onChange={(e) => 'setStockProd(e.target.value)'}/>
                            <label htmlFor="prixAchat" className="form-label">Prix Achat</label>
                            <input type="number"  step="0.01" id="prixAchat" className="form-control" placeholder="Enter Achat" required  onChange={(e) => 'setPrixAchat(e.target.value)'}/>
            
                            <label htmlFor="prixVente" className="form-label">prix Vente</label>
                            <input type="number"   step="0.01" id="prixVente" className="form-control" placeholder="Enter Vente" required  onChange={(e) => setPrixVente(e.target.value)}/>
                            <label htmlFor="categorie" className="form-label">categorie</label>
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setCategorieProd(e.target.value)}  defaultValue="0">
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>

                        </div>  
                        <div className="mb-3">
                            <label htmlFor="descritpion" className="form-label">Description</label>
                            <input type="text" id="description" className="form-control" placeholder="Description" required  onChange={(e) => setDescProd(e.target.value)}/>
                             
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewProduit}>Enregistrer</button>
                            
                        </div>
                    </ModalFooter>
                </form>
            </Modal> */}

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour d'un appro </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateAppro}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="produit" className="form-label">produit</label>
                            <InputGroup>
                                <Input type="select" value={produit} onChange={(e) => setProduit(e.target.value)}  >
                                <option value="">Sélectionnez un produit</option> {/* Option vide par défaut */}
                                {Object.values(produits.produits).map((prod) => (
                                    <option key={prod.id} value={prod.id}>
                                    {prod.nomProd}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                            <label htmlFor="prixVente" className="form-label">Prix Achat</label>
                            <input type="decimal"   id="prixAchat" className="form-control" placeholder="prix Achat" required  onKeyDown={handleKeyDown}   value={ prixAchat}   onChange={(event) => {setPrixAchat(event.target.value);setMontant(event.target.value*quantite)}}/>
                            <label htmlFor="prixVente" className="form-label">Prix Vente</label>
                            <input type="decimal"    id="prixVente" className="form-control" placeholder="prix Vente" required  onKeyDown={handleKeyDown}   value={prixVente}   onChange={(event) => setPrixVente(event.target.value)}/>
                           
                            <label htmlFor="quantite" className="form-label">Quantite</label>
                            <input type="decimal"     id="quantite" className="form-control" placeholder="quantite" required   onKeyDown={handleKeyDown}  value={quantite}   onChange={(event) => {setQuantite(event.target.value);setMontant(event.target.value*prixAchat)}}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                            <input type="decimal"    id="montant" className="form-control" placeholder="montant" required   onKeyDown={handleKeyDown}  value={parseFloat(montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}   onChange={(event) => setMontant(event.target.value)}/>
                             
                            <label htmlFor="agent" className="form-label">Fournisseur</label>
                            <InputGroup>
                                <Input type="select" value={fournisseur} onChange={(e) => setFournisseur(e.target.value)}  >
                                <option value="">Sélectionnez un Fournisseur</option> {/* Option vide par défaut */}
                                {Object.values(fournisseurs.fournisseurs).map((fr) => (
                                    <option key={fr.id} value={fr.id}>
                                    {fr.libelle+' '}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                 
                        </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateAppro}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>


              {/* SHOW Modal */}
              <Modal isOpen={modal_show} toggle={() => { tog_show(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Plus d'info</ModalHeader>
                <form className="tablelist-form" >
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="produit" className="form-label">N°Facture</label>
                            <input type="text" id="produit" className="form-control"   disabled="true" value={numFacFrs} />
                            <label htmlFor="immatTracteur" className="form-label">N°Tracteur</label>
                            <input type="text" id="immatTracteur" className="form-control"   disabled="true"  value={immatTracteur}   />
                            <label htmlFor="immatCiterne" className="form-label">N°Citerne</label>
                            <input type="text" id="immatCiterne" className="form-control" disabled="true" value={immatCiterne}  />
                            <label htmlFor="nomPrenomChaufeur" className="form-label">Chauffeur</label>
                            <input type="text" id="nomPrenomChaufeur" className="form-control" disabled="true"  value={nomPrenomChaufeur}  />
                            <label htmlFor="permis" className="form-label">Permis de conduire</label>
                            <input type="text" id="permis" className="form-control"   disabled="true" value={permis}  />
                            <label htmlFor="contact" className="form-label">Contact</label>
                            <input type="text" id="contact" className="form-control" disabled="true"     value={contact}  />
                             
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_show(false)}>Fermer</button>
                             
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default HistoAppro;
