// Fonction pour générer un ID unique de 8 caractères numériques
const generateUniqueId=() =>{
    const idLength = 4; // Longueur de l'ID souhaitée
    let id = '';
  
    // Générer un nombre aléatoire avec la longueur souhaitée
    for (let i = 0; i < idLength; i++) {
      id += Math.floor(Math.random() * 10); // Ajouter un chiffre aléatoire de 0 à 9
    }
  
    return id;
    console.log('id',id)
  }
  
 export default generateUniqueId;