/* DETAIL_CUVES */
export const GET_DETAIL_CUVES = "GET_DETAIL_CUVES";
export const GET_DETAIL_CUVES_SUCCESS = "GET_DETAIL_CUVES_SUCCESS";
export const GET_DETAIL_CUVES_FAIL = "GET_DETAIL_CUVES_FAIL";

export const GET_DETAIL_CUVE = "GET_DETAIL_CUVE";
export const GET_DETAIL_CUVE_SUCCESS = "GET_DETAIL_CUVE_SUCCESS";
export const GET_DETAIL_CUVE_FAIL = "GET_DETAIL_CUVE_FAIL";

export const ADD_NEW_DETAIL_CUVE = "ADD_NEW_DETAIL_CUVE";
export const ADD_DETAIL_CUVE_SUCCESS = "ADD_DETAIL_CUVE_SUCCESS";
export const ADD_DETAIL_CUVE_FAIL = "ADD_DETAIL_CUVE_FAIL";

export const UPDATE_DETAIL_CUVE = "UPDATE_DETAIL_CUVE";
export const UPDATE_DETAIL_CUVE_SUCCESS = "UPDATE_DETAIL_CUVE_SUCCESS";
export const UPDATE_DETAIL_CUVE_FAIL = "UPDATE_DETAIL_CUVE_FAIL";

export const DELETE_DETAIL_CUVE = "DELETE_DETAIL_CUVE";
export const DELETE_DETAIL_CUVE_SUCCESS = "DELETE_DETAIL_CUVE_SUCCESS";
export const DELETE_DETAIL_CUVE_FAIL = "DELETE_DETAIL_CUVE_FAIL";

 