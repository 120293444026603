import {
  GET_VENTES,
  GET_VENTES_SUCCESS,
  GET_VENTES_FAIL,
  GET_VENTE,
  GET_VENTE_FAIL,
  GET_VENTE_SUCCESS,
  ADD_NEW_VENTE,
  ADD_VENTE_SUCCESS,
  ADD_VENTE_FAIL,
  UPDATE_VENTE,
  UPDATE_VENTE_SUCCESS,
  UPDATE_VENTE_FAIL,
  DELETE_VENTE,
  DELETE_VENTE_SUCCESS,
  DELETE_VENTE_FAIL,
} from "./actionTypes";

export const getVentes = () => ({
  type: GET_VENTES,
});

export const getVentesSuccess = ventes => ({
  type: GET_VENTES_SUCCESS,
  payload: ventes,
});

export const getVentesFail = error => ({
  type: GET_VENTES_FAIL,
  payload: error,
});

export const getVente = () => ({
  type: GET_VENTE,
});

export const getVenteSuccess = vente => ({
  type: GET_VENTE_SUCCESS,
  payload: vente,
});

export const getVenteFail = error => ({
  type: GET_VENTE_FAIL,
  payload: error,
});

export const addNewVente = vente => ({
  type: ADD_NEW_VENTE,
  payload: vente,
});

export const addVenteSuccess = vente => ({
  type: ADD_VENTE_SUCCESS,
  payload: vente,
});

export const addVenteFail = error => ({
  type: ADD_VENTE_FAIL,
  payload: error,
  
});

export const updateVente = (vt,vente) => ({
  type: UPDATE_VENTE,
  payload: { id: vt, data: vente },
});

export const updateVenteSuccess =  (vt,vente)  => ({
  type: UPDATE_VENTE_SUCCESS,
  payload: { id: vt, data: vente },
});

export const updateVenteFail = error => ({
  type: UPDATE_VENTE_FAIL,
  payload: error,
});

export const deleteVente = vente => ({
  type: DELETE_VENTE,
  payload: vente,
});

export const deleteVenteSuccess = vente => ({
  type: DELETE_VENTE_SUCCESS,
  payload: vente,
});

export const deleteVenteFail = error => ({
  type: DELETE_VENTE_FAIL,
  payload: error,
});



 
