/* POMPES */
export const GET_POMPES = "GET_POMPES";
export const GET_POMPES_SUCCESS = "GET_POMPES_SUCCESS";
export const GET_POMPES_FAIL = "GET_POMPES_FAIL";

export const GET_POMPE = "GET_POMPE";
export const GET_POMPE_SUCCESS = "GET_POMPE_SUCCESS";
export const GET_POMPE_FAIL = "GET_POMPE_FAIL";

export const ADD_NEW_POMPE = "ADD_NEW_POMPE";
export const ADD_POMPE_SUCCESS = "ADD_POMPE_SUCCESS";
export const ADD_POMPE_FAIL = "ADD_POMPE_FAIL";

export const UPDATE_POMPE = "UPDATE_POMPE";
export const UPDATE_POMPE_SUCCESS = "UPDATE_POMPE_SUCCESS";
export const UPDATE_POMPE_FAIL = "UPDATE_POMPE_FAIL";

export const DELETE_POMPE = "DELETE_POMPE";
export const DELETE_POMPE_SUCCESS = "DELETE_POMPE_SUCCESS";
export const DELETE_POMPE_FAIL = "DELETE_POMPE_FAIL";

 