import { 
  GET_MOUVEMENTSTOCKS,
  GET_MOUVEMENTSTOCKS_SUCCESS,
  GET_MOUVEMENTSTOCKS_FAIL,
  GET_MOUVEMENTSTOCK,
  GET_MOUVEMENTSTOCK_FAIL,
  GET_MOUVEMENTSTOCK_SUCCESS,
  ADD_NEW_MOUVEMENTSTOCK,
  ADD_MOUVEMENTSTOCK_SUCCESS,
  ADD_MOUVEMENTSTOCK_FAIL,
  UPDATE_MOUVEMENTSTOCK,
  UPDATE_MOUVEMENTSTOCK_SUCCESS,
  UPDATE_MOUVEMENTSTOCK_FAIL,
  DELETE_MOUVEMENTSTOCK,
  DELETE_MOUVEMENTSTOCK_SUCCESS,
  DELETE_MOUVEMENTSTOCK_FAIL,
} from "./actionTypes";

export const getMouvementStocks = () => ({
  type: GET_MOUVEMENTSTOCKS,
});

export const getMouvementStocksSuccess = mouvementStocks => ({
  type: GET_MOUVEMENTSTOCKS_SUCCESS,
  payload: mouvementStocks,
});

export const getMouvementStocksFail = error => ({
  type: GET_MOUVEMENTSTOCKS_FAIL,
  payload: error,
});

export const getMouvementStock = () => ({
  type: GET_MOUVEMENTSTOCK,
});

export const getMouvementStockSuccess = mouvementStock => ({
  type: GET_MOUVEMENTSTOCK_SUCCESS,
  payload: mouvementStock,
});

export const getMouvementStockFail = error => ({
  type: GET_MOUVEMENTSTOCK_FAIL,
  payload: error,
});

export const addNewMouvementStock = mouvementStock => ({
  type: ADD_NEW_MOUVEMENTSTOCK,
  payload: mouvementStock,
});

export const addMouvementStockSuccess = mouvementStock => ({
  type: ADD_MOUVEMENTSTOCK_SUCCESS,
  payload: mouvementStock,
});

export const addMouvementStockFail = error => ({
  type: ADD_MOUVEMENTSTOCK_FAIL,
  payload: error,
  
});

export const updateMouvementStock = (prod,mouvementStock) => ({
  type: UPDATE_MOUVEMENTSTOCK,
  payload: { id: prod, data: mouvementStock },
});

export const updateMouvementStockSuccess = (prod, mouvementStock) => ({
  type: UPDATE_MOUVEMENTSTOCK_SUCCESS,
  payload: { id: prod, data: mouvementStock },
});

export const updateMouvementStockFail = error => ({
  type: UPDATE_MOUVEMENTSTOCK_FAIL,
  payload: error,
});

export const deleteMouvementStock = mouvementStock => ({
  type: DELETE_MOUVEMENTSTOCK,
  payload: mouvementStock,
});

export const deleteMouvementStockSuccess = mouvementStock => ({
  type: DELETE_MOUVEMENTSTOCK_SUCCESS,
  payload: mouvementStock,
});

export const deleteMouvementStockFail = error => ({
  type: DELETE_MOUVEMENTSTOCK_FAIL,
  payload: error,
});



 
