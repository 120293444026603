/* VENTES */
export const GET_VENTES = "GET_VENTES";
export const GET_VENTES_SUCCESS = "GET_VENTES_SUCCESS";
export const GET_VENTES_FAIL = "GET_VENTES_FAIL";

export const GET_VENTE = "GET_VENTE";
export const GET_VENTE_SUCCESS = "GET_VENTE_SUCCESS";
export const GET_VENTE_FAIL = "GET_VENTE_FAIL";

export const ADD_NEW_VENTE = "ADD_NEW_VENTE";
export const ADD_VENTE_SUCCESS = "ADD_VENTE_SUCCESS";
export const ADD_VENTE_FAIL = "ADD_VENTE_FAIL";

export const UPDATE_VENTE = "UPDATE_VENTE";
export const UPDATE_VENTE_SUCCESS = "UPDATE_VENTE_SUCCESS";
export const UPDATE_VENTE_FAIL = "UPDATE_VENTE_FAIL";

export const DELETE_VENTE = "DELETE_VENTE";
export const DELETE_VENTE_SUCCESS = "DELETE_VENTE_SUCCESS";
export const DELETE_VENTE_FAIL = "DELETE_VENTE_FAIL";

 