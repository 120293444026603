import React, { useState, useEffect }  from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { groupBy } from 'lodash';

import {
  //produits
  getProduits as onGetProduits,
  getVentes as onGetVentes,
 

   
} from "../../store/actions"; 



export const LineColumnArea = (annee) => {
  
const dispatch = useDispatch();
 
const ventes = useSelector((state) => ({  ventes:  state.ventes.ventes  }));
const appros = useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements    }));
 
//  console.log('state', useSelector((state) => ({  ventes:  state  })))
//  console.log("APPRRRRO  st",useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements  })))
// console.log("appros",appros)
useEffect(() => {   
  dispatch(onGetProduits());   
},[dispatch]);
 
 
            /**CALCULE DES QUANTITES APPRO */ 
            
            const approsParProduitEtMois = groupBy(Object.values(appros.approvisionnements), appro => { 
              const date = new Date(appro.dateEnregistrement); 
              const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0 
              const annee = date.getFullYear(); 
              return `${appro.produit}-${mois}-${annee}`;
            });

            
              // const approsFiltres = Object.keys(approsParProduitEtMois)
              // .filter(key => {
              //   const [, , annee] = key.split('-'); // Découper la clé pour récupérer l'année  
              //   return parseInt(annee, 10) === parseInt(annee.annee, 10); // Filtrer par année
              // })
              // .reduce((result, key) => {
              //   console.log("approsParProduitEtMois[key]",approsParProduitEtMois[key])
              //   result[key] = approsParProduitEtMois[key];
              //   return result;
              // }, {});
             
              const filteredAppros = Object.keys(approsParProduitEtMois)
                        .filter(key => {
                          const anneePart = key.split('-')[2];
                          return parseInt(anneePart, 10) === parseInt(annee.annee, 10);
                        })
                        .reduce((obj, key) => {
                          obj[key] = approsParProduitEtMois[key];
                          return obj;
                        }, {});
              // console.log('approsParProduitEtMois',approsParProduitEtMois)

              // console.log('filteredAppros',filteredAppros)

            // calucle des des quantités appro du par prduits,mois et années
            const quantiteProduitApproMois={}
            for (const key in filteredAppros) {
              const approsGroupees = filteredAppros[key];
              const quantite= approsGroupees.reduce((acc, appro) => acc + appro.quantite, 0); ;
            //parseFloat(quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            quantiteProduitApproMois[key]=quantite
            }
            // console.log("quantiteProduitApproMois",quantiteProduitApproMois)

                /**** dermination des quandité par produit des differents mois de l'annee*/
                const essenceAppro=Object.keys(quantiteProduitApproMois).filter(key => key.startsWith("5")).map(key => ({ [key]: quantiteProduitApproMois[key] }));;
                //const esJuin = Object.values(essence).find(obj => Object.keys(obj)[0].includes('-6-'));
              const gazoilAppro=Object.keys(quantiteProduitApproMois).filter(key => key.startsWith("4")).map(key => ({ [key]: quantiteProduitApproMois[key] }));;

              const essenceMotoAppro=Object.keys(quantiteProduitApproMois).filter(key => key.startsWith("7")).map(key => ({ [key]: quantiteProduitApproMois[key] }));;
            /*FIN DES QUANTITES APPRO */


        /**CALCULE DES QUANTITES VENDUES */
        const ventesParProduitEtMois = groupBy(Object.values(ventes.ventes), vente => { 
          const date = new Date(vente.dateEnregistrement); 
          const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0 
          const annee = date.getFullYear(); 
          return `${vente.produit}-${mois}-${annee}`;
        });

        const filteredVentes = Object.keys(ventesParProduitEtMois)
        .filter(key => {
          const anneePart = key.split('-')[2];
          return parseInt(anneePart, 10) === parseInt(annee.annee, 10);
        })
        .reduce((obj, key) => {
          obj[key] = ventesParProduitEtMois[key];
          return obj;
        }, {});

        // calucle des des quantités ventes du par prduits,mois et années
        const quantiteProduitVenteMois={}
        for (const key in filteredVentes) {
          const ventesGroupees = filteredVentes[key];
          const quantite= ventesGroupees.reduce((acc, vente) => acc + vente.quantiteReel, 0);
        // console.log(`Quantités des ventes pour ${key}: ${quantite}`);
        //parseFloat(quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        quantiteProduitVenteMois[key]=quantite
        }


            /**** dermination des quandité par produit des differents mois de l'annee*/
          const essenceVendu=Object.keys(quantiteProduitVenteMois).filter(key => key.startsWith("5")).map(key => ({ [key]: quantiteProduitVenteMois[key] }));;
            //const esJuin = Object.values(essence).find(obj => Object.keys(obj)[0].includes('-6-'));
          const gazoilVendu=Object.keys(quantiteProduitVenteMois).filter(key => key.startsWith("4")).map(key => ({ [key]: quantiteProduitVenteMois[key] }));;

          const essenceMotoVendu=Object.keys(quantiteProduitVenteMois).filter(key => key.startsWith("7")).map(key => ({ [key]: quantiteProduitVenteMois[key] }));;
        /*FIN DES QUANTITES VENDUES */




           /**CALCULE DU CHIFFRE D AFFAIRE */ 
  
          // calucle des des chiffre ventes du par prduits,mois et années
          const chiffreProduitVenteMois={}
          for (const key in filteredVentes) {
            const ventesGroupees = filteredVentes[key];
            const montant= ventesGroupees.reduce((acc, vente) => acc + vente.montantVerse, 0);
          // console.log(`Quantités des ventes pour ${key}: ${quantite}`);
          //parseFloat(quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          chiffreProduitVenteMois[key]=montant
          }
  
  
              /**** dermination des quandité par produit des differents mois de l'annee*/
            const essenceCa=Object.keys(chiffreProduitVenteMois).filter(key => key.startsWith("5")).map(key => ({ [key]: chiffreProduitVenteMois[key] }));;
              //const esJuin = Object.values(essence).find(obj => Object.keys(obj)[0].includes('-6-'));
            const gazoilCa=Object.keys(chiffreProduitVenteMois).filter(key => key.startsWith("4")).map(key => ({ [key]: chiffreProduitVenteMois[key] }));;
  
            const essenceMotoCa=Object.keys(chiffreProduitVenteMois).filter(key => key.startsWith("7")).map(key => ({ [key]: chiffreProduitVenteMois[key] }));;
          /*FIN CHIFFRE D AFFAIRES */



const MoisValue=(ob,p)=>{
  const ObjetMois = ob.map(obj => {
      const ancienneCle = Object.keys(obj)[0];
      const mois = ancienneCle.length>=3 ? ancienneCle.split('-')[1]: ancienneCle; // Obtient le mois
      const valeur = Object.values(obj)[0];
      
      return { [mois]: valeur };
    });

  const objetMoisRecherche = ObjetMois.find(obj => Object.keys(obj)[0] === p);

  if (objetMoisRecherche) {
  const valeurMoisRecherche = Object.values(objetMoisRecherche)[0];
  //console.log(`La valeur pour le mois ${6} est : ${valeurMoisRecherche}`);
  return parseInt(valeurMoisRecherche)
  } else {
  return 0
  }

}

 

///FUSION  DES OBJETS POUR LA SORITIES
  // pour le tout 
  // Fusionner les tableaux A et B
const qpprosFusionne = {};
const ventesFusionne = {};
const caFusionne = {};

// Fonction pour fusionner un objet avec objFusionne
const fusionnerObjet = (objet) => {
  for (const cle in objet) {
    if (cle in qpprosFusionne) {
      qpprosFusionne[cle] += objet[cle];
    } else {
      qpprosFusionne[cle] = objet[cle];
    }
  }
};


// Fusionner les appros
essenceAppro.forEach(fusionnerObjet);
gazoilAppro.forEach(fusionnerObjet);
essenceMotoAppro.forEach(fusionnerObjet);
const qpprosFusionne2 =Object.entries(qpprosFusionne).map(([cle, valeur]) => ({ [cle]: valeur }));
 

/////funsionner les resultats de la premiiere fusion
const approCumulParMois = {};

qpprosFusionne2.forEach(objet => {
  for (const cle in objet) {
    const valeur = objet[cle];
    const [,mois,] = cle.split('-'); // Obtient le mois à partir de la clé
    if (mois in approCumulParMois) {
      approCumulParMois[mois] += valeur;
    } else {
      approCumulParMois[mois] = valeur;
    }
  }
});
const approCumulParMois2 =Object.entries(approCumulParMois).map(([cle, valeur]) => ({ [cle]: valeur }));

///ventes cumul 
// Fonction pour fusionner un objet avec objFusionne
const fusionnerVentes = (objet) => {
  for (const cle in objet) {
    if (cle in ventesFusionne) {
      ventesFusionne[cle] += objet[cle];
    } else {
      ventesFusionne[cle] = objet[cle];
    }
  }
};


// Fusionner les appros
essenceVendu.forEach(fusionnerVentes);
gazoilVendu.forEach(fusionnerVentes);
essenceVendu.forEach(fusionnerVentes);
const ventesFusionne2 =Object.entries(ventesFusionne).map(([cle, valeur]) => ({ [cle]: valeur }));
 

/////funsionner les resultats de la premiiere fusion
const ventesCumulParMois = {};

ventesFusionne2.forEach(objet => {
  for (const cle in objet) {
    const valeur = objet[cle];
    const [,mois,] = cle.split('-'); // Obtient le mois à partir de la clé
    if (mois in ventesCumulParMois) {
      ventesCumulParMois[mois] += valeur;
    } else {
      ventesCumulParMois[mois] = valeur;
    }
  }
});
const ventesCumulParMois2 =Object.entries(ventesCumulParMois).map(([cle, valeur]) => ({ [cle]: valeur }));

// chiffre d affaire cummul

// Fonction pour fusionner un objet avec objFusionne
 const fusionnerCa = (objet) => {
  for (const cle in objet) {
    if (cle in caFusionne) {
      caFusionne[cle] += objet[cle];
    } else {
      caFusionne[cle] = objet[cle];
    }
  }
};


// Fusionner les appros
essenceCa.forEach(fusionnerCa);
gazoilCa.forEach(fusionnerCa);
essenceMotoCa.forEach(fusionnerCa);
const caFusionne2 =Object.entries(caFusionne).map(([cle, valeur]) => ({ [cle]: valeur }));
 

/////funsionner les resultats de la premiiere fusion
const caCumulParMois = {};

caFusionne2.forEach(objet => {
  for (const cle in objet) {
    const valeur = objet[cle];
    const [,mois,] = cle.split('-'); // Obtient le mois à partir de la clé
    if (mois in caCumulParMois) {
      caCumulParMois[mois] += valeur;
    } else {
      caCumulParMois[mois] = valeur;
    }
  }
});
 const caCumulParMois2 =Object.entries(caCumulParMois).map(([cle, valeur]) => ({ [cle]: valeur }));

 


/// { ...gazoilCa, ...essenceCa, ...essenceMotoCa }

const dataAppros =annee.produit===4 ? gazoilAppro : annee.produit===5 ? essenceAppro : annee.produit===7 ? essenceMotoAppro :  approCumulParMois2
const dataAVentes =annee.produit===4 ? gazoilVendu : annee.produit===5 ? essenceVendu : annee.produit===7 ? essenceMotoVendu : ventesCumulParMois2
const dataCa =annee.produit===4 ? gazoilCa : annee.produit===5 ? essenceCa: annee.produit===7 ? essenceMotoCa :  caCumulParMois2
  
 

const [LineColumnAreaData , setLineColumnAreaData] = useState({
  series: [
    {
      name: "C.A divisé par 1000 ",
      type: "column",
      data:  [MoisValue(dataCa,'1')/1000, MoisValue(dataCa,'2')/1000 , MoisValue(dataCa,'3')/1000, MoisValue(dataCa,'4')/1000, MoisValue(dataCa,'5')/1000,  MoisValue(dataCa,'6')/1000, MoisValue(dataCa,'7')/1000,MoisValue(dataCa,'8')/1000,MoisValue(dataCa,'9')/1000,MoisValue(dataCa,'10')/1000,MoisValue(dataCa,'11')/1000,MoisValue(dataCa,'12')/1000],
    },
    {
      name: "Quantité Appro",
      type: "area",
      data: [MoisValue(dataAppros,'1'), MoisValue(dataAppros,'2') , MoisValue(dataAppros,'3'), MoisValue(dataAppros,'4'), MoisValue(dataAppros,'5'),  MoisValue(dataAppros,'6'), MoisValue(dataAppros,'7'),MoisValue(dataAppros,'8'),MoisValue(dataAppros,'9'),MoisValue(dataAppros,'10'),MoisValue(dataAppros,'11'),MoisValue(dataAppros,'12')],
    },
    {
      name: "Quantité Vendue",
      type: "line",
      data: [MoisValue(dataAVentes,'1'), MoisValue(dataAVentes,'2') , MoisValue(dataAVentes,'3'), MoisValue(dataAVentes,'4'), MoisValue(dataAVentes,'5'),  MoisValue(dataAVentes,'6'), MoisValue(dataAVentes,'7'),MoisValue(dataAVentes,'8'),MoisValue(dataAVentes,'9'),MoisValue(dataAVentes,'10'),MoisValue(dataAVentes,'11'),MoisValue(dataAVentes,'12')],
    },
  ],
  options: {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 0.5, 1],
      curve: "smooth",
      dashArray: [0, 8, 5]
    },
    plotOptions: {
      bar: {
        columnWidth: "18%",
      },
    },
    colors: ["#0ab39c", "rgba(87, 199, 255, 0.18)", "rgb(251, 77, 83)"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Decembre"
    ],
    markers: {
      size: 0,
    },
    legend: {
      offsetY: 5,
    },
    xaxis: {
      type: "month",
    },
    yaxis: {
      title: {
        text: "Valeurs",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
             
            return parseFloat(y).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " Valeurs"  //parseFloat(y.toFixed(0) ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          }
         
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  },
})

//const updatedSeries = [...LineColumnAreaData.series, ...additionalData];


  return(
    <React.Fragment>
        <ReactApexChart
          options={LineColumnAreaData.options}
          series={LineColumnAreaData.series}
          type="line"
          height="450"
          stacked= "false"
          className="apex-charts"
        />
      </React.Fragment>
  )
}

export default LineColumnArea;
