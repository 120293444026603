import {
  GET_CUVES_SUCCESS,
  GET_CUVES_FAIL,
  GET_CUVE_SUCCESS,
  GET_CUVE_FAIL,
  ADD_CUVE_SUCCESS,
  ADD_CUVE_FAIL,
  UPDATE_CUVE_SUCCESS,
  UPDATE_CUVE_FAIL,
  DELETE_CUVE_SUCCESS,
  DELETE_CUVE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  cuves: [],
  error: {},
};

const Cuves = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUVES_SUCCESS:
      return {
        ...state,
        cuves: action.payload,
      };

    case GET_CUVES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_CUVE_SUCCESS:
      return {
        ...state,
        cuve: action.payload,
      };
  
    case GET_CUVE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_CUVE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        cuves: [...state.cuves, action.payload],
      };

    case ADD_CUVE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_CUVE_SUCCESS:
      return {
        ...state,
        cuves: state.cuves.map(cuve =>
          cuve.id.toString() === action.payload.id.toString()
            ? { cuve, ...action.payload }
            : cuve
        ),
        isCuveUpdated: true
      };

    case UPDATE_CUVE_FAIL:
      return {
        ...state,
        error: action.payload,
        isCuveUpdated: false
      };

    case DELETE_CUVE_SUCCESS:
      return {
        ...state,
        cuves: state.cuves.filter(
          cuve => cuve.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_CUVE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Cuves;
