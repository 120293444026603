import {
  GET_DETAIL_CUVES,
  GET_DETAIL_CUVES_SUCCESS,
  GET_DETAIL_CUVES_FAIL,
  GET_DETAIL_CUVE,
  GET_DETAIL_CUVE_FAIL,
  GET_DETAIL_CUVE_SUCCESS,
  ADD_NEW_DETAIL_CUVE,
  ADD_DETAIL_CUVE_SUCCESS,
  ADD_DETAIL_CUVE_FAIL,
  UPDATE_DETAIL_CUVE,
  UPDATE_DETAIL_CUVE_SUCCESS,
  UPDATE_DETAIL_CUVE_FAIL,
  DELETE_DETAIL_CUVE,
  DELETE_DETAIL_CUVE_SUCCESS,
  DELETE_DETAIL_CUVE_FAIL,
} from "./actionTypes";

export const getDetailCuves = () => ({
  type: GET_DETAIL_CUVES,
});

export const getDetailCuvesSuccess = detailCuves => ({
  type: GET_DETAIL_CUVES_SUCCESS,
  payload: detailCuves,
});

export const getDetailCuvesFail = error => ({
  type: GET_DETAIL_CUVES_FAIL,
  payload: error,
});

export const getDetailCuve = () => ({
  type: GET_DETAIL_CUVE,
});

export const getDetailCuveSuccess = detailCuve => ({
  type: GET_DETAIL_CUVE_SUCCESS,
  payload: detailCuve,
});

export const getDetailCuveFail = error => ({
  type: GET_DETAIL_CUVE_FAIL,
  payload: error,
});

export const addNewDetailCuve = detailCuve => ({
  type: ADD_NEW_DETAIL_CUVE,
  payload: detailCuve,
});

export const addDetailCuveSuccess = detailCuve => ({
  type: ADD_DETAIL_CUVE_SUCCESS,
  payload: detailCuve,
});

export const addDetailCuveFail = error => ({
  type: ADD_DETAIL_CUVE_FAIL,
  payload: error,
  
});

export const updateDetailCuve = detailCuve => ({
  type: UPDATE_DETAIL_CUVE,
  payload: detailCuve,
});

export const updateDetailCuveSuccess = detailCuve => ({
  type: UPDATE_DETAIL_CUVE_SUCCESS,
  payload: detailCuve,
});

export const updateDetailCuveFail = error => ({
  type: UPDATE_DETAIL_CUVE_FAIL,
  payload: error,
});

export const deleteDetailCuve = detailCuve => ({
  type: DELETE_DETAIL_CUVE,
  payload: detailCuve,
});

export const deleteDetailCuveSuccess = detailCuve => ({
  type: DELETE_DETAIL_CUVE_SUCCESS,
  payload: detailCuve,
});

export const deleteDetailCuveFail = error => ({
  type: DELETE_DETAIL_CUVE_FAIL,
  payload: error,
});



 
