import React, { useEffect, useState } from 'react';
import Select  from 'react-select';
import LineColumnArea from './LineColumnArea';
import { useSelector, useDispatch } from "react-redux";
import { groupBy } from 'lodash';

import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap";

import { OverViewData } from '../../CommonData/Data/index';

 
 
const OverView = () => {
             const [forceUpdate, setForceUpdate] = useState(0);
            const [annee,setAnnee]=useState(2020);
            const [produit,setProduit]=useState(5);
           // console.log("annee val",annee)
            
         ///useEffect(() => { setAnnee(localStorage.getItem('anneeEnCours')); setProduit(5) }, []); 
         useEffect(() => {
            // Définir l'année après 2 secondes
            setTimeout(() => {
              setAnnee(localStorage.getItem('anneeEnCours'));
              setProduit(0);
            }, 	2000);
        
            // Définir le produit
            
          }, []); 

           
 
 

         const approvisionnements = useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements  }));

         
            /**CALCULE DES QUANTITES APPRO */
        const annees = groupBy(Object.values(approvisionnements.approvisionnements), appro => {
    
        const date = new Date(appro.dateEnregistrement);
      
        const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0
         
        const annee = date.getFullYear(); 
        
        return `${annee}`;
      });

           // Trouver la valeur maximale dans le tableau
          // const valeurMaximale = Math.max(...Object.keys(annees).map(Number));

           const anneeOptions=Object.keys(annees).map(an => ({value:an, label:an })) 
            const defaultValue ={ value:localStorage.getItem('anneeEnCours') , label:localStorage.getItem('anneeEnCours')};  
  
            /// lorsque l'utilisateur choisi un produit

                const handleChangeAnnee =(an)=>{
                    setAnnee(an.value) 
                    
                    localStorage.setItem('anneeEnCours', (an.value).toString());
                      
                }
        
         
               
        
    return (
        <React.Fragment>
            <Col xl={8}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="card-title">Analyse</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <div className="d-flex align-items-center">
                                   <Select defaultValue={defaultValue} options={anneeOptions} onChange={handleChangeAnnee} /> 
                                    <button type="button" className="btn btn-soft-secondary btn-sm me-1 "  onClick={() => setProduit(0)} >
                                        TOUT
                                    </button>
                                        
                                    <button type="button" className="btn btn-soft-primary btn-sm me-1" onClick={() => setProduit(5)}>
                                    ESSENCE
                                    </button>
                                    <button type="button"  className="btn btn-soft-info btn-sm me-1" onClick={() => setProduit(4)} >
                                        GAZOIL
                                    </button>
                                    <button type="button" className="btn btn-soft-warning btn-sm me-1 " onClick={() => setProduit(7)} >
                                        ESSENCE MOTO
                                    </button>
                                   
                                </div>
                            </div>
                        </div>
                        <div>
                            <LineColumnArea key={produit}  annee={annee  ? annee: localStorage.getItem('anneeEnCours')} produit={produit? produit:0}   />
                        
                         </div>

                         
                    </CardBody>
                   {/* <CardBody className="border-top">
                        <div className="text-muted text-center">
                            <Row>
                                {OverViewData.map((item, key) => (<Col md={4} key={key} className="border-end">
                                    <div>
                                        <p className="mb-2"><i className={"mdi mdi-circle font-size-12 me-1 text-" + item.color}></i> {item.title}</p>
                                    </div>
                                </Col>))}
                            </Row>
                        </div>
                    </CardBody>*/}
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default OverView;
