import {
  GET_DEPENSE_SOUSCATEGORIES_SUCCESS,
  GET_DEPENSE_SOUSCATEGORIES_FAIL,
  GET_DEPENSE_SOUSCATEGORIE_SUCCESS,
  GET_DEPENSE_SOUSCATEGORIE_FAIL,
  ADD_DEPENSE_SOUSCATEGORIE_SUCCESS,
  ADD_DEPENSE_SOUSCATEGORIE_FAIL,
  UPDATE_DEPENSE_SOUSCATEGORIE_SUCCESS,
  UPDATE_DEPENSE_SOUSCATEGORIE_FAIL,
  DELETE_DEPENSE_SOUSCATEGORIE_SUCCESS,
  DELETE_DEPENSE_SOUSCATEGORIE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  depenseSousCategories: [],
  error: {},
};

const DepenseSousCategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPENSE_SOUSCATEGORIES_SUCCESS:
      return {
        ...state,
        depenseSousCategories: action.payload,
      };

    case GET_DEPENSE_SOUSCATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DEPENSE_SOUSCATEGORIE_SUCCESS:
      return {
        ...state,
        depenseSousCategorie: action.payload,
      };
  
    case GET_DEPENSE_SOUSCATEGORIE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_DEPENSE_SOUSCATEGORIE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        depenseSousCategories: [...state.depenseSousCategories, action.payload],
      };

    case ADD_DEPENSE_SOUSCATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_DEPENSE_SOUSCATEGORIE_SUCCESS:
      return {
        ...state,
        depenseSousCategories: state.depenseSousCategories.map(depenseSousCategorie =>
          depenseSousCategorie.id.toString() === action.payload.id.toString()
            ? { depenseSousCategorie, ...action.payload }
            : depenseSousCategorie
        ),
        isDepenseSousCategorieUpdated: true
      };

    case UPDATE_DEPENSE_SOUSCATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
        isDepenseSousCategorieUpdated: false
      };

    case DELETE_DEPENSE_SOUSCATEGORIE_SUCCESS:
      return {
        ...state,
        depenseSousCategories: state.depenseSousCategories.filter(
          depenseSousCategorie => depenseSousCategorie.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DEPENSE_SOUSCATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default DepenseSousCategories;
