import React from "react";
import { Link } from "react-router-dom";

//SimpleBar
import SimpleBar from "simplebar-react";


import { Card, CardBody, CardTitle, Col } from "reactstrap";


import { NotificationsData } from "../../CommonData/Data/index";
import VenteChartComponent from "../Charts/venteCharts";

const Vente = () => {
  return (
    <React.Fragment>
      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Vente Comparées</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
                <VenteChartComponent op='0' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Vente Essence</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
              <VenteChartComponent op='5' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>


      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Vente Gas-Oil</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
              <VenteChartComponent op='4' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Vente Essence Moto</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
              <VenteChartComponent op='7' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default Vente;
