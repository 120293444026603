import { takeEvery, put, call } from "redux-saga/effects"

// Salaires Redux States
import {
  ADD_NEW_SALAIRE,
  DELETE_SALAIRE,
  GET_SALAIRES,
  GET_SALAIRE,
  UPDATE_SALAIRE,
} from "./actionTypes"
import { 
  getSalairesSuccess,
  getSalairesFail,
  getSalaireSuccess,
  getSalaireFail,
  addSalaireFail,
  addSalaireSuccess,
  updateSalaireSuccess,
  updateSalaireFail,
  deleteSalaireSuccess,
  deleteSalaireFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSalaires,
  getSalaire,
  addNewSalaire,
  updateSalaire,
  deleteSalaire,
} from "../../helpers/api_back"

function* fetchSalaires() {
  try {
    const response = yield call(getSalaires)
    yield put(getSalairesSuccess(response))
  } catch (error) {
    yield put(getSalairesFail(error))
  }
}

function* fetchSalaire() {
  try {
    const response = yield call(getSalaire)
    yield put(getSalaireSuccess(response))
  } catch (error) {
    yield put(getSalaireFail(error))
  }
}


function* onAddNewSalaire({ payload: salaire }) {
 
  try {
    const response = yield call(addNewSalaire, salaire)
    
    yield put(addSalaireSuccess(response))
  } catch (error) {
    yield put(addSalaireFail(error))
  }
}

function* onUpdateSalaire({ payload: salaire }) {
  try {
    const response = yield call(updateSalaire, salaire)
    yield put(updateSalaireSuccess(response))
  } catch (error) {
    yield put(updateSalaireFail(error))
  }
}

function* onDeleteSalaire({ payload: salaire }) {
  try {
    const response = yield call(deleteSalaire, salaire)
    yield put(deleteSalaireSuccess(response))
  } catch (error) {
    yield put(deleteSalaireFail(error))
  }
}
 

function* salairesSaga() {
  yield takeEvery(GET_SALAIRES, fetchSalaires)
  yield takeEvery(GET_SALAIRE, fetchSalaire)
  yield takeEvery(ADD_NEW_SALAIRE, onAddNewSalaire)
  yield takeEvery(UPDATE_SALAIRE, onUpdateSalaire)
  yield takeEvery(DELETE_SALAIRE, onDeleteSalaire) 
}

export default salairesSaga
