import React, { useState, useEffect } from 'react'; 

import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import Select  from 'react-select';
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';
import PDFGenerator from '../../utilitaires/PDFGenerator'; 


import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
 
// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //typeDemandes
    getTypeDemandes as onGetTypeDemandes,
    getModeRemboursements as onGetModeRemboursements,

    //demandes
    addNewDemande as onAddNewDemande,
    deleteDemande as onDeleteDemande,
    getDemandes as onGetDemandes,
    getDemande as onGetDemande,
    updateDemande as onUpdateDemande,
    getAgents as onGetAgents,
  } from "../../store/actions"; 

  
 
const Demandes = () => {

                    const d=new Date();
                    const today = new Date().toISOString().split('T')[0];
                    const dateDeb = new Date(d.setDate(d.getDate() -7)).toISOString().split('T')[0];


                    const [typMsg, setTypMsg] = useState('');
                    const [startDate, setStartDate] = useState(dateDeb);
                    const [endDate, setEndDate] = useState(today);

                    const [cat, setCat] = useState('');
                    const [modeRembs, setModeRembs] = useState('');
                    const [selectedTypeDmds, setSelectedTypeDmds] = useState([]);
                    const [selectedModeRembs, setSelectedModeRembs] = useState([]);
                    
                    const [dmds, setDmds] = useState('');
                    const [selectedDmds, setSelectedDmds] = useState([]);
                    const [selectedAgents, setSelectedAgents] = useState([]);

                    const dispatch = useDispatch();
                    //console.log("Document",Document)

                    //  const end = new Date(endDate );
                    //  end.setDate(end.getDate() + 1);  

                    
                    const demandes = useSelector((state) => ({  demandes: state.demandes.demandes  }));
                    const typeDemandes = useSelector((state) => ({  typeDemandes:  state.typeDemandes.typeDemandes  }));
                    const modeRemboursements = useSelector((state) => ({  modeRemboursements:  state.modeRemboursements.modeRemboursements  }));
                    const users = useSelector((state) => ({  users:  state.users.users  }));
                    const agents = useSelector((state) => ({  agents:  state.agents.agents  }));
        
            
                    const handleGoBack = () => {
                        // Fonction pour revenir à la page précédente
                        window.history.back();
                    };


                    /**option de filtre */
                   
                    const typeDmdOptions=Object.values(typeDemandes.typeDemandes).map(tyd => ({
                        value:tyd.id, label:tyd.libelle
                    }))
                    typeDmdOptions.unshift({ value: '', label: '' });
                   // console.log("typeDmdOptions",typeDmdOptions)


                    const modeRemboursOptions=Object.values(modeRemboursements.modeRemboursements).map(mod => ({
                        value:mod.id, label:mod.libelle,
                    }))

                    //console.log("modeRemboursOptions",modeRemboursOptions)


                    modeRemboursOptions.unshift({ value: '', label: '', }); 
                    
                    //console.log("selectedTypeDmds",selectedTypeDmds)

                  
                    
                    const agentsOptions=Object.values(agents.agents).map(ag => ({
                        value:ag.id, label:ag.prenom+' '+ag.nom
                    }))
                    agentsOptions.unshift({ value: '', label: '' });

                    /// lorsque l'utilisateur choisi une typeDemande
                    const handleChangeCat=(cat)=>{
                        setCat(cat.value) ;
                    }

                    const handleChangeSousCat=(cat)=>{
                    setModeRembs(cat.value) 
                    
                    }   

 
            ///lorsque l'utilisateur choisi une depense
            const handleChangeDmds =(dps)=>{
            
                setDmds(dps.value)
            }

            const resetFiltre=()=>{
                setCat('')
                setModeRembs('')
                setDmds('')
                setStartDate(dateDeb)
                setEndDate(today)
                //window.location.reload();
            }


                    function alert() {

                        setTimeout(() => {
                        setTypMsg('');
                        }, 12000);
                    
                    return (
                        <>
                        { typMsg==="add" ? (
                        <Alert variant="success">
                            Enregistrement effectué avec succès !
                        </Alert> 
                        ): typMsg==="upd" ? (
                            <Alert color="info">
                            Modification effectuée avec succès !
                            </Alert> 
                        ): typMsg==="del" ? (
                            <Alert color="danger">
                            Suppression effectuée avec succès !
                            </Alert> 
                        ):""}
                    </>
                        
                    );
                    }
 

        
            const [modal_list, setmodal_list] = useState(false);
            
            function tog_list() {
                setmodal_list(!modal_list);
            }

            const [modal_update, setmodal_update] = useState(false);
            
            function tog_update() {
                setmodal_update(!modal_update);
            }

            const [modal_delete, setmodal_delete] = useState(false);
            function tog_delete() {
                setmodal_delete(!modal_delete);
            }
    
   
    
            /// Enregistrements d'un depense
            const [objet, setObjet] = useState('');
            const [description, setDescription] = useState('');
            const [echeance, setEcheance] = useState('');
            const [montant, setMontant] = useState(0); 
            const [montantPaye, setMontantPaye] = useState(0); 
            const [montantVerse, setMontantVerse] = useState(0); 
            const [agent, setAgent] = useState(''); 
            
            const [dateDmd, setDateDmd] = useState(''); 
            const [statut, setStatut] = useState('');
            const [typeDemande, setTypeDemande] = useState(''); 
            const [modeRemboursement, setModeRemboursement] = useState(''); 
            
            console.log('montantVerse',parseFloat(montantVerse))
            console.log('montantPaye',parseFloat(montantVerse)+parseFloat(montantPaye||0))

            const [idDmd, setIdDmd] = useState(''); 

        const newDemande = {
            objet: objet,
            description:description,
            echeance:echeance,
            montant:montant||0,
            dateDmd:dateDmd,
            statut:1,
            typeDemande:typeDemande,
            modeRemboursement:modeRemboursement,
            montantVerse:montantVerse||0,
            montantPaye:montantPaye||0,
            agent:agent
        };  
        //console.log("newDemande",newDemande)

            const saveNewDemande = (e) => {
                e.preventDefault(); 
                dispatch(onAddNewDemande(newDemande));
                setTypMsg('add')
                setmodal_list(false);
                
            
            };    

            // Mise a jour d"un Demande 
            const upDemande = { 
                objet: objet,
                description:description,
                echeance:echeance,
                montant:montant||0,
                dateDmd:dateDmd, 
                typeDemande:typeDemande,
                modeRemboursement:modeRemboursement,
                montantVerse:montantVerse,
                montantPaye:  parseFloat(montantVerse||0)+parseFloat(montantPaye||0),
                agent:agent,
                statut:((parseFloat(montantPaye||0)+parseFloat(montantVerse||0))==parseFloat(montant||0))? 3 :((parseFloat(montantPaye||0)+parseFloat(montantVerse||0))>1 && (parseFloat(montantPaye||0)+parseFloat(montantVerse||0))<parseFloat(montant||0))? 2:1,
            };

           // console.log('upDemande',upDemande)
            

            const saveUpdateDemande = (e) => {
                e.preventDefault(); 
                dispatch(onUpdateDemande(idDmd,upDemande));
                setTypMsg('upd')
                setmodal_update(false);
                
            };


            /// Suppression d'un Demande
            

            const [listIdDep, setListIdDep] = useState([]);

            const idDmdChecBoxSelected = (depId) => {
                setListIdDep(prevSelectedRows => ({
                ...prevSelectedRows,
                [depId]: !prevSelectedRows[depId]
                }));
                
            };
            

            const filteredList = Object.entries(listIdDep)
            .filter(([key, value]) => value === true)
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

            
            const depIds = Object.keys(filteredList);
            
            const deleteDemande = ( depense) => { 
                
                if(depense)dispatch(onDeleteDemande(depense));
                setTypMsg('del')
                if(listIdDep && depIds){depIds.forEach((depId) =>dispatch(onDeleteDemande(depId)))}
                setTypMsg('del')

            };
                



 




            /// section pour configurer la pagination

 
            const [filteredData, setFilteredData] = useState(demandes.demandes);
           // console.log('demandes.demandes',demandes.demandes)

            useEffect(() => {
                    
                // Fonction de recherche
                const searchData = () => {
                const filtered =  Object.values(demandes.demandes).filter((item) => {
                    const itemDate = new Date(item.createdAt);
                    const start = new Date(startDate);
                    const end = new Date(endDate );
                    // Ajouter 1 jour à la date de fin
                    end.setDate(end.getDate() + 1);  
                   
            

                    return     selectedTypeDmds.length && selectedAgents.length && selectedModeRembs.length   ? itemDate >= start && itemDate <= end && selectedTypeDmds.includes(item.typeDemande) && selectedModeRembs.includes(item.modeRemboursement) && selectedAgents.includes(item.agent)
                            : selectedTypeDmds.length ? itemDate >= start && itemDate <= end && selectedTypeDmds.includes(item.typeDemande) 
                            : selectedModeRembs.length ? itemDate >= start && itemDate <= end && selectedModeRembs.includes(item.modeRemboursement) 
                            : selectedAgents.length  ? itemDate >= start && itemDate <= end && selectedAgents.includes(item.agent)
                            : itemDate >= start && itemDate <= end       

                            
                });
                //console.log('filtered',filtered)

                setFilteredData(filtered); 
                
                };

                // Appeler la fonction de recherche lorsque les dates changent
                searchData();
            }, [d,dateDeb,startDate, endDate,selectedTypeDmds,selectedAgents,typMsg,onDeleteDemande,onUpdateDemande,onAddNewDemande]);

           // [d,dateDeb,startDate, endDate,selectedTypeDmds,selectedDmds,typMsg,onDeleteDemande,onUpdateDemande,onAddNewDemande]


 //console.log('filteredData',filteredData)
                const dataTable= filteredData.length!==0 ? 
                filteredData.map(dmd => {
                    const typeDemande = Object.values(typeDemandes.typeDemandes).find(tyD => tyD.id === dmd.typeDemande);
                    const modeRemboursement = Object.values(modeRemboursements.modeRemboursements).find(mod => mod.id === dmd.modeRemboursement);
                    //console.log('xcate',typeDemande)
                    const user = Object.values(users.users).find(us => us.id === dmd.createdBy);
                    const agent = Object.values(agents.agents).find(ag => ag.id === dmd.agent);
                    return { ...dmd , user: user ? user.prenom+' '+user.nom :'',agentInfo: agent ? agent.prenom+' '+agent.nom :'',typeDemandeInfo:typeDemande?typeDemande.libelle:'' ,modeRemboursementInfo:modeRemboursement?modeRemboursement.libelle:''  };
                }) 
                :   Object.values(demandes.demandes).map(dmd => {
                    const typeDemande = Object.values(typeDemandes.typeDemandes).find(tyD => tyD.id === dmd.typeDemande);
                    const modeRemboursement = Object.values(modeRemboursements.modeRemboursements).find(mod => mod.id === dmd.modeRemboursement);
                    const agent = Object.values(agents.agents).find(ag => ag.id === dmd.agent);
                    const user = Object.values(users.users).find(us => us.id === dmd.createdBy);
                    return { ...dmd , user: user ? user.prenom+' '+user.nom :'',agentInfo: agent ? agent.prenom+' '+agent.nom :'',typeDemandeInfo:typeDemande?typeDemande.libelle:'' ,modeRemboursementInfo:modeRemboursement?modeRemboursement.libelle:''  };
                }) ;
                //,typeDemandedep:typeDemande.objet

                const [currentPage, setCurrentPage] = useState(0);
                const PER_PAGE = 10;
  

                const handleSearch = (dep) => {
                    const value = dep.target.value;
                    const filtered = _.filter(dataTable, (item) =>
                    _.startsWith(_.toLower(item.objet), _.toLower(value))||
                    _.startsWith(_.toLower(item.montant), _.toLower(value))||
                    _.startsWith(_.toLower(item.dateDmd), _.toLower(value))|| 
                    _.startsWith(_.toLower(item.id), _.toLower(value))||
                    _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
                    _.startsWith(_.toLower(item.createdBy), _.toLower(value))
                    );
                    setFilteredData(filtered);
                    setCurrentPage(0);
                };


            const handleSort = (dep, sortKey) => {
                const sorted = _.sortBy(dataTable, sortKey);
                setFilteredData(sorted);
                setCurrentPage(0);
            };


                     const offset = currentPage * PER_PAGE;
                    const pageCount = Math.ceil(dataTable.length / PER_PAGE);

 
            //generer le pdf
            const headText ='LISTE DES PRETS '
            const tableHead =[['N°','Date','Type','Remboursement', 'Objet', 'Montant','Montant Payé','Echéance']]
            const tableD=Object.values(dataTable).map((dmd)=>{ 
            //const agent = Object.values(agents.agents).find(ag => ag.id === vt.agent);
           // const depense = Object.values(demandes.demandes).find(dep=> dep.id === dmd.depense);
            const typeDemande = Object.values(typeDemandes.typeDemandes).find(tpD => tpD.id === dmd.typeDemande);
            const modeRemboursement = Object.values(modeRemboursements.modeRemboursements).find(mod => mod.id === dmd.modeRemboursement);
            return { ...dmd,typeDemande: typeDemande? typeDemande.libelle:'',modeRemboursement: modeRemboursement? modeRemboursement.libelle:'' };})

            const tableData= tableD.map((d, index) => [index+1, formatedDate(d.dateDmd).slice(0,10), d.typeDemande,d.modeRemboursement,d.objet,parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),parseFloat(d.montantPaye).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '),formatedDate(d.dateDmd).slice(0,10)]);



                 //interdiction des valeurs non numerique
            const handleKeyDown = (e) => {
            
                            const { key } = e;

                        if (!/\d/.test(key)  && key !== 'Backspace' ){
                        
                            if(key !== '.' ) {

                                if(key !== 'Tab') {
                                if(key !== 'ArrowRight'){
                                    if(key !== 'ArrowLeft') e.preventDefault() ;
                                }
                                }   
                            } 

                        }
            };


        const tableFoot=[['','','','TOTAL',parseFloat(tableD.reduce((acc, dp) => acc + dp.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ]]





                const currentPageData =  dataTable.length>0? dataTable.slice(offset, offset + PER_PAGE).map((dmd,index) =>  (
                    <tr key={index}   style={index % 2 === 0 ? { backgroundColor: "#E6F1FF",border: "2px solid #3282F6",color:'black',fontSize:'20px'} :{ backgroundColor: "#EBFFED" ,border: "2px solid #3282F6",color:'black',fontSize:'20px'}}>
                    <th scope="row" style={{ border: "2px solid #3282F6" }} >
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idDmdChecBoxSelected(dmd.id)}}   />
                        </div>
                    </th>
                    <td className="dep_id" style={{ display: "none" ,border: "2px solid #3282F6"}}>{dmd.id}</td>
                    <td   className="dep_index">{index+1}</td>
                    
                    <td  style={{ border: "2px solid #3282F6" }}  className="dmd_name">{dmd.typeDemandeInfo}</td>
                    <td  style={{ border: "2px solid #3282F6" }}  className="dmd_name">{dmd.agentInfo}</td>
                    <td  style={{ border: "2px solid #3282F6" }}  className="dmd_name">{dmd.modeRemboursementInfo}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dmd_name">{dmd.objet}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dmd_name">{dmd.description}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dmd_name">{parseFloat(dmd.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dmd_name">{parseFloat(dmd.montantPaye).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
            
                    <td style={{ border: "2px solid #3282F6" }}   className="dmd_name">{formatedDate(dmd.dateDmd).slice(0,10)}</td> 
                    <td style={{ border: "2px solid #3282F6" }}   className="dmd_name">{formatedDate(dmd.echeance).slice(0,10)}</td> 
                    <td style={{ border: "2px solid #3282F6",color:dmd.statut==2? '#FAC50E':dmd.statut==3? "#47A35C": "#0018A8" }}  className="dmd_name">{dmd.statut==2? 'Encours':dmd.statut==3? "Terminé": "Nouveau"}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dmd_date">{ dmd.user}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dmd_utilisateur">{formatedDate(dmd.createdAt)}</td>
                    <td style={{ border: "2px solid #3282F6" }}  >
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <button className="btn btn-sm btn-success edit-item-btn"
                                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setTypeDemande(dmd.typeDemande);setAgent(dmd.agent);setModeRemboursement(dmd.modeRemboursement);setObjet(dmd.objet);setDescription(dmd.description);setMontant(dmd.montant);setMontantPaye(dmd.montantPaye);setDateDmd(new Date(dmd.dateDmd).toISOString().split('T')[0]);setEcheance(new Date(dmd.echeance).toISOString().split('T')[0]);setIdDmd(dmd.id);tog_update()} }>Modifier</button>
                            </div>
                            <div className="remove">
                                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteDemande(dmd.id)}>Suppr.</button>
                            </div>
                        </div>
                    </td>
                </tr>


                )) :'';

            useEffect(() => { 
        
                    dispatch(onGetTypeDemandes());
                    dispatch(onGetModeRemboursements());
                    dispatch(onGetUsers());
                    dispatch(onGetDemandes());
                    dispatch(onGetAgents());
                    setFilteredData(demandes.demandes);
                    
                     
                },[alert]); //onAddNewDemande,onDeleteDemande,onUpdateDemande,Demandes

   


            return (
                <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                        <FiArrowLeft /> Retour
                    </Button>
                        <Breadcrumbs title="Demande" breadcrumbItem="Les opérations de prets" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    {/* <CardHeader>
                                        <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                    
                                    </CardHeader> */}

                                    <CardBody>
                                        <div id="customerList">
                                            <Row className="g-4 mb-3">
                                                <Col className="col-sm-auto">
                                                    <div className="d-flex gap-1">
                                                        <Button color="success" className="add-btn" onClick={() => {tog_list();setMontant('')}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                        <Button color="soft-danger"  onClick={() => {deleteDemande('');setListIdDep('')}}            
                                                        // onClick="deleteMultiple()"
                                                        ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                        <span></span> 
                                                        <PDFGenerator tableData={tableData} tableHead={tableHead} headText={headText} tableFoot={tableFoot} />
                                                        

                                                    </div> 
                                                
                                                </Col>
                                                
                                                        
                                                <Col>  {alert()}</Col>
                                                <Col className="col-sm">
                                                    {/* <div className="d-flex justify-content-sm-end">
                                                        <div className="search-box ms-2">
                                                            <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                            <i className="mdi mdi-magnify search-icon"></i>
                                                        </div>
                                                    </div> */}
                                <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", }}>LES OPERATIONS DE PRETS</span>

                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col>
                                                <label htmlFor="catSelect">Sélectionner une typeDemande</label>
                                                {/* <Select defaultValue='' options={typeDmdOptions} onChange={handleChangeCat} /> */}
                                                <MultiSelect value={selectedTypeDmds} onChange={(e) => {setSelectedTypeDmds(e.value)}} defaultValue='' options={typeDmdOptions} optionLabel="label" 
                                                    filter placeholder="Choisir un type demande" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                                </Col>
                                                <Col>
                                                <label htmlFor="catSelect">Sélectionner un mode remboursement</label>
                                                {/* <Select defaultValue='' options={typeDmdOptions} onChange={handleChangeCat} /> */}
                                                <MultiSelect value={selectedModeRembs} onChange={(e) => {setSelectedModeRembs(e.value)}} defaultValue='' options={modeRemboursOptions} optionLabel="label" 
                                                    filter placeholder="Choisir mode remboursement" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                                </Col>
                                                <Col>
                                                <label htmlFor="depSelect">Sélectionner un agent</label>
                                                {/* <Select defaultValue='' options={ dmdsOptions}    onChange={handleChangeDmds}/> */}
                                                <MultiSelect value={selectedAgents} onChange={(e) => {setSelectedAgents(e.value)}} defaultValue='' options={agentsOptions} optionLabel="label" 
                                                    filter placeholder="Choisir un agent" maxSelectedLabels={10} className="w-full md:w-20rem" />

                                                </Col>
    
                                                <Col>
                                                <label htmlFor="startDate">Du</label> 
                                            
                                                <Input   type="date" value={startDate}  onChange={(e) => setStartDate(e.target.value)}  />
                                            
                                                </Col>
                                                    
                                                <Col> 
                                                <label htmlFor="endate">Au</label> 
                                            
                                                <Input  type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                                
                                                </Col> 
                                                <Col> 
                                                <label htmlFor="reset">Réinitialiser</label> 
                                                <div><Button color="primary" onClick={resetFiltre}>Annuler Fitre</Button> </div>  
                                                                            
                                                
                                                
                                                </Col>   
                                            </Row>

                                            <div className="table-responsive table-card mt-3 mb-1">
                                                <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                    <thead className="table-light">
                                                        <tr style={{ border: "2px solid #3282F6",color:"#3580BB" ,fontSize: "20px" }}>
                                                            <th scope="col" style={{border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4' , width: "50px" }}>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                                </div>
                                                            </th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4' }} className="sort" data-sort="customer_name" onClick={(dep) => handleSort(dep, 'id')}>N°</th>                                                            
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="typeDemande" onClick={(dep) => handleSort(dep, 'typeDemande')}>Type Demande</th>
                                                                                                       
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="Emprunteur" onClick={(dep) => handleSort(dep, 'Emprunteur')}>Emprunteur</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="modeRemboursement" onClick={(dep) => handleSort(dep, 'modeRemboursement')}>Remboursement</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="objet" onClick={(dep) => handleSort(dep, 'objet')}>Objet</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="objet" onClick={(dep) => handleSort(dep, 'objet')}>Description</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="motant" onClick={(dep) => handleSort(dep, 'montant')}>Montant</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="motantPaye" onClick={(dep) => handleSort(dep, 'montantPaye')}>Montant Payé</th>
                                                            
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="date" onClick={(dep) => handleSort(dep, 'createdAt')}>Date pret</th>
                                                            
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="Echance" onClick={(dep) => handleSort(dep, 'Echance')}>Echéance</th>
                                                            
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="statut" onClick={(statut) => handleSort(statut, 'statut')}>Statut</th>
                                                
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="createdBy" onClick={(dep) => handleSort(dep, 'createdBy')}>Crée par</th>
                                                            
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="date" onClick={(dep) => handleSort(dep, 'createdAt')}>Date</th>
                                                        
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}}   className="sort" data-sort="action">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {currentPageData}
                                                    
                                                        
                                                    </tbody>
                                                </Table>
                                                <ReactPaginate
                                                    previousLabel={'Préc.'}
                                                    nextLabel={'Suiv.'}
                                                    breakLabel={'...'}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={(data) => setCurrentPage(data.selected)}
                                                    containerClassName={'pagination'}
                                                    activeClassName={'active'}
                                                    />
                                                <div style={{textAlign: 'left',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>PRETS: <span style={{color: '#377E47'}}>{filteredData.length}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(filteredData.reduce((acc, dp) => acc + dp.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span></h1></div>
                                                <div className="noresult" style={{ display: "none" }}>
                                                    <div className="text-center">
                                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                        </lord-icon>
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                            orders for you search.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                {/* <div className="pagination-wrap hstack gap-2">
                                                    <Link className="page-item pagination-prev disabled" to="#">
                                                        Previous
                                                    </Link>
                                                    <ul className="pagination listjs-pagination mb-0"></ul>
                                                    <Link className="page-item pagination-next" to="#">
                                                        Next
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        
                    </Container>
                </div>

                {/* Add Modal */}
                <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Demande </ModalHeader>
                    <form className="tablelist-form" onSubmit={saveNewDemande}>
                        <ModalBody> 

                            <div className="mb-3">
                            <label htmlFor="dateDmd" className="form-label">Agent demandeur</label>
                            <InputGroup>
                                    <Input type="select" onChange={(e) => setAgent(e.target.value)}  defaultValue="0">
                                    <option value="">Sélectionnez un agent</option> {/* Option vide par défaut */}
                                    {Object.values(agents.agents).map((ag) => (
                                        <option key={ag.id} value={ag.id}>
                                        {ag.prenom+" "+ag.nom}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>

                             <label htmlFor="dateDmd" className="form-label">Date demande</label>
                                <input type="date"  id="dateDmd" className="form-control" placeholder="Enter Date" required  onChange={(e) => setDateDmd(e.target.value)}/>
                               
                                <label htmlFor="dateDmd" className="form-label">Echéance</label>
                                <input type="date"  id="echeance" className="form-control" placeholder="Précisser l'écheance" required  onChange={(e) => setEcheance(e.target.value)}/>
                               
                                <label htmlFor="typeDemande" className="form-label">Type demande</label>
                            
                                <InputGroup>
                                    <Input type="select" onChange={(e) => setTypeDemande(e.target.value)}  defaultValue="0">
                                    <option value="">Sélectionnez un type</option> {/* Option vide par défaut */}
                                    {Object.values(typeDemandes.typeDemandes).map((typDmd) => (
                                        <option key={typDmd.id} value={typDmd.id}>
                                        {typDmd.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>
                                <label htmlFor="typeDemande" className="form-label">Remboursement</label>
                            
                                <InputGroup>
                                    <Input type="select" onChange={(e) => setModeRemboursement(e.target.value)}  defaultValue="0">
                                    <option value="">Sélectionnez un mode remboursement</option> {/* Option vide par défaut */}
                                    {Object.values(modeRemboursements.modeRemboursements) 
                                    .map((mod) => (
                                        <option key={mod.id} value={mod.id}>
                                        {mod.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>

                                <label htmlFor="objet" className="form-label">Objet</label>
                                <input type="text" id="objet" className="form-control" placeholder="Enter l'obejt" required  onChange={(e) => setObjet(e.target.value)}/>
                                <label htmlFor="objet" className="form-label">Description</label>
                                <input type="text" id="description" className="form-control" placeholder="Enter la description" required  onChange={(e) => setDescription(e.target.value)}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                                <input type="decimal"  id="montant" value={ montant  }  onKeyDown={handleKeyDown} className="form-control" placeholder="Enter montant" required  onChange={(e) => setMontant((e.target.value).replace(/ /g, '').replace(/,/g, ''))}/>
                
                            </div>  
                        
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                                <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewDemande}>Enregistrer</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>

                {/* Update Modal */}
                <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Demande </ModalHeader>
                    <form className="tablelist-form" onSubmit={saveUpdateDemande}>
                        <ModalBody> 

                            <div className="mb-3"> 
  {/* DEBUT */}
          <label htmlFor="dateDmd" className="form-label">Demandeur</label>
                            <InputGroup>
                                    <Input type="select" value={agent} onChange={(e) => setAgent(e.target.value)}  >
                                    <option value="">Sélectionnez un agent</option> {/* Option vide par défaut */}
                                    {Object.values(agents.agents).map((ag) => (
                                        <option key={ag.id} value={ag.id}>
                                        {ag.prenom+" "+ag.nom}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>

                             <label htmlFor="dateDmd" className="form-label">Date demande</label>
                                <input type="date"  id="dateDmd" className="form-control" placeholder="Enter Date"  value={dateDmd}  onChange={(e) => setDateDmd(e.target.value)}/>
                               
                                <label htmlFor="dateDmd" className="form-label">Echéance</label>
                                <input type="date"  id="echeance" className="form-control" placeholder="Précisser l'écheance" value={echeance}  onChange={(e) => setEcheance(e.target.value)}/>
                               
                                <label htmlFor="typeDemande" className="form-label">Type demande</label>
                            
                                <InputGroup>
                                    <Input type="select" value={typeDemande} onChange={(e) => setTypeDemande(e.target.value)}  >
                                    <option value="" >Sélectionnez un type</option> {/* Option vide par défaut */}
                                    {Object.values(typeDemandes.typeDemandes).map((typDmd) => (
                                        <option key={typDmd.id} value={typDmd.id}>
                                        {typDmd.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>
                                <label htmlFor="remboursement" className="form-label">Remboursement</label>
                            
                                <InputGroup>
                                    <Input type="select" value={modeRemboursement} onChange={(e) => setModeRemboursement(e.target.value)} >
                                    <option value="">Sélectionnez un mode remboursement</option> {/* Option vide par défaut */}
                                    {Object.values(modeRemboursements.modeRemboursements) 
                                    .map((mod) => (
                                        <option key={mod.id} value={mod.id}>
                                        {mod.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>

                                <label htmlFor="objet" className="form-label">Objet</label>
                                <input type="text" id="objet" className="form-control" placeholder="Enter l'obejt" value={objet}  onChange={(e) => setObjet(e.target.value)}/>
                                <label htmlFor="objet" className="form-label">Description</label>
                                <input type="text" id="description" className="form-control" placeholder="Enter la description" value={description}  onChange={(e) => setDescription(e.target.value)}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                                <input type="decimal" disabled  style={{backgroundColor:'#871D15',color:"#FFFFFF"}}   id="montant" value={ montant  }  onKeyDown={handleKeyDown} className="form-control" placeholder="montant" required  onChange={(e) => setMontant((e.target.value).replace(/ /g, '').replace(/,/g, ''))}/>
                        
                         <label htmlFor="montantPaye" className="form-label">Montant Payé</label>
                                <input type="decimal" disabled style={{backgroundColor:'#173F3F',color:"#FFFFFF"}} id="montantPaye" value={ montantPaye  }  onKeyDown={handleKeyDown} className="form-control" placeholder="montant"   onChange={(e) => setMontantPaye((e.target.value).replace(/ /g, '').replace(/,/g, ''))}/>
                
                         <label htmlFor="montantVerse" className="form-label">Versément en cours</label>
                                <input type="decimal"  style={{backgroundColor:'#5C5B1F',color:"#FFFFFF"}} id="montantVerse"   onKeyDown={handleKeyDown} className="form-control" placeholder="montantVerse"   onChange={(e) => setMontantVerse((e.target.value).replace(/ /g, '').replace(/,/g, ''))}/>
                

  {/* fin */} 
                            </div>  
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                                <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateDemande}>Mettre à jour</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>

                {/* Remove Modal */}
                <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                    <div className="modal-header">
                        <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                    </div>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you Sure ?</h4>
                                <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                            <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>

            );
};

export default Demandes;
