import React from "react";
import StockCercleChart from "./StockCercleChart";

import { Card, CardBody, Col, Row } from "reactstrap";

import { SocialSourceData } from "../../CommonData/Data/index";

const StockCercle = () => {
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card  style={{ width: 290, height: 124, backgroundColor:"#153F78"}}>
          <CardBody>
            {/* <div className="d-flex  align-items-center"  >
              <div className="flex-grow-1">
                <h5 className="card-title">Stock</h5>
              </div>
              <div className="flex-shrink-0">
                <select className="form-select form-select-sm mb-0 my-n1">
                  {[
                    "May",
                    "April",
                    "March",
                    "February",
                    "January",
                    "December",
                  ].map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}
            {/* RadialChart */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '98%' }}>
            <StockCercleChart />
                
        {/*        <div >
                <span className={"avatar-title square font-size-18 bg-primary"}
                    style={{ width: 50, height:15 , }}   >
                    <span className={'Gas-oil'+"text-white"} style={{ fontSize:12 , }} >Essence</span>
                  </span>

                  <span className={"avatar-title square font-size-18 bg-info"}
                    style={{ width: 50, height:15 , }}   >
                    <span className={'Gas-oil'+"text-white"} style={{ fontSize:12 , }} >Gas-oil</span>
                  </span>

                  <span className={"avatar-title square font-size-18 bg-warning"}
                    style={{ width: 50, height:15 , }}   >
                    <span className={'Gas-oil'+"text-white"} style={{ fontSize:12 , }} >Moto</span>
                  </span>
                  </div> */}

            </div>  
            {/* <Row>
              {SocialSourceData.map((item, key) => (
                <div key={key} className="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span
                        className={
                          "avatar-title rounded-circle font-size-18 bg-" +
                          item.bgcolor
                        }
                      >
                        <i className={item.icon + " text-white"}></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">{item.title}</h5>
                    <p className="text-muted mb-0">{item.count} sales</p>
                  </div>
                </div>
              ))}
            </Row> */}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default StockCercle;
