import { takeEvery, put, call } from "redux-saga/effects"

// PRODUITs Redux States
import {
  ADD_NEW_MOUVEMENTSTOCK,
  DELETE_MOUVEMENTSTOCK,
  GET_MOUVEMENTSTOCKS,
  GET_MOUVEMENTSTOCK,
  UPDATE_MOUVEMENTSTOCK,
} from "./actionTypes"
import {
  getMouvementStocksSuccess,
  getMouvementStocksFail,
  getMouvementStockSuccess,
  getMouvementStockFail,
  addMouvementStockFail,
  addMouvementStockSuccess,
  updateMouvementStockSuccess,
  updateMouvementStockFail,
  deleteMouvementStockSuccess,
  deleteMouvementStockFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMouvementStocks,
  getMouvementStock,
  addNewMouvementStock,
  updateMouvementStock,
  deleteMouvementStock,
} from "../../helpers/api_back"
 
function* fetchMouvementStocks() {
  try {
    const response = yield call(getMouvementStocks) 
    yield put(getMouvementStocksSuccess(response))
  } catch (error) {
    yield put(getMouvementStocksFail(error))
  }

}

function* fetchMouvementStock() {
  try {
    const response = yield call(getMouvementStock)
    yield put(getMouvementStockSuccess(response))
  } catch (error) {
    yield put(getMouvementStockFail(error))
  }
}


function* onAddNewMouvementStock({ payload: mouvementStock }) {
 
  try {
    const response = yield call(addNewMouvementStock, mouvementStock)
    
    yield put(addMouvementStockSuccess(response))
  } catch (error) {
    yield put(addMouvementStockFail(error))
  }
}

function* onUpdateMouvementStock({ payload: mouvementStock }) {
  try {
    const response = yield call(updateMouvementStock, mouvementStock)
    yield put(updateMouvementStockSuccess(response))
  } catch (error) {
    yield put(updateMouvementStockFail(error))
  }
}

function* onDeleteMouvementStock({ payload: mouvementStock }) {
  try {
    const response = yield call(deleteMouvementStock, mouvementStock)
    yield put(deleteMouvementStockSuccess(response))
  } catch (error) {
    yield put(deleteMouvementStockFail(error))
  }
}
 

function* mouvementStocksSaga() {
  yield takeEvery(GET_MOUVEMENTSTOCKS, fetchMouvementStocks)
  yield takeEvery(GET_MOUVEMENTSTOCK, fetchMouvementStock)
  yield takeEvery(ADD_NEW_MOUVEMENTSTOCK, onAddNewMouvementStock)
  yield takeEvery(UPDATE_MOUVEMENTSTOCK, onUpdateMouvementStock)
  yield takeEvery(DELETE_MOUVEMENTSTOCK, onDeleteMouvementStock) 
}

export default mouvementStocksSaga
