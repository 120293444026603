import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup,FormGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';
import { generateCode } from '../../utilitaires/generateCode';
import {getAlphabetLetter} from '../../utilitaires/ascii';

// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,
    
    //Equipes
    getEquipes as onGetEquipes,    

    //Agents
    addNewAgent as onAddNewAgent,
    deleteAgent as onDeleteAgent,
    getAgents as onGetAgents,
    getAgent as onGetAgent,
    updateAgent as onUpdateAgent,
  } from "../../store/actions"; 

  
 
const Agents = () => {
const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document) 

const agents = useSelector((state) => ({  agents:  state.agents.agents  }));
const equipes = useSelector((state) => ({  equipes:  state.equipes.equipes  }));
const users = useSelector((state) => ({  users:  state.users.users  }));


 
 
const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };



function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 1000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_show, setmodal_show] = useState(false);
    
    function tog_show() {
        setmodal_show(!modal_show);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
 

    
/// Enregistrements d'une Agent
const [nom, setNom] = useState('KANOU');
const [prenom, setPrenom] = useState('FASO');
const [sexe, setSexe] = useState('');
const [telephone, setTelephone] = useState('');
const [adresse, setAdresse] = useState('');
///const [state, setState] = useState(false);
const [typeAgent, setTypeAgent] = useState('');
const [equipe,setEquipe]=useState('');
const [actif,setActif]=useState(true);
const [dateIntegration,setDateIntegration]=useState('');

const [matricule,setMatricule]=useState('');
const [salaireBase,setSalaireBase]=useState('');
const [numCompte,setNumCompte]=useState('');
const [dateNaissance,setDateNaissance]=useState('');
const [lieuNaissance,setLieuNaissance]=useState('');
const [identiteNationale,setIdentiteNationale]=useState('');
const [nationalite,setNationalite]=useState('');
 


 
const [idAgent, setIdAgent] = useState('');

const agentsList= agents.agents
 console.log('agentsList',agentsList)
  //Extraire les parties numériques des codes et trouver la valeur maximale
/*  const maxCodeNumber = agentsList && agentsList[0] ?agentsList
 .filter(agent => /^\d+$/.test(parseInt(agent.matricule.slice(4), 10)))
 .map(agent => parseInt(agent.matricule.slice(4), 10))  // Convertir la partie numérique en nombre
 .reduce((max, current) => Math.max(max, current), 0):0;  // Trouver la valeur maximale */
 const maxCodeNumber = agentsList && agentsList.length > 0 
 ? agentsList
     .map(agent => agent.matricule.slice(4)) // Extraire la partie après les 4 premiers caractères
     .filter(code => /^\d+$/.test(code)) // Vérifier si c'est un nombre entier positif
     .map(code => parseInt(code, 10)) // Convertir en entier
     .reduce((max, current) => Math.max(max, current), 0) // Trouver le max
 : 0;
 
   

//on recuprer les deux premier caractere de l'anne encoure

const currentYear = new Date().getFullYear();
const lastTwoDigits = currentYear.toString().slice(-2);

 const firstname=prenom? prenom[0].toLocaleUpperCase() :'FASO'[0];
 const lastName=nom? nom[0].toLocaleUpperCase():'KANOU'[0]

const codeGen =generateCode(maxCodeNumber+1,lastTwoDigits+firstname+lastName,3)+getAlphabetLetter(maxCodeNumber+1)
console.log('codeGen',codeGen)


const newAgent = {
    nom: nom,
    prenom:prenom,
    sexe:sexe,
    telephone: telephone,
    adresse:adresse,  
    typeAgent: typeAgent,
    equipe:equipe,
    matricule:matricule,
    dateIntegration:dateIntegration,
    salaireBase:salaireBase,
    numCompte:numCompte,
    dateNaissance:dateNaissance,
    lieuNaissance:lieuNaissance,
    identiteNationale:identiteNationale,
    nationalite:nationalite,
    actif:actif,
};

const saveNewAgent = (e) => {
    e.preventDefault(); 
    dispatch(onAddNewAgent(newAgent));
    setTypMsg('add')
    setmodal_list(false);
    
 
  };

  // Mise a jour d"une Agent 
  const upAgent = { 
    nom: nom,
    prenom:prenom,
    sexe:sexe,
    telephone: telephone,
    adresse:adresse, 
    matricule:matricule,
    dateIntegration:dateIntegration,
    salaireBase:salaireBase,
    numCompte:numCompte,
    dateNaissance:dateNaissance,
    lieuNaissance:lieuNaissance,
    identiteNationale:identiteNationale,
    nationalite:nationalite, 
    typeAgent: typeAgent,

    equipe:equipe,
    actif:actif,

  };

  const saveUpdateAgent= (e) => {
    e.preventDefault();  
    dispatch(onUpdateAgent(idAgent,upAgent));
    setTypMsg('upd')
    setmodal_update(false);
    
  };


  /// Suppression d'une Agent
 

  const [listIdAgent, setListIdAgent] = useState([]);

  const idAgentChecBoxSelected = (agentId) => {
    setListIdAgent(prevSelectedRows => ({
      ...prevSelectedRows,
      [agentId]: !prevSelectedRows[agentId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdAgent)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const agentIds = Object.keys(filteredList);
 
  const deleteAgent = ( agent) => { 
    if(agent)dispatch(onDeleteAgent(agent));
    setTypMsg('del')
    if(listIdAgent && agentIds){agentIds.forEach((agentId) =>dispatch(onDeleteAgent(agentId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(agents.agents);


const dataTable= filteredData.length!==0 ? 
filteredData.map(agent => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === agent.categorie);
    //console.log('xcate',categorie)
    const user = Object.values(users.users).find(us => us.id === agent.createdBy);
    const equipe = Object.values(equipes.equipes).find(eq => eq.id === agent.equipe);
    return { ...agent , user: user? user.prenom+' '+user.nom :'',equipeInfo:equipe? equipe.libelle:'' };
  }) 
  : 
  agents.agents.map(agent => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === agent.categorie);
    const user = Object.values(users.users).find(us => us.id === agent.createdBy);
    const equipe = Object.values(equipes.equipes).find(eq => eq.id === agent.equipe);
    return { ...agent,user: user? user.prenom+' '+user.nom :'',equipeInfo:equipe? equipe.libelle:''  };
  });
//,categorieagent:categorie.libelle

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (agent) => {
    const value = agent.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.nom), _.toLower(value))||
      _.startsWith(_.toLower(item.prenom), _.toLower(value))|| 
      _.startsWith(_.toLower(item.sexe), _.toLower(value))|| 
      _.startsWith(_.toLower(item.telephone), _.toLower(value))||
      _.startsWith(_.toLower(item.adresse), _.toLower(value))|| 
      _.startsWith(_.toLower(item.typeAgent), _.toLower(value))||
      _.startsWith(_.toLower(item.salaireBase), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (agent, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


 
const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((agent,index) =>  (
    <tr key={agent.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idAgentChecBoxSelected(agent.id)}}   />
        </div>
    </th>
    <td className="agent_id" style={{ display: "none" }}>{agent.id}</td>
    <td className="agent_index">{index+1}</td>
    <td className="matricule">{agent.matricule}</td> 
    <td className="libelle">{agent.nom}</td> 
    <td className="libelle">{agent.prenom}</td> 
    <td className="sexe">{agent.sexe}</td> 
    <td className="libelle">{agent.telephone}</td> 
    <td className="libelle">{agent.adresse}</td> 
    <td className="libelle">{agent.typeAgent}</td> 
    <td className="libelle">{parseFloat(agent.salaireBase).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td> 
    <td className="prod_name">{agent.equipeInfo}</td>
    <td className="agent_actif">{agent.actif==1? '✅' :'❌' } </td>
   
    {/* <td className="agentuit">{agent.agentuit ? Object.values(agentuits.agentuits).find(agent=>agent.id==agent.agentuit).nomagent:''}</td> */}
    <td className="agent_date">{ agent.user}</td>
    <td className="agent_utilisateur">{formatedDate(agent.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setNom(agent.nom);setPrenom(agent.prenom);setSexe(agent.sexe);setTelephone(agent.telephone);setAdresse(agent.adresse);setMatricule(agent.matricule);
                        setDateIntegration(agent.dateIntegration);setSalaireBase(agent.salaireBase);setNationalite(agent.nationalite);setIdentiteNationale(agent.identiteNationale);
                        setDateNaissance(agent.dateNaissance);setLieuNaissance(agent.lieuNaissance);setNumCompte(agent.numCompte);
                    setTypeAgent(agent.typeAgent);setEquipe(agent.equipe);setActif(agent.actif);setIdAgent(agent.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteAgent(agent.id)}>Suppr.</button>
            </div>
            <div className="show">
                <button className="btn btn-sm btn-info remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> {setIdAgent(agent.id);setNom(agent.nom);setPrenom(agent.prenom);setSexe(agent.sexe);setTelephone(agent.telephone);setAdresse(agent.adresse);setMatricule(agent.matricule);
                        setDateIntegration(agent.dateIntegration);setSexe(agent.sexe);setSalaireBase(agent.salaireBase);setNumCompte(agent.numCompte);setNationalite(agent.nationalite);setIdentiteNationale(agent.identiteNationale);
                        setDateNaissance(agent.dateNaissance);setLieuNaissance(agent.lieuNaissance);tog_show()}}>Voir Plus</button>
            </div>
        </div>
    </td>
</tr>


));

   useEffect(() => { 
        
        dispatch(onGetAgents());
        dispatch(onGetUsers());
        dispatch(onGetAgents());
        dispatch(onGetEquipes());
        setFilteredData(agents.agents)
    },[dispatch,typMsg,onAddNewAgent,onUpdateAgent,onDeleteAgent,equipe,nom,prenom,sexe,typeAgent,adresse,telephone]); 

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="agentuit" breadcrumbItem="Liste des agents" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i>  AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deleteAgent('');setListIdAgent('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="agent_id" onClick={(agent) => handleSort(agent, 'id')}>N°</th>
                                                        <th className="sort" data-sort="matricule" onClick={(agent) => handleSort(agent, 'matricule')}>Matricule</th>
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'nom')}>Nom</th>
                                                      
                                                        <th className="sort" data-sort="agentuit" onClick={(agent) => handleSort(agent, 'prenom')}>Prénom</th> 
                                                        
                                                        <th className="sort" data-sort="agentuit" onClick={(agent) => handleSort(agent, 'sexe')}>Sexe</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'telephone')}>Téléphone</th>
                                                        <th className="sort" data-sort="agentuit" onClick={(agent) => handleSort(agent, 'adresse')}>Adresse</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'typeAgent')}>Type Agent</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'salaireBase')}>Salaire de base</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'equipe')}>Equipe</th> 
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'actif')}>Actif</th> 
                                                        <th className="sort" data-sort="createdBy" onClick={(agent) => handleSort(agent, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(agent) => handleSort(agent, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered  >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Agent </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewAgent}>
                    <ModalBody> 

                        <div className="mb-3">
                         <label htmlFor="prenom" className="form-label">Prénom</label>
                            <input type="text" id="prenom" className="form-control" placeholder="Entrer Prenom" required  onChange={(e) => {setPrenom(e.target.value);setMatricule(codeGen)}}/>
                            <label htmlFor="nom" className="form-label">Nom</label> 
                            <input type="text" id="nom" className="form-control" placeholder="Entrer Nom" required  onChange={(e) => {setNom(e.target.value);setMatricule(codeGen)}}/>
                           
                            
                            <label htmlFor="sexe" className="form-label">Sexe</label>
                            <InputGroup>
                                <Input type="select" placeholder="Selectionnez sexe"  onChange={(e) => {setSexe(e.target.value);setMatricule(codeGen)}}  >
                                <option value="">Sélectionnez un genre</option> {/* Option vide par défaut */}
                                <option value="M">MASCULIN</option>
                                <option value="F">FEMININ</option> 
                                </Input>
                            </InputGroup>

                            <label htmlFor="matricule" className="form-label">Matricule</label>
                           <input type="text" id="matricule" className="form-control" value={matricule} placeholder="Entrer matricule"   onChange={(e) => setMatricule(e.target.value)}/>
                            


                            <label htmlFor="telephone" className="form-label">Téléphone</label>
                            <input type="text" id="telephone" className="form-control" placeholder="Entrer Telephone" required  onChange={(e) => setTelephone(e.target.value)}/>
                            <label htmlFor="adresse" className="form-label">Adresse</label>
                            <input type="text" id="adresse" className="form-control" placeholder="Entrer Adresse"   onChange={(e) => setAdresse(e.target.value)}/>

                            <label htmlFor="dateIntegration" className="form-label">Date Integration</label>
                            <input type="date" id="dateIntegration" className="form-control" placeholder="Date d'integration"   onChange={(e) => setDateIntegration(e.target.value)}/>
                            <label htmlFor="salaireBase" className="form-label">salaire de Base</label>
                            <input type="text" id="salaireBase" className="form-control"  placeholder="Salaire de base"   onChange={(e) => setSalaireBase(e.target.value)}/>
                            <label htmlFor="numCompte" className="form-label">N° compte</label>
                            <input type="text" id="numCompte" className="form-control"  placeholder="N° compte"   onChange={(e) => setNumCompte(e.target.value)}/>

                            <label htmlFor="dateNaissance" className="form-label">Date Naissance</label>
                            <input type="date" id="dateNaissance" className="form-control" placeholder="Date Naissance"   onChange={(e) => setDateNaissance(e.target.value)}/>

                            <label htmlFor="lieuNaissance" className="form-label">Lieu Naissance</label>
                            <input type="text" id="lieuNaissance" className="form-control"  placeholder="Lieu Naissance"   onChange={(e) => setLieuNaissance(e.target.value)} />

                            <label htmlFor="identiteNationale" className="form-label">N° Identité Nationale</label>
                            <input type="text" id="identiteNationale" className="form-control"   placeholder="N° de piece d identité"   onChange={(e) => setIdentiteNationale(e.target.value)}/>

                            <label htmlFor="nationalite" className="form-label">Nationalité</label>
                            <input type="text" id="nationalite" className="form-control"    placeholder="Nationalité"   onChange={(e) => setNationalite(e.target.value)}/>


                            <label htmlFor="typeAgent" className="form-label">Type Agent</label>
                            <InputGroup>
                                <Input type="select" placeholder="Selectionnez un type"  onChange={(e) => setTypeAgent(e.target.value)}  >
                                <option value="">Sélectionnez un role</option> {/* Option vide par défaut */}
                                <option value="POMPISTE">POMPISTE</option>
                                <option value="GERANT">GERANT</option>
                                <option value="SUPERVISEUR">SUPERVISEUR</option>
                                <option value="COMPTABLE">COMPTABLE</option>
                                <option value="CHEF DE PISTE">CHEF DE PISTE</option>
                                <option value="AUTRE">AUTRE</option>
                                </Input>
                            </InputGroup>
                            <label htmlFor="equipe" className="form-label">Actif ?</label>
                            <FormGroup switch> 
                               <Input 
                                type="switch"
                                style={{ width: '30px' }} 
                                // defaultChecked
                                checked={actif==1}
                                onClick={() => {
                                    setActif(!actif);  
                                     
                                }}
                                 />
                            
                           </FormGroup>

                            <label htmlFor="equipe" className="form-label">Equipe</label>
                           
                           <InputGroup>
                               <Input type="select" onChange={(e) => setEquipe(e.target.value)}  defaultValue="0">
                               <option value="">Sélectionnez une equipe</option> {/* Option vide par défaut */}
                               {Object.values(equipes.equipes).map((eq) => (
                                   <option key={eq.id} value={eq.id}>
                                   {eq.libelle}
                                   </option>
                               ))}
                               </Input>
                           </InputGroup>

                        </div>  
                         
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewAgent}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered  >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Agent </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateAgent}>
                    <ModalBody> 

                        <div className="mb-3">
                        <label htmlFor="matricule" className="form-label">Matricule</label>
                        <input type="text" id="matricule" className="form-control" placeholder="Entrer le matricule"  value={matricule}  onChange={(e) => setMatricule(e.target.value)}/>
                            
                            <label htmlFor="nom" className="form-label">Nom</label>
                            <input type="text" id="prenom" className="form-control" placeholder="Entrer Nom" required  value={nom} onChange={(event) => setNom(event.target.value)}/>
                            <label htmlFor="prenom" className="form-label">Prénom</label>
                            <input type="text" id="prenom" className="form-control" placeholder="Entrer Prenom" required  value={prenom} onChange={(event) => setPrenom(event.target.value)}/>
                            <label htmlFor="sexe" className="form-label">Sexe</label>
                            <InputGroup>
                                <Input type="select" placeholder="Selectionnez sexe"  value={sexe} onChange={(e) => setSexe(e.target.value)}  >
                                <option value="">Sélectionnez un genre</option> {/* Option vide par défaut */}
                                <option value="M">MASCULIN</option>
                                <option value="F">FEMININ</option> 
                                </Input>
                            </InputGroup>
                            <label htmlFor="telephone" className="form-label">Téléphone</label>
                            <input type="text" id="telephone" className="form-control" placeholder="Entrer Telephone" required  value={telephone} onChange={(event) => setTelephone(event.target.value)}/>
                            <label htmlFor="adresse" className="form-label">Adresse</label>
                            <input type="text" id="adresse" className="form-control" placeholder="Entrer Adresse" required  value={adresse} onChange={(event) => setAdresse(event.target.value)}/>


                            <label htmlFor="dateIntegration" className="form-label">Date Integration</label>
                            <input type="date" id="dateIntegration" className="form-control" placeholder="Date d'integration"  value={dateIntegration}  onChange={(e) => setDateIntegration(e.target.value)}/>
                            <label htmlFor="salaireBase" className="form-label">salaire de Base</label>
                            <input type="text" id="salaireBase" className="form-control"  placeholder="Salaire de base"  value={salaireBase} onChange={(e) => setSalaireBase(e.target.value)}/>
                            <label htmlFor="numCompte" className="form-label">N° compte</label>
                            <input type="text" id="numCompte" className="form-control"  placeholder="N° compte" value={numCompte}  onChange={(e) => setNumCompte(e.target.value)}/>

                            <label htmlFor="dateNaissance" className="form-label">Date Naissance</label>
                            <input type="date" id="dateNaissance" className="form-control" placeholder="Date Naissance" value={dateNaissance}  onChange={(e) => setDateNaissance(e.target.value)}/>

                            <label htmlFor="lieuNaissance" className="form-label">Lieu Naissance</label>
                            <input type="text" id="lieuNaissance" className="form-control"  placeholder="Lieu Naissance"  value={lieuNaissance} onChange={(e) => setLieuNaissance(e.target.value)} />

                            <label htmlFor="identiteNationale" className="form-label">N° Identité Nationale</label>
                            <input type="text" id="identiteNationale" className="form-control"   placeholder="N° de piece d identité" value={identiteNationale}   onChange={(e) => setIdentiteNationale(e.target.value)}/>

                            <label htmlFor="nationalite" className="form-label">Nationalité</label>
                            <input type="text" id="nationalite" className="form-control"    placeholder="Nationalité" value={nationalite}  onChange={(e) => setNationalite(e.target.value)}/>



                            <label htmlFor="typeAgent" className="form-label">Type Agent</label>
                            <InputGroup>
                                <Input type="select" value={typeAgent} onChange={(e) => setTypeAgent(e.target.value)}  >
                                <option value="">Sélectionnez un role</option> {/* Option vide par défaut */}
                                <option value="POMPISTE">POMPISTE</option>
                                <option value="GERANT">GERANT</option>
                                <option value="SUPERVISEUR">SUPERVISEUR</option>
                                <option value="COMPTABLE">COMPTABLE</option>
                                <option value="CHEF DE PISTE">CHEF DE PISTE</option>
                                <option value="AUTRE">AUTRE</option>
                                </Input>
                            </InputGroup>
                            <label htmlFor="equipe" className="form-label">Actif ?</label>
                            <FormGroup switch> 
                               <Input 
                                type="switch"
                                style={{ width: '30px' }} 
                                checked={actif==1}
                   
                                onClick={() => {
                                    setActif(!actif);  
                                     
                                }}
                                 />
                            
                           </FormGroup>

                            <label htmlFor="equipe" className="form-label">Equipe</label>
                            <InputGroup>
                                <Input type="select" value={equipe} onChange={(e) => setEquipe(e.target.value)}  >
                                <option value="">Sélectionnez une equipe</option> {/* Option vide par défaut */}
                                {Object.values(equipes.equipes).map((eq) => (
                                    <option key={eq.id} value={eq.id}>
                                    {eq.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateAgent}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

               {/* show Modal */}   
               <Modal isOpen={modal_show} toggle={() => { tog_show(); }} centered  >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}>  Plus d information sur l'agent </ModalHeader>
                <form className="tablelist-form" >
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="nom" className="form-label">Matricule</label>
                            <input type="text" id="matricule" className="form-control" value={matricule} disabled={true}/>
                            <label htmlFor="nom" className="form-label">Nom</label>
                            <input type="text" id="prenom" className="form-control"  value={nom} disabled={true}/>
                            <label htmlFor="prenom" className="form-label">Prénom</label>
                            <input type="text" id="prenom" className="form-control"   value={prenom} disabled={true}/>
                            <label htmlFor="sexe" className="form-label">Sexe</label>
                            <InputGroup>
                                <Input type="select" placeholder="Selectionnez sexe"  value={sexe} disabled={true} onChange={(e) => setSexe(e.target.value)}  >
                                <option value="">Sélectionnez un genre</option> {/* Option vide par défaut */}
                                <option value="M">MASCULIN</option>
                                <option value="F">FEMININ</option> 
                                </Input>
                            </InputGroup>
                            <label htmlFor="dateIntegration" className="form-label">Date Integration</label>
                            <input type="text" id="dateIntegration" className="form-control"  value={formatedDate(dateIntegration)} disabled={true}/>
                            <label htmlFor="salaireBase" className="form-label">salaire de Base</label>
                            <input type="text" id="salaireBase" className="form-control"  value={salaireBase} disabled={true}/>
                            <label htmlFor="numCompte" className="form-label">N° compte</label>
                            <input type="text" id="numCompte" className="form-control"  value={numCompte} disabled={true}/>

                            <label htmlFor="dateNaissance" className="form-label">Date Naissance</label>
                            <input type="text" id="dateNaissance" className="form-control" value={formatedDate(dateNaissance)}   disabled={true}/>

                            <label htmlFor="lieuNaissance" className="form-label">Lieu Naissance</label>
                            <input type="text" id="lieuNaissance" className="form-control" value={lieuNaissance}   disabled={true}/>

                            <label htmlFor="identiteNationale" className="form-label">N° Identité Nationale</label>
                            <input type="text" id="identiteNationale" className="form-control"  value={identiteNationale} disabled={true}/>

                            <label htmlFor="nationalite" className="form-label">Nationalité</label>
                            <input type="text" id="nationalite" className="form-control"  value={nationalite} disabled={true}/>
 

                               
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_show(false)}>Fermer</button>
                             
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Agents;
