import {
  GET_TYPEDEMANDES_SUCCESS,
  GET_TYPEDEMANDES_FAIL,
  GET_TYPEDEMANDE_SUCCESS,
  GET_TYPEDEMANDE_FAIL,
  ADD_TYPEDEMANDE_SUCCESS,
  ADD_TYPEDEMANDE_FAIL,
  UPDATE_TYPEDEMANDE_SUCCESS,
  UPDATE_TYPEDEMANDE_FAIL,
  DELETE_TYPEDEMANDE_SUCCESS,
  DELETE_TYPEDEMANDE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  typeDemandes: [],
  error: {},
};

const TypeDemandes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TYPEDEMANDES_SUCCESS:
      return {
        ...state,
        typeDemandes: action.payload,
      };

    case GET_TYPEDEMANDES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_TYPEDEMANDE_SUCCESS:
      return {
        ...state,
        typeDemande: action.payload,
      };
  
    case GET_TYPEDEMANDE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_TYPEDEMANDE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        typeDemandes: [...state.typeDemandes, action.payload],
      };

    case ADD_TYPEDEMANDE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TYPEDEMANDE_SUCCESS:
      return {
        ...state,
        typeDemandes: state.typeDemandes.map(typeDemande =>
          typeDemande.id.toString() === action.payload.id.toString()
            ? { typeDemande, ...action.payload }
            : typeDemande
        ),
        isTypeDemandeUpdated: true
      };

    case UPDATE_TYPEDEMANDE_FAIL:
      return {
        ...state,
        error: action.payload,
        isTypeDemandeUpdated: false
      };

    case DELETE_TYPEDEMANDE_SUCCESS:
      return {
        ...state,
        typeDemandes: state.typeDemandes.filter(
          typeDemande => typeDemande.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_TYPEDEMANDE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default TypeDemandes;
