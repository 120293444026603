import React, { useState, useEffect } from 'react'; 

import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash'; 
import ReactApexChart from "react-apexcharts";
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import Select  from 'react-select'; 
//redux
import { useSelector, useDispatch } from "react-redux"; 
 // Importez les fonctions de regroupement de tableau
import { groupBy } from 'lodash'; 
 

import {
    //users

    getUsers as onGetUsers,

    //categories
    getDepenseCategories as onGetCategories,

    //depenses
    addNewDepense as onAddNewDepense,
    deleteDepense as onDeleteDepense,
    getDepenses as onGetDepenses,
    getDepense as onGetDepense,
    getVente as onGetVentes,
    getApprovisionnement as onGetApprovisionnement,
    updateDepense as onUpdateDepense,
  } from "../../store/actions"; 

  
 
const AnalyseDepenses = () => {
    
const d=new Date();
const today = new Date().toISOString().split('T')[0];
 const dateDeb = new Date(d.setDate(d.getDate() -7)).toISOString().split('T')[0];


const [annee, setAnnee] = useState(localStorage.getItem('anneeEnCours'));
const [startDate, setStartDate] = useState(dateDeb);
const [endDate, setEndDate] = useState(today);

const [cat, setCat] = useState('0');
const [deps, setDeps] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document)

//  const end = new Date(endDate );
//  end.setDate(end.getDate() + 1);  

   
const depenses = useSelector((state) => ({  depenses: state.depenses.depenses  }));
const ventes = useSelector((state) => ({  ventes:  state.ventes.ventes  }));

const categories = useSelector((state) => ({  categories:  state.depenseCategories.depenseCategories  }));

const appros = useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements    }));
 
const users = useSelector((state) => ({  users:  state.users.users  }));


const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };


  /**option de filtre */
  const catOptions=Object.values(categories.categories).map(cat => ({
    value:cat.id, label:cat.libelle
}))
catOptions.unshift({ value: '0', label: '---Toutes les catégories---' });
const defaultValueCat ={ value: '0', label: '---Toutes les catégories---' }


   /**CALCULE DES ANNEES DE DEPENSES */
   const annees = groupBy(Object.values(depenses.depenses), dep => {
    
    const date = new Date(dep.dateDep);
  
    const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0
     
    const annee = date.getFullYear(); 
    
    return `${annee}`;
  });

const anneeOptions=Object.keys(annees).map(an => ({value:an, label:an })) 
const defaultValue ={ value:localStorage.getItem('anneeEnCours') , label:localStorage.getItem('anneeEnCours')};  


const handleChangeAnnee =(an)=>{
    setAnnee(an.value)     
}



const depsOptionsFilter = Object.values(depenses.depenses).filter(dp=> cat ? dp.categorie===cat:dp.categorie===dp.categorie);

const depsOptions=Object.values(depsOptionsFilter).map(dep => ({
    value:dep.id, label:dep.libelle
}))
depsOptions.unshift({ value: '', label: '' });

/// lorsque l'utilisateur choisi une categorie
const handleChangeCat=(cat)=>{
    setCat((cat.value).toString()) 
 
}

 
 
  
   useEffect(() => { 
        
        dispatch(onGetCategories());
        dispatch(onGetUsers());
        dispatch(onGetDepenses()); 
    },[dispatch,onAddNewDepense,onUpdateDepense,onDeleteDepense]); //onAddNewDepense,onDeleteDepense,onUpdateDepense,Depenses

   

    // regroupement des depenses par categories ,mois et par annéee
    const depensesParEtMois = groupBy(Object.values(depenses.depenses), dep => { 
        const date = new Date(dep.dateDep); 
        const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0 
        const annee = date.getFullYear(); 
        return `${dep.categorie}-${mois}-${annee}`;
         });
    
    
              const filteredDepenses = Object.keys(depensesParEtMois)
              .filter(key => {
                const anneePart = key.split('-')[2];
                return parseInt(anneePart, 10) === parseInt(annee, 10);
              })
              .reduce((obj, key) => {
                obj[key] = depensesParEtMois[key];
                return obj;
              }, {});

             
              //nouveau objet filteredDepensesparMois 
              /*  // Fonction pour calculer la somme des montants par ID à partir d'un tableau de dépenses
                    const sommeMontantsParCat = (depenses) => {
                      return depenses.reduce((acc, depense) => {
                        if (!acc[depense.categorie]) {
                          acc[depense.categorie] = 0;
                        }
                        acc[depense.categorie] += depense.montant;
                        return acc;
                      }, {});
                    };

                    // Reconstituer un nouvel objet avec la somme des montants par ID pour toutes les clés
                    const sommeTotaleMontantsParCat = Object.keys(depensesParEtMois).reduce((acc, cle) => {
                      const depensesPourCle = depensesParEtMois[cle];
                      const sommeMontants = sommeMontantsParCat(depensesPourCle);
                      acc[cle] = sommeMontants;
                      return acc;
                    }, {});

              console.log('sommeTotaleMontantsParCat',sommeTotaleMontantsParCat) */
    // calucle des montant des depenses par categories ,mois et années
    const depensesMois={}
    for (const key in filteredDepenses) {
        const depensesGroupees = filteredDepenses[key];
        const montant= depensesGroupees.reduce((acc, dep) => acc + dep.montant, 0);
       // console.log(`Quantités des ventes pour ${key}: ${quantite}`);
       //parseFloat(quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
       depensesMois[key]=montant
      }

       /**** dermination des quandité par produit des differents mois de l'annee*/
    const depensesCategorie=cat==='0' ? Object.keys(depensesMois).map(key => ({ [key]: depensesMois[key] })) : Object.keys(depensesMois).filter(key => key.indexOf(cat + '-') === 0).map(key => ({ [key]: depensesMois[key] }));
      const MoisValue=(ob,p)=>{
          /* console.log('ob',ob,'p',p) */
       /*    console.log('ob',ob) */
            const ObjetMois = ob.map(obj => {
                const ancienneCle = Object.keys(obj)[0]; 
                const mois =ancienneCle.length===6 ? ancienneCle.split('-')[0] : ancienneCle.split('-')[1];// Obtient le mois
                
                const valeur = Object.values(obj)[0]; 
                return { [mois]: valeur };
              });
               
            //const objetMoisRecherche = ObjetMois.find(obj => Object.keys(obj)[0] === p);
            let objetMoisRecherche = 0;
            for (const objet of ObjetMois) {
              // Vérifier si la clé de l'objet est égale à 3
              if (objet.hasOwnProperty(p)) {
                  // Ajouter la valeur à la somme
                  objetMoisRecherche += objet[p];
              }
          }
           /*  console.log('ObjetMois',ObjetMois)
            console.log('objetMoisRecherche',objetMoisRecherche) */
            if (objetMoisRecherche) {
           // const valeurMoisRecherche = Object.values(objetMoisRecherche)[0];
             
            return parseFloat(objetMoisRecherche)
            } else {
            //console.log(`Aucun objet trouvé pour le mois ${6}`);
            return 0
            }

        }
       

/// DEPENSES PAR CATEGORIE

    //     //groupement par catégorie  depenses
    // const depensesParCategorie = groupBy(Object.values(depenses.depenses), dep => { 
    //     const date = new Date(dep.dateDep); 
    //     const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0 
    //     const annee = date.getFullYear(); 
    //     return   `${dep.categorie}-${mois}-${annee}`;
    // });
        
   
    // const filteredDepensesCategorie = Object.keys(depensesParCategorie)
    // .filter(key => {
    //   const anneePart = key.split('-')[2];
    //   return parseInt(anneePart, 10) === parseInt(annee, 10);
    // })
    // .reduce((obj, key) => {
    //   obj[key] = depensesParCategorie[key];
    //   return obj;
    // }, {});


      // calucle des montant des depenses par categories ,mois et années
          /**** dermination des quandité par produit des differents mois de l'annee*/
    const depensesCategorie2=  Object.keys(depensesMois).map(key => ({ [key]: depensesMois[key] })) 
  
      const categorieValue=(ob,p)=>{
                    const ObjetCategorie = ob.map(obj => {
                        const ancienneCle = Object.keys(obj)[0];
                        const cat = ancienneCle.split('-')[0]; // Obtient le la categorie
                        const valeur = Object.values(obj)[0];
                        
                        return { [cat]: valeur };
                    });
                    
                    const objetCatRecherche = ObjetCategorie.filter(obj => Object.keys(obj)[0] === p);
                    


                    const sommeCumulative= objetCatRecherche ? objetCatRecherche.reduce((acc, obj) => {
                                const valeur = Object.values(obj)[0];
                                return acc + valeur;
                            }, 0) : 0
                    return sommeCumulative;
        
            }
    
            const optionsCat = catOptions.filter(objet => objet.value !== "0");
            const categoriesDepenseValeurs = optionsCat.map(cat => [categorieValue(depensesCategorie2,(cat.value).toString()), cat.label]).sort((a, b) => a[0] - b[0]);
           /// radoum des couleurs 
            function getRandomColor() {
                const r = Math.floor(Math.random() * 256);
                const g = Math.floor(Math.random() * 256);
                const b = Math.floor(Math.random() * 256);
                return `rgb(${r},${g},${b})`;
              }
             const  categoriesDepenseValeursCouleur=categoriesDepenseValeurs.map((cat,index)=>{
                const couleur=getRandomColor();
                return [ cat[0], cat[1],couleur ];
             })

              

            const catDepensesData = categoriesDepenseValeursCouleur.map(element => element[0]);
            
            const catDepensesLabel = categoriesDepenseValeursCouleur.map(element => element[1]);
            const catDepensesColor = categoriesDepenseValeursCouleur.map(element => element[2]);

    


            //VENTE CUMULEE
             // regroupement des depenses par categories ,mois et par annéee
            const ventesCumulees = groupBy(Object.values(ventes.ventes), vt => { 
                const date = new Date(vt.dateEnregistrement); 
                const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0 
                const annee = date.getFullYear(); 
                return `${mois}-${annee}`;
            });
    
    
              const filteredVentes = Object.keys(ventesCumulees)
              .filter(key => {
                const anneePart = key.split('-')[1];
                return parseInt(anneePart, 10) === parseInt(annee, 10);
              })
              .reduce((obj, key) => {
                obj[key] = ventesCumulees[key];
                return obj;
              }, {});

        
            // calucle des montant des ventes par ,mois et années
    const ventesMois={}
    for (const key in filteredVentes) {
        const ventesGroupees = filteredVentes[key];
        const montant= ventesGroupees.reduce((acc, vt) => acc + vt.montantVerse, 0); 
       ventesMois[key]=montant
      }


       /**** dermination des sommes  vendues mois de l'annee*/
    const ventesSommes=Object.keys(ventesMois).map(key => ({ [key]: ventesMois[key] })) ;


    /* console.log("ventesSommes",ventesSommes) */
  
 //APPROS CUMULEE
           //  regroupement des depenses par categories ,mois et par annéee
             const approsCumulees = groupBy(Object.values(appros.approvisionnements), vt => { 
                const date = new Date(vt.dateEnregistrement); 
                const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0 
                const annee = date.getFullYear(); 
                return `${mois}-${annee}`;
            });
    
    
              const filteredAppros = Object.keys(approsCumulees)
              .filter(key => {
                const anneePart = key.split('-')[1];
                return parseInt(anneePart, 10) === parseInt(annee, 10);
              })
              .reduce((obj, key) => {
                obj[key] = approsCumulees[key];
                return obj;
              }, {});

        
        // calucle des montant des appros par ,mois et années
    const approsMois={}
    for (const key in filteredAppros) {
        const approGroupees = filteredAppros[key];
        const montant= approGroupees.reduce((acc, ap) => acc + ap.montant, 0); 
        approsMois[key]=montant
      }


       /**** dermination des sommes  appro mois de l'annee*/
    const approsSommes=Object.keys(approsMois).map(key => ({ [key]: approsMois[key] })) ;

 //console.log("MoisValue(depensesCategorie,'3')",MoisValue(depensesCategorie,'3'))

//   console.log('MoisValue(approsSommes 6', MoisValue(approsSommes,'6'))




        const stateDepenses= {
          
            series: [{
              name: 'Depense',
              data:  [MoisValue(depensesCategorie,'1'), MoisValue(depensesCategorie,'2') , MoisValue(depensesCategorie,'3'), MoisValue(depensesCategorie,'4'), MoisValue(depensesCategorie,'5'),  MoisValue(depensesCategorie,'6'), MoisValue(depensesCategorie,'7'),MoisValue(depensesCategorie,'8'),MoisValue(depensesCategorie,'9'),MoisValue(depensesCategorie,'10'),MoisValue(depensesCategorie,'11'),MoisValue(depensesCategorie,'12')]
            }],
            options: {
              chart: {
                height: 500,
                type: 'bar',
              },
              colors: [ '#3B55CC'],
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  dataLabels: {
                    position: 'top', // top, center, bottom
                  },
                }
              },
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                  return parseFloat(val,0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')+" F CFA"  ;
                },
                offsetY: -20,
                style: {
                  fontSize: '12px',
                  colors: ["#304758"]
                }
              },
              
              xaxis: {
                categories: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
                position: 'bottom',
                axisBorder: {
                  show: true
                },
                axisTicks: {
                  show: true
                },
                crosshairs: {
                  fill: {
                    type: 'gradient',
                    gradient: {
                      colorFrom: '#25105E',
                      colorTo: '#000000',
                      stops: [0, 100],
                      opacityFrom: 0.4,
                      opacityTo: 0.5,
                    }
                  }
                },
                tooltip: {
                  enabled: false,
                },
                labels: {
                  style: { 
                    color:  'black' ,
                      fontSize: '12px', // Taille de police
                      fontWeight: '800', // Gras 
                      // Autres styles CSS peuvent être ajoutés ici
                  },
                   
              }
              },
              yaxis: {
                axisBorder: {
                  show: true
                },
                axisTicks: {
                  show: true,
                },
                labels: {
                  show: true,
                  formatter: function (val) {
                    return parseFloat(val,0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')+" F CFA"  ;
                  },
                  style: { 
                    color:  'black' ,
                      fontSize: '12px', // Taille de police
                      fontWeight: '800', // Gras 
                      // Autres styles CSS peuvent être ajoutés ici
                  },
                   
                }
              
              },
              title: {
                text: '',
                floating: true,
                offsetY: 490,
                align: 'center',
                
                style: {
                  color: '#444'
                }
              }
            },
          
          };    
            
          ///State par categories 
           const  stateDepensesCategories = {
          
            series: [{
              data: catDepensesData
            }],
            options: {
              chart: {
                height: 350,
                type: 'bar',
                events: {
                  click: function(chart, w, e) {
                    // console.log(chart, w, e)
                  }
                }
              },
              colors: catDepensesColor, 
              plotOptions: {
                bar: {
                  columnWidth: '90%',
                  distributed: true,
                }
              },
              dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return parseFloat(val,0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')+" F"; // Formater la valeur ici (par exemple, avec 2 décimales)
                  },
                  style: {
                    fontSize: '12px',
                    color:  'black' ,
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                   
                  },
                  
                  offsetX: 0,
                  offsetY: 0,
                  textAnchor: 'middle',
                  rotate: {
                    degrees: 45 // Angle de rotation des étiquettes de données en degrés (vers la gauche dans ce cas)
                  }
              },
              legend: {
                show: false
              },
              xaxis: {
                categories:  catDepensesLabel,
                labels: {
                  style: { 
                    color:  'black' ,
                      fontSize: '12px', // Taille de police
                      fontWeight: '800', // Gras 
                      // Autres styles CSS peuvent être ajoutés ici
                  },
                   
                  
                }
              },
              yaxis: {
                axisBorder: {
                  show: true
                },
                axisTicks: {
                  show: true,
                },
                labels: {
                  show: true,
                  formatter: function (val) {
                    return parseFloat(val,0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')+" F"  ;
                  },
                  style: { 
                    color:  'black' ,
                      fontSize: '12px', // Taille de police
                      fontWeight: '800', // Gras 
                      // Autres styles CSS peuvent être ajoutés ici
                  },
                }
              
              },
            },
          
          
          };


                //State comparé

                const stateDepenseComparee = {
          
                    series: [{
                      data: [MoisValue(depensesCategorie,'12'), MoisValue(depensesCategorie,'11') , MoisValue(depensesCategorie,'10'), MoisValue(depensesCategorie,'9'), MoisValue(depensesCategorie,'8'),  MoisValue(depensesCategorie,'7'), MoisValue(depensesCategorie,'6'),MoisValue(depensesCategorie,'5'),MoisValue(depensesCategorie,'4'),MoisValue(depensesCategorie,'3'),MoisValue(depensesCategorie,'2'),MoisValue(depensesCategorie,'1')]
                    },
                     
                    {
                        data: [(MoisValue(ventesSommes,'12')-MoisValue(approsSommes,'12')), (MoisValue(ventesSommes,'11')-MoisValue(approsSommes,'11')) , (MoisValue(ventesSommes,'10')-MoisValue(approsSommes,'10')), (MoisValue(ventesSommes,'9')-MoisValue(approsSommes,'9')), (MoisValue(ventesSommes,'8')-MoisValue(approsSommes,'8')),  (MoisValue(ventesSommes,'7')-MoisValue(approsSommes,'7')), (MoisValue(ventesSommes,'6')-MoisValue(approsSommes,'6')),(MoisValue(ventesSommes,'5')-MoisValue(approsSommes,'5')),(MoisValue(ventesSommes,'4')-MoisValue(approsSommes,'4')),(MoisValue(ventesSommes,'3')-MoisValue(approsSommes,'3')),(MoisValue(ventesSommes,'2')-MoisValue(approsSommes,'2')),(MoisValue(ventesSommes,'1')-MoisValue(approsSommes,'1'))]
                      },
                      {
                        data: [(MoisValue(ventesSommes,'12')-MoisValue(approsSommes,'12'))-MoisValue(depensesCategorie,'12'), (MoisValue(ventesSommes,'11')-MoisValue(approsSommes,'11'))-MoisValue(depensesCategorie,'11') , (MoisValue(ventesSommes,'10')-MoisValue(approsSommes,'10'))-MoisValue(depensesCategorie,'10'), (MoisValue(ventesSommes,'9')-MoisValue(approsSommes,'9'))-MoisValue(depensesCategorie,'9'), (MoisValue(ventesSommes,'8')-MoisValue(approsSommes,'8'))-MoisValue(depensesCategorie,'8'),  (MoisValue(ventesSommes,'7')-MoisValue(approsSommes,'7'))-MoisValue(depensesCategorie,'7'), (MoisValue(ventesSommes,'6')-MoisValue(approsSommes,'6'))-MoisValue(depensesCategorie,'6'),(MoisValue(ventesSommes,'5')-MoisValue(approsSommes,'5'))-MoisValue(depensesCategorie,'5'),(MoisValue(ventesSommes,'4')-MoisValue(approsSommes,'4'))-MoisValue(depensesCategorie,'4'),(MoisValue(ventesSommes,'3')-MoisValue(approsSommes,'3'))-MoisValue(depensesCategorie,'3'),(MoisValue(ventesSommes,'2')-MoisValue(approsSommes,'2'))-MoisValue(depensesCategorie,'2'),(MoisValue(ventesSommes,'1')-MoisValue(approsSommes,'1'))-MoisValue(depensesCategorie,'1')]
                      },
                    ],
                    options: {
                      chart: {
                        type: 'bar',
                        height: 430
                      },
                      colors: ['#BA0123', '#3B55CC','#2DAD25'],
                      plotOptions: {
                        bar: {
                          horizontal: true, 
                          dataLabels: {
                            position: 'top',
                          },
                        },  
                        barHeight: '100%', 
                        
                      },
                      dataLabels: {
                        enabled: false,
                        offsetX: -6,
                        style: { 
                          color:  'black' ,
                            fontSize: '12px', // Taille de police
                            fontWeight: '800', // Gras 
                            // Autres styles CSS peuvent être ajoutés ici
                        },
                         
                        formatter: function (val) {
                          return parseFloat(val,0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')+" F"; // Formater la valeur ici (par exemple, avec 2 décimales)
                        },
                      },
                      stroke: {
                        show: true,
                        width: 1,
                        colors: ['#FFF']
                      },
                      tooltip: {
                        shared: true,
                        intersect: false
                      },
                      xaxis: {
                        categories: ["Decembre", "Novembre", "Octobre", "Septembre", "Aouti", "Juillet", "Juin", "Mai", "Avril", "Mars", "Fevrier", "Janvier"],
                    },
                    labels: {
                      show: true,
                      formatter: function (val) {
                        return parseFloat(val,0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')+" F CFA"  ;
                      },
                      style: { 
                        color:  'black' ,
                          fontSize: '12px', // Taille de police
                          fontWeight: '800', // Gras 
                          // Autres styles CSS peuvent être ajoutés ici
                      },
                       
                    }
                    },
                  
                  
                  };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Depense" breadcrumbItem="Analyse des opérations de décaissement" />

                    <Row>
                        <Col lg={12}>
                            <Card> 

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3"> 
                                            <Col className="col-sm"> 
                                                     <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", alignItems: 'center'}}>ANALYSE DES OPERATIONS DE DECAISSEMENT</span>
                                            </Col>
                                        </Row>

                                        <Row style={{backgroundColor:"#000B70", color: 'inherit', fontSize:"12"}}>
                                            <Col >
                                            <label htmlFor="catSelect"  style={{color: 'white', fontSize:"12"}}>Année</label>
                                            <Select defaultValue={defaultValue} options={anneeOptions} onChange={handleChangeAnnee} />
                                            </Col>
                                           <Col>
                                            <label htmlFor="catSelect" style={{color: 'white', fontSize:"12"}}>Sélectionner une categorie</label>
                                            <Select defaultValue={defaultValueCat} options={catOptions} onChange={handleChangeCat} />
                                            </Col> 
                                        </Row>

                                        <Row  style={{backgroundColor: '#F1FCFF', fontSize:"12",color:"#000000"}}>
                                        <ReactApexChart options={stateDepenses.options} series={stateDepenses.series} type="bar" height={500}  className="my-chart" />
                                        </Row>
                                        <Row  style={{backgroundColor:"#000B70", color: 'white', fontSize:"16", height:"45"}}>
                                        <Col>   <span>Analyse comparée des dépenses par catégorie </span> </Col>
                                            {/* <Col style={{justifyContent:"center"}}><span>Analyse comparée des opérations de décaissement mensuelles par rapport aux ecarts ventes-appro et bénéfices</span></Col>
                                         */}
                                        </Row>
                                        <Row  style={{backgroundColor: '#F1FCFF', fontSize:"12"}}>
                                            <Col>
                                            <ReactApexChart options={stateDepensesCategories.options} series={stateDepensesCategories.series} type="bar" height={500}/>
                                            </Col>

                                            {/* <Col >
                                            <ReactApexChart options={stateDepenseComparee.options} series={stateDepenseComparee.series} type="bar" height={500} />
                                            </Col> */}
                                        
                                        </Row>
                                       

                                       

                                        
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

          
        </React.Fragment>
    );
};

export default AnalyseDepenses;
