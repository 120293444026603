import { takeEvery, put, call } from "redux-saga/effects"

// Pompes Redux States
import {
  ADD_NEW_POMPE,
  DELETE_POMPE,
  GET_POMPES,
  GET_POMPE,
  UPDATE_POMPE,
} from "./actionTypes"
import {
  getPompesSuccess,
  getPompesFail,
  getPompeSuccess,
  getPompeFail,
  addPompeFail,
  addPompeSuccess,
  updatePompeSuccess,
  updatePompeFail,
  deletePompeSuccess,
  deletePompeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPompes,
  getPompe,
  addNewPompe,
  updatePompe,
  deletePompe,
} from "../../helpers/api_back"

function* fetchPompes() {
  try {
    const response = yield call(getPompes)
    yield put(getPompesSuccess(response))
  } catch (error) {
    yield put(getPompesFail(error))
  }
}

function* fetchPompe() {
  try {
    const response = yield call(getPompe)
    yield put(getPompeSuccess(response))
  } catch (error) {
    yield put(getPompeFail(error))
  }
}


function* onAddNewPompe({ payload: pompe }) {
 
  try {
    const response = yield call(addNewPompe, pompe)
    
    yield put(addPompeSuccess(response))
  } catch (error) {
    yield put(addPompeFail(error))
  }
}

function* onUpdatePompe({ payload: pompe }) {
  try {
    const response = yield call(updatePompe, pompe)
    yield put(updatePompeSuccess(response))
  } catch (error) {
    yield put(updatePompeFail(error))
  }
}

function* onDeletePompe({ payload: pompe }) {
  try {
    const response = yield call(deletePompe, pompe)
    yield put(deletePompeSuccess(response))
  } catch (error) {
    yield put(deletePompeFail(error))
  }
}
 

function* pompesSaga() {
  yield takeEvery(GET_POMPES, fetchPompes)
  yield takeEvery(GET_POMPE, fetchPompe)
  yield takeEvery(ADD_NEW_POMPE, onAddNewPompe)
  yield takeEvery(UPDATE_POMPE, onUpdatePompe)
  yield takeEvery(DELETE_POMPE, onDeletePompe) 
}

export default pompesSaga
