import React, { useState, useEffect } from 'react'; 

import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //categories
    getCategories as onGetCategories,

    //produits
    addNewProduit as onAddNewProduit,
    deleteProduit as onDeleteProduit,
    getProduits as onGetProduits,
    getProduit as onGetProduit,
    updateProduit as onUpdateProduit,

        //MouvementStock
    addNewMouvementStock as onAddNewMouvementStock,

  } from "../../store/actions"; 

  
 
const ListProduits = () => {
const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document)
   
const produits = useSelector((state) => ({  produits: state.produits.produits  }));
const categories = useSelector((state) => ({  categories:  state.categories.categories  }));
const users = useSelector((state) => ({  users:  state.users.users  }));

const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };

 

function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 9000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    const [modal_mouv, setmodal_mouv] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }
     
    function tog_mouv() {
        setmodal_mouv(!modal_mouv);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'un produit
const [nomProd, setNomProd] = useState('');
const [stockProd, setStockProd] = useState('');
const [stockVente, setStockVente] = useState('');
const [stockMin,setStockMin]=useState('');
const [prixAchat, setPrixAchat] = useState('');
const [prixVente, setPrixVente] = useState('');
const [categorieProd, setCategorieProd] = useState('');
const [descProd, setDescProd] = useState('');

const [stockVenteAvant, setStockVenteAvant] = useState(0);
const [stockProdAvant, setStockProdAvant] = useState(0);
const [quantiteAffectee, setQuantiteAffectee] = useState(0);
const [stockVenteApres, setStockVenteApres] = useState(0);
const [stockProdApres, setStockProdApres] = useState(0);
 





const [idProd, setIdProd] = useState('');

const newProduit = {
    nomProd: nomProd,
    stockProd:stockProd,
    stockVente:stockVente,
    stockMin:stockMin,
    prixAchat:prixAchat,
    prixVente:prixVente,
    categorie:categorieProd,
    description:descProd
};

const saveNewProduit = (e) => {
    e.preventDefault(); 
    setTypMsg('add')
    dispatch(onAddNewProduit(newProduit));
    
    setmodal_list(false);
    
 
  };

  // Mise a jour d"un Produit 
  const upProduit = { 
    nomProd: nomProd, 
    stockProd:stockProd,
    stockVente:stockVente,
    stockMin:stockMin,
    prixVente:prixVente,
    prixAchat:prixAchat,
    categorie:categorieProd,
    description:descProd
  };

  const mouvStock ={
    produit:idProd,
    stockProdAvant:stockProdAvant,
    stockVenteAvant:stockVenteAvant,
    quantiteAffectee:parseFloat(quantiteAffectee),
    stockProdApres:stockProdApres,
    stockVenteApres:stockVenteApres,
  }

  const saveUpdateProduit = (e) => {
    e.preventDefault(); 
    setTypMsg('upd')
    dispatch(onUpdateProduit(idProd,upProduit));
    setmodal_update(false);
    
  };


  /// Suppression d'un produit
 

  const [listIdProd, setListIdProd] = useState([]);

  const idProdChecBoxSelected = (prodId) => {
    setListIdProd(prevSelectedRows => ({
      ...prevSelectedRows,
      [prodId]: !prevSelectedRows[prodId]
    }));
     
  };


  const saveMouv= (e) => {
    e.preventDefault(); 
    setTypMsg('upd')
    dispatch(onAddNewMouvementStock(mouvStock));
    setmodal_mouv(false);
    
  };
 

  const filteredList = Object.entries(listIdProd)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const prodIds = Object.keys(filteredList);
 
  const deleteProduit = ( produit) => { 
    setTypMsg('del')
    if(produit)dispatch(onDeleteProduit(produit));
    if(listIdProd && prodIds){prodIds.forEach((prodId) =>dispatch(onDeleteProduit(prodId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(produits.produits);


const dataTable= filteredData.length!==0 ? 
filteredData.map(prod => {
    const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    const user = Object.values(users.users).find(us => us.id === prod.createdBy);
    return { ...prod , user: user? user.prenom+' '+user.nom :'',categorieInfo:categorie?categorie.libelle:'' };
  }) 
  : 
  produits.produits.map(prod => {
    const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    const user = Object.values(users.users).find(us => us.id === prod.createdBy);
    return { ...prod,user:user? user.prenom+' '+user.nom:'' ,categorieInfo:categorie?categorie.libelle:'' };
  });
//,categorieProd:categorie.libelle

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (prod) => {
    const value = prod.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.nomProd), _.toLower(value))||
      _.startsWith(_.toLower(item.stockProd), _.toLower(value))||
      _.startsWith(_.toLower(item.stockVente), _.toLower(value))||
      _.startsWith(_.toLower(item.stockMin), _.toLower(value))||
      _.startsWith(_.toLower(item.prixAchat), _.toLower(value))||
      _.startsWith(_.toLower(item.prixVente), _.toLower(value))||
      _.startsWith(_.toLower(item.categorieProd), _.toLower(value))||
      _.startsWith(_.toLower(item.description), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (prod, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);

 

const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((prod,index) =>  (
    <tr key={prod.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idProdChecBoxSelected(prod.id)}}   />
        </div>
    </th>
    <td className="prod_id" style={{ display: "none" }}>{prod.id}</td>
    <td className="prod_index">{index+1}</td>
    <td className="prod_name">{prod.nomProd}</td>
    <td className="prod_name">{parseFloat(prod.stockProd).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td className="prod_name">{parseFloat(prod.stockVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td className="prod_name">{parseFloat(prod.stockMin).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td className="prod_name">{ parseFloat(prod.prixAchat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td className="prod_name">{parseFloat(prod.prixVente).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
    <td className="prod_name">{prod.description}</td>
    <td className="prod_name">{prod.categorieInfo}</td>
    <td className="prod_date">{ prod.user}</td>
    <td className="prod_utilisateur">{formatedDate(prod.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setNomProd(prod.nomProd);setStockProd(prod.stockProd);setStockVente(prod.stockVente);setStockMin(prod.stockMin);setPrixAchat(prod.prixAchat);setPrixVente(prod.prixVente);setCategorieProd(prod.categorie);setDescProd(prod.description);setIdProd(prod.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteProduit(prod.id)}>Suppr.</button>
            </div>
            <div className="edit">
                <button className="btn btn-sm btn-info edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setNomProd(prod.nomProd);setStockProdAvant(prod.stockProd);setStockVenteAvant(prod.stockVente);setQuantiteAffectee(0);setStockProdApres(prod.stockProd);setStockVenteApres(prod.stockVente);setIdProd(prod.id);tog_mouv()} }>Mouv</button>
            </div> 
        </div>
    </td>
</tr>


));

   useEffect(() => { 
        
        dispatch(onGetCategories());
        dispatch(onGetUsers());
        dispatch(onGetProduits());
        setFilteredData(produits.produits)
         
    },[dispatch,typMsg,onDeleteProduit,onUpdateProduit,onAddNewProduit,onAddNewMouvementStock,modal_mouv]); //onAddNewProduit,onDeleteProduit,onUpdateProduit,produits

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Produit" breadcrumbItem="Liste des produits" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deleteProduit('');setListIdProd('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="customer_name" onClick={(prod) => handleSort(prod, 'id')}>N°</th>
                                                        <th className="sort" data-sort="nom" onClick={(prod) => handleSort(prod, 'nom')}>nom</th>
                                                        <th className="sort" data-sort="stockProd" onClick={(prod) => handleSort(prod, 'stock')}>stock</th>
                                                        <th className="sort" data-sort="stockVente" onClick={(prod) => handleSort(prod, 'stockVente')}>Boutique</th>
                                                        <th className="sort" data-sort="stockMin" onClick={(prod) => handleSort(prod, 'stockMin')}>stock Min</th>
                                                        <th className="sort" data-sort="prixAchat" onClick={(prod) => handleSort(prod, 'prixAchat')}>Prix Achat</th>
                                                        <th className="sort" data-sort="prixVente" onClick={(prod) => handleSort(prod, 'prixVente')}>Prix Vente</th>
                                                        <th className="sort" data-sort="description" onClick={(prod) => handleSort(prod, 'description')}>description</th>
                                                        <th className="sort" data-sort="categorie" onClick={(prod) => handleSort(prod, 'categorie')}>categorie</th>
                                                        <th className="sort" data-sort="createdBy" onClick={(prod) => handleSort(prod, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(prod) => handleSort(prod, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Produit </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewProduit}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="libelle" className="form-label">Nom</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(e) => setNomProd(e.target.value)}/>
                            <label htmlFor="stock" className="form-label">Stock</label>
                            <input type="decimal"     id="stock" className="form-control" placeholder="Enter Stock" required  onChange={(e) => setStockProd(e.target.value)}/>
                            <label htmlFor="stock" className="form-label">Stock Vente</label>
                            <input type="decimal"     id="stock" className="form-control" placeholder="Enter Vente" required  onChange={(e) => setStockVente(e.target.value)}/>
                            <label htmlFor="stock" className="form-label">Stock Min</label>
                            <input type="decimal"     id="stockMin" className="form-control" placeholder="Enter Stock Min" required  onChange={(e) => setStockMin(e.target.value)}/>
                            <label htmlFor="prixAchat" className="form-label">Prix Achat</label>
                            <input type="decimal"    id="prixAchat" className="form-control" placeholder="Enter Achat" required  onChange={(e) => setPrixAchat(e.target.value)}/>
            
                            <label htmlFor="prixVente" className="form-label">Prix Vente</label>
                            <input type="decimal"     id="prixVente" className="form-control" placeholder="Enter Vente" required  onChange={(e) => setPrixVente(e.target.value)}/>
                            <label htmlFor="categorie" className="form-label">Categorie</label>
                           
                            <InputGroup>
                                <Input type="select" onChange={(e) => setCategorieProd(e.target.value)}  defaultValue="0">
                                <option value="">Sélectionnez une catégorie</option> {/* Option vide par défaut */}
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>

                        </div>  
                        <div className="mb-3">
                            <label htmlFor="descritpion" className="form-label">Description</label>
                            <input type="text" id="description" className="form-control" placeholder="Description"   onChange={(e) => setDescProd(e.target.value)}/>
                             
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewProduit}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Produit </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateProduit}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="libelle" className="form-label">Nom</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  value={nomProd} onChange={(event) => setNomProd(event.target.value)}/>
                            <label htmlFor="stock" className="form-label">Stock</label>
                            <input type="decimal"     id="stock" className="form-control" placeholder="Stock" required   value={stockProd}   onChange={(event) => setStockProd(event.target.value)}/>
                            <label htmlFor="stockVente" className="form-label">Stock Vente</label>
                            <input type="decimal"     id="stock" className="form-control" placeholder="Stock vente" required   value={stockVente}   onChange={(event) => setStockVente(event.target.value)}/>
                           
                            <label htmlFor="stockMin" className="form-label">Stock Min</label>
                            <input type="decimal"    id="stockMin" className="form-control" placeholder="StockMin" required   value={stockMin}   onChange={(event) => setStockMin(event.target.value)}/>
                           
                            <label htmlFor="prixAchat" className="form-label">Prix Achat</label>
                            <input type="decimal"   id="prixAchat" className="form-control" placeholder="Prix Achat" required   value={prixAchat}   onChange={(event) => setPrixAchat(event.target.value)}/>
                            <label htmlFor="prixVente" className="form-label">Prix Vente</label>
                            <input type="decimal"    id="prixVente" className="form-control" placeholder="Stock Mini" required   value={prixVente}   onChange={(event) => setPrixVente(event.target.value)}/>
                            
                            <label htmlFor="categorie" className="form-label">categorie</label>
                            <InputGroup>
                                <Input type="select" value={categorieProd} onChange={(e) => setCategorieProd(e.target.value)}  >
                                <option value="">Sélectionnez une catégorie</option> {/* Option vide par défaut */}
                                {Object.values(categories.categories).map((cat) => (
                                    <option key={cat.id} value={cat.id}>
                                    {cat.libelle}
                                    </option>
                                ))}
                                </Input>
                            </InputGroup>
                            <label htmlFor="description" className="form-label">Description</label>
                            <input type="text" id="description" className="form-control" placeholder="Description"    value={descProd}   onChange={(event) => setDescProd(event.target.value)}/>
                        </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateProduit}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal> 
            
            {/* Mouv Modal */}
             <Modal isOpen={modal_mouv} toggle={() => { tog_mouv(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mouvement de Stock </ModalHeader>
                <form className="tablelist-form" onSubmit={saveMouv}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="Produit" className="form-label">Produit</label>
                            <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  value={nomProd} onChange={(event) => setNomProd(event.target.value)}/>
                            <label htmlFor="stock" className="form-label">Magasin</label>
                            <input type="decimal"     id="stock" className="form-control" placeholder="Stock" required   value={stockProdAvant}   onChange={(event) => setStockProdAvant(event.target.value)}/>
                            <label htmlFor="stockVente" className="form-label">Boutique</label>
                            <input type="decimal"     id="stock" className="form-control" placeholder="Boutique" required   value={stockVenteAvant}   onChange={(event) => setStockVenteAvant(event.target.value)}/>
                           
                            <label htmlFor="stockMin" className="form-label">Quantité à Affecter</label>
                            <input type="decimal"   style= {{backgroundColor:"#8C160D",color:"#FFFF"}}  id="quantite" className="form-control" placeholder="Quantité" required   value={quantiteAffectee}   onChange={(event) => {setQuantiteAffectee(event.target.value);setStockProdApres(stockProdAvant-event.target.value); setStockVenteApres( parseFloat(stockVenteAvant)+parseFloat(event.target.value))}}/>
                           
                            <label htmlFor="stockApres" className="form-label">Magasin après</label>
                            <input type="decimal"   style={{color:"red"}}  id="stock" className="form-control" placeholder="Stock" required   value={stockProdApres}   onChange={(event) => setStockProdApres(event.target.value)}/>
                            <label htmlFor="stockVente" className="form-label">Boutique après</label>
                            <input type="decimal" style={{backgroundColor:'#00034F',color:"#FFFD55"}} id="stock" className="form-control" placeholder="Stock boutique" required   value={stockVenteApres}   onChange={(event) => setStockVenteApres(event.target.value)}/>
                           
                            
                        </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_mouv(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveMouv}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default ListProduits;
