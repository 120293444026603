import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';


// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //produits
    getProduits as onGetProduits,

    //parametres
    addNewParametre as onAddNewParametre,
    deleteParametre as onDeleteParametre,
    getParametres as onGetParametres,
    getParametre as onGetParametre,
    updateParametre as onUpdateParametre,
  } from "../../store/actions"; 

  
 
const Parametres = () => {
const [typMsg, setTypMsg] = useState('');
const dispatch = useDispatch();
 //console.log("Document",Document)
   
const produits = useSelector((state) => ({  produits: state.produits.produits  }));
const parametres = useSelector((state) => ({  parametres:  state.parametres.parametres  }));
const users = useSelector((state) => ({  users:  state.users.users  }));


const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };




function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }
    
   
    
/// Enregistrements d'une parametre
const [code, setCode] = useState('');
const [valeur, setValeur] = useState('');
const [description, setDescription] = useState('');
const [produit, setProduit] = useState('');

const [idParametre, setIdParametre] = useState('');

const newParametre = {
    code: code,
    valeur:valeur,  
    description:description, 
};

const saveNewParametre = (e) => {
    e.preventDefault(); 
    setTypMsg('add')
    dispatch(onAddNewParametre(newParametre));
    
    setmodal_list(false);
    
 
  };

  // Mise a jour d"une Parametre 
  const upParametre = { 
    code: code,
    valeur:valeur,  
    description:description, 
  };

  const saveUpdateParametre= (e) => {
    e.preventDefault();  
    dispatch(onUpdateParametre(idParametre,upParametre));
    setTypMsg('upd') 
    setmodal_update(false);
    
  };


  /// Suppression d'une parametre
 

  const [listIdParametre, setListIdParametre] = useState([]);

  const idParametreChecBoxSelected = (parametreId) => {
    setListIdParametre(prevSelectedRows => ({
      ...prevSelectedRows,
      [parametreId]: !prevSelectedRows[parametreId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdParametre)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const parametreIds = Object.keys(filteredList);
 
  const deleteParametre = ( parametre) => { 
    if(parametre)dispatch(onDeleteParametre(parametre));
    setTypMsg('del')
    if(listIdParametre && parametreIds){parametreIds.forEach((parametreId) =>dispatch(onDeleteParametre(parametreId)))}
    setTypMsg('del')

  };
      


 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(parametres.parametres);


const dataTable= filteredData.length!==0 ? 
filteredData.map(parametre => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    //console.log('xcate',categorie)
    const user = Object.values(users.users).find(us => us.id === parametre.createdBy); 
    return { ...parametre , user: user? user.prenom+' '+user.nom :'',  };
  }) 
  : 
  parametres.parametres.map(parametre => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === prod.categorie);
    const user = Object.values(users.users).find(us => us.id === parametre.createdBy);
     return { ...parametre,user: user? user.prenom+' '+user.nom :'' ,  };
  });
//,categorieProd:categorie.libelle

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;

const handleSearch = (parametre) => {
    const value = parametre.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.code), _.toLower(value))||
      _.startsWith(_.toLower(item.valeur), _.toLower(value))|| 
      
      _.startsWith(_.toLower(item.description), _.toLower(value))|| 
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (parametre, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


 
const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((parametre,index) =>  (
    <tr key={parametre.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idParametreChecBoxSelected(parametre.id)}}   />
        </div>
    </th>
    <td className="parametre_id" style={{ display: "none" }}>{parametre.id}</td>
    <td className="parametre_index">{index+1}</td>
    <td className="libelle">{parametre.code}</td> 
    <td className="produit">{parametre.valeur}</td>
    <td className="produit">{parametre.description}</td>
    <td className="parametre_date">{ parametre.user}</td>
    <td className="parametre_utilisateur">{formatedDate(parametre.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setCode(parametre.code);setValeur(parametre.valeur);setDescription(parametre.description);setIdParametre(parametre.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteParametre(parametre.id)}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));

   useEffect(() => { 
        
        dispatch(onGetParametres());
        dispatch(onGetUsers()); 
        setFilteredData(parametres.parametres)
         
    },[dispatch,typMsg,onAddNewParametre,onUpdateParametre,onDeleteParametre]); 

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="Configuration" breadcrumbItem="Liste des configurations" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deleteParametre('');setListIdParametre('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="parametre_id" onClick={(parametre) => handleSort(parametre, 'id')}>N°</th>
                                                        <th className="sort" data-sort="code" onClick={(parametre) => handleSort(parametre, 'code')}>Code</th>
                                                        <th className="sort" data-sort="valeur" onClick={(parametre) => handleSort(parametre, 'valeur')}>Valeur</th> 
                                                        
                                                        <th className="sort" data-sort="descri^ption" onClick={(parametre) => handleSort(parametre, 'description')}>Description</th> 
                                                        <th className="sort" data-sort="createdBy" onClick={(parametre) => handleSort(parametre, 'createdBy')}>Crée par</th>
                                                        <th className="sort" data-sort="date" onClick={(parametre) => handleSort(parametre, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Parametre </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewParametre}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="code" className="form-label">Code</label>
                            <input type="text" id="code" className="form-control" placeholder="Enter Code" required  onChange={(e) => setCode(e.target.value)}/>
                            <label htmlFor="valeur" className="form-label">Valeur</label> 
                            <input type="text" id="valeur" className="form-control" placeholder="Enter Valeur" required  onChange={(e) => setValeur(e.target.value)}/>
                            <label htmlFor="description" className="form-label">Description</label> 
                            <input type="text" id="desc ription" className="form-control" placeholder="Enter Description"   onChange={(e) => setDescription(e.target.value)}/>
                           
                             

                        </div>  
                         
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewParametre}>Enregistrer</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Parametre </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateParametre}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="code" className="form-label">Code</label>
                            <input type="text" id="cdde" className="form-control" placeholder="Enter Code" required  value={code} onChange={(event) => setCode(event.target.value)}/>
                            <label htmlFor="valeur" className="form-label">Valeur</label> 
                            <input type="text" id="valeur" className="form-control" placeholder="Enter Valeur" value={valeur} required  onChange={(e) => setValeur(e.target.value)}/>
                            <label htmlFor="description" className="form-label">Description</label> 
                            <input type="text" id="desc ription" className="form-control" placeholder="Enter Description" value={description} onChange={(e) => setDescription(e.target.value)}/>
                           
                           
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateParametre}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Parametres;
