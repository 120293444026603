/* DEPENSE_SOUSCATEGORIES */
export const GET_DEPENSE_SOUSCATEGORIES = "GET_DEPENSE_SOUSCATEGORIES";
export const GET_DEPENSE_SOUSCATEGORIES_SUCCESS = "GET_DEPENSE_SOUSCATEGORIES_SUCCESS";
export const GET_DEPENSE_SOUSCATEGORIES_FAIL = "GET_DEPENSE_SOUSCATEGORIES_FAIL";

export const GET_DEPENSE_SOUSCATEGORIE = "GET_DEPENSE_SOUSCATEGORIE";
export const GET_DEPENSE_SOUSCATEGORIE_SUCCESS = "GET_DEPENSE_SOUSCATEGORIE_SUCCESS";
export const GET_DEPENSE_SOUSCATEGORIE_FAIL = "GET_DEPENSE_SOUSCATEGORIE_FAIL";

export const ADD_NEW_DEPENSE_SOUSCATEGORIE = "ADD_NEW_DEPENSE_SOUSCATEGORIE";
export const ADD_DEPENSE_SOUSCATEGORIE_SUCCESS = "ADD_DEPENSE_SOUSCATEGORIE_SUCCESS";
export const ADD_DEPENSE_SOUSCATEGORIE_FAIL = "ADD_DEPENSE_SOUSCATEGORIE_FAIL";

export const UPDATE_DEPENSE_SOUSCATEGORIE = "UPDATE_DEPENSE_SOUSCATEGORIE";
export const UPDATE_DEPENSE_SOUSCATEGORIE_SUCCESS = "UPDATE_DEPENSE_SOUSCATEGORIE_SUCCESS";
export const UPDATE_DEPENSE_SOUSCATEGORIE_FAIL = "UPDATE_DEPENSE_SOUSCATEGORIE_FAIL";

export const DELETE_DEPENSE_SOUSCATEGORIE = "DELETE_DEPENSE_SOUSCATEGORIE";
export const DELETE_DEPENSE_SOUSCATEGORIE_SUCCESS = "DELETE_DEPENSE_SOUSCATEGORIE_SUCCESS";
export const DELETE_DEPENSE_SOUSCATEGORIE_FAIL = "DELETE_DEPENSE_SOUSCATEGORIE_FAIL";

 