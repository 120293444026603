const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        
    },
    // {
    //     label: "Calendar",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/calendar",
    // },
    // {
    //     label: "Email",
    //     icon: "mdi mdi-email-outline",
    //     subItem: [
    //         { sublabel: "Inbox", link: "/inbox" },
    //         { sublabel: "Read Email", link: "/read-email" },
    //         { sublabel: "Email Compose", link: "/compose-email" },
    //     ],
    // },
    {
        label: "Gestions",
        isMainMenu: true,
    },
    {
        label: "Produits",
        icon: "mdi mdi-food-croissant",
        subItem: [
            { sublabel: "Categories produits", link: "/categories" },
            { sublabel: "Liste des produits", link: "/produits" },
 
        ],
    },

    {
        label: "Approvisionnements",
        icon: "mdi mdi-elevator",
        subItem: [
            { sublabel: "Fournisseurs", link: "/fournisseurs" },
            { sublabel: "Approvisionnements", link: "/Approvisionnements" },
            { sublabel: "Historiques Appro", link: "/histoAppro" },
 
        ],
    },

    {
        label: "Vente",
        icon: "mdi mdi-chart-line-variant",
        subItem: [
            { sublabel: "Vente", link: "/ventes" },
            { sublabel: "Autre Vente", link: "/autreVentes" },
            { sublabel: "Ventes Historiques", link: "/ventesHistoriques" },
 
        ],
    },
    {
        label: "Depense",
        icon: "mdi mdi-chart-timeline",
        subItem: [
            { sublabel: "Depenses", link: "/depenses" },
            { sublabel: "Depenses Categories", link: "/depensesCategories" },
            { sublabel: "Sous Categories", link: "/depensesSousCategories" },
            { sublabel: "Analyse", link: "/AnalyseDepenses" },
 
        ],
    },

    {
        label: "Pret",
        icon: "mdi mdi-chart-timeline",
        subItem: [
            { sublabel: "Prets", link: "/Prets" },
           
            
 
        ],
    },

    {
        label: "Salaire",
        icon: "mdi mdi-chart-timeline",
        subItem: [
            { sublabel: "Salaires", link: "/Salaires" },
            { sublabel: "Historique Salaires", link: "/HistoSalaires" },
            
 
        ],
    },



     
    {
        label: "Parametres",
        isMainMenu: true,
    },
    {
        label: "Cuves",
        icon: "mdi mdi-cup-water",
        subItem: [
            { sublabel: "Cuves", link: "/cuves" },
            // { sublabel: "Details Cuves", link: "/ui-alerts" },
            { sublabel: "Pompes", link: "/pompes" },

        ],
    },
    // {
    //     label: "Stock",
    //     icon: "ri-eraser-fill",
    //     issubMenubadge: true,
    //     bgcolor: "bg-danger",
    //     badgeValue: "8",
    //     subItem: [
    //         { sublabel: "Prix", link: "/ui-badge" },
    //         { sublabel: "Stock", link: "/stock" },
    //     ],
    // },
    {
        label: "Agents",
        icon: "mdi mdi-account-group",
        subItem: [
            { sublabel: "Equipes", link: "/equipes" }, 
            { sublabel: "Agents", link: "/agents" }, 

        ],
    },

    {
        label: "Fichiers",
        icon: "mdi mdi-download-network",
        subItem: [
            { sublabel: "Base données", link: "/bd" }, 
            

        ],
    },
    
    
    // {
    //     label: "Multi Level",
    //     icon: "ri-share-line",
    //     subItem: [
    //         { sublabel: "Level 1.1", link: "/#" },
    //         {
    //             sublabel: "Level 1.2", link: "/#",
    //             subMenu: [
    //                 { title: "Level 2.1" },
    //                 { title: "Level 2.2" },
    //             ],
    //         },
    //     ],
    // },


    

  
    {
        label: "Utilisateurs",
        isMainMenu: true,
    },
    {
        label: "Gestion Utilisateurs",
        icon: "mdi mdi-account-circle-outline",
        subItem: [
            // { sublabel: "Login", link: "/auth-login" },
            { sublabel: "Utilisateurs", link: "/utilisateurs" },
            // { sublabel: "Recover Password", link: "/auth-recoverpw" },
            // { sublabel: "Lock Screen", link: "/auth-lock-screen" },
        ],
    },
   
]
export default SidebarData;