import React, { useState, useEffect } from 'react'; 
import { FiArrowLeft } from 'react-icons/fi';

import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup ,FormGroup } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';
import Select  from 'react-select';

// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
 
    //Users
    addNewUser as onAddNewUser,
    deleteUser as onDeleteUser,
    getUsers as onGetUsers,
    getUser as onGetUser,
    updateUser as onUpdateUser,

    
     //Agents
     getAgents as onGetAgents,
      //equipes
    getEquipes as onGetEquipes,
 
  } from "../../store/actions"; 

  
 
const Users = () => {
const [typMsg, setTypMsg] = useState('');
const [passMsg,setPassMsg]=useState('');
const dispatch = useDispatch();
 //console.log("Document",Document) 

const users = useSelector((state) => ({  users:  state.users.users  }));
const equipes = useSelector((state) => ({  equipes:  state.equipes.equipes  }));
const agents = useSelector((state) => ({  agents:   Object.values(state.agents.agents).filter(a=>a.actif==1)  }));
 
const agentsEq = Object.values(agents.agents).map((agt) => { 
    const equipe = Object.values(equipes.equipes).find(eq=> eq.id === agt.equipe);
   
   return { ...agt , equipeInfo:equipe? equipe.libelle:'' };   
  });

const agntOptions = Object.values(agentsEq).map(agnt =>  ({
    value:agnt.id, label:'👷🏽'+agnt.nom+" "+agnt.prenom + ' 👥 '+ agnt.equipeInfo 
   }))

   agntOptions.unshift({ value: '', label: '' });

   /* const findAgentName  =(ag) => agntOptions.find(a => a.value===ag)
  console.log("name",findAgentName(20)) */
  //console.log("agntOptions",agntOptions.find(a => a.value===20).label)

const handleGoBack = () => {
    // Fonction pour revenir à la page précédente
    window.history.back();
  };


function alert() {

    setTimeout(() => {
      setTypMsg('');
    }, 12000);
  
  return (
    <>
    { typMsg==="add" ? (
      <Alert variant="success">
        Enregistrement effectué avec succès !
      </Alert> 
    ): typMsg==="upd" ? (
        <Alert color="info">
          Modification effectuée avec succès !
        </Alert> 
      ): typMsg==="del" ? (
        <Alert color="danger">
          Suppression effectuée avec succès !
        </Alert> 
      ):""}
  </>
     
  );
 }
 

 
    const [modal_list, setmodal_list] = useState(false);
    
    function tog_list() {
        setmodal_list(!modal_list);
    }

    const [modal_update, setmodal_update] = useState(false);
    
    function tog_update() {
        setmodal_update(!modal_update);
    }

    const [modal_delete, setmodal_delete] = useState(false);
    function tog_delete() {
        setmodal_delete(!modal_delete);
    }

     //message suppression
 const [isSupMsgOpen, setIsSupMsgOpen] = useState(false);

 const closeMsgSupPopup = () => {
    setIsSupMsgOpen(false);
  };
  
    
   


/// Enregistrements d'un compte
const [nom, setNom] = useState('');
const [prenom, setPrenom] = useState('');
const [email, setEmail] = useState('');
const [motdepasse, setMotdepasse] = useState('');
const [motdepasseConfirm, setMotdepasseConfirm] = useState('');
const [role, setRole] = useState('');
const [actif,setActif]=useState(true);
const [agnt, setAgnt] = useState('');
console.log('agnt',agnt)
 
 

// if(motdepasseConfirm && motdepasse!==motdepasseConfirm){
//     setPassMsg('mot')
    
    
// }
// console.log(' passMsg',passMsg)

const [idUser, setIdUser] = useState('');

const newUser = {
    nom: nom,
    prenom:prenom,
    email:email,
    motdepasse: motdepasse,
    role:role,  
    agent:agnt ,
    actif:actif,

};

const saveNewUser = (e) => {
    e.preventDefault(); 
    dispatch(onAddNewUser(newUser));
    setTypMsg('add')
    setmodal_list(false);
    
 
  };

  // Mise a jour d"un utilisateur 
  const upUser = { 
    nom: nom,
    prenom:prenom,
    email:email,
    motdepasse: motdepasse,
    role:role,   
    agent:agnt,
    actif:actif,
  };

  const saveUpdateUser= (e) => {
    e.preventDefault();  
    dispatch(onUpdateUser(idUser,upUser));
    setTypMsg('upd')
    setmodal_update(false);
    
  };


  /// Suppression d'un Utilisateur
 

  const [listIdUser, setListIdUser] = useState([]);

  const idUserChecBoxSelected = (userId) => {
    setListIdUser(prevSelectedRows => ({
      ...prevSelectedRows,
      [userId]: !prevSelectedRows[userId]
    }));
     
  };
 

  const filteredList = Object.entries(listIdUser)
  .filter(([key, value]) => value === true)
  .reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  
  const userIds = Object.keys(filteredList);
 
  const deleteUser = ( user) => { 
    if(user)dispatch(onDeleteUser(user));
    setTypMsg('del')
    if(listIdUser && userIds){userIds.forEach((userId) =>dispatch(onDeleteUser(userId)))}
    setTypMsg('del')

  };
      

  

 




/// section pour configurer la pagination

 
const [filteredData, setFilteredData] = useState(users.users);


const dataTable= filteredData.length!==0 ? 
filteredData.map(user => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === agent.categorie);
   // console.log('xcate agntOptions',agntOptions)
    const userC = Object.values(users.users).find(us => us.id === user.createdBy);
    const agentC =user.agent ? agntOptions.find(agnt => agnt.value==user.agent):'';
    
    return { ...user , agt:agentC? agentC.label:'', user: userC? userC.prenom+' '+userC.nom :'k' };
  }) 
  : 
  users.users.map(user => {
    //const categorie = Object.values(categories.categories).find(cat => cat.id === agent.categorie);
    const userC = Object.values(users.users).find(us => us.id === user.createdBy);
    const agentC =user.agent ? agntOptions.find(agnt => agnt.value===user.agent):'';
    return { ...user,agt:agentC? agentC.label:'',user: userC? userC.prenom+' '+userC.nom :'l' };
  });

//,categorieagent:categorie.libelle

const [currentPage, setCurrentPage] = useState(0);
const PER_PAGE = 10;
 

const handleSearch = (user) => {
    const value = user.target.value;
    const filtered = _.filter(dataTable, (item) =>
      _.startsWith(_.toLower(item.nom), _.toLower(value))||
      _.startsWith(_.toLower(item.prenom), _.toLower(value))|| 
      _.startsWith(_.toLower(item.email), _.toLower(value))|| 
      _.startsWith(_.toLower(item.motdepasse), _.toLower(value))|| 
      _.startsWith(_.toLower(item.role), _.toLower(value))||
      _.startsWith(_.toLower(item.agent), _.toLower(value))||
      _.startsWith(_.toLower(item.id), _.toLower(value))||
      _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
      _.startsWith(_.toLower(item.createdBy), _.toLower(value))
    );
    setFilteredData(filtered);
    setCurrentPage(0);
  };


const handleSort = (user, sortKey) => {
    const sorted = _.sortBy(dataTable, sortKey);
    setFilteredData(sorted);
    setCurrentPage(0);
  };


  const offset = currentPage * PER_PAGE;
const pageCount = Math.ceil(dataTable.length / PER_PAGE);


  
const currentPageData = dataTable.slice(offset, offset + PER_PAGE).map((us,index) =>  (
    <tr key={us.id}>
    <th scope="row">
        <div className="form-check">
            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idUserChecBoxSelected(us.id)}}   />
        </div>
    </th>
    <td className="user_id" style={{ display: "none" }}>{us.id}</td>
    <td className="agent_index">{index+1}</td>
    <td className="libelle">{us.nom}</td> 
    <td className="libelle">{us.prenom}</td> 
    <td className="sexe">{us.email}</td> 
    {/* <td className="libelle">{us.motdepasse}</td>   */}
    <td className="agent">{ us.agt}</td> 
    <td className="libelle">{us.role.toUpperCase()}</td> 
    <td className="user_actif">{us.actif==1? '✅' :'❌' } </td>
    
    {/* <td className="agentuit">{agent.agentuit ? Object.values(agentuits.agentuits).find(agent=>agent.id==agent.agentuit).nomagent:''}</td> */}
    {/* <td className="agent_date">{ us.user}</td> */}
    <td className="agent_utilisateur">{formatedDate(us.createdAt)}</td>
    <td>
        <div className="d-flex gap-2">
            <div className="edit">
                <button className="btn btn-sm btn-success edit-item-btn"
                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setNom(us.nom);setPrenom(us.prenom);setActif(us.actif);setEmail(us.email);setMotdepasse(us.motdepasse);setRole(us.role);setAgnt(us.agent);setIdUser(us.id);tog_update()} }>Modifier</button>
            </div>
            <div className="remove">
                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> {deleteUser(us.id)}}>Suppr.</button>
            </div>
        </div>
    </td>
</tr>


));

   useEffect(() => { 
        
         
        dispatch(onGetUsers()); 
        setFilteredData(users.users)
    },[dispatch,typMsg,onAddNewUser,onUpdateUser,onDeleteUser,]); 

   


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                    <FiArrowLeft /> Retour
                  </Button>
                    <Breadcrumbs title="agentuit" breadcrumbItem="Liste des Utilisateur" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                  
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => {tog_list();}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                    <Button color="soft-danger"  onClick={() => {deleteUser('');setListIdUser('')}}            
                                                    // onClick="deleteMultiple()"
                                                    ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                </div> 
                                            </Col>
                                            <Col>  {alert()}</Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                        <i className="mdi mdi-magnify search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="agent_id" onClick={(agent) => handleSort(agent, 'id')}>N°</th>
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'nom')}>Nom</th>
                                                        <th className="sort" data-sort="agentuit" onClick={(agent) => handleSort(agent, 'prenom')}>Prénom</th> 
                                                        
                                                        <th className="sort" data-sort="agentuit" onClick={(agent) => handleSort(agent, 'email')}>Email</th> 
                                                        {/* <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'motdepasse')}>Mot de passe</th>   */}
                                                        <th className="sort" data-sort="libelle" onClick={(agent) => handleSort(agent, 'agent')}>Agent</th> 
                                                        <th className="sort" data-sort="agent" onClick={(agent) => handleSort(agent, 'role')}>Role</th> 
                                                        <th className="sort" data-sort="actif" onClick={(agent) => handleSort(agent, 'actif')}>Actif</th> 
                                                        {/* <th className="sort" data-sort="createdBy" onClick={(agent) => handleSort(agent, 'createdBy')}>Crée par</th> */}
                                                        <th className="sort" data-sort="date" onClick={(agent) => handleSort(agent, 'createdAt')}>Date Création</th>
                                                    
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {currentPageData}
                                                   
                                                     
                                                </tbody>
                                            </Table>
                                            <ReactPaginate
                                                previousLabel={'Préc.'}
                                                nextLabel={'Suiv.'}
                                                breakLabel={'...'}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={(data) => setCurrentPage(data.selected)}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                                />
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            {/* <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                     
                </Container>
            </div>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Utilisateur </ModalHeader>
                <form className="tablelist-form" onSubmit={saveNewUser}>
                    <ModalBody> 

                        <div className="mb-3">
                            <label htmlFor="nom" className="form-label">Nom</label> 
                            <input type="text" id="nom" className="form-control" placeholder="Entrer Nom" required  onChange={(e) => setNom(e.target.value)}/>
                            <label htmlFor="prenom" className="form-label">Prénom</label>
                            <input type="text" id="prenom" className="form-control" placeholder="Entrer Prenom" required  onChange={(e) => setPrenom(e.target.value)}/>
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" id="email" className="form-control" placeholder="Entrer Email" required  onChange={(e) => setEmail(e.target.value)}/>
                           


                            <label htmlFor="motdepasse" className="form-label">Mot de Passe</label>
                            <input type="password" id="motdepasse" className="form-control" placeholder="Entrer Mot de passe" required  onChange={(e) => setMotdepasse(e.target.value)}/>
                            <label htmlFor="motdepasseConfirm" className="form-label">Confirmer Mot de Passe</label>
                            <input type="password" id="motdepasseConfirm" className="form-control" placeholder="Confirmer Mot de passe" required  onChange={(e) => setMotdepasseConfirm(e.target.value)}/><div style={{color:'red'}}>{motdepasseConfirm && motdepasse!==motdepasseConfirm ? 'Mot passe non identique':''}</div>
                            
                             <label htmlFor="role" className="form-label">Role</label>
                            <InputGroup>
                                <Input type="select" placeholder="Selectionnez un role"  onChange={(e) => setRole(e.target.value)}  >
                                <option value="">Sélectionnez un role</option> {/* Option vide par défaut */}
                                <option value="admin">ADMIN</option>v
                                <option value="carburantVendeur">CARBURANT VENDEUR</option> 
                                <option value="autreVendeur">AUTRE VENDEUR</option> 
                                <option value="carburantAutreVendeur">CARBURANT-AUTRE VENDEUR</option>
                              
                                </Input>
                            </InputGroup>
                            <label htmlFor="agntSelect">Sélectionner un agent</label>
                            <Select options={agntOptions}   onChange={(e) => setAgnt(e.value)}/>
                            <label htmlFor="equipe" className="form-label">Actif ?</label>
                            <FormGroup switch> 
                               <Input 
                                type="switch"
                                style={{ width: '30px' }} 
                                // defaultChecked
                                checked={actif==1}
                                onClick={() => {
                                    setActif(!actif);  
                                     
                                }}
                                 />
                            
                           </FormGroup>

                        </div>  
                         
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                            { motdepasseConfirm && motdepasse===motdepasseConfirm ?<button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewUser}>Enregistrer</button> :''}
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

             {/* Update Modal */}
             <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour de l'Utilisateur </ModalHeader>
                <form className="tablelist-form" onSubmit={saveUpdateUser}>
                    <ModalBody> 

                        <div className="mb-3">
                            
                            <label htmlFor="nom" className="form-label">Nom</label>
                            <input type="text" id="prenom" className="form-control" placeholder="Entrer Nom" required  value={nom} onChange={(event) => setNom(event.target.value)}/>
                            <label htmlFor="prenom" className="form-label">Prénom</label>
                            <input type="text" id="prenom" className="form-control" placeholder="Entrer Prenom" required  value={prenom} onChange={(event) => setPrenom(event.target.value)}/>
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" id="email" className="form-control" placeholder="Entrer Email" required  value={email} onChange={(event) => setEmail(event.target.value)}/>
                            
                            {/* <label htmlFor="motdepasse" className="form-label">Mot de Passe</label>
                            <input type="password" id="password" className="form-control" placeholder="Entrer Mot de passe" required  value={motdepasse} onChange={(event) => setMotdepasse(event.target.value)}/> */}
                            <label htmlFor="role" className="form-label">Role</label>
                            <InputGroup>
                                <Input type="select"  value={role} onChange={(e) => setRole(e.target.value)}  >
                                <option value="">Sélectionnez un role</option> {/* Option vide par défaut */}
                                <option value="admin">ADMIN</option>
                               
                                <option value="carburantVendeur">CARBURANT VENDEUR</option> 
                                <option value="autreVendeur">AUTRE VENDEUR</option> 
                                <option value="carburantAutreVendeur">CARBURANT-AUTRE VENDEUR</option>
                                </Input>
                            </InputGroup>
                            <label htmlFor="agntSelect">Sélectionner un agent</label>
                            <Select  options={agntOptions}  value={agnt ? agntOptions.find(a => a.value == agnt):''}  onChange={(e) => setAgnt(e.value)}/>

                            <label htmlFor="equipe" className="form-label">Actif ?</label>
                            <FormGroup switch> 
                               <Input 
                                type="switch"
                                style={{ width: '30px' }} 
                                checked={actif==1}
                   
                                onClick={() => {
                                    setActif(!actif);  
                                     
                                }}
                                 />
                            
                           </FormGroup>
                           </div>  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                            <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateUser}>Mettre à jour</button>
                            {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Remove Modal */}
            <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                <div className="modal-header">
                    <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                        <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={isSupMsgOpen} toggle={closeMsgSupPopup} centered  > 
                      <ModalHeader >Alerte: <span style={{color:'#235911' ,textAlign:'center', fontSize:'35px'}}> Etes-vous sur de vouloir supprimer ? </span></ModalHeader>
                      
                      <ModalBody >
                      <Button onClick={ ''}>Oui </Button><Button onClick={isSupMsgOpen? setIsSupMsgOpen(false):''}>Non </Button>
                      </ModalBody> 
                </Modal>
        </React.Fragment>
    );
};

export default Users;
