import {
  GET_DETAIL_CUVES_SUCCESS,
  GET_DETAIL_CUVES_FAIL,
  GET_DETAIL_CUVE_SUCCESS,
  GET_DETAIL_CUVE_FAIL,
  ADD_DETAIL_CUVE_SUCCESS,
  ADD_DETAIL_CUVE_FAIL,
  UPDATE_DETAIL_CUVE_SUCCESS,
  UPDATE_DETAIL_CUVE_FAIL,
  DELETE_DETAIL_CUVE_SUCCESS,
  DELETE_DETAIL_CUVE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  detailCuves: [],
  error: {},
};

const DetailCuves = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_CUVES_SUCCESS:
      return {
        ...state,
        detailCuves: action.payload,
      };

    case GET_DETAIL_CUVES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DETAIL_CUVE_SUCCESS:
      return {
        ...state,
        detailCuve: action.payload,
      };
  
    case GET_DETAIL_CUVE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_DETAIL_CUVE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        detailCuves: [...state.detailCuves, action.payload],
      };

    case ADD_DETAIL_CUVE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_DETAIL_CUVE_SUCCESS:
      return {
        ...state,
        detailCuves: state.detailCuves.map(detailCuve =>
          detailCuve.id.toString() === action.payload.id.toString()
            ? { detailCuve, ...action.payload }
            : detailCuve
        ),
        isDetailCuveUpdated: true
      };

    case UPDATE_DETAIL_CUVE_FAIL:
      return {
        ...state,
        error: action.payload,
        isDetailCuveUpdated: false
      };

    case DELETE_DETAIL_CUVE_SUCCESS:
      return {
        ...state,
        detailCuves: state.detailCuves.filter(
          detailCuve => detailCuve.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DETAIL_CUVE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default DetailCuves;
