/* DEPENSES */
export const GET_MODEREMBOURSEMENTS = "GET_MODEREMBOURSEMENTS";
export const GET_MODEREMBOURSEMENTS_SUCCESS = "GET_MODEREMBOURSEMENTS_SUCCESS";
export const GET_MODEREMBOURSEMENTS_FAIL = "GET_MODEREMBOURSEMENTS_FAIL";

export const GET_MODEREMBOURSEMENT = "GET_MODEREMBOURSEMENT";
export const GET_MODEREMBOURSEMENT_SUCCESS = "GET_MODEREMBOURSEMENT_SUCCESS";
export const GET_MODEREMBOURSEMENT_FAIL = "GET_MODEREMBOURSEMENT_FAIL";

export const ADD_NEW_MODEREMBOURSEMENT = "ADD_NEW_MODEREMBOURSEMENT";
export const ADD_MODEREMBOURSEMENT_SUCCESS = "ADD_MODEREMBOURSEMENT_SUCCESS";
export const ADD_MODEREMBOURSEMENT_FAIL = "ADD_MODEREMBOURSEMENT_FAIL";

export const UPDATE_MODEREMBOURSEMENT = "UPDATE_MODEREMBOURSEMENT";
export const UPDATE_MODEREMBOURSEMENT_SUCCESS = "UPDATE_MODEREMBOURSEMENT_SUCCESS";
export const UPDATE_MODEREMBOURSEMENT_FAIL = "UPDATE_MODEREMBOURSEMENT_FAIL";

export const DELETE_MODEREMBOURSEMENT = "DELETE_MODEREMBOURSEMENT";
export const DELETE_MODEREMBOURSEMENT_SUCCESS = "DELETE_MODEREMBOURSEMENT_SUCCESS";
export const DELETE_MODEREMBOURSEMENT_FAIL = "DELETE_MODEREMBOURSEMENT_FAIL";

 