import React from "react";
import { Link } from "react-router-dom";

//SimpleBar
import SimpleBar from "simplebar-react";


import { Card, CardBody, CardTitle, Col } from "reactstrap";


import { NotificationsData } from "../../CommonData/Data/index";
import ApproChartComponent from "../Charts/approCharts";

const Appro = () => {
  return (
    <React.Fragment>
      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Appro Comparés</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
                <ApproChartComponent op='0' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Appro Essence</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
              <ApproChartComponent op='5' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>


      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Appro Gas-Oil</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
              <ApproChartComponent op='4' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col lg={3}>
        <Card>
          <CardBody>
            <CardTitle>Appro Essence Moto</CardTitle>

            <div className="pe-3">
              <SimpleBar style={{ maxHeight: "287px" }}>
              <ApproChartComponent op='7' />
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default Appro;
