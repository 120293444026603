import React, { useState, useEffect } from 'react'; 

import { FiArrowLeft } from 'react-icons/fi';
import { Table,Alert,Button, Card, CardBody, CardHeader, Col, Container,  Modal, ModalBody, ModalFooter, Row, ModalHeader,Input, InputGroup  } from 'reactstrap';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import Breadcrumbs from "../../components/Common/Breadcrumb"; 
import Select  from 'react-select';
import { Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";
import formatedDate from '../../utilitaires/formatedDate';
import PDFGenerator from '../../utilitaires/PDFGenerator'; 


import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
 
// Import Flatepicker
import Flatpickr from "react-flatpickr";

import {
    //users

    getUsers as onGetUsers,

    //categories
    getDepenseCategories as onGetCategories,
    getDepenseSousCategories as onGetSousCategories,

    //depenses
    addNewDepense as onAddNewDepense,
    deleteDepense as onDeleteDepense,
    getDepenses as onGetDepenses,
    getDepense as onGetDepense,
    updateDepense as onUpdateDepense,
  } from "../../store/actions"; 

  
 
const Depenses = () => {

                    const d=new Date();
                    const today = new Date().toISOString().split('T')[0];
                    const dateDeb = new Date(d.setDate(d.getDate() -7)).toISOString().split('T')[0];


                    const [typMsg, setTypMsg] = useState('');
                    const [startDate, setStartDate] = useState(dateDeb);
                    const [endDate, setEndDate] = useState(today);

                    const [cat, setCat] = useState('');
                    const [sousCat, setSousCat] = useState('');
                    const [selectedCats, setSelectedCats] = useState([]);
                    const [selectedSousCats, setSelectedSousCats] = useState([]);
                    
                    const [deps, setDeps] = useState('');
                    const [selectedDeps, setSelectedDeps] = useState([]);

                    const dispatch = useDispatch();
                    //console.log("Document",Document)

                    //  const end = new Date(endDate );
                    //  end.setDate(end.getDate() + 1);  

                    
                    const depenses = useSelector((state) => ({  depenses: state.depenses.depenses  }));
                    const categories = useSelector((state) => ({  categories:  state.depenseCategories.depenseCategories  }));
                    const sousCategories = useSelector((state) => ({  sousCategories:  state.depenseSousCategories.depenseSousCategories  }));
                    const users = useSelector((state) => ({  users:  state.users.users  }));


                    const handleGoBack = () => {
                        // Fonction pour revenir à la page précédente
                        window.history.back();
                    };


                    /**option de filtre */
                    const catOptions=Object.values(categories.categories).map(cat => ({
                        value:cat.id, label:cat.libelle
                    }))
                    catOptions.unshift({ value: '', label: '' });

                    const sousCatOptions=Object.values(sousCategories.sousCategories).map(cat => ({
                        value:cat.id, label:cat.libelle,categorie:cat.depenseCategorie,
                    }))

                    sousCatOptions.unshift({ value: '', label: '', }); 
                    
                    //console.log("selectedCats",selectedCats)

                    const sousCatOptonFilter=sousCatOptions.filter(sCat=>selectedCats.length>=1? selectedCats.includes(sCat.categorie)===true : sCat.categorie===sCat.categorie)
                    //console.log("sousCatOptonFilter",sousCatOptonFilter)

                    const depsOptionsFilter = Object.values(depenses.depenses).filter(dp=> cat ? dp.categorie===cat:dp.categorie===dp.categorie);

                    const depsOptions=Object.values(depsOptionsFilter).map(dep => ({
                        value:dep.id, label:dep.libelle
                    }))
                    depsOptions.unshift({ value: '', label: '' });

                    /// lorsque l'utilisateur choisi une categorie
                    const handleChangeCat=(cat)=>{
                        setCat(cat.value) ;
                    }

                    const handleChangeSousCat=(cat)=>{
                    setSousCat(cat.value) 
                    
                    }   

 
            ///lorsque l'utilisateur choisi une depense
            const handleChangeDeps =(dps)=>{
            
                setDeps(dps.value)
            }

            const resetFiltre=()=>{
                setCat('')
                setSousCat('')
                setDeps('')
                setStartDate(dateDeb)
                setEndDate(today)
                //window.location.reload();
            }


                    function alert() {

                        setTimeout(() => {
                        setTypMsg('');
                        }, 12000);
                    
                    return (
                        <>
                        { typMsg==="add" ? (
                        <Alert variant="success">
                            Enregistrement effectué avec succès !
                        </Alert> 
                        ): typMsg==="upd" ? (
                            <Alert color="info">
                            Modification effectuée avec succès !
                            </Alert> 
                        ): typMsg==="del" ? (
                            <Alert color="danger">
                            Suppression effectuée avec succès !
                            </Alert> 
                        ):""}
                    </>
                        
                    );
                    }
 

        
            const [modal_list, setmodal_list] = useState(false);
            
            function tog_list() {
                setmodal_list(!modal_list);
            }

            const [modal_update, setmodal_update] = useState(false);
            
            function tog_update() {
                setmodal_update(!modal_update);
            }

            const [modal_delete, setmodal_delete] = useState(false);
            function tog_delete() {
                setmodal_delete(!modal_delete);
            }
    
   
    
            /// Enregistrements d'un depense
            const [libelle, setLibelle] = useState('');
            const [montant, setMontant] = useState('');
            const [dateDep, setDateDep] = useState(''); 
            const [categorie, setCategorie] = useState(''); 
            const [sousCategorie, setSousCategorie] = useState(''); 
            

            const [idDep, setIdDep] = useState(''); 

        const newDepense = {
            libelle: libelle,
            montant:montant,
            dateDep:dateDep,
            categorie:categorie,
            sousCategorie:sousCategorie,
        };  

            const saveNewDepense = (e) => {
                e.preventDefault(); 
                dispatch(onAddNewDepense(newDepense));
                setTypMsg('add')
                setmodal_list(false);
                
            
            };    

            // Mise a jour d"un Depense 
            const upDepense = { 
                libelle: libelle,
                montant:montant,
                dateDep:dateDep,    
                categorie:categorie,
                sousCategorie:sousCategorie,
            };

            const saveUpdateDepense = (e) => {
                e.preventDefault(); 
                dispatch(onUpdateDepense(idDep,upDepense));
                setTypMsg('upd')
                setmodal_update(false);
                
            };


            /// Suppression d'un Depense
            

            const [listIdDep, setListIdDep] = useState([]);

            const idDepChecBoxSelected = (depId) => {
                setListIdDep(prevSelectedRows => ({
                ...prevSelectedRows,
                [depId]: !prevSelectedRows[depId]
                }));
                
            };
            

            const filteredList = Object.entries(listIdDep)
            .filter(([key, value]) => value === true)
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

            
            const depIds = Object.keys(filteredList);
            
            const deleteDepense = ( depense) => { 
                
                if(depense)dispatch(onDeleteDepense(depense));
                setTypMsg('del')
                if(listIdDep && depIds){depIds.forEach((depId) =>dispatch(onDeleteDepense(depId)))}
                setTypMsg('del')

            };
                



 




            /// section pour configurer la pagination

 
            const [filteredData, setFilteredData] = useState(depenses.depenses);

            useEffect(() => {
                    
                // Fonction de recherche
                const searchData = () => {
                const filtered =  Object.values(depenses.depenses).filter((item) => {
                    const itemDate = new Date(item.dateDep);
                    const start = new Date(startDate);
                    const end = new Date(endDate );
                    // Ajouter 1 jour à la date de fin
                    end.setDate(end.getDate() + 1);  
            

                    return     selectedCats.length && selectedDeps.length  ? itemDate >= start && itemDate <= end && selectedCats.includes(item.categorie) && selectedSousCats.includes(item.sousCategorie) && selectedDeps.includes(item.id)
                            : selectedCats.length ? itemDate >= start && itemDate <= end && selectedCats.includes(item.categorie) 
                            : selectedSousCats.length ? itemDate >= start && itemDate <= end && selectedSousCats.includes(item.sousCategorie) 
                            : selectedDeps.length  ? itemDate >= start && itemDate <= end && selectedDeps.includes(item.id)
                            : itemDate >= start && itemDate <= end       

                            
                });

                setFilteredData(filtered); 
                
                };

                // Appeler la fonction de recherche lorsque les dates changent
                searchData();
            }, [d,dateDeb,startDate, endDate,selectedCats,selectedDeps,typMsg,onDeleteDepense,onUpdateDepense,onAddNewDepense]);

           // [d,dateDeb,startDate, endDate,selectedCats,selectedDeps,typMsg,onDeleteDepense,onUpdateDepense,onAddNewDepense]


 
                const dataTable= filteredData.length!==0 ? 
                filteredData.map(dep => {
                    const categorie = Object.values(categories.categories).find(cat => cat.id === dep.categorie);
                    const sousCategorie = Object.values(sousCategories.sousCategories).find(cat => cat.id === dep.sousCategorie);
                    //console.log('xcate',categorie)
                    const user = Object.values(users.users).find(us => us.id === dep.createdBy);
                    return { ...dep , user: user ? user.prenom+' '+user.nom :'',categorieInfo:categorie?categorie.libelle:'' ,sousCategorieInfo:sousCategorie?sousCategorie.libelle:''  };
                }) 
                : '';
                //,categoriedep:categorie.libelle

                const [currentPage, setCurrentPage] = useState(0);
                const PER_PAGE = 10;
 

                const handleSearch = (dep) => {
                    const value = dep.target.value;
                    const filtered = _.filter(dataTable, (item) =>
                    _.startsWith(_.toLower(item.libelle), _.toLower(value))||
                    _.startsWith(_.toLower(item.montant), _.toLower(value))||
                    _.startsWith(_.toLower(item.dateDep), _.toLower(value))|| 
                    _.startsWith(_.toLower(item.id), _.toLower(value))||
                    _.startsWith(_.toLower(item.createdAt), _.toLower(value))||
                    _.startsWith(_.toLower(item.createdBy), _.toLower(value))
                    );
                    setFilteredData(filtered);
                    setCurrentPage(0);
                };


            const handleSort = (dep, sortKey) => {
                const sorted = _.sortBy(dataTable, sortKey);
                setFilteredData(sorted);
                setCurrentPage(0);
            };


                     const offset = currentPage * PER_PAGE;
                    const pageCount = Math.ceil(dataTable.length / PER_PAGE);

 
            //generer le pdf
            const headText ='LISTE DES DEPENSES '
            const tableHead =[['N°','Date','Catégorie','Sous Catégorie', 'Libelle', 'Montant']]
            const tableD=Object.values(dataTable).map((dps)=>{ 
            //const agent = Object.values(agents.agents).find(ag => ag.id === vt.agent);
            const depense = Object.values(depenses.depenses).find(dep=> dep.id === dps.depense);
            const categorie = Object.values(categories.categories).find(cat => cat.id === dps.categorie);
            const sousCategorie = Object.values(sousCategories.sousCategories).find(cat => cat.id === dps.sousCategorie);
            return { ...dps,categorie: categorie? categorie.libelle:'',sousCategorie: sousCategorie? sousCategorie.libelle:'' };})

            const tableData= tableD.map((d, index) => [index+1, formatedDate(d.dateDep).slice(0,10), d.categorie,d.sousCategorie,d.libelle,parseFloat(d.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')]);



                 //interdiction des valeurs non numerique
            const handleKeyDown = (e) => {
            
                            const { key } = e;

                        if (!/\d/.test(key)  && key !== 'Backspace' ){
                        
                            if(key !== '.' ) {

                                if(key !== 'Tab') {
                                if(key !== 'ArrowRight'){
                                    if(key !== 'ArrowLeft') e.preventDefault() ;
                                }
                                }   
                            } 

                        }
            };


        const tableFoot=[['','','','TOTAL',parseFloat(tableD.reduce((acc, dp) => acc + dp.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') ]]





                const currentPageData =  dataTable.length>0? dataTable.slice(offset, offset + PER_PAGE).map((dep,index) =>  (
                    <tr key={index}   style={index % 2 === 0 ? { backgroundColor: "#E6F1FF",border: "2px solid #3282F6",color:'black',fontSize:'20px'} :{ backgroundColor: "#EBFFED" ,border: "2px solid #3282F6",color:'black',fontSize:'20px'}}>
                    <th scope="row" style={{ border: "2px solid #3282F6" }} >
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="chk_child" value="option1"  onChange={()=>{idDepChecBoxSelected(dep.id)}}   />
                        </div>
                    </th>
                    <td className="dep_id" style={{ display: "none" ,border: "2px solid #3282F6"}}>{dep.id}</td>
                    <td   className="dep_index">{index+1}</td>
                    <td style={{ border: "2px solid #3282F6" }}   className="dep_name">{formatedDate(dep.dateDep).slice(0,10)}</td> 
                    <td  style={{ border: "2px solid #3282F6" }}  className="dep_name">{dep.categorieInfo}</td>
                    <td  style={{ border: "2px solid #3282F6" }}  className="dep_name">{dep.sousCategorieInfo}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dep_name">{dep.libelle}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dep_name">{parseFloat(dep.montant).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dep_date">{ dep.user}</td>
                    <td style={{ border: "2px solid #3282F6" }}  className="dep_utilisateur">{formatedDate(dep.createdAt)}</td>
                    <td style={{ border: "2px solid #3282F6" }}  >
                        <div className="d-flex gap-2">
                            <div className="edit">
                                <button className="btn btn-sm btn-success edit-item-btn"
                                    data-bs-toggle="modal" data-bs-target="#showModal" onClick={() =>{setLibelle(dep.libelle);setMontant(dep.montant);setDateDep(new Date(dep.dateDep).toISOString().split('T')[0]);setCategorie(dep.categorie);setSousCategorie(dep.sousCategorie);setIdDep(dep.id);tog_update()} }>Modifier</button>
                            </div>
                            <div className="remove">
                                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={()=> deleteDepense(dep.id)}>Suppr.</button>
                            </div>
                        </div>
                    </td>
                </tr>


                )) :'';

            useEffect(() => { 
        
                    dispatch(onGetCategories());
                    dispatch(onGetSousCategories());
                    dispatch(onGetUsers());
                    dispatch(onGetDepenses());
                    setFilteredData(depenses.depenses)
                },[typMsg,onAddNewDepense,onUpdateDepense,onDeleteDepense]); //onAddNewDepense,onDeleteDepense,onUpdateDepense,Depenses

   


            return (
                <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Button color="warning"  size="sm"  outline onClick={handleGoBack}>
                        <FiArrowLeft /> Retour
                    </Button>
                        <Breadcrumbs title="Depense" breadcrumbItem="Les opérations de décaissement" />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    {/* <CardHeader>
                                        <h4 className="card-title mb-0">Ajout, Modification & Suppression</h4>
                                    
                                    </CardHeader> */}

                                    <CardBody>
                                        <div id="customerList">
                                            <Row className="g-4 mb-3">
                                                <Col className="col-sm-auto">
                                                    <div className="d-flex gap-1">
                                                        <Button color="success" className="add-btn" onClick={() => {tog_list();setMontant('')}} id="create-btn"><i className="mdi mdi-plus-circle-outline"></i> AJOUT</Button>
                                                        <Button color="soft-danger"  onClick={() => {deleteDepense('');setListIdDep('')}}            
                                                        // onClick="deleteMultiple()"
                                                        ><i className="mdi mdi-delete"></i> CORBEILLE</Button> 
                                                        <span></span> 
                                                        <PDFGenerator tableData={tableData} tableHead={tableHead} headText={headText} tableFoot={tableFoot} />
                                                        

                                                    </div> 
                                                
                                                </Col>
                                                
                                                        
                                                {/* <Col>  {alert()}</Col> */}
                                                <Col className="col-sm">
                                                    {/* <div className="d-flex justify-content-sm-end">
                                                        <div className="search-box ms-2">
                                                            <input type="text" className="form-control search" placeholder="Recherche..."  onChange={handleSearch}/>
                                                            <i className="mdi mdi-magnify search-icon"></i>
                                                        </div>
                                                    </div> */}
                                <span style={{color: '#000C7B', fontSize: '40px',fontFamily:"inherit",fontStyle:"normal",fontWeight:"bold", }}>LES OPERATIONS DE DECAISSEMENT</span>

                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col>
                                                <label htmlFor="catSelect">Sélectionner une categorie</label>
                                                {/* <Select defaultValue='' options={catOptions} onChange={handleChangeCat} /> */}
                                                <MultiSelect value={selectedCats} onChange={(e) => {setSelectedCats(e.value)}} defaultValue='' options={catOptions} optionLabel="label" 
                                                    filter placeholder="Choisir une catégorie" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                                </Col>
                                                <Col>
                                                <label htmlFor="catSelect">Sélectionner la sous categorie</label>
                                                {/* <Select defaultValue='' options={catOptions} onChange={handleChangeCat} /> */}
                                                <MultiSelect value={selectedSousCats} onChange={(e) => {setSelectedSousCats(e.value)}} defaultValue='' options={sousCatOptonFilter} optionLabel="label" 
                                                    filter placeholder="Choisir une sous catégorie" maxSelectedLabels={10} className="w-full md:w-20rem" />
                                                </Col>
                                                <Col>
                                                <label htmlFor="depSelect">Sélectionner une depense</label>
                                                {/* <Select defaultValue='' options={ depsOptions}    onChange={handleChangeDeps}/> */}
                                                <MultiSelect value={selectedDeps} onChange={(e) => {setSelectedDeps(e.value)}} defaultValue='' options={depsOptions} optionLabel="label" 
                                                    filter placeholder="Choisir une depense" maxSelectedLabels={10} className="w-full md:w-20rem" />

                                                </Col>
    
                                                <Col>
                                                <label htmlFor="startDate">Du</label> 
                                            
                                                <Input   type="date" value={startDate}  onChange={(e) => setStartDate(e.target.value)}  />
                                            
                                                </Col>
                                                    
                                                <Col> 
                                                <label htmlFor="endate">Au</label> 
                                            
                                                <Input  type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                                
                                                </Col> 
                                                <Col> 
                                                <label htmlFor="reset">Réinitialiser</label> 
                                                <div><Button color="primary" onClick={resetFiltre}>Annuler Fitre</Button> </div>  
                                                                            
                                                
                                                
                                                </Col>   
                                            </Row>

                                            <div className="table-responsive table-card mt-3 mb-1">
                                                <Table className="table align-middle table-nowrap" id="customerTable" hover>
                                                    <thead className="table-light">
                                                        <tr style={{ border: "2px solid #3282F6",color:"#3580BB" ,fontSize: "20px" }}>
                                                            <th scope="col" style={{border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4' , width: "50px" }}>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                                </div>
                                                            </th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4' }} className="sort" data-sort="customer_name" onClick={(dep) => handleSort(dep, 'id')}>N°</th>                                                
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="dateDep" onClick={(dep) => handleSort(dep, 'dateDep')}>Date depense</th>                                                         
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="categorie" onClick={(dep) => handleSort(dep, 'categorie')}>Categorie</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="sousCategorie" onClick={(dep) => handleSort(dep, 'sousCategorie')}>Sous Categorie</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="libelle" onClick={(dep) => handleSort(dep, 'libelle')}>Libelle</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="motant" onClick={(dep) => handleSort(dep, 'montant')}>Montant</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="createdBy" onClick={(dep) => handleSort(dep, 'createdBy')}>Crée par</th>
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}} className="sort" data-sort="date" onClick={(dep) => handleSort(dep, 'createdAt')}>Date Création</th>
                                                        
                                                            <th style={{ border: "2px solid #3282F6", backgroundColor:"#3A083E", color:'#F7F6F4'}}   className="sort" data-sort="action">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {currentPageData}
                                                    
                                                        
                                                    </tbody>
                                                </Table>
                                                <ReactPaginate
                                                    previousLabel={'Préc.'}
                                                    nextLabel={'Suiv.'}
                                                    breakLabel={'...'}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={(data) => setCurrentPage(data.selected)}
                                                    containerClassName={'pagination'}
                                                    activeClassName={'active'}
                                                    />
                                                <div style={{textAlign: 'left',fontSize:30,fontFamily:'monospace',fontStyle:'bold'}}> <h1>DEPENSES: <span style={{color: '#377E47'}}>{filteredData.length}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  MONTANT:  <span style={{color: '#0016BF'}}>{parseFloat(filteredData.reduce((acc, dp) => acc + dp.montant, 0)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span></h1></div>
                                                <div className="noresult" style={{ display: "none" }}>
                                                    <div className="text-center">
                                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                        </lord-icon>
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                            orders for you search.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                {/* <div className="pagination-wrap hstack gap-2">
                                                    <Link className="page-item pagination-prev disabled" to="#">
                                                        Previous
                                                    </Link>
                                                    <ul className="pagination listjs-pagination mb-0"></ul>
                                                    <Link className="page-item pagination-next" to="#">
                                                        Next
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        
                    </Container>
                </div>

                {/* Add Modal */}
                <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Ajouter Depense </ModalHeader>
                    <form className="tablelist-form" onSubmit={saveNewDepense}>
                        <ModalBody> 

                            <div className="mb-3">
                            <label htmlFor="dateDep" className="form-label">Date depense</label>
                                <input type="date"  id="dateDep" className="form-control" placeholder="Enter Date" required  onChange={(e) => setDateDep(e.target.value)}/>
                                <label htmlFor="categorie" className="form-label">categorie</label>
                            
                                <InputGroup>
                                    <Input type="select" onChange={(e) => setCategorie(e.target.value)}  defaultValue="0">
                                    <option value="">Sélectionnez une catégorie</option> {/* Option vide par défaut */}
                                    {Object.values(categories.categories).map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                        {cat.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>
                                <label htmlFor="categorie" className="form-label">sous Categorie</label>
                            
                                <InputGroup>
                                    <Input type="select" onChange={(e) => setSousCategorie(e.target.value)}  defaultValue="0">
                                    <option value="">Sélectionnez la sous  catégorie</option> {/* Option vide par défaut */}
                                    {Object.values(sousCategories.sousCategories)
                                    .filter(cat=>categorie ? cat.depenseCategorie===parseInt(categorie):'')
                                    .map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                        {cat.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>

                                <label htmlFor="libelle" className="form-label">Libelle</label>
                                <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  onChange={(e) => setLibelle(e.target.value)}/>
                            <label htmlFor="montant" className="form-label">Montant</label>
                                <input type="decimal"  id="montant" value={ montant  }  onKeyDown={handleKeyDown} className="form-control" placeholder="Enter montant" required  onChange={(e) => setMontant((e.target.value).replace(/ /g, '').replace(/,/g, ''))}/>
                
                            </div>  
                        
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Fermer</button>
                                <button type="submit" className="btn btn-success" id="add-btn"  onClick={() => saveNewDepense}>Enregistrer</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>

                {/* Update Modal */}
                <Modal isOpen={modal_update} toggle={() => { tog_update(); }} centered >
                    <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Mise à jour Depense </ModalHeader>
                    <form className="tablelist-form" onSubmit={saveUpdateDepense}>
                        <ModalBody> 

                            <div className="mb-3">
                            <label htmlFor="dateDep" className="form-label">Date depense</label>
                                <input type="date"    id="dateDep" className="form-control" placeholder="date depense" required   value={dateDep}   onChange={(event) => setDateDep(event.target.value)}/>
                                
                                <label htmlFor="categorie" className="form-label">categorie</label>
                                <InputGroup>
                                    <Input type="select" value={categorie} onChange={(e) => setCategorie(e.target.value)}  >
                                    <option value="">Sélectionnez une catégorie</option> {/* Option vide par défaut */}
                                    {Object.values(categories.categories).map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                        {cat.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>

                                <label htmlFor="sousCategorie" className="form-label">sous Categorie</label>
                                <InputGroup>
                                    <Input type="select" value={sousCategorie} onChange={(e) => setSousCategorie(e.target.value)}  >
                                    <option value="">Sélectionnez une catégorie</option> {/* Option vide par défaut */}
                                    {Object.values(sousCategories.sousCategories)
                                    .filter(cat=>categorie ? cat.depenseCategorie===parseInt(categorie):'')
                                    .map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                        {cat.libelle}
                                        </option>
                                    ))}
                                    </Input>
                                </InputGroup>


                                

                                
                                <label htmlFor="libelle" className="form-label">Libelle</label>
                                <input type="text" id="libelle" className="form-control" placeholder="Enter Libelle" required  value={libelle} onChange={(event) => setLibelle(event.target.value)}/>
                                <label htmlFor="montant" className="form-label">Montant</label>
                                <input type="decimal"   id="montant"  value={ montant } onKeyDown={handleKeyDown} className="form-control" placeholder="Montant" required     onChange={(e) => setMontant((e.target.value).replace(/ /g, '').replace(/,/g, ''))}/>
                                
                            </div>  
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={() => setmodal_update(false)}>Fermer</button>
                                <button type="submit" className="btn btn-success" id="add-btn"  onClick={() =>saveUpdateDepense}>Mettre à jour</button>
                                {/* <button type="button" className="btn btn-success" id="edit-btn">Update</button> */}
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>

                {/* Remove Modal */}
                <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn" id="deleteRecordModal" centered >
                    <div className="modal-header">
                        <Button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </Button>
                    </div>
                    <ModalBody>
                        <div className="mt-2 text-center">
                            {/* <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                                colors="primary:#f7b84b,secondary:#f06548" style={{ width: "100px", height: "100px" }}></lord-icon> */}
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Are you Sure ?</h4>
                                <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Close</button>
                            <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!</button>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>

            );
};

export default Depenses;
