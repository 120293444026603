import { takeEvery, put, call } from "redux-saga/effects"

// DetailCuves Redux States
import {
  ADD_NEW_DETAIL_CUVE,
  DELETE_DETAIL_CUVE,
  GET_DETAIL_CUVES,
  GET_DETAIL_CUVE,
  UPDATE_DETAIL_CUVE,
} from "./actionTypes"
import {
  getDetailCuvesSuccess,
  getDetailCuvesFail,
  getDetailCuveSuccess,
  getDetailCuveFail,
  addDetailCuveFail,
  addDetailCuveSuccess,
  updateDetailCuveSuccess,
  updateDetailCuveFail,
  deleteDetailCuveSuccess,
  deleteDetailCuveFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDetailCuves,
  getDetailCuve,
  addNewDetailCuve,
  updateDetailCuve,
  deleteDetailCuve,
} from "../../helpers/api_back"

function* fetchDetailCuves() {
  try {
    const response = yield call(getDetailCuves)
    yield put(getDetailCuvesSuccess(response))
  } catch (error) {
    yield put(getDetailCuvesFail(error))
  }
}

function* fetchDetailCuve() {
  try {
    const response = yield call(getDetailCuve)
    yield put(getDetailCuveSuccess(response))
  } catch (error) {
    yield put(getDetailCuveFail(error))
  }
}


function* onAddNewDetailCuve({ payload: detailCuve }) {
 
  try {
    const response = yield call(addNewDetailCuve, detailCuve)
    
    yield put(addDetailCuveSuccess(response))
  } catch (error) {
    yield put(addDetailCuveFail(error))
  }
}

function* onUpdateDetailCuve({ payload: detailCuve }) {
  try {
    const response = yield call(updateDetailCuve, detailCuve)
    yield put(updateDetailCuveSuccess(response))
  } catch (error) {
    yield put(updateDetailCuveFail(error))
  }
}

function* onDeleteDetailCuve({ payload: detailCuve }) {
  try {
    const response = yield call(deleteDetailCuve, detailCuve)
    yield put(deleteDetailCuveSuccess(response))
  } catch (error) {
    yield put(deleteDetailCuveFail(error))
  }
}
 

function* detailCuvesSaga() {
  yield takeEvery(GET_DETAIL_CUVES, fetchDetailCuves)
  yield takeEvery(GET_DETAIL_CUVE, fetchDetailCuve)
  yield takeEvery(ADD_NEW_DETAIL_CUVE, onAddNewDetailCuve)
  yield takeEvery(UPDATE_DETAIL_CUVE, onUpdateDetailCuve)
  yield takeEvery(DELETE_DETAIL_CUVE, onDeleteDetailCuve) 
}

export default detailCuvesSaga
