import {
  GET_CUVES,
  GET_CUVES_SUCCESS,
  GET_CUVES_FAIL,
  GET_CUVE,
  GET_CUVE_FAIL,
  GET_CUVE_SUCCESS,
  ADD_NEW_CUVE,
  ADD_CUVE_SUCCESS,
  ADD_CUVE_FAIL,
  UPDATE_CUVE,
  UPDATE_CUVE_SUCCESS,
  UPDATE_CUVE_FAIL,
  DELETE_CUVE,
  DELETE_CUVE_SUCCESS,
  DELETE_CUVE_FAIL,
} from "./actionTypes";

export const getCuves = () => ({
  type: GET_CUVES,
});

export const getCuvesSuccess = cuves => ({
  type: GET_CUVES_SUCCESS,
  payload: cuves,
});

export const getCuvesFail = error => ({
  type: GET_CUVES_FAIL,
  payload: error,
});

export const getCuve = () => ({
  type: GET_CUVE,
});

export const getCuveSuccess = cuve => ({
  type: GET_CUVE_SUCCESS,
  payload: cuve,
});

export const getCuveFail = error => ({
  type: GET_CUVE_FAIL,
  payload: error,
});

export const addNewCuve = cuve => ({
  type: ADD_NEW_CUVE,
  payload: cuve,
});

export const addCuveSuccess = cuve => ({
  type: ADD_CUVE_SUCCESS,
  payload: cuve,
});

export const addCuveFail = error => ({
  type: ADD_CUVE_FAIL,
  payload: error,
  
});

export const updateCuve = (cuv,cuve) => ({
  type: UPDATE_CUVE,
  payload: { id: cuv, data: cuve },
} );

export const updateCuveSuccess = (cuv,cuve) => ({
  type: UPDATE_CUVE_SUCCESS,
  payload:  { id: cuv, data: cuve }, 
});

export const updateCuveFail = error => ({
  type: UPDATE_CUVE_FAIL,
  payload: error,
});

export const deleteCuve = cuve => ({
  type: DELETE_CUVE,
  payload: cuve,
});

export const deleteCuveSuccess = cuve => ({
  type: DELETE_CUVE_SUCCESS,
  payload: cuve,
});

export const deleteCuveFail = error => ({
  type: DELETE_CUVE_FAIL,
  payload: error,
});



 
