/* APPROVISIONNEMENTS */
export const GET_APPROVISIONNEMENTS = "GET_APPROVISIONNEMENTS";
export const GET_APPROVISIONNEMENTS_SUCCESS = "GET_APPROVISIONNEMENTS_SUCCESS";
export const GET_APPROVISIONNEMENTS_FAIL = "GET_APPROVISIONNEMENTS_FAIL";

export const GET_APPROVISIONNEMENT = "GET_APPROVISIONNEMENT";
export const GET_APPROVISIONNEMENT_SUCCESS = "GET_APPROVISIONNEMENT_SUCCESS";
export const GET_APPROVISIONNEMENT_FAIL = "GET_APPROVISIONNEMENT_FAIL";

export const ADD_NEW_APPROVISIONNEMENT = "ADD_NEW_APPROVISIONNEMENT";
export const ADD_APPROVISIONNEMENT_SUCCESS = "ADD_APPROVISIONNEMENT_SUCCESS";
export const ADD_APPROVISIONNEMENT_FAIL = "ADD_APPROVISIONNEMENT_FAIL";

export const UPDATE_APPROVISIONNEMENT = "UPDATE_APPROVISIONNEMENT";
export const UPDATE_APPROVISIONNEMENT_SUCCESS = "UPDATE_APPROVISIONNEMENT_SUCCESS";
export const UPDATE_APPROVISIONNEMENT_FAIL = "UPDATE_APPROVISIONNEMENT_FAIL";

export const DELETE_APPROVISIONNEMENT = "DELETE_APPROVISIONNEMENT";
export const DELETE_APPROVISIONNEMENT_SUCCESS = "DELETE_APPROVISIONNEMENT_SUCCESS";
export const DELETE_APPROVISIONNEMENT_FAIL = "DELETE_APPROVISIONNEMENT_FAIL";

 