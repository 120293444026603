import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";

import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";


import {
 
 
  getEquipes as onGetEquipes,
  getProduits as onGetProduits,
  getUsers as onGetUsers,
  getAgents as onGetAgents,
  getPompes as onGetPompes,
  getVentes as onGetVentes,
  getCuves as onGetCuves,
  getCategories as onGetCategories,
  
} from "../../store/actions";

const FirstCirclesPanel = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => ({  categories: state.categories.categories  }));
  const produits = useSelector((state) => ({  produits: state.produits.produits  }));
  const agents = useSelector((state) => ({  agents: state.agents.agents  }));
  const pompes = useSelector((state) => ({  pompes: state.pompes.pompes  }));
  const cuves = useSelector((state) => ({  cuves: state.cuves.cuves  }));
  const equipes = useSelector((state) => ({  equipes: state.equipes.equipes  }));
  const users = useSelector((state) => ({  users: state.users.users  }));
  //const utilisateurs = useSelector((state) => ({  produits: state.utilisateurs.utilisateurs  }));

  useEffect(() => { 

    dispatch(onGetProduits());
   // dispatch(onGetUtilisateurs());
    dispatch(onGetAgents());
    dispatch(onGetPompes());
    dispatch(onGetCuves());
    dispatch(onGetUsers());
    dispatch(onGetEquipes())
    dispatch(onGetCategories())
     
},[dispatch]); //onAddNewProduit,onDeleteProduit,onUpdateProduit,produits

/// je comptes les produits par categories 
const prodCategoriesCounts = Object.values(produits.produits).reduce((counts, produit) => {
  const { categorie } = produit;
  if (!counts[categorie]) {
    counts[categorie] = 0;
  }
  counts[categorie]++;
  return counts;
}, {});

/// je comptes les agents par categories 
const agentCategoriesCounts = Object.values(agents.agents).reduce((counts, agent) => {
  const { role } = agent;
  if (!counts[role]) {
    counts[role] = 0;
  }
  counts[role]++;
  return counts;
}, {});

console.log('prodCategoriesCounts',prodCategoriesCounts)
console.log('utilisateur nb',users.users)
  
  return (
    <React.Fragment>
      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    {/* <RadialChart1 nb={(Object.keys(prodCategoriesCounts).length * 100)/produits.produits.length} /> */}
                     { <RadialChart1 nb={ 75} /> }
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Produits</p>
                  <h5 className="mb-3">{produits.produits.length}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      {Object.keys(prodCategoriesCounts).length}{" "}
                      <i className="mdi mdi-call-made align-bottom ms-1"></i>
                    </span>{" "}
                    types de catégories
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
          <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-2" className="apex-charts" dir="ltr">
                    <RadialChart2 nb={(Object.keys(pompes.pompes).length * 99)/agents.agents.length} />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Pompes</p>
                  <h5 className="mb-3">{pompes.pompes.length}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      {Object.keys(cuves.cuves).length}{" "}
                      <i className="mdi mdi-call-made align-bottom ms-1"></i>
                    </span>{" "}
                    Cuves
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
          <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart3 nb={(Object.keys(agentCategoriesCounts).length * 100)/agents.agents.length} />
                  </div>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Agents</p>
                  <h5 className="mb-3">{agents.agents.length}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      {Object.keys(agentCategoriesCounts).length}{" "}
                      <i className="mdi mdi-call-made align-bottom ms-1"></i>
                    </span>{" "}
                    types d'agents
                  </p>
                </div>
              </div>
            </CardBody>
            {/* <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                  />
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Bounce Rate</p>
                  <h5 className="mb-3">24.03 %</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-danger me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-down-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p>
                </div>
              </div>
            </CardBody> */}
          </Card>
        </Col>

        {/* <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-group-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">New Visitors</p>
                  <h5 className="mb-3">435</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      0.01%{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}
          <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="mdi mdi-account-supervisor-outline"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Utilisateurs</p>
                  <h5 className="mb-3">{Object.values(users.users).length}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      {Object.values(users.users).length*100/Object.values(agents.agents).length} %{" "}
                      <i className="mdi mdi-call-made"></i>
                    </span>{" "}
                    par rapport aux agents
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default FirstCirclesPanel;
