import {
  GET_POMPES,
  GET_POMPES_SUCCESS,
  GET_POMPES_FAIL,
  GET_POMPE_SUCCESS,
  GET_POMPE_FAIL,
  ADD_POMPE_SUCCESS,
  ADD_POMPE_FAIL,
  UPDATE_POMPE_SUCCESS,
  UPDATE_POMPE_FAIL,
  DELETE_POMPE_SUCCESS,
  DELETE_POMPE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  pompes: [],
  error: {},
};

const Pompes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POMPES:
      return {
        ...state, 
        loading: true,
        error: null,
      };
    case GET_POMPES_SUCCESS:
      return {
        ...state,
        pompes: action.payload,
      };

    case GET_POMPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_POMPE_SUCCESS:
      return {
        ...state,
        pompe: action.payload,
      };
  
    case GET_POMPE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_POMPE_SUCCESS:
     // console.log('Hello Sagas ok!');
      return {
        ...state,
        pompes: [...state.pompes, action.payload],
      };

    case ADD_POMPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_POMPE_SUCCESS:
      return {
        ...state,
        pompes: state.pompes.map(pompe =>
          pompe.id.toString() === action.payload.id.toString()
            ? { pompe, ...action.payload }
            : pompe
        ),
        isPompeUpdated: true
      };

    case UPDATE_POMPE_FAIL:
      return {
        ...state,
        error: action.payload,
        isPompeUpdated: false
      };

    case DELETE_POMPE_SUCCESS:
      return {
        ...state,
        pompes: state.pompes.filter(
          pompe => pompe.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_POMPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Pompes;
