import {
  GET_DEPENSE_CATEGORIES_SUCCESS,
  GET_DEPENSE_CATEGORIES_FAIL,
  GET_DEPENSE_CATEGORIE_SUCCESS,
  GET_DEPENSE_CATEGORIE_FAIL,
  ADD_DEPENSE_CATEGORIE_SUCCESS,
  ADD_DEPENSE_CATEGORIE_FAIL,
  UPDATE_DEPENSE_CATEGORIE_SUCCESS,
  UPDATE_DEPENSE_CATEGORIE_FAIL,
  DELETE_DEPENSE_CATEGORIE_SUCCESS,
  DELETE_DEPENSE_CATEGORIE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  depenseCategories: [],
  error: {},
};

const DepenseCategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPENSE_CATEGORIES_SUCCESS:
      return {
        ...state,
        depenseCategories: action.payload,
      };

    case GET_DEPENSE_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DEPENSE_CATEGORIE_SUCCESS:
      return {
        ...state,
        depenseCategorie: action.payload,
      };
  
    case GET_DEPENSE_CATEGORIE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_DEPENSE_CATEGORIE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        depenseCategories: [...state.depenseCategories, action.payload],
      };

    case ADD_DEPENSE_CATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_DEPENSE_CATEGORIE_SUCCESS:
      return {
        ...state,
        depenseCategories: state.depenseCategories.map(depenseCategorie =>
          depenseCategorie.id.toString() === action.payload.id.toString()
            ? { depenseCategorie, ...action.payload }
            : depenseCategorie
        ),
        isDepenseCategorieUpdated: true
      };

    case UPDATE_DEPENSE_CATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
        isDepenseCategorieUpdated: false
      };

    case DELETE_DEPENSE_CATEGORIE_SUCCESS:
      return {
        ...state,
        depenseCategories: state.depenseCategories.filter(
          depenseCategorie => depenseCategorie.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DEPENSE_CATEGORIE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default DepenseCategories;
