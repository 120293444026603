import React,{useEffect,useState}  from "react"; 
import { useSelector, useDispatch } from "react-redux";
//import UsePanel from "./UserPanel";
import FirstCirclesPanel from "./FirstCirclesPanel";
import FirstCirclesPanelRupture from "./FirstCirclesPanelRupture";

import ChartComponent from "../Charts/PetroCharts"

import OrderStatus from "./OrderStatus";
import Notifications from "./Notifications";
import SocialSource from "./SocialSource";
import OverView from "./OverView";  

import { Row, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
 
import logoLight from "../../assets/images/bg.jpg";
import SalesChart from '../../utilitaires/SalesChart';

import { getVentes as onGetVentes, getApprovisionnements as onGetApprovisionnements,
  getApprovisionnement as onGetApprovisionnement,} from "../../store/actions";  
import Appro from "./Appro";
import Vente from "./Vente";
import CA from "./CA";  
import NbVentesLines from "./NbVentesLines";
 


const Dashboard = () => {
  const dispatch = useDispatch();

 // verifie si le token est expirer et redire sur la page de connexion
const userObjet=JSON.parse(localStorage.getItem("authUser")); 
if(userObjet){
  const expirationDate = new Date(userObjet.exp*1000);

  if(expirationDate < new Date())  localStorage.removeItem('authUser');; 
} 


 const PETROFASO=localStorage.getItem("PETROFASO"); 


 if(PETROFASO==='start'){window.location.reload(); localStorage.setItem("PETROFASO", 'run');} 
 //console.log("PETROFASO DASH",PETROFASO)


  document.title = "Tableau de Bord | PETROFASO";

 
  //const ventes = useSelector((state) => ({  ventes: state.ventes.ventes })); 
  //const approvisionnements = useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements  }));
 
  useEffect(() => {  
    dispatch(onGetVentes()); 
    dispatch(onGetApprovisionnements()); 
    dispatch(onGetApprovisionnement()); 
     
},[dispatch]);  
//console.log("Ventes", ventes,'approvisionnements', approvisionnements);
// const approvisionnements = useSelector((state) => ({  approvisionnements: state.approvisionnements.approvisionnements  }));
//  console.log("approvisionnements",approvisionnements)
  //les donnees du charts
 // const [fuelSalesData, setFuelSalesData] = useState([]);

  // Exemple de données de ventes par mois et par type de carburant (à remplacer par votre source de données)
  // const fetchFuelSalesData = async () => {
  //   try {
  //     // Ici, vous devriez effectuer un appel à votre API ou base de données pour obtenir les données
  //     const response = await fetch('votre_endpoint_api');
  //     const data = await response.json();
  //     setFuelSalesData(data); // Mettre à jour les données d'État
  //   } catch (error) {
  //     console.error('Erreur lors de la récupération des données de ventes', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchFuelSalesData();
  // }, []);

  
   
 //localstorage de l'annee en cours
  const date = new Date();
  const anneeEnCours = date.getFullYear();
  localStorage.setItem('anneeEnCours', anneeEnCours.toString());

 
  const userConn = JSON.parse(localStorage.getItem("authUser")).utilisateur;

  return (
    <React.Fragment>
      <div className="page-content">
        {userConn.role==="admin" ?  <Container fluid={true}>
          <Breadcrumbs title="PRETOFASO" breadcrumbItem="Tableau de Bord" />
          {/*  <UsePanel /> User Panel Charts */}
          {/* Premier Panneau des stats sous forme de cercle */}
          <FirstCirclesPanel/>
          <span>Rupture de stock</span>
          <FirstCirclesPanelRupture/>
        
          <Row>
           
            <OverView />
             <NbVentesLines/>
            {/* <SocialSource /> */}
          </Row>

          <Row>
          <Appro />
            {/* <OrderStatus /> */}
           
            {/* <Notifications /> */}
          </Row>
          <Row>
          <Vente /> 
          </Row>
          <Row>
          <CA /> 
          </Row>

          {/* Latest Transaction Table */}
          {/* <LatestTransation /> */}
          {/* <div style={{ display: 'flex', alignItems: 'center' ,marginLeft: '10px' }}>
                <span className="logo-lg">
                <img src={logoLight} alt="logo-light" height="600" width='100%' />
                </span>
                    
                  <div>
                    <h2>Chiffre d'Affaire Mensuel</h2>
                    <ChartComponent />
                    <SalesChart fuelSalesData={fuelSalesData} />
                  </div>
                      
          
          </div> */}
       
         
        </Container> :
         <Container fluid={true}>
         <Breadcrumbs title="PRETOFASO" breadcrumbItem="Tableau de Bord" />
         

         
         <div style={{ display: 'flex', alignItems: 'center' ,marginLeft: '10px' }}>
               <span className="logo-lg">
               <img src={logoLight} alt="logo-light" height="600" width='100%' />
               </span>
     
         </div>
      
        
       </Container> }
       

      </div>
    </React.Fragment>
  );
};

export default Dashboard;
