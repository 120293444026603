import {
  GET_PRODUITS,
  GET_PRODUITS_SUCCESS,
  GET_PRODUITS_FAIL,
  GET_PRODUIT_SUCCESS,
  GET_PRODUIT_FAIL,
  ADD_PRODUIT_SUCCESS,
  ADD_PRODUIT_FAIL,
  UPDATE_PRODUIT_SUCCESS,
  UPDATE_PRODUIT_FAIL,
  DELETE_PRODUIT_SUCCESS,
  DELETE_PRODUIT_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  produits: [],
  loading: false,
  error: {},
};

const Produits = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUITS:
      return {
        ...state, 
        loading: true,
        error: null,
      };

      case GET_PRODUITS_SUCCESS:
        return {
          ...state,
          loading: false,
          produits: action.payload,
        };

    case GET_PRODUITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_PRODUIT_SUCCESS:
      return {
        ...state,
        loading: false,
        produits: action.payload,
      };
  
    case GET_PRODUIT_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_PRODUIT_SUCCESS:
    //  console.log('Hello Sagas ok!');
      return {
        ...state,
        produits: [...state.produits, action.payload],
      };

    case ADD_PRODUIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PRODUIT_SUCCESS:
      return {
        ...state,
        produits: state.produits.map(produit =>
          produit.id.toString() === action.payload.id.toString()
            ? { produit, ...action.payload }
            : produit
        ),
        isPRODUITUpdated: true
      };

    case UPDATE_PRODUIT_FAIL:
      return {
        ...state,
        error: action.payload,
        isProduitUpdated: false
      };

    case DELETE_PRODUIT_SUCCESS:
      return {
        ...state,
        produits: state.produits.filter(
          produit => produit.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PRODUIT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Produits;
