/* DEMANDES */
export const GET_DEMANDES = "GET_DEMANDES";
export const GET_DEMANDES_SUCCESS = "GET_DEMANDES_SUCCESS";
export const GET_DEMANDES_FAIL = "GET_DEMANDES_FAIL";

export const GET_DEMANDE = "GET_DEMANDE";
export const GET_DEMANDE_SUCCESS = "GET_DEMANDE_SUCCESS";
export const GET_DEMANDE_FAIL = "GET_DEMANDE_FAIL";

export const ADD_NEW_DEMANDE = "ADD_NEW_DEMANDE";
export const ADD_DEMANDE_SUCCESS = "ADD_DEMANDE_SUCCESS";
export const ADD_DEMANDE_FAIL = "ADD_DEMANDE_FAIL";

export const UPDATE_DEMANDE = "UPDATE_DEMANDE";
export const UPDATE_DEMANDE_SUCCESS = "UPDATE_DEMANDE_SUCCESS";
export const UPDATE_DEMANDE_FAIL = "UPDATE_DEMANDE_FAIL";

export const DELETE_DEMANDE = "DELETE_DEMANDE";
export const DELETE_DEMANDE_SUCCESS = "DELETE_DEMANDE_SUCCESS";
export const DELETE_DEMANDE_FAIL = "DELETE_DEMANDE_FAIL";

 