import { takeEvery, put, call } from "redux-saga/effects"

// Demandes Redux States
import {
  ADD_NEW_DEMANDE,
  DELETE_DEMANDE,
  GET_DEMANDES,
  GET_DEMANDE,
  UPDATE_DEMANDE,
} from "./actionTypes"
import {
  getDemandesSuccess,
  getDemandesFail,
  getDemandeSuccess,
  getDemandeFail,
  addDemandeFail,
  addDemandeSuccess,
  updateDemandeSuccess,
  updateDemandeFail,
  deleteDemandeSuccess,
  deleteDemandeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getDemandes,
  getDemande,
  addNewDemande,
  updateDemande,
  deleteDemande,
} from "../../helpers/api_back"

function* fetchDemandes() {
  try {
    const response = yield call(getDemandes)
    yield put(getDemandesSuccess(response))
  } catch (error) {
    yield put(getDemandesFail(error))
  }
}

function* fetchDemande() {
  try {
    const response = yield call(getDemande)
    yield put(getDemandeSuccess(response))
  } catch (error) {
    yield put(getDemandeFail(error))
  }
}


function* onAddNewDemande({ payload: demande }) {
 
  try {
    const response = yield call(addNewDemande, demande)
    
    yield put(addDemandeSuccess(response))
  } catch (error) {
    yield put(addDemandeFail(error))
  }
}

function* onUpdateDemande({ payload: demande }) {
  try {
    const response = yield call(updateDemande, demande)
    yield put(updateDemandeSuccess(response))
  } catch (error) {
    yield put(updateDemandeFail(error))
  }
}

function* onDeleteDemande({ payload: demande }) {
  try {
    const response = yield call(deleteDemande, demande)
    yield put(deleteDemandeSuccess(response))
  } catch (error) {
    yield put(deleteDemandeFail(error))
  }
}
 

function* demandesSaga() {
  yield takeEvery(GET_DEMANDES, fetchDemandes)
  yield takeEvery(GET_DEMANDE, fetchDemande)
  yield takeEvery(ADD_NEW_DEMANDE, onAddNewDemande)
  yield takeEvery(UPDATE_DEMANDE, onUpdateDemande)
  yield takeEvery(DELETE_DEMANDE, onDeleteDemande) 
}

export default demandesSaga
