import {
  GET_DEPENSE_SOUSCATEGORIES,
  GET_DEPENSE_SOUSCATEGORIES_SUCCESS,
  GET_DEPENSE_SOUSCATEGORIES_FAIL,
  GET_DEPENSE_SOUSCATEGORIE,
  GET_DEPENSE_SOUSCATEGORIE_FAIL,
  GET_DEPENSE_SOUSCATEGORIE_SUCCESS,
  ADD_NEW_DEPENSE_SOUSCATEGORIE,
  ADD_DEPENSE_SOUSCATEGORIE_SUCCESS,
  ADD_DEPENSE_SOUSCATEGORIE_FAIL,
  UPDATE_DEPENSE_SOUSCATEGORIE,
  UPDATE_DEPENSE_SOUSCATEGORIE_SUCCESS,
  UPDATE_DEPENSE_SOUSCATEGORIE_FAIL,
  DELETE_DEPENSE_SOUSCATEGORIE,
  DELETE_DEPENSE_SOUSCATEGORIE_SUCCESS,
  DELETE_DEPENSE_SOUSCATEGORIE_FAIL,
} from "./actionTypes";

export const getDepenseSousCategories = () => ({
  type: GET_DEPENSE_SOUSCATEGORIES,
});

export const getDepenseSousCategoriesSuccess = depenseSousCategories => ({
  type: GET_DEPENSE_SOUSCATEGORIES_SUCCESS,
  payload: depenseSousCategories,
});

export const getDepenseSousCategoriesFail = error => ({
  type: GET_DEPENSE_SOUSCATEGORIES_FAIL,
  payload: error,
});

export const getDepenseSousCategorie = () => ({
  type: GET_DEPENSE_SOUSCATEGORIE,
});

export const getDepenseSousCategorieSuccess = depenseSousCategorie => ({
  type: GET_DEPENSE_SOUSCATEGORIE_SUCCESS,
  payload: depenseSousCategorie,
});

export const getDepenseSousCategorieFail = error => ({
  type: GET_DEPENSE_SOUSCATEGORIE_FAIL,
  payload: error,
});

export const addNewDepenseSousCategorie = depenseSousCategorie => ({
  type: ADD_NEW_DEPENSE_SOUSCATEGORIE,
  payload: depenseSousCategorie,
});

export const addDepenseSousCategorieSuccess = depenseSousCategorie => ({
  type: ADD_DEPENSE_SOUSCATEGORIE_SUCCESS,
  payload: depenseSousCategorie,
});

export const addDepenseSousCategorieFail = error => ({
  type: ADD_DEPENSE_SOUSCATEGORIE_FAIL,
  payload: error,
  
});

export const updateDepenseSousCategorie = (dep,depenseSousCategorie) => ({
  type: UPDATE_DEPENSE_SOUSCATEGORIE,
  payload:  { id: dep, data: depenseSousCategorie },
});

export const updateDepenseSousCategorieSuccess = depenseSousCategorie => ({
  type: UPDATE_DEPENSE_SOUSCATEGORIE_SUCCESS,
  payload: depenseSousCategorie,
});

export const updateDepenseSousCategorieFail = error => ({
  type: UPDATE_DEPENSE_SOUSCATEGORIE_FAIL,
  payload: error,
});

export const deleteDepenseSousCategorie = depenseSousCategorie => ({
  type: DELETE_DEPENSE_SOUSCATEGORIE,
  payload: depenseSousCategorie,
});

export const deleteDepenseSousCategorieSuccess = depenseSousCategorie => ({
  type: DELETE_DEPENSE_SOUSCATEGORIE_SUCCESS,
  payload: depenseSousCategorie,
});

export const deleteDepenseSousCategorieFail = error => ({
  type: DELETE_DEPENSE_SOUSCATEGORIE_FAIL,
  payload: error,
});



 
