import {
  GET_POMPES,
  GET_POMPES_SUCCESS,
  GET_POMPES_FAIL,
  GET_POMPE,
  GET_POMPE_FAIL,
  GET_POMPE_SUCCESS,
  ADD_NEW_POMPE,
  ADD_POMPE_SUCCESS,
  ADD_POMPE_FAIL,
  UPDATE_POMPE,
  UPDATE_POMPE_SUCCESS,
  UPDATE_POMPE_FAIL,
  DELETE_POMPE,
  DELETE_POMPE_SUCCESS,
  DELETE_POMPE_FAIL,
} from "./actionTypes";

export const getPompes = () => ({
  type: GET_POMPES,
});

export const getPompesSuccess = pompes => ({
  type: GET_POMPES_SUCCESS,
  payload: pompes,
});

export const getPompesFail = error => ({
  type: GET_POMPES_FAIL,
  payload: error,
});

export const getPompe = () => ({
  type: GET_POMPE,
});

export const getPompeSuccess = pompe => ({
  type: GET_POMPE_SUCCESS,
  payload: pompe,
});

export const getPompeFail = error => ({
  type: GET_POMPE_FAIL,
  payload: error,
});

export const addNewPompe = pompe => ({
  type: ADD_NEW_POMPE,
  payload: pompe,
});

export const addPompeSuccess = pompe => ({
  type: ADD_POMPE_SUCCESS,
  payload: pompe,
});

export const addPompeFail = error => ({
  type: ADD_POMPE_FAIL,
  payload: error,
  
});

export const updatePompe = (pmp,pompe) => ({
  type: UPDATE_POMPE,
  payload:  { id: pmp, data: pompe },
});

export const updatePompeSuccess = (pmp,pompe) => ({
  type: UPDATE_POMPE_SUCCESS,
  payload:  { id: pmp, data: pompe },
});

export const updatePompeFail = error => ({
  type: UPDATE_POMPE_FAIL,
  payload: error,
});

export const deletePompe = pompe => ({
  type: DELETE_POMPE,
  payload: pompe,
});

export const deletePompeSuccess = pompe => ({
  type: DELETE_POMPE_SUCCESS,
  payload: pompe,
});

export const deletePompeFail = error => ({
  type: DELETE_POMPE_FAIL,
  payload: error,
});



 
