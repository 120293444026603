/* CUVES */
export const GET_CUVES = "GET_CUVES";
export const GET_CUVES_SUCCESS = "GET_CUVES_SUCCESS";
export const GET_CUVES_FAIL = "GET_CUVES_FAIL";

export const GET_CUVE = "GET_CUVE";
export const GET_CUVE_SUCCESS = "GET_CUVE_SUCCESS";
export const GET_CUVE_FAIL = "GET_CUVE_FAIL";

export const ADD_NEW_CUVE = "ADD_NEW_CUVE";
export const ADD_CUVE_SUCCESS = "ADD_CUVE_SUCCESS";
export const ADD_CUVE_FAIL = "ADD_CUVE_FAIL";

export const UPDATE_CUVE = "UPDATE_CUVE";
export const UPDATE_CUVE_SUCCESS = "UPDATE_CUVE_SUCCESS";
export const UPDATE_CUVE_FAIL = "UPDATE_CUVE_FAIL";

export const DELETE_CUVE = "DELETE_CUVE";
export const DELETE_CUVE_SUCCESS = "DELETE_CUVE_SUCCESS";
export const DELETE_CUVE_FAIL = "DELETE_CUVE_FAIL";

 