import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2'; 
import { useSelector, useDispatch } from "react-redux";

// Importez les fonctions de regroupement de tableau
import { groupBy } from 'lodash';

const ChartComponent = () => {

    const ventes = useSelector((state) => ({  ventes: state.ventes.ventes }));
   //console.log("ventes",Object.values(ventes.ventes))

    // regroupement des ventes par produit,mois et par annéee
    const ventesParProduitEtMois = groupBy(Object.values(ventes.ventes), vente => {
        //console.log("vente.dateEnregistrement",vente)
        const date = new Date(vente.dateEnregistrement);
        //console.log('date',date)
        const mois = date.getMonth() + 1; // Les mois sont indexés à partir de 0
        //console.log('mois',mois)
        const annee = date.getFullYear();
        //console.log('annee',annee)
        // return `${vente.produit}-${mois}-${annee}`;
        return `${vente.produit}-${mois}-${annee}`;
    });
     
    //console.log("ventesParProduitEtMois",ventesParProduitEtMois)

    // calucle des des quantités ventes du par prduits,mois et années
    const quantiteProduitMois={}
    for (const key in ventesParProduitEtMois) {
        const ventesGroupees = ventesParProduitEtMois[key];
        const quantite= ventesGroupees.reduce((acc, vente) => acc + vente.quantiteReel, 0);
       // console.log(`Quantités des ventes pour ${key}: ${quantite}`);
       //parseFloat(quantite).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
       quantiteProduitMois[key]=quantite
      }

    /**** dermination des quandité par produit des differents mois de l'annee*/
    const essence=Object.keys(quantiteProduitMois).filter(key => key.startsWith("5")).map(key => ({ [key]: quantiteProduitMois[key] }));;
        //const esJuin = Object.values(essence).find(obj => Object.keys(obj)[0].includes('-6-'));
    const gazoil=Object.keys(quantiteProduitMois).filter(key => key.startsWith("4")).map(key => ({ [key]: quantiteProduitMois[key] }));;
     
    const essenceMoto=Object.keys(quantiteProduitMois).filter(key => key.startsWith("7")).map(key => ({ [key]: quantiteProduitMois[key] }));;
     

            const MoisValue=(ob,p)=>{
                const ObjetMois = ob.map(obj => {
                    const ancienneCle = Object.keys(obj)[0];
                    const mois = ancienneCle.split('-')[1]; // Obtient le mois
                    const valeur = Object.values(obj)[0];
                    
                    return { [mois]: valeur };
                  });

                const objetMoisRecherche = ObjetMois.find(obj => Object.keys(obj)[0] === p);

                if (objetMoisRecherche) {
                const valeurMoisRecherche = Object.values(objetMoisRecherche)[0];
                //console.log(`La valeur pour le mois ${6} est : ${valeurMoisRecherche}`);
                return parseInt(valeurMoisRecherche)
                } else {
                //console.log(`Aucun objet trouvé pour le mois ${6}`);
                return 0
                }

            }
            
            //console.log(MoisValue(essence,'7'))



          //console.log("essenceMois",essenceMois);

    //console.log("esJuin",`${Object.values(esJuin)[0]}`)
   /// console.log('KKOn',Object.values(Object.values(essence).find(obj => Object.keys(obj)[0].includes('-6-')))[0])
  //console.log('essence',essence)
 

 
  
 

    

        ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
        );

        const options = {
        responsive: true,
        plugins: {
            legend: {
            position: 'top' ,
            },
            title: {
            display: true,
            text: "Quantité Vendue par mois",
            },
        },
        };

       
        
        const data = {
        labels : ['Janvier', ' Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        datasets: [
            {
            label: 'Essence',
            data: [MoisValue(essence,'1'), MoisValue(essence,'2') , MoisValue(essence,'3'), MoisValue(essence,'4'), MoisValue(essence,'5'),  MoisValue(essence,'6'), MoisValue(essence,'7'),MoisValue(essence,'8'),MoisValue(essence,'9'),MoisValue(essence,'10'),MoisValue(essence,'11'),MoisValue(essence,'12')],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
            label: 'Gazoil',
            data:[MoisValue(gazoil,'1'), MoisValue(gazoil,'2') , MoisValue(gazoil,'3'), MoisValue(gazoil,'4'), MoisValue(gazoil,'5'),  MoisValue(gazoil,'6'), MoisValue(gazoil,'7'),MoisValue(gazoil,'8'),MoisValue(gazoil,'9'),MoisValue(gazoil,'10'),MoisValue(gazoil,'11'),MoisValue(gazoil,'12')],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Essence Moto',
                data:[MoisValue(essenceMoto,'1'), MoisValue(essenceMoto,'2') , MoisValue(essenceMoto,'3'), MoisValue(essenceMoto,'4'), MoisValue(essenceMoto,'5'),  MoisValue(essenceMoto,'6'), MoisValue(essenceMoto,'7'),MoisValue(essenceMoto,'8'),MoisValue(essenceMoto,'9'),MoisValue(essenceMoto,'10'),MoisValue(essenceMoto,'11'),MoisValue(essenceMoto,'12')],
                backgroundColor: 'rgba(255, 100, 235, 0.5)',
            },
        ],
        };

        return (
            <div>
              <h2>Bar</h2>
              <Bar options={options} data={data} /> 
            </div>
          );

        // export function App() {
        // return <Bar options={options} data={data} />;
        // }
}

export default ChartComponent