import {
  GET_SALAIRES,
  GET_SALAIRES_SUCCESS,
  GET_SALAIRES_FAIL,
  GET_SALAIRE,
  GET_SALAIRE_FAIL,
  GET_SALAIRE_SUCCESS,
  ADD_NEW_SALAIRE,
  ADD_SALAIRE_SUCCESS,
  ADD_SALAIRE_FAIL,
  UPDATE_SALAIRE,
  UPDATE_SALAIRE_SUCCESS,
  UPDATE_SALAIRE_FAIL,
  DELETE_SALAIRE,
  DELETE_SALAIRE_SUCCESS,
  DELETE_SALAIRE_FAIL,
} from "./actionTypes";

export const getSalaires = () => ({
  type: GET_SALAIRES,
});

export const getSalairesSuccess = salaires => ({
  type: GET_SALAIRES_SUCCESS,
  payload: salaires,
});

export const getSalairesFail = error => ({
  type: GET_SALAIRES_FAIL,
  payload: error,
});

export const getSalaire = () => ({
  type: GET_SALAIRE,
});

export const getSalaireSuccess = salaire => ({
  type: GET_SALAIRE_SUCCESS,
  payload: salaire,
});

export const getSalaireFail = error => ({
  type: GET_SALAIRE_FAIL,
  payload: error,
});

export const addNewSalaire = salaire => ({
  type: ADD_NEW_SALAIRE,
  payload: salaire,
});

export const addSalaireSuccess = salaire => ({
  type: ADD_SALAIRE_SUCCESS,
  payload: salaire,
});

export const addSalaireFail = error => ({
  type: ADD_SALAIRE_FAIL,
  payload: error,
  
});

export const updateSalaire = (appr,salaire) => ({
  type: UPDATE_SALAIRE,
  payload: { id: appr, data: salaire },
 
});

export const updateSalaireSuccess = (appr,salaire) => ({
  type: UPDATE_SALAIRE_SUCCESS,
  payload: { id: appr, data: salaire },
});

export const updateSalaireFail = error => ({
  type: UPDATE_SALAIRE_FAIL,
  payload: error,
});

export const deleteSalaire = salaire => ({
  type: DELETE_SALAIRE,
  payload: salaire,
});

export const deleteSalaireSuccess = salaire => ({
  type: DELETE_SALAIRE_SUCCESS,
  payload: salaire,
});

export const deleteSalaireFail = error => ({
  type: DELETE_SALAIRE_FAIL,
  payload: error,
});



 
