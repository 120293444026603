import { takeEvery, put, call } from "redux-saga/effects"

// Parametres Redux States
import {
  ADD_NEW_PARAMETRE,
  DELETE_PARAMETRE,
  GET_PARAMETRES,
  GET_PARAMETRE,
  UPDATE_PARAMETRE,
} from "./actionTypes"
import {
  getParametresSuccess,
  getParametresFail,
  getParametreSuccess,
  getParametreFail,
  addParametreFail,
  addParametreSuccess,
  updateParametreSuccess,
  updateParametreFail,
  deleteParametreSuccess,
  deleteParametreFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getParametres,
  getParametre,
  addNewParametre,
  updateParametre,
  deleteParametre,
} from "../../helpers/api_back"

function* fetchParametres() {
  try {
    const response = yield call(getParametres)
    yield put(getParametresSuccess(response))
  } catch (error) {
    yield put(getParametresFail(error))
  }
}

function* fetchParametre() {
  try {
    const response = yield call(getParametre)
    yield put(getParametreSuccess(response))
  } catch (error) {
    yield put(getParametreFail(error))
  }
}


function* onAddNewParametre({ payload: parametre }) {
 
  try {
    const response = yield call(addNewParametre, parametre)
    
    yield put(addParametreSuccess(response))
  } catch (error) {
    yield put(addParametreFail(error))
  }
}

function* onUpdateParametre({ payload: parametre }) {
  try {
    const response = yield call(updateParametre, parametre)
    yield put(updateParametreSuccess(response))
  } catch (error) {
    yield put(updateParametreFail(error))
  }
}

function* onDeleteParametre({ payload: parametre }) {
  try {
    const response = yield call(deleteParametre, parametre)
    yield put(deleteParametreSuccess(response))
  } catch (error) {
    yield put(deleteParametreFail(error))
  }
}
 

function* parametresSaga() {
  yield takeEvery(GET_PARAMETRES, fetchParametres)
  yield takeEvery(GET_PARAMETRE, fetchParametre)
  yield takeEvery(ADD_NEW_PARAMETRE, onAddNewParametre)
  yield takeEvery(UPDATE_PARAMETRE, onUpdateParametre)
  yield takeEvery(DELETE_PARAMETRE, onDeleteParametre) 
}

export default parametresSaga
