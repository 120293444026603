import {
  GET_MODEREMBOURSEMENTS,
  GET_MODEREMBOURSEMENTS_SUCCESS,
  GET_MODEREMBOURSEMENTS_FAIL,
  GET_MODEREMBOURSEMENT,
  GET_MODEREMBOURSEMENT_FAIL,
  GET_MODEREMBOURSEMENT_SUCCESS,
  ADD_NEW_MODEREMBOURSEMENT,
  ADD_MODEREMBOURSEMENT_SUCCESS,
  ADD_MODEREMBOURSEMENT_FAIL,
  UPDATE_MODEREMBOURSEMENT,
  UPDATE_MODEREMBOURSEMENT_SUCCESS,
  UPDATE_MODEREMBOURSEMENT_FAIL,
  DELETE_MODEREMBOURSEMENT,
  DELETE_MODEREMBOURSEMENT_SUCCESS,
  DELETE_MODEREMBOURSEMENT_FAIL,
} from "./actionTypes";

export const getModeRemboursements = () => ({
  type: GET_MODEREMBOURSEMENTS,
});

export const getModeRemboursementsSuccess = modeRemboursements => ({
  type: GET_MODEREMBOURSEMENTS_SUCCESS,
  payload: modeRemboursements,
});

export const getModeRemboursementsFail = error => ({
  type: GET_MODEREMBOURSEMENTS_FAIL,
  payload: error,
});

export const getModeRemboursement = () => ({
  type: GET_MODEREMBOURSEMENT,
});

export const getModeRemboursementSuccess = modeRemboursement => ({
  type: GET_MODEREMBOURSEMENT_SUCCESS,
  payload: modeRemboursement,
});

export const getModeRemboursementFail = error => ({
  type: GET_MODEREMBOURSEMENT_FAIL,
  payload: error,
});

export const addNewModeRemboursement = modeRemboursement => ({
  type: ADD_NEW_MODEREMBOURSEMENT,
  payload: modeRemboursement,
});

export const addModeRemboursementSuccess = modeRemboursement => ({
  type: ADD_MODEREMBOURSEMENT_SUCCESS,
  payload: modeRemboursement,
});

export const addModeRemboursementFail = error => ({
  type: ADD_MODEREMBOURSEMENT_FAIL,
  payload: error,
  
});

export const updateModeRemboursement = (mod,modeRemboursement) => ({
  type: UPDATE_MODEREMBOURSEMENT,
  payload:  { id: mod, data: modeRemboursement },
});

export const updateModeRemboursementSuccess = modeRemboursement => ({
  type: UPDATE_MODEREMBOURSEMENT_SUCCESS,
  payload: modeRemboursement,
});

export const updateModeRemboursementFail = error => ({
  type: UPDATE_MODEREMBOURSEMENT_FAIL,
  payload: error,
});

export const deleteModeRemboursement = modeRemboursement => ({
  type: DELETE_MODEREMBOURSEMENT,
  payload: modeRemboursement,
});

export const deleteModeRemboursementSuccess = modeRemboursement => ({
  type: DELETE_MODEREMBOURSEMENT_SUCCESS,
  payload: modeRemboursement,
});

export const deleteModeRemboursementFail = error => ({
  type: DELETE_MODEREMBOURSEMENT_FAIL,
  payload: error,
});



 
