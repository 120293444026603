import {
  GET_PRIXX_SUCCESS,
  GET_PRIXX_FAIL,
  GET_PRIX_SUCCESS,
  GET_PRIX_FAIL,
  ADD_PRIX_SUCCESS,
  ADD_PRIX_FAIL,
  UPDATE_PRIX_SUCCESS,
  UPDATE_PRIX_FAIL,
  DELETE_PRIX_SUCCESS,
  DELETE_PRIX_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  prixx: [],
  error: {},
};

const Prixx = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRIXX_SUCCESS:
      return {
        ...state,
        prixx: action.payload,
      };

    case GET_PRIXX_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PRIX_SUCCESS:
      return {
        ...state,
        prix: action.payload,
      };
  
    case GET_PRIX_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_PRIX_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        prixx: [...state.prixx, action.payload],
      };

    case ADD_PRIX_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PRIX_SUCCESS:
      return {
        ...state,
        prixx: state.prixx.map(prix =>
          prix.id.toString() === action.payload.id.toString()
            ? { prix, ...action.payload }
            : prix
        ),
        isPrixUpdated: true
      };

    case UPDATE_PRIX_FAIL:
      return {
        ...state,
        error: action.payload,
        isPrixUpdated: false
      };

    case DELETE_PRIX_SUCCESS:
      return {
        ...state,
        prixx: state.prixx.filter(
          prix => prix.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PRIX_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Prixx;
