import {
  GET_PRIXX,
  GET_PRIXX_SUCCESS,
  GET_PRIXX_FAIL,
  GET_PRIX,
  GET_PRIX_FAIL,
  GET_PRIX_SUCCESS,
  ADD_NEW_PRIX,
  ADD_PRIX_SUCCESS,
  ADD_PRIX_FAIL,
  UPDATE_PRIX,
  UPDATE_PRIX_SUCCESS,
  UPDATE_PRIX_FAIL,
  DELETE_PRIX,
  DELETE_PRIX_SUCCESS,
  DELETE_PRIX_FAIL,
} from "./actionTypes";

export const getPrixx = () => ({
  type: GET_PRIXX,
});

export const getPrixxSuccess = prixx => ({
  type: GET_PRIXX_SUCCESS,
  payload: prixx,
});

export const getPrixxFail = error => ({
  type: GET_PRIXX_FAIL,
  payload: error,
});

export const getPrix = () => ({
  type: GET_PRIX,
});

export const getPrixSuccess = prix => ({
  type: GET_PRIX_SUCCESS,
  payload: prix,
});

export const getPrixFail = error => ({
  type: GET_PRIX_FAIL,
  payload: error,
});

export const addNewPrix = prix => ({
  type: ADD_NEW_PRIX,
  payload: prix,
});

export const addPrixSuccess = prix => ({
  type: ADD_PRIX_SUCCESS,
  payload: prix,
});

export const addPrixFail = error => ({
  type: ADD_PRIX_FAIL,
  payload: error,
  
});

export const updatePrix = prix => ({
  type: UPDATE_PRIX,
  payload: prix,
});

export const updatePrixSuccess = prix => ({
  type: UPDATE_PRIX_SUCCESS,
  payload: prix,
});

export const updatePrixFail = error => ({
  type: UPDATE_PRIX_FAIL,
  payload: error,
});

export const deletePrix = prix => ({
  type: DELETE_PRIX,
  payload: prix,
});

export const deletePrixSuccess = prix => ({
  type: DELETE_PRIX_SUCCESS,
  payload: prix,
});

export const deletePrixFail = error => ({
  type: DELETE_PRIX_FAIL,
  payload: error,
});



 
