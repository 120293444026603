import {
  GET_DEMANDES_SUCCESS,
  GET_DEMANDES_FAIL,
  GET_DEMANDE_SUCCESS,
  GET_DEMANDE_FAIL,
  ADD_DEMANDE_SUCCESS,
  ADD_DEMANDE_FAIL,
  UPDATE_DEMANDE_SUCCESS,
  UPDATE_DEMANDE_FAIL,
  DELETE_DEMANDE_SUCCESS,
  DELETE_DEMANDE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  demandes: [],
  error: {},
};

const Demandes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEMANDES_SUCCESS:
      return {
        ...state,
        demandes: action.payload,
      };

    case GET_DEMANDES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_DEMANDE_SUCCESS:
      return {
        ...state,
        demande: action.payload,
      };
  
    case GET_DEMANDE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_DEMANDE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        demandes: [...state.demandes, action.payload],
      };

    case ADD_DEMANDE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_DEMANDE_SUCCESS:
      return {
        ...state,
        demandes: state.demandes.map( demande =>
          demande.id.toString() === action.payload.id.toString()
            ? { demande, ...action.payload }
            : demande
        ),
        isDemandeUpdated: true
      };

    case UPDATE_DEMANDE_FAIL:
      return {
        ...state,
        error: action.payload,
        isDemandeUpdated: false
      };

    case DELETE_DEMANDE_SUCCESS:
      return {
        ...state,
        demandes: state.demandes.filter(
           demande => demande.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_DEMANDE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Demandes;
