import { takeEvery, put, call } from "redux-saga/effects"

// Users Redux States
import {
  ADD_NEW_USER,
  DELETE_USER,
  GET_USERS,
  GET_USER,
  UPDATE_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserSuccess,
  getUserFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  getUser,
  addNewUser,
  updateUser,
  deleteUser,
} from "../../helpers/api_back"

function* fetchUsers() {
 
  try {
    ///yield put({type:GET_USERS})
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
    
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUser() {
  try {
    const response = yield call(getUser)
    yield put(getUserSuccess(response))
  } catch (error) {
    yield put(getUserFail(error))
  }
}


function* onAddNewUser({ payload: user}) {
 
  try {
    const response = yield call(addNewUser, user)
    
    yield put(addUserSuccess(response))
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
 

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser) 
}

export default usersSaga
