import {
  GET_MOUVEMENTSTOCKS,
  GET_MOUVEMENTSTOCKS_SUCCESS,
  GET_MOUVEMENTSTOCKS_FAIL,
  GET_MOUVEMENTSTOCK_SUCCESS,
  GET_MOUVEMENTSTOCK_FAIL,
  ADD_MOUVEMENTSTOCK_SUCCESS,
  ADD_MOUVEMENTSTOCK_FAIL,
  UPDATE_MOUVEMENTSTOCK_SUCCESS,
  UPDATE_MOUVEMENTSTOCK_FAIL,
  DELETE_MOUVEMENTSTOCK_SUCCESS,
  DELETE_MOUVEMENTSTOCK_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  mouvementStocks: [],
  loading: false,
  error: {},
};

const MouvementStocks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MOUVEMENTSTOCKS:
      return {
        ...state, 
        loading: true,
        error: null,
      };

      case GET_MOUVEMENTSTOCKS_SUCCESS:
        return {
          ...state,
          loading: false,
          mouvementStocks: action.payload,
        };

    case GET_MOUVEMENTSTOCKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_MOUVEMENTSTOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        mouvementStocks: action.payload,
      };
  
    case GET_MOUVEMENTSTOCK_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_MOUVEMENTSTOCK_SUCCESS:
    //  console.log('Hello Sagas ok!');
      return {
        ...state,
        mouvementStocks: [...state.mouvementStocks, action.payload],
      };

    case ADD_MOUVEMENTSTOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MOUVEMENTSTOCK_SUCCESS:
      return {
        ...state,
        mouvementStocks: state.mouvementStocks.map(mouvementStock =>
          mouvementStock.id.toString() === action.payload.id.toString()
            ? { mouvementStock, ...action.payload }
            : mouvementStock
        ),
        isPRODUITUpdated: true
      };

    case UPDATE_MOUVEMENTSTOCK_FAIL:
      return {
        ...state,
        error: action.payload,
        isProduitUpdated: false
      };

    case DELETE_MOUVEMENTSTOCK_SUCCESS:
      return {
        ...state,
        mouvementStocks: state.mouvementStocks.filter(
          mouvementStock => mouvementStock.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_MOUVEMENTSTOCK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default MouvementStocks;
