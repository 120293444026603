import {
  GET_EQUIPES_SUCCESS,
  GET_EQUIPES_FAIL,
  GET_EQUIPE_SUCCESS,
  GET_EQUIPE_FAIL,
  ADD_EQUIPE_SUCCESS,
  ADD_EQUIPE_FAIL,
  UPDATE_EQUIPE_SUCCESS,
  UPDATE_EQUIPE_FAIL,
  DELETE_EQUIPE_SUCCESS,
  DELETE_EQUIPE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  equipes: [],
 /// loading: false,
  error: {},
};

const Equipes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EQUIPES_SUCCESS:
      return {
        ...state, 
       equipes: action.payload,
      };

    case GET_EQUIPES_FAIL:
      return {
        ...state,
      //  loading: false,
        error: action.payload,
      };

    case GET_EQUIPE_SUCCESS:
      return {
        ...state,
        equipe: action.payload,
      };
  
    case GET_EQUIPE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_EQUIPE_SUCCESS:
    //  console.log('Hello Sagas ok!');
      return {
        ...state,
        equipes: [...state.equipes, action.payload],
      };

    case ADD_EQUIPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EQUIPE_SUCCESS:
      return {
        ...state,
        equipes: state.equipes.map(equipe =>
          equipe.id.toString() === action.payload.id.toString()
            ? { equipe, ...action.payload }
            : equipe
        ),
        isEquipeUpdated: true
      };

    case UPDATE_EQUIPE_FAIL:
      return {
        ...state,
        error: action.payload,
        isEquipeUpdated: false
      };

    case DELETE_EQUIPE_SUCCESS:
      return {
        ...state,
        equipes: state.equipes.filter(
          equipe => equipe.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_EQUIPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Equipes;
