import {
  GET_PARAMETRES_SUCCESS,
  GET_PARAMETRES_FAIL,
  GET_PARAMETRE_SUCCESS,
  GET_PARAMETRE_FAIL,
  ADD_PARAMETRE_SUCCESS,
  ADD_PARAMETRE_FAIL,
  UPDATE_PARAMETRE_SUCCESS,
  UPDATE_PARAMETRE_FAIL,
  DELETE_PARAMETRE_SUCCESS,
  DELETE_PARAMETRE_FAIL, 
} from "./actionTypes";

const INIT_STATE = {
  parametres: [],
  error: {},
};

const Parametres = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PARAMETRES_SUCCESS:
      return {
        ...state,
        parametres: action.payload,
      };

    case GET_PARAMETRES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PARAMETRE_SUCCESS:
      return {
        ...state,
        parametre: action.payload,
      };
  
    case GET_PARAMETRE_FAIL:
       return {
        ...state,
        error: action.payload,
       }; 

    case ADD_PARAMETRE_SUCCESS:
      console.log('Hello Sagas ok!');
      return {
        ...state,
        parametres: [...state.parametres, action.payload],
      };

    case ADD_PARAMETRE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_PARAMETRE_SUCCESS:
      return {
        ...state,
        parametres: state.parametres.map(parametre =>
          parametre.id.toString() === action.payload.id.toString()
            ? { parametre, ...action.payload }
            : parametre
        ),
        isParametreUpdated: true
      };

    case UPDATE_PARAMETRE_FAIL:
      return {
        ...state,
        error: action.payload,
        isParametreUpdated: false
      };

    case DELETE_PARAMETRE_SUCCESS:
      return {
        ...state,
        parametres: state.parametres.filter(
          parametre => parametre.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PARAMETRE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    
    default:
      return state;
  }
};

export default Parametres;
