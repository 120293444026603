import { takeEvery, put, call } from "redux-saga/effects"

// ModeRemboursements Redux States
import {
  ADD_NEW_MODEREMBOURSEMENT,
  DELETE_MODEREMBOURSEMENT,
  GET_MODEREMBOURSEMENTS,
  GET_MODEREMBOURSEMENT,
  UPDATE_MODEREMBOURSEMENT,
} from "./actionTypes"
import {
  getModeRemboursementsSuccess,
  getModeRemboursementsFail,
  getModeRemboursementSuccess,
  getModeRemboursementFail,
  addModeRemboursementFail,
  addModeRemboursementSuccess,
  updateModeRemboursementSuccess,
  updateModeRemboursementFail,
  deleteModeRemboursementSuccess,
  deleteModeRemboursementFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getModeRemboursements,
  getModeRemboursement,
  addNewModeRemboursement,
  updateModeRemboursement,
  deleteModeRemboursement,
} from "../../helpers/api_back"

function* fetchModeRemboursements() {
  try {
    const response = yield call(getModeRemboursements)
    yield put(getModeRemboursementsSuccess(response))
  } catch (error) {
    yield put(getModeRemboursementsFail(error))
  }
}

function* fetchModeRemboursement() {
  try {
    const response = yield call(getModeRemboursement)
    yield put(getModeRemboursementSuccess(response))
  } catch (error) {
    yield put(getModeRemboursementFail(error))
  }
}


function* onAddNewModeRemboursement({ payload: modeRemboursement }) {
 
  try {
    const response = yield call(addNewModeRemboursement, modeRemboursement)
    
    yield put(addModeRemboursementSuccess(response))
  } catch (error) {
    yield put(addModeRemboursementFail(error))
  }
}

function* onUpdateModeRemboursement({ payload: modeRemboursement }) {
  try {
    const response = yield call(updateModeRemboursement, modeRemboursement)
    yield put(updateModeRemboursementSuccess(response))
  } catch (error) {
    yield put(updateModeRemboursementFail(error))
  }
}

function* onDeleteModeRemboursement({ payload: modeRemboursement }) {
  try {
    const response = yield call(deleteModeRemboursement, modeRemboursement)
    yield put(deleteModeRemboursementSuccess(response))
  } catch (error) {
    yield put(deleteModeRemboursementFail(error))
  }
}
 

function* ModeRemboursementsSaga() {
  yield takeEvery(GET_MODEREMBOURSEMENTS, fetchModeRemboursements)
  yield takeEvery(GET_MODEREMBOURSEMENT, fetchModeRemboursement)
  yield takeEvery(ADD_NEW_MODEREMBOURSEMENT, onAddNewModeRemboursement)
  yield takeEvery(UPDATE_MODEREMBOURSEMENT, onUpdateModeRemboursement)
  yield takeEvery(DELETE_MODEREMBOURSEMENT, onDeleteModeRemboursement) 
}

export default ModeRemboursementsSaga
