import { takeEvery, put, call } from "redux-saga/effects"

// Agents Redux States
import {
  ADD_NEW_AGENT,
  DELETE_AGENT,
  GET_AGENTS,
  GET_AGENT,
  UPDATE_AGENT,
} from "./actionTypes"
import {
  getAgentsSuccess,
  getAgentsFail,
  getAgentSuccess,
  getAgentFail,
  addAgentFail,
  addAgentSuccess,
  updateAgentSuccess,
  updateAgentFail,
  deleteAgentSuccess,
  deleteAgentFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAgents,
  getAgent,
  addNewAgent,
  updateAgent,
  deleteAgent,
} from "../../helpers/api_back"

function* fetchAgents() {
  try {
    const response = yield call(getAgents)
    yield put(getAgentsSuccess(response))
  } catch (error) {
    yield put(getAgentsFail(error))
  }
}

function* fetchAgent() {
  try {
    const response = yield call(getAgent)
    yield put(getAgentSuccess(response))
  } catch (error) {
    yield put(getAgentFail(error))
  }
}


function* onAddNewAgent({ payload: agent }) {
 
  try {
    const response = yield call(addNewAgent, agent)
    
    yield put(addAgentSuccess(response))
  } catch (error) {
    yield put(addAgentFail(error))
  }
}

function* onUpdateAgent({ payload: agent }) {
  try {
    const response = yield call(updateAgent, agent)
    yield put(updateAgentSuccess(response))
  } catch (error) {
    yield put(updateAgentFail(error))
  }
}

function* onDeleteAgent({ payload: agent }) {
  try {
    const response = yield call(deleteAgent, agent)
    yield put(deleteAgentSuccess(response))
  } catch (error) {
    yield put(deleteAgentFail(error))
  }
}
 

function* agentsSaga() {
  yield takeEvery(GET_AGENTS, fetchAgents)
  yield takeEvery(GET_AGENT, fetchAgent)
  yield takeEvery(ADD_NEW_AGENT, onAddNewAgent)
  yield takeEvery(UPDATE_AGENT, onUpdateAgent)
  yield takeEvery(DELETE_AGENT, onDeleteAgent) 
}

export default agentsSaga
