import {
  GET_TYPEDEMANDES,
  GET_TYPEDEMANDES_SUCCESS,
  GET_TYPEDEMANDES_FAIL,
  GET_TYPEDEMANDE,
  GET_TYPEDEMANDE_FAIL,
  GET_TYPEDEMANDE_SUCCESS,
  ADD_NEW_TYPEDEMANDE,
  ADD_TYPEDEMANDE_SUCCESS,
  ADD_TYPEDEMANDE_FAIL,
  UPDATE_TYPEDEMANDE,
  UPDATE_TYPEDEMANDE_SUCCESS,
  UPDATE_TYPEDEMANDE_FAIL,
  DELETE_TYPEDEMANDE,
  DELETE_TYPEDEMANDE_SUCCESS,
  DELETE_TYPEDEMANDE_FAIL,
} from "./actionTypes";

export const getTypeDemandes = () => ({
  type: GET_TYPEDEMANDES,
});

export const getTypeDemandesSuccess = typeDemandes => ({
  type: GET_TYPEDEMANDES_SUCCESS,
  payload: typeDemandes,
});

export const getTypeDemandesFail = error => ({
  type: GET_TYPEDEMANDES_FAIL,
  payload: error,
});

export const getTypeDemande = () => ({
  type: GET_TYPEDEMANDE,
});

export const getTypeDemandeSuccess = typeDemande => ({
  type: GET_TYPEDEMANDE_SUCCESS,
  payload: typeDemande,
});

export const getTypeDemandeFail = error => ({
  type: GET_TYPEDEMANDE_FAIL,
  payload: error,
});

export const addNewTypeDemande = typeDemande => ({
  type: ADD_NEW_TYPEDEMANDE,
  payload: typeDemande,
});

export const addTypeDemandeSuccess = typeDemande => ({
  type: ADD_TYPEDEMANDE_SUCCESS,
  payload: typeDemande,
});

export const addTypeDemandeFail = error => ({
  type: ADD_TYPEDEMANDE_FAIL,
  payload: error,
  
});

export const updateTypeDemande = (typD,typeDemande) => ({
  type: UPDATE_TYPEDEMANDE,
  payload:  { id: typD, data: typeDemande },
});

export const updateTypeDemandeSuccess = typeDemande => ({
  type: UPDATE_TYPEDEMANDE_SUCCESS,
  payload: typeDemande,
});

export const updateTypeDemandeFail = error => ({
  type: UPDATE_TYPEDEMANDE_FAIL,
  payload: error,
});

export const deleteTypeDemande = typeDemande => ({
  type: DELETE_TYPEDEMANDE,
  payload: typeDemande,
});

export const deleteTypeDemandeSuccess = typeDemande => ({
  type: DELETE_TYPEDEMANDE_SUCCESS,
  payload: typeDemande,
});

export const deleteTypeDemandeFail = error => ({
  type: DELETE_TYPEDEMANDE_FAIL,
  payload: error,
});



 
