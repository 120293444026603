import { 
  GET_PRODUITS,
  GET_PRODUITS_SUCCESS,
  GET_PRODUITS_FAIL,
  GET_PRODUIT,
  GET_PRODUIT_FAIL,
  GET_PRODUIT_SUCCESS,
  ADD_NEW_PRODUIT,
  ADD_PRODUIT_SUCCESS,
  ADD_PRODUIT_FAIL,
  UPDATE_PRODUIT,
  UPDATE_PRODUIT_SUCCESS,
  UPDATE_PRODUIT_FAIL,
  DELETE_PRODUIT,
  DELETE_PRODUIT_SUCCESS,
  DELETE_PRODUIT_FAIL,
} from "./actionTypes";

export const getProduits = () => ({
  type: GET_PRODUITS,
});

export const getProduitsSuccess = produits => ({
  type: GET_PRODUITS_SUCCESS,
  payload: produits,
});

export const getProduitsFail = error => ({
  type: GET_PRODUITS_FAIL,
  payload: error,
});

export const getProduit = () => ({
  type: GET_PRODUIT,
});

export const getProduitSuccess = produit => ({
  type: GET_PRODUIT_SUCCESS,
  payload: produit,
});

export const getProduitFail = error => ({
  type: GET_PRODUIT_FAIL,
  payload: error,
});

export const addNewProduit = produit => ({
  type: ADD_NEW_PRODUIT,
  payload: produit,
});

export const addProduitSuccess = produit => ({
  type: ADD_PRODUIT_SUCCESS,
  payload: produit,
});

export const addProduitFail = error => ({
  type: ADD_PRODUIT_FAIL,
  payload: error,
  
});

export const updateProduit = (prod,produit) => ({
  type: UPDATE_PRODUIT,
  payload: { id: prod, data: produit },
});

export const updateProduitSuccess = (prod, produit) => ({
  type: UPDATE_PRODUIT_SUCCESS,
  payload: { id: prod, data: produit },
});

export const updateProduitFail = error => ({
  type: UPDATE_PRODUIT_FAIL,
  payload: error,
});

export const deleteProduit = produit => ({
  type: DELETE_PRODUIT,
  payload: produit,
});

export const deleteProduitSuccess = produit => ({
  type: DELETE_PRODUIT_SUCCESS,
  payload: produit,
});

export const deleteProduitFail = error => ({
  type: DELETE_PRODUIT_FAIL,
  payload: error,
});



 
